import trim from "lodash/trim";
import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { Grid, Dialog, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TransitionProps } from "@mui/material/transitions";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import { Moment } from "moment";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { LinkButton, NavButton } from "../../Components/Buttons";
import {
  appColors,
  appFonts,
  useAppTheme,
  appDialogTransparentPaperProps,
  appStyles,
} from "../../theme";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useIsFirstRender from "../../lib/hooks/useIsFirstRender";
import { gotoTaskRouteIfNoErrors } from "../../features/task/taskSlice";
import { updatePrefIdentityVerified } from "../../features/preferences/prefSlice";
import CustomTextField from "../../Components/CustomTextField";
import { GetPatientIDByECKioskAPI } from "../../api/GetPatientIDByECKioskAPI";
import { filterZipInput } from "../../utility/inputFilterFunctions";
import { KioskContact } from "../../features/kiosk/kioskContact";
import { clearPatientLoadedApplicationData, loadAndInitApplicationData } from "../../appInit";
import Header from "../../Components/Header";
import DOBForm from "../forms/verificationForms/DOBForm";
import { ReactComponent as KioskHeaderSvg } from "../../assets/kiosk_header.svg";
import { TermsOfUseUrl, PrivacyUrl } from "../../data/docData";
import { AppButton } from "../../Components/Buttons";
import { updateUIErrorPopupContext } from "../../features/ui/uiSlice";
import { appInactivityTimer } from "../../appInactivityTimer";
import { formatDate } from "../../utility/utilityFunctions";
import { Aem } from "../../lib/aem/components/Aem";
import { tracker } from "../../appTracker";
import { AccAutoFillTokens } from "../../data/accessibility/AccessibilityData";
import "react-pdf/dist/esm/Page/TextLayer.css";




import workerContent from "../../../src/pdf.worker.min.json";

var workerBlob = new Blob([workerContent],{type : 'text/javascript'});
var workerBlobURL = URL.createObjectURL(workerBlob);
pdfjs.GlobalWorkerOptions.workerSrc = workerBlobURL


const imageVerticalShift = 64;

const useStyles = makeStyles({
  root: {
    position: "relative",
    color: "#ffffff",
    width: "100%",
    height: "100%",
    fontFamily: appFonts.regular,
  },
  graphicArea: {
    marginBottom: `-${imageVerticalShift}px`,
  },
  graphic: {
    width: "100%",
    height: "100%",
    maxWidth: 487,
    maxHeight: 274,
    textAlign: "center",
  },
  content: {
    marginTop: "75px",
    padding: "0 16px 0 16px",
  },
  contentWidth: {
    maxWidth: "320px",
  },
  label1: {
    fontFamily: appFonts.bold,
    fontSize: "32px",
    textAlign: "center",
    display: "flex",
    lineHeight: "48px",
    marginBottom: "16px",
  },
  label2: {
    fontFamily: appFonts.bold,
    fontSize: "18px",
    textAlign: "center",
    display: "flex",
    lineHeight: "28px",
    marginBottom: "16px",
  },
  label3: {
    fontFamily: appFonts.bold,
    fontSize: "20px",
    textAlign: "center",
    display: "flex",
    lineHeight: "28px",
    marginBottom: "16px",
  },
  informationRect: {
    minHeight: "421px",
    background: appColors.darkGray1,
    borderRadius: "20px",
    marginTop: "15px",
    marginBottom: "15px",
    padding: "27px 22px 53px 22px",
  },
  formTextLabel: {
    position: "relative",
    fontSize: "14px",
    fontFamily: appFonts.medium,
    lineHeight: "26px",
    color: appColors.white,
    opacity: 0.7,
  },
  inputLine: {
    position: "relative",
    marginBottom: "16px",
    minHeight: "60px",
    fontFamily: appFonts.medium,
  },
  dobForm: {
    width: "100%",
    height: "100%",
    textAlign: "left",
  },
  formStyle: {
    position: "relative",
    marginBottom: "30px",
  },
  pgFunc: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    padding: "0 47px",
  },
  btnCancel: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    maxWidth: "80px",
    maxHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
    textTransform: "none",
    backgroundColor: "rgba(255,255,255,0.1)",
    border: "1px solid #FFFFFF",
    fontSize: "16px",
  },
  pdfHeader: {
    fontFamily: appFonts.medium,
    fontSize: "22px",
    lineHeight: "40px",
    color: appColors.white,
    paddingTop: "20px",
    paddingBottom: "30px",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  pdfPage: {
    "& .react-pdf__Document": {
      "& .react-pdf__Page": {
        "& .react-pdf__Page__canvas": {
          marginBottom: "15px",
        }
      },
    }
  },
  actionLinks: {
    margin: "8px 0 0 0",
  },
  hdiv: {
    minWidth: "35px",
    fontSize: "16px",
  },
  linkBtn: {
    ...appStyles.clickbable,
    textDecorationColor: "white",
    fontSize: "16px",
  },
  kioskHeader: {
    minHeight: "0px",
    paddingTop: "16px",
    paddingBottom: "0px",
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const KioskPatientScreen: React.FC = () => {
  const theme = useAppTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const width = useWindowWidth();
  const isFirstRender = useIsFirstRender();

  const patientFnameInputId = useId();
  const patientLnameInputId = useId();
  const zipInputId = useId();
  const dialogTitleId = useId();

  const kioskMode: boolean = useAppSelector<boolean>((state) => state.preferences.isKioskMode);
  const webAccessCodeMode: boolean = useAppSelector<boolean>((state) => state.preferences.isWebAccessCodeMode);
  const publicDevice: boolean = useAppSelector<boolean>((state) => state.preferences.isPublicDevice);

  // used to force re-render after aem content gets reloaded when the langudage changes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _aemRefresh: any = useAppSelector((state) => state?.aem?.refresh);

  // Dialog modal 
  const [numPages, setNumPages] = useState<any>(9);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [activePdfName, setActivePdfName] = useState<string>();
  const [activePdfLink, setActivePdfLink] = useState<any>();

  const termsLabel: string = Aem.get("BODY_TERMS_TEXT_4", "Terms of Use");
  const privacyLabel: string = Aem.get("BODY_TERMS_TEXT_5", "Privacy Policy");
  const termsLink: string = TermsOfUseUrl;
  const privacyLink: string = PrivacyUrl;
  
  const [patient_fname, setPatientFname] = useState<string>("");
  const [patient_lname, setPatientLname] = useState<string>("");
  const [dobValue, setDobValue] = useState<Moment | null>(null);
  const [zip, setZip] = useState<string>("");

  const [disableButton, setDisableButton] = useState<boolean>(true);
  const dobFieldError: boolean = false;
  
  const pageRefs = useRef({});

  const onItemClick = ({ pageNumber }) => {
    pageRefs.current[pageNumber].scrollIntoView({ behavior: 'smooth' });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleFindAppointmentsClick = async () => {
    let dobStr = formatDate(dobValue, "YYYY-MM-DD");
    let data: KioskContact = {
      first_name: trim(patient_fname),
      last_name: trim(patient_lname),
      birth_date: trim(dobStr),
      zip: trim(zip),
    };
    let patientInfo: any = await GetPatientIDByECKioskAPI(data).catch(() => null);
    if (!!patientInfo?.patient_id) {
      let patientId: string = patientInfo.patient_id || "";
      await dispatch(updatePrefIdentityVerified(true));
      await loadAndInitApplicationData(patientId, "", kioskMode, webAccessCodeMode, publicDevice, dispatch);
      
      // restart the inactivity timer before moving onto the next screen
      if (appInactivityTimer.started) {
        appInactivityTimer.stop();
      }
      appInactivityTimer.reset();
      appInactivityTimer.start();

      await dispatch(gotoTaskRouteIfNoErrors("terms"));
    } else {
      await dispatch(updatePrefIdentityVerified(false));
      await clearPatientLoadedApplicationData(dispatch);

      setPatientFname("");
      setPatientLname("");
      setDobValue(null);
      setZip("");
      
      tracker.formError([{ field: "kiosk", alert: "patient not found" }]);
      dispatch(updateUIErrorPopupContext({ component: "KioskErrorPopup" }));
    }
  };

  useEffect(() => {
    // stop the inactivity timer on load of this screen
    // remember to re-start the timer when we leave this screen
    if (isFirstRender) {
      appInactivityTimer.stop();
      appInactivityTimer.reset();
    }
  }, [isFirstRender]);
  
  useEffect(() => {
    if (!!(patient_fname && patient_lname && dobValue && zip)) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [patient_fname, patient_lname, dobValue, zip]);

  // watch for form errors and send them to the analytics tracker
  useEffect(() => {
    tracker.watchFormErrors([
      { field: "dob", alert: "dob invalid", value: dobFieldError },
    ]);
  }, [dobFieldError]);

  return (
    <Grid container direction="column" className={clsx(classes.root, theme.navContent)}>
      <Grid container direction="column" className={clsx(theme.navHeader, theme.bgOrangeGrad)}>
        <Header center={false} showMenuButton={false} showSelectLanguage={true}
          className={classes.kioskHeader} />
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={theme.textCenter}
        >
          <Grid item className={classes.graphicArea}>
            <KioskHeaderSvg className={classes.graphic} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        className={clsx(theme.textCenter, classes.content)}
      >
        <Grid item className={classes.label1}>
          <div className={classes.contentWidth}>
            <Aem cid="BODY_KIOSK_TEXT_1">
              Hello!
            </Aem>
          </div>
        </Grid>
        <Grid item className={classes.label2}>
          <div className={classes.contentWidth}>
            <Aem cid="BODY_KIOSK_TEXT_3">
              Welcome to the patient check-in, to get started please enter the patient's information.
            </Aem>
          </div>
        </Grid>
        <Grid item >
          <Grid container
            direction="column"
            alignItems="center"
            className={classes.informationRect}>
            <Grid item className={classes.label3}>
              <Aem cid="BODY_KIOSK_TEXT_2">
                Enter the patient's information
              </Aem>
            </Grid>
            <Grid item>
              <form className={classes.formStyle} autoComplete="false" autoSave="false">
                <CustomTextField
                  id={patientFnameInputId}
                  className={classes.inputLine}
                  label={
                    <Aem cid="BODY_DEMOGRAPHICS_PATIENT_NAME_TEXT_1">
                      First Name
                    </Aem>
                  }
                  autoComplete={AccAutoFillTokens.off}
                  required={true}
                  name="patientFname"
                  fullWidth={true}
                  variant="filled"
                  type="text"
                  value={patient_fname}
                  inputProps={{
                    maxLength: 30,
                  }}
                  onChange={(e) => {
                    e.stopPropagation();
                    setPatientFname(e.target.value);
                  }}
                />
                <CustomTextField
                  id={patientLnameInputId}
                  className={classes.inputLine}
                  label={
                    <Aem cid="BODY_DEMOGRAPHICS_PATIENT_NAME_TEXT_2">
                      Last Name
                    </Aem>
                  }
                  autoComplete={AccAutoFillTokens.off}
                  required={true}
                  name="patientLname"
                  fullWidth={true}
                  variant="filled"
                  type="text"
                  value={patient_lname}
                  inputProps={{
                    maxLength: 30,
                  }}
                  onChange={(e) => {
                    setPatientLname(e.target.value);
                  }}
                />
                <Grid item className={classes.dobForm}>
                  <DOBForm
                    date={dobValue}
                    aemIdDateOfBirth="BODY_DEMOGRAPHICS_PATIENT_REPRESENTATIVE_TEXT_3"
                    aemIdDateError="BODY_DOB_VERIFY_ERROR_1"            
                    onDateValueChange={(v) => setDobValue(v)}
                    required={true}
                    error={dobFieldError}
                  />
                </Grid>
                <CustomTextField
                  id={zipInputId}
                  className={classes.inputLine}
                  label={
                    <Aem cid="BODY_DEMOGRAPHICS_PATIENT_ADDRESS_TEXT_6">
                      Zip Code
                    </Aem>
                  }
                  autoComplete={AccAutoFillTokens.off}
                  required={true}
                  name="zip"
                  variant="filled"                  
                  value={zip}
                  fullWidth={true}
                  inputProps={{
                    maxLength: 5,
                  }}
                  onChange={(e) => {
                    setZip(filterZipInput(e.target.value));
                  }}
                />
              </form>
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.pgFunc}>
              <Grid item className={theme.fillx}>
                <NavButton
                  accentColor={"orange"}
                  disabled={disableButton}
                  label={<Aem cid="ACTION_FINDAPPTSBUTTON_TEXT_1">Find appointments</Aem>}
                  fullWidth={true}
                  trackName="find appointments"
                  trackLocation="body"
                  onClick={handleFindAppointmentsClick} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={clsx(classes.actionLinks, theme.textCenter)}
      >
        <Grid item className={theme.textCenter}> 
          <LinkButton className={classes.linkBtn}
            onClick={() => {
              setActivePdfName(termsLabel);
              setActivePdfLink(termsLink);
              setOpenDialog(true);
            }} 
            trackName="terms"
            trackLocation="overlay"
          >
            {termsLabel}
          </LinkButton>
        </Grid>
        <Grid item className={clsx(classes.hdiv, theme.textCenter)}> 
          &nbsp;|&nbsp;
        </Grid>
        <Grid item className={theme.textCenter}> 
          <LinkButton className={classes.linkBtn} 
            onClick={() => {
              setActivePdfName(privacyLabel);
              setActivePdfLink(privacyLink);
              setOpenDialog(true);
            }} 
            trackName="privacy"
            trackLocation="overlay"
          >
            {privacyLabel}
          </LinkButton>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={true}
        disableRestoreFocus={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        TransitionComponent={Transition}
        aria-labelledby={dialogTitleId}
        PaperProps={appDialogTransparentPaperProps}
      >
        <Grid
          style={{
            height: "100%",
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.pdfHeader}
          >
            <Grid
              item
              id={dialogTitleId}
              className={theme.headerTitle}
            >
              {activePdfName}
            </Grid>
            <Grid item style={{ display: "flex" }}>
              <AppButton
                variant="outlined"
                disableElevation
                className={classes.btnCancel}
                trackName="cancel"
                trackLocation="overlay"
                onClick={() => setOpenDialog(false)}
              >
                <Aem cid="ACTION_CLOSEBUTTON_TEXT_1">Close</Aem>
              </AppButton>
            </Grid>
          </Grid>
          {/* pdf content */}
          <Grid className={classes.pdfPage}
            container
            direction="column"
            style={{ alignContent: "center", paddingBottom: "20px" }}
          >
            {activePdfLink !== undefined
              && (
                <Document
                  file={activePdfLink}
                  onLoadSuccess={onDocumentLoadSuccess}
                  externalLinkTarget="_blank"
                  onItemClick={onItemClick}
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map(page => (
                      <div key={page} ref={el => { pageRefs.current[page] = el; }}>
                        <Page
                          renderTextLayer={false}
                          pageNumber={page}
                          width={width?? 300}
                          loading=""
                        />
                      </div>
                    ))}
                </Document>
              )}
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default KioskPatientScreen;
