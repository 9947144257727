import orderBy from "lodash/orderBy";
import { AxiosRequestConfig } from "axios";
import axios from "../interceptors/interceptors";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import { GetCdoLongName } from "../data/CDO";
import config from "../config";


const handleError = (err: any, suppressError: boolean = false) => {
  return HandleApiErrorMiddleware("GetAppointmentAPI", err, suppressError);
};

export const getAppointmentById = async (apptId: string): Promise<any | undefined> => {
  let route: string = "GetAppointmentByID";
  let apiCfg = config.appointmentApi;
  let url: string = apiCfg.url + route;
  if (apiCfg.code) { 
    url += "?code=" + apiCfg.code;
  }

  const body = {
    appointmentId: apptId,
  };
  let req: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(body),
  };

  return axios(req)
    .then((response) => {
      let res = response?.data;
      // check for case where invalid HTTP400 is returned in body instead of headers
      if (!res || res === 400 || res === "400") {
        res = undefined;
      }
      return res;
    })
    .catch((error) => {
      handleError(error, true);
      return undefined;
    });
}; 

export const getAllFutureAppointmentsByPatientIdAPI = async (patientId: string,kioskMode: boolean): Promise<any[] | undefined> => {
  let route: string = "GetFutureApptsByPatient";
  let apiCfg = config.appointmentApi;
  let url: string = apiCfg.url + route;
  if (apiCfg.code) { 
    url += "?code=" + apiCfg.code;
  }
  const defaultMode: string | undefined = kioskMode ? "kiosk" : "";
  const cdoLongName = GetCdoLongName();
  const body = {
    cdoName: cdoLongName,
    patientID: patientId,
    mode: defaultMode,
  };
  let req: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(body),
  };

  return axios(req)
    .then((response) => {
      let res = response?.data;
      if (res) {
        res = sortApiAppointments(res);
      } else {
        res = undefined; 
      }
      return res; 
    })
    .catch((error) => {
      handleError(error, true);
      return undefined;
    });
};

const sortApiAppointments = (apptInfos: any[]): any[] => {
  if (apptInfos && apptInfos.length > 0) {
    apptInfos = orderBy(apptInfos, ["appt_datetime_utc", "appt_datetime"], ["asc", "asc"]);
  }
  return apptInfos;
};

export const getPatientByWebAccessCodeAPI = async (webAccessCode: string): Promise<any | undefined> => {
  let route: string = "GetPatientByWebAccessCode";
  let apiCfg = config.appointmentApi;
  let url: string = apiCfg.url + route;
  if (apiCfg.code) {
    url += "?code=" + apiCfg.code;
  }

  const body = {
    web_access_code: webAccessCode,
  };
  let req: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(body),
  };

  return axios(req)
    .then((response) => {
      let res = response?.data;
      // check for case where invalid HTTP400 is returned in body instead of headers
      if (!res || res === 400 || res === "400") {
        res = undefined;
      }
      return res;
    })
    .catch((error) => {
      handleError(error, true);
      return undefined;
    });
}; 


export const deletePatientWebAccessCodeAPI = async (patientId: string): Promise<any | undefined> => {
  let route: string = "deletePatientWebAccessCode";
  let apiCfg = config.appointmentApi;
  let url: string = apiCfg.url + route;
  if (apiCfg.code) {
    url += "?code=" + apiCfg.code;
  }

  const body = {
    patientID: patientId,
  };
  let req: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(body),
  };

  return axios(req)
    .then((response) => {
      let res = response?.data;
      // check for case where invalid HTTP400 is returned in body instead of headers
      if (!res || res === 400 || res === "400") {
        res = undefined;
      }
      return res;
    })
    .catch((error) => {
      handleError(error, true);
      return undefined;
    });
}; 
