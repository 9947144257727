import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Grid } from "@mui/material";
import { appColors } from "../theme";

const useStyles = makeStyles({
  content: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  spinner: {
    "&.MuiCircularProgress-colorPrimary": {
      color: appColors.orangeMid,
  	},
  },
  accentWhite: {
    "&.MuiCircularProgress-colorPrimary": {
      color: appColors.white,
    },
  },
  accentOrange: {
    "&.MuiCircularProgress-colorPrimary": {
      color: appColors.orangeMid,
    },
  },
  accentGreen: {
    "&.MuiCircularProgress-colorPrimary": {
      color: appColors.greenMid,
    },
  },
  accentPurple: {
    "&.MuiCircularProgress-colorPrimary": {
      color: appColors.purpleMid,
    },
  },
  accentBlue: {
    "&.MuiCircularProgress-colorPrimary": {
      color: appColors.blueMid,
    },
  },
});


interface LoadingSpinnerProps {
  size?: string | number;
  accentColor?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props: LoadingSpinnerProps) => {
  const classes = useStyles();

  let { size, accentColor } = props;

  let accentColorClass;
  switch (accentColor) { 
    case "white":
      accentColorClass = classes.accentWhite;
      break;
    case "orange":
      accentColorClass = classes.accentOrange;
      break;
    case "green":
      accentColorClass = classes.accentGreen;
      break;
    case "purple":
      accentColorClass = classes.accentPurple;
      break;
    case "blue":
      accentColorClass = classes.accentBlue;
      break;
    default:
      accentColorClass = classes.accentOrange;
      break;
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.content}>
      <Grid item>
        <CircularProgress color="primary" size={size} className={clsx(classes.spinner, accentColorClass)} />
      </Grid>
    </Grid>
  );
};

LoadingSpinner.defaultProps = {
  size: 72,
  accentColor: "orange",
};

export default LoadingSpinner;

