import { 
  IsReturningPatientAppointment, 
  IsValidAppointmentTypeId,
} from "../Fhir";
import { 
  CreateNewDocCounts, 
  DocCounts, 
  PciDocTypes,
 } from "../docData";
 import { 
  CdoLongName,
  CdoShortName,
  CdoSystem,
  CdoType, 
  CdoUid, 
  FindBestMatchingCdoType, 
  GetCdoLongNameByCdoType, 
  GetCdoShortNameByCdoType, 
  GetCdoSystemByCdoType,
  GetCdoUidByCdoType, 
} from "./CdoData";
import {
  CaliDefaultDocAppointmentTypeId, 
  CaliDocTypeToPciDocTypeMap,
  CaliVisitTypeToAppointmentTypeIdMap,
  CaliSupportedPciDocTypes,
  DefaultInvalidVisitTypeAppointmentTypeId,
  DefaultMissingVisitTypeAppointmentTypeId, 
  GetCaliRequiredDocTypesByCdoVisitType, 
  IsCaliDocTypeRequiredByCdoVisitType,
} from "./Cali";
import config from "../../config";

const GetConfigCdoId = (): string => {
  return config.cdo.id || "";
};

const GetConfigCdoSystem = (): CdoSystem => {
  return config.cdo.system || "";
};

let _cachedCdoType: CdoType | undefined = undefined;
export const GetCdoType = (): CdoType => {
  if (_cachedCdoType === undefined) { 
    _cachedCdoType = FindBestMatchingCdoType(GetConfigCdoId());
  }
  return _cachedCdoType as CdoType;
};

export const GetCdoUid = (): CdoUid => {
  const cdoType = GetCdoType();
  return GetCdoUidByCdoType(cdoType);
};

export const GetCdoShortName = (): CdoShortName => {
  const cdoType = GetCdoType();
  return GetCdoShortNameByCdoType(cdoType);
};

export const GetCdoLongName = (): CdoLongName => {
  const cdoType = GetCdoType();
  return GetCdoLongNameByCdoType(cdoType);
};

export const GetCdoSystem = (): CdoSystem => {
  let cdoSystem = GetConfigCdoSystem();
  if (!cdoSystem) {
    const cdoType = GetCdoType();
    cdoSystem = GetCdoSystemByCdoType(cdoType);
  }
  return cdoSystem;
};

export const DoesCdoSupportDocCounts = (): boolean => {
  let cdoType = GetCdoType();
  return (cdoType === CdoType.Cali);
};

export const GetPciDocTypeFromCdoDocType = (cdoDocType: string, defaultValue: string = ""): string => {
  let cdoType = GetCdoType();
  let retval: string = defaultValue || "";
  if (cdoType === CdoType.Cali && cdoDocType && CaliDocTypeToPciDocTypeMap.hasOwnProperty(cdoDocType)) {
    retval = CaliDocTypeToPciDocTypeMap[cdoDocType];
  }
  return retval;
};

export const ParseDocCounts = (input: any[]): DocCounts => {
  // always return a valid object
  let data: any = {};
  if (input) {
    input.forEach((v) => {
      let count: number = parseInt(v?.DocCount, 0);
      let doctype: string = GetPciDocTypeFromCdoDocType(v?.DocType || "");
      if (doctype) {
        data[doctype] = count >= 0 ? count : 0;
      }
    });
  }
  
  return CreateNewDocCounts(data);
};


export const GetVisitTypeFromCdoVisitType = (cdoVisitType: string, defaultValue: string = ""): string => {
  let cdoType = GetCdoType();
  if (cdoType === CdoType.Cali && !defaultValue) { 
    defaultValue = CaliDefaultDocAppointmentTypeId; 
  }

  let visitType: string = defaultValue || "";
  if (cdoType === CdoType.Cali) { 
    if (cdoVisitType && !IsValidAppointmentTypeId(cdoVisitType)) {
      visitType = cdoVisitType;
    }
  }
  return visitType;
};

export const GetAppointmentTypeFromCdoVisitType = (cdoVisitType: string, defaultValue: string = ""): string => {
  let cdoType = GetCdoType();
  let appointmentTypeId: string = defaultValue || "";
  if (IsValidAppointmentTypeId(cdoVisitType)) {
    appointmentTypeId = cdoVisitType; 
  } else if (cdoType === CdoType.Cali) {
    // NOTE: special behavior
    // if the cdoVisitType is valid and non-empty, but missing from the map, assume it is an established patient
    // if the cdoVisitType is invalid, assume it is a new Patient;
    let tmpId = CaliVisitTypeToAppointmentTypeIdMap[cdoVisitType];
    if (!tmpId) { 
      if (cdoVisitType) {
        tmpId = DefaultMissingVisitTypeAppointmentTypeId;
      } else {
        tmpId = DefaultInvalidVisitTypeAppointmentTypeId;  
      }
    }
    appointmentTypeId = tmpId || appointmentTypeId || "";
  } else {
    appointmentTypeId = cdoVisitType || appointmentTypeId || "";
  }
  return appointmentTypeId;
};

export const GetDocAppointmentTypeFromAppointmentType = (appointmentTypeId: string, defaultValue: string = ""): string => {
  let cdoType = GetCdoType();
  let docAppointmentTypeId: string = defaultValue;
  if (cdoType === CdoType.Cali) {
    docAppointmentTypeId = CaliDefaultDocAppointmentTypeId;
  } else {
    docAppointmentTypeId = appointmentTypeId;
  }
  return docAppointmentTypeId;
};


export const IsReturningPatientAppointmentByCdoVisitType = (cdoVisitType: string, appointmentTypeId: string): boolean => {
  let retval: boolean = false;
  let cdoType = GetCdoType();
  if (!appointmentTypeId) { 
    appointmentTypeId = GetAppointmentTypeFromCdoVisitType(cdoVisitType);
  }
  if (cdoType === CdoType.Cali) {
    let tmpAppointmentTypeId: string = appointmentTypeId;
    if (cdoVisitType) {
      tmpAppointmentTypeId = GetAppointmentTypeFromCdoVisitType(cdoVisitType);
      if (!tmpAppointmentTypeId) {
        tmpAppointmentTypeId = appointmentTypeId;
      }
    }
    retval = IsReturningPatientAppointment(tmpAppointmentTypeId);
  } else {
    retval = IsReturningPatientAppointment(appointmentTypeId);
  }
  return retval;
};

export const GetCdoSupportedPciDocTypes = (): PciDocTypes[] => {
  let docTypes: PciDocTypes[] = [];
  let cdoType = GetCdoType();
  if (cdoType === CdoType.Cali) {
    docTypes = CaliSupportedPciDocTypes;
  }
  return docTypes;  
};

export const DoesCdoSupportPciDocType = (docType: PciDocTypes): boolean => {
  let cdoDocTypes = GetCdoSupportedPciDocTypes();
  return !!cdoDocTypes?.includes(docType);
};

export const GetRequiredDocTypesByCdoVisitType = (cdoVisitType: string): string[] => {
  let retval: string[] = [];
  let cdoType = GetCdoType();
  if (cdoType === CdoType.Cali) {
    retval = GetCaliRequiredDocTypesByCdoVisitType(cdoVisitType);
  }
  return retval;
};

export const IsDocTypeRequiredByAnyCdoVisitTypes = (docType: PciDocTypes, cdoVisitTypes: string[]): boolean => {
  let retval: boolean = false;
  if (cdoVisitTypes?.length > 0) {
    retval = cdoVisitTypes.some((cdoVisitType: string) => IsDocTypeRequiredByCdoVisitType(docType, cdoVisitType));
  }
  return retval;
};

export const IsDocTypeRequiredByCdoVisitType = (docType: PciDocTypes, cdoVisitType: string): boolean => {
  let retval: boolean = false;
  let cdoType = GetCdoType();
  if (cdoType === CdoType.Cali) {
    retval = IsCaliDocTypeRequiredByCdoVisitType(docType, cdoVisitType);
  }
  return retval;
};
