import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { ReactComponent as CheckinSvg } from "../assets/illustration_checkin.svg";
import KioskStartOverPrompt from "../Components/Kiosk/KioskStartOverPrompt";
import { appFonts, useAppTheme } from "../theme";
import Header from "../Components/Header";
import { useAppSelector } from "../app/hooks";
import Aem from "../lib/aem/components/Aem";
import { SingleApptState, hasConfirmedPreRegDoneAppointmentWithinTimeWindow } from "../features/appt/apptSlice";
import { AppointmentArrivalTimeRange } from "../data/apptData";

const imageVerticalShift = 45;

const useStyles = makeStyles({
  root: {
    position: "relative",
    color: "#ffffff",
    width: "100%",
    height: "100%",
    fontFamily: appFonts.regular,
  },
  apptText: {
    marginTop: "24px",
    marginBottom: "30px",
    fontFamily: appFonts.bold,
    fontSize: "22px",
    textAlign: "center",
    padding: "0 36px",
  },
  graphicArea: {
    marginBottom: `-${imageVerticalShift}px`,
  },  
  graphic: {
    width: "100%",
    height: "100%",
    maxWidth: 372,
    maxHeight: 188,
    textAlign: "center",
    display: "inherit",
  },
  content: {
    marginTop: "55px", 
    padding: "0 16px 0 16px",
  },
  label1: {
    fontFamily: appFonts.bold,
    fontSize: "22px",
    textAlign: "center",
    marginBottom: "25px",
  },
  label2: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    textAlign: "center",
    marginBottom: "25px",
  },
});

interface PreRegisteredFinishScreenProps {
}

const PreRegisteredFinishScreen: React.FC<PreRegisteredFinishScreenProps> = (props: PreRegisteredFinishScreenProps) => {
  const classes = useStyles();
  const theme = useAppTheme();

  const patientFirstName: string = useAppSelector((state) => state.patient.first_name);
  const patientNameStr = patientFirstName ? " " + patientFirstName : "";

  // only show the start over button when in kiosk or publicDevice mode
  const isKioskOrPublicDeviceMode: boolean = useAppSelector<boolean>((state) => (state.preferences.isKioskMode || state.preferences.isPublicDevice));

  const allAppointmentItems: SingleApptState[] = useAppSelector<SingleApptState[]>((state) => state.appt.allAppointmentInfos);
  const hasApptWithinWindow = hasConfirmedPreRegDoneAppointmentWithinTimeWindow(
    allAppointmentItems, 
    AppointmentArrivalTimeRange.earlyTimeOffsetMins, 
    AppointmentArrivalTimeRange.lateTimeOffsetMins
  );

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column">
        <Grid container direction="column" className={clsx(theme.navHeader, theme.bgOrangeGrad)}>
          <Header center={true} showMenuButton={true} />
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={theme.textCenter}
          >
            <Grid item className={classes.graphicArea}>
              <CheckinSvg className={classes.graphic} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={clsx(theme.textCenter, classes.content)}
        >
          <Grid item className={classes.label1}>
            <Aem cid="BODY_PRECHECKEDINPAGE_TEXT_1">
              You're all set, {patientNameStr}!
            </Aem>
          </Grid>

          <Grid item className={classes.label2}>
            <Aem cid="BODY_PRECHECKEDINPAGE_TEXT_2">
              You have pre-registered for this appointment. 
              Your appointment details have been sent to the phone number on file.
            </Aem>
          </Grid>
        </Grid>

        {/* show the I am here button with no message */}
        {isKioskOrPublicDeviceMode && hasApptWithinWindow && (
          <KioskStartOverPrompt
            showMesssageText={false}
            showYesStartOverButton={false}
            showNoContinueButton={false}
            showIAmHereButton={true}
            checkArrivalStatus={true}
            autoHideAfterActions={true}
            enableSessionTimeoutCheck={true}
            defaultNoContinueRoute={""}
            defaultIAmHereRoute={""}
            arrivedNextRoute={"kioskArrivedFinish"}
          />
        )}

        {/* show the start over button with a message */}
        {isKioskOrPublicDeviceMode && (
          <KioskStartOverPrompt
            showMesssageText={true}
            showYesStartOverButton={true}
            showNoContinueButton={false}
            showIAmHereButton={false}
            checkArrivalStatus={false}
            autoHideAfterActions={false}
            enableSessionTimeoutCheck={true}
            defaultNoContinueRoute={""}
            defaultIAmHereRoute={""}
            arrivedNextRoute={"kioskArrivedFinish"}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PreRegisteredFinishScreen;
