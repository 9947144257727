import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PopupContext {
  component: React.ReactElement<any, any> | string;
  props?: any | undefined | null;
}

export interface UIState {
  menuOpen: boolean;         // true when the header nav menu is open
  messagePopupContext: PopupContext | null;
  errorPopupContext: PopupContext | null;
}

const initialState: UIState = {
  menuOpen: false,
  messagePopupContext: null,
  errorPopupContext: null,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    updateUIMenuOpen(state, action: PayloadAction<boolean>) {
      state.menuOpen = action.payload;
    },
    updateUIMenuOpenToggle(state, _action: PayloadAction<undefined>) {
      state.menuOpen = !state.menuOpen;
    },
    updateUIMessagePopupContext(state, action: PayloadAction<PopupContext | null>) {
      state.messagePopupContext = action.payload || null;
    },
    updateUIErrorPopupContext(state, action: PayloadAction<PopupContext | null>) {
      state.errorPopupContext = action.payload || null;
    },
  },
});

export const {
  updateUIMenuOpen,
  updateUIMenuOpenToggle,
  updateUIMessagePopupContext,
  updateUIErrorPopupContext,
} = uiSlice.actions;

export default uiSlice.reducer;

