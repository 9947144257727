import React, { useState } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { Grid, TextField, Dialog, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TransitionProps } from "@mui/material/transitions";
import SearchIcon from "@mui/icons-material/Search";
import { AppButton, BackButton, NavButton } from "../../../Components/Buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  appColors,
  appDialogTransparentPaperProps,
  appFonts,
  appStyles,
  useAppTheme,
} from "../../../theme";
import Header from "../../../Components/Header";
import ProgressStepper from "../../../Components/ProgressStepper";
import {
  gotoNextTaskStep,
  gotoPrevTaskStep,
  gotoPendingTaskStep,
  setTaskTransitionPending,
  gotoTaskRoute,
} from "../../../features/task/taskSlice";
import CustomTextField from "../../../Components/CustomTextField";
import {
  updateCdoLanguageKey,
  updateCdoLanguageName,
} from "../../../features/patient/patientSlice";
import { AccAutoFillTokens } from "../../../data/accessibility/AccessibilityData";
import { LanguageIsoKvPairs } from '../../../data/languageData';
import { UpdatePatientDemographicsAPI } from "../../../api/UpdateFhirAPI";
import Aem, { AemContentTypes } from "../../../lib/aem/components/Aem";
import { AemKVPair } from "../../../lib/aem";


const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  headerTitle: {
    marginTop: "0px",
  },
  inpBox: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    color: "#152831",
    "& .MuiInputLabel-shrink": {
      fontSize: "14px",
      transform: "translate(0, 1.5px)",
    },
    "& .MuiFormLabel-root": {
      fontFamily: appFonts.semibold,
      letterSpacing: 0,
    },
    marginBottom: "13px",
  },
  nameLabel: {
    minHeight: "22px",
    marginBottom: "5px",
    fontFamily: appFonts.bold,
    fontSize: "18px",
    lineHeight: "22px",
  },
  nameValue: {
    minHeight: "22px",
    marginBottom: "16px",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    lineHeight: "22px",
  },
  formTextLabel: {
    marginRight: "5px",
    fontSize: "14px",
    fontFamily: appFonts.semibold,
    color: "rgb(255,255,255,0.7)",
  },
  inputContainer: {
    position: "relative",
    width: "100%",
    fontSize: "18px",
    color: appColors.white,
    fontFamily: appFonts.medium,
    marginTop: "16px",
    marginBottom: "24px",
  },
  masked: {
    position: "relative",
    width: "100%",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    backgroundColor: "transparent",
    color: appColors.white,
    outline: "none",
    boxSizing: "border-box",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    textTransform: "uppercase",
    "&:hover": {
      borderColor: "rgba(255,255,255,0.7)",
    },
  },
  overlayScrim: {
    position: "fixed",
    height: "100%",
    backgroundColor: appColors.blackScrim,
    overflow: "auto",
  },
  overlayHeader: {
    position: "relative",
    marginTop: "30px",
    marginBottom: "5px",
  },
  choicesBox: {
    maxHheight: "100%",
    paddingLeft: "60px",
    paddingBottom: "20px",
  },
  choicesRow: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "49px",
    padding: "5px 16px",
    color: appColors.white,
    fontFamily: appFonts.bold,
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.3)",
    },
  },
  searchIcon: {
    width: "22px",
    height: "22px",
    margin: "10px 22px 0 22px",
    color: appColors.white,
  },
  cancelButtonContainer: {
    margin: "0 15px",
    justifyContent: "center",
  },
  cancelButton: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    minWidth: "80px",
    minHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
  },
  searchInputArea: {
    width: "100%",
  },
  searchInputTextField: {
    width: "100%",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    color: appColors.white,
  },
  searchInputInput: {
    color: appColors.white,
    ...appStyles.inputUnderlineBorder,

    "&$root": {
      color: appStyles.white,
    },
    "&.MuiInputBase-root": {
      color: appStyles.white,
    },
  },
  date: {
    position: "relative",
    width: "100%",
    outline: "none",
    boxSizing: "border-box",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",

    "& .MuiInputBase-colorPrimary ": {
      color: appColors.white,
      fontSize: "18px",
      fontFamily: appFonts.medium,
      "&.MuiInputBase-input": {
        textTransform: "uppercase",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(255,255,255,0.7)",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(255,255,255,0.4)",
    },
  },
  pgFunc: {
    justifyContent: "space-between",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  headerText: {
    marginTop: "24px",
    marginBottom: "20px",
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReviewPatientNameScreen() {
  const theme = useAppTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const searchInputId = useId();
  const langInputId = "language-input-" + useId();

  const patient_fname = useAppSelector((state) => state.patient.first_name);
  const patient_lname = useAppSelector((state) => state.patient.last_name);
  const dob = useAppSelector((state) => state.patient.dob);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disableButton, setDisableButton] = React.useState(false);

  const ariaLanguageLabel: string = Aem.get(
    "ACCESSIBILITY_LANGUAGE_TEXT_1",
    "Select Language"
  );
  const ariaSearchLabel: string = Aem.get(
    "ACCESSIBILITY_SEARCH_TEXT_1",
    "Enter Search"
  );

  // load the aem lists during init
  const aemLanguageOptions: any[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_NAME_PRI_LANG_CODES",
    "BODY_DEMOGRAPHICS_PATIENT_NAME_PRI_LANG_LIST",
    LanguageIsoKvPairs,
  );

  const appCdoLanguageKey: string = useAppSelector((state) => state.patient.cdoLanguageKey)
  const appCdoLanguageName: string = useAppSelector((state) => state.patient.cdoLanguageName)

  const [cdoLanguageKey, setCdoLanguageKey] = useState<string>(appCdoLanguageKey);

  const defaultCdoLanguageName = Aem.getListKVPairValue(aemLanguageOptions, cdoLanguageKey) || appCdoLanguageName;
  const [cdoLanguageName, setCdoLanguageName] = useState<string>(defaultCdoLanguageName);

  const [overlay, setOverlay] = useState<boolean>(false);

  const [searchLanguage, setSearchLanguage] = useState<string>("");

  const fullScreen: boolean = true;


  const handleClick = (option: AemKVPair) => {
    let key = option?.Key || "";
    let val = option?.Value || "";
    setCdoLanguageKey(key);
    setCdoLanguageName(val);
  };

  const filterOptions = (list: AemKVPair[]): AemKVPair[] => {
    if (!searchLanguage || searchLanguage.length === 0) {
      return list;
    }
    const searchTextLowered: string = searchLanguage.toLowerCase();
    return (list || []).filter((option) => {
      return (option?.Value || "").toLowerCase().includes(searchTextLowered);
    });
  };

  const renderLanguageOverlay = () => {
    let filteredOptions = filterOptions(aemLanguageOptions);
    return (
      <Grid
        container
        direction="column"
        className={clsx(classes.overlayScrim, theme.nowrap)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={clsx(classes.overlayHeader, theme.nowrap)}
        >
          <Grid item>
            <SearchIcon className={classes.searchIcon} />
          </Grid>
          <Grid item className={classes.searchInputArea}>
            <TextField
              id={searchInputId}
              variant="standard"
              className={classes.searchInputTextField}
              inputProps={{
                "aria-label": ariaSearchLabel,
              }}
              InputProps={{
                classes: {
                  underline: classes.searchInputInput,
                },
              }}
              value={searchLanguage}
              autoComplete={AccAutoFillTokens.off}
              onChange={(e) => {
                setSearchLanguage(e.target.value);
              }}
            />
          </Grid>
          <Grid item className={classes.cancelButtonContainer}>
            <AppButton
              variant="outlined"
              disableElevation
              className={classes.cancelButton}
              trackName="cancel"
              trackLocation="overlay"
              onClick={() => {
                setOverlay(!overlay);
                setSearchLanguage("");
              }}
            >
              <Aem cid="ACTION_CANCELBUTTON_TEXT_1">Cancel</Aem>
            </AppButton>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          className={clsx(classes.choicesBox, theme.nowrap)}
        >
          {filteredOptions.map((option: AemKVPair, idx: number) => {
            return (
              <Grid
                key={idx}
                item
                xs={12}
                className={classes.choicesRow}
                onClick={() => {
                  setOverlay(!overlay);
                  handleClick(option);
                  setSearchLanguage("");
                }}
              >
                {option?.Value || option}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  const patientId: string = useAppSelector((state) => state.patient.patientId);

  const updateDetails = async () => {
    // update API state
    let data: any = {
      patientId,
    };
    data.primaryLanguage = {
      system: null,
      code: cdoLanguageKey,
      display: cdoLanguageName,
      codingSystemName: null,
    };
    await UpdatePatientDemographicsAPI(data);
  };

  const pendingNextRoute: string = useAppSelector(
    (state) => state.task.pendingNextRoute
  );
  const editMode: boolean = !!pendingNextRoute;

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column" className={theme.navContent}>
        <Grid
          container
          direction="column"
          className={clsx(theme.navHeader, theme.bgGreenGrad)}
        >
          <Header showMenuButton={true} />

          {!editMode && <ProgressStepper step={1} totalSteps={4} />}
          <Grid container className={classes.pgFunc}>
            <Grid item>
              <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
                <Aem cid="HEADER_DEMOGRAPHICS_PATIENT_NAME_TEXT_1">
                  Patient Information
                </Aem>
              </h1>
            </Grid>
            <Grid item>
              {/* <DemoSVG/> */}
              <Aem cid="HEADER_DEMOGRAPHICS_PATIENT_NAME_LOGO_1" type={AemContentTypes.imageUrl}></Aem>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="column" className={theme.navSection}>
          <Grid item className={clsx(classes.headerText, theme.headerText)}>
            <Aem cid="BODY_DEMOGRAPHICS_PATIENT_NAME_TEXT_6">
              Below is the name we have on file for you. If this information is
              incorrect, please inform the clinic during your appointment.
            </Aem>
          </Grid>
          <Grid item className={classes.nameLabel}>
            <Aem cid="BODY_DEMOGRAPHICS_PATIENT_NAME_TEXT_1">First Name</Aem>
          </Grid>
          <Grid item className={classes.nameValue}>
            {patient_fname}
          </Grid>

          <Grid item className={classes.nameLabel}>
            <Aem cid="BODY_DEMOGRAPHICS_PATIENT_NAME_TEXT_2">Last Name</Aem>
          </Grid>
          <Grid item className={classes.nameValue}>
            {patient_lname}
          </Grid>

          <Grid item className={classes.nameLabel}>
            <Aem cid="BODY_DEMOGRAPHICS_PATIENT_NAME_TEXT_4">Date of Birth</Aem>
          </Grid>
          <Grid item className={classes.nameValue}>
            {dob}
          </Grid>

          <CustomTextField
            id={langInputId}
            className={classes.inpBox}
            label={Aem.get(
              "BODY_DEMOGRAPHICS_PATIENT_NAME_TEXT_5",
              "Primary Language"
            )}
            name="language"
            fullWidth={true}
            variant="standard"            
            value={cdoLanguageName}
            onClick={(e) => {
              setOverlay(!overlay);
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        className={theme.navStickyFooter}
        style={{
          alignItems: "center",
        }}
      >
        <Grid item xs={3}>
          <BackButton
            trackName="prev"
            trackLocation="nav footer"
            onClick={async () => {
              if (editMode) {
                await dispatch(gotoPendingTaskStep("consent/review"));
              } else {
                await dispatch(gotoPrevTaskStep());
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <NavButton
            accentColor={"green"}
            disabled={disableButton}
            label={Aem.get("ACTION_NEXTBUTTON_TEXT_1", "Next")}
            fullWidth={true}
            trackName="next"
            trackLocation="nav footer"
            onClick={async () => {
              dispatch(setTaskTransitionPending(true));
              dispatch(updateCdoLanguageKey(cdoLanguageKey));
              dispatch(updateCdoLanguageName(cdoLanguageName));
              await updateDetails();
              if (editMode) {
                dispatch(gotoTaskRoute("reviewPatient/demographics"));
              }
              else {
                dispatch(gotoNextTaskStep());
              }
            }}
          />
        </Grid>
      </Grid>

      <Dialog
        fullScreen={fullScreen}
        disableRestoreFocus={true}
        open={overlay}
        onClose={() => setOverlay(false)}
        TransitionComponent={Transition}
        aria-label={ariaLanguageLabel}
        aria-labelledby=""
        PaperProps={appDialogTransparentPaperProps}
      >
        {renderLanguageOverlay()}
      </Dialog>
    </Grid>
  );
}
