import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useIsMounted from "../../lib/hooks/useIsMounted";
import { appColors, appFonts, appStyles, AppThemeColorKeys } from "../../theme";
import { ReactComponent as CloseIconSvg } from "../../assets/icons/close.svg";
import ProgressStepper from "../ProgressStepper";
import LoadingSpinner from "../LoadingSpinner";
import { AppButton, NavButton } from "../Buttons";
import { ISurveyConfig, ISurveyResponse, SurveyQuestionTypes, SurveyResponseValue } from "./SurveyDefs";
import SurveyQuestionDisplay from "./SurveyQuestionDisplay";
import { SaveNpsSurveyResponseDataAPI } from "../../api/SaveNpsSurveyResponseAPI";
import { useAppSelector } from "../../app/hooks";
import { GetCdoShortName } from "../../data/CDO";
import { Aem } from "../../lib/aem";


const useStyles = makeStyles({
  root: {
    position: "relative",
    ...appStyles.fillxy,
    ...appStyles.bgBlueGrad,
    minHeight: "250px",
    height: "100%",
    padding: "10px 15px 25px 15px",
  },
  headerArea: {
    position: "relative",
    width: "100%",
    minHeight: "14px",
  },
  contentArea: {
    position: "relative",
    flex: "1 0 auto",
    width: "100%",
    marginTop: "15px",
  },
  footerArea: {
    minHeight: "20px",
    width: "100%",
  },
  loadingScrim: {
    ...appStyles.absxy,
    overflow: "hidden",
    backgroundColor: appColors.blackA03,
    // backgroundColor: "pink",
    pointerEvents: "none",
    opacity: 1, 
    zIndex: 102,
    transition: "opacity 50ms ease-in-out",
  },
  actions: {
    width: "100%",
  },
  actionButton: {
  },
  stepperArea: {
    justifyContent: "center",
  },
  closeButtonArea: {
    justifyContent: "center",
  },
  closeButton: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    minWidth: "32px",
    minHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    fill: appColors.white,
    color: appColors.white,
    stroke: appColors.white,
  },
});

interface SurveyPanelProps {
  config: ISurveyConfig;
  onClose?: () => void;
}

export const SurveyPanel: React.FC<SurveyPanelProps> = (props: SurveyPanelProps) => {
  const classes = useStyles();
  const isMounted = useIsMounted();

  const { config, onClose } = props;
  const showCloseButton = !!onClose;

  // AEM lookups for button labels
  const backBtnText = Aem.get("ACTION_BACKBUTTON_TEXT_1", "Back");
  const nextBtnText = Aem.get("ACTION_NEXTBUTTON_TEXT_1", "Next");
  const submitBtnText = Aem.get("ACTION_SUBMITBUTTON_TEXT_1", "Submit");

  const cdoShortName = GetCdoShortName();
  const patientId: string = useAppSelector<string>((state) => state.patient.patientId);

  const [curStepIdx, setCurStepIdx] = useState<number>(-1);
  const [numSteps, setNumSteps] = useState<number>(0);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
  const [allResponses, setAllResponses] = useState<ISurveyResponse[]>([]);
  
  const minStepIdx = 0;
  const maxStepIdx = numSteps - 1;
  const hasSteps = (numSteps > 0);
  const curStepNum = (curStepIdx >= 0)? curStepIdx + 1: 0;

  const backBtnLabel = backBtnText;
  const nextBtnLabel = (hasSteps && curStepIdx >= maxStepIdx)? submitBtnText: nextBtnText;
  const curQuestionData = (curStepIdx >= 0)? config?.questions?.[curStepIdx]: undefined;

  const setResponseValue = (idx: number, value: SurveyResponseValue, questionType: SurveyQuestionTypes) => {
    const newResponseItem = { value };
    const newResponses = [ ...allResponses ];
    while (newResponses.length < idx + 1) { 
      newResponses.push({ value: null });
    }
    newResponses[idx] = newResponseItem;
    setAllResponses(newResponses);
  };

  const getResponseValueByIdx = (idx: number, defaultValue: SurveyResponseValue = null): SurveyResponseValue => {
    let responseItem = allResponses[idx];
    return responseItem? responseItem.value: defaultValue;
  };
  const hasResponseValueByIdx = (idx: number): boolean => {
    let responseItem = allResponses[idx];
    return !!(responseItem && responseItem.value != null && responseItem.value !== undefined && responseItem.value !== "");
  };

  const getFirstResponseValueByQuestionType = (questionType: SurveyQuestionTypes): SurveyResponseValue => {
    let responseValue: SurveyResponseValue = null;
    const idx = config?.questions?.findIndex(v => v.type === questionType);
    if (idx >= 0) { 
      let responseItem = allResponses[idx];
      responseValue = responseItem? responseItem.value: null;
    }
    return responseValue;    
  };

  const canMoveBack = (hasSteps && curStepIdx > minStepIdx);
  const canMoveNext = (hasSteps && (!curQuestionData?.required || hasResponseValueByIdx(curStepIdx)));  
  const showActionButtons: boolean = (!showSummary && !curQuestionData?.required);
  const showBackButton: boolean = false;
  const autoHideOnCompletionDelayMS: number = 3000;

  const handleBackClicked = async () => {
    if (hasSteps && curStepIdx > minStepIdx) {
      setCurStepIdx(curStepIdx - 1);
    }
  };

  const handleNextClicked = async () => {
    if (hasSteps) {
      if (curStepIdx < maxStepIdx) {
        setCurStepIdx(curStepIdx + 1);
      } else {
        await submitSurveyResponsesToAPI();
        setShowSummary(true);

        if (autoHideOnCompletionDelayMS > 0) { 
          setTimeout(() => {
            if (!isMounted()) { return; }    
            if (onClose) { onClose(); }
          }, autoHideOnCompletionDelayMS);
        }
      }
    }
  };

  const submitSurveyResponsesToAPI = async () => {
    setShowLoadingSpinner(true);

    // The API assumes that there is only 1 likert and 1 short answer question, 
    // so grab the first of each from the 
    const likertScoreVal = getFirstResponseValueByQuestionType(SurveyQuestionTypes.Likert);
    const shortAnswerVal = getFirstResponseValueByQuestionType(SurveyQuestionTypes.ShortAnswer);
    const likertScore: number | null = Number.isFinite(likertScoreVal)? likertScoreVal as number: null;
    const shortAnswer: string | null = (typeof shortAnswerVal === 'string')? shortAnswerVal as string: null;

    let res = await SaveNpsSurveyResponseDataAPI(cdoShortName, patientId, likertScore, shortAnswer).catch(() => false);
    setShowLoadingSpinner(false);
    return res;
  };

  const handleClose = () => {
    if (onClose) { onClose(); }
  };

  const handleResponseChanged = (value: any, questionType: SurveyQuestionTypes) => {
    setResponseValue(curStepIdx, value, questionType);
  };

  const moveToNextQuestion = useCallback(() => {
    if (hasSteps && curStepIdx < maxStepIdx) {
      setCurStepIdx(curStepIdx + 1);
    }
  }, [hasSteps, curStepIdx, maxStepIdx, setCurStepIdx]);
  
  useEffect(() => {
    let numQuestions = config?.questions?.length || 0;
    if (numSteps !== numQuestions) {
      setNumSteps(numQuestions);
      if (numQuestions > 0 && curStepIdx === -1) { 
        setCurStepIdx(0);
      }
    }
  }, [config, numSteps, curStepIdx]);

  return(
    <Grid 
      container 
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"        
      className={classes.root}
    >
      {showLoadingSpinner && (
        <div className={classes.loadingScrim}>
          <LoadingSpinner size={48} />
        </div>
      )}
      
      <Grid item className={classes.headerArea}>
        <Grid 
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Grid item className={classes.stepperArea}>
            {!showSummary && (
              <ProgressStepper
                step={showSummary? numSteps: curStepNum} 
                totalSteps={numSteps}
                centerVertically={true}
              />
            )}
          </Grid>
          {showCloseButton && (
            <Grid item className={classes.closeButtonArea}>
              <AppButton
                variant="text" 
                disableElevation
                className={classes.closeButton}
                trackName="close"
                trackLocation="overlay"
                onClick={handleClose}
              >
                <CloseIconSvg />
              </AppButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item className={classes.contentArea}>
        {!showSummary && (
          <SurveyQuestionDisplay 
            questionData={curQuestionData}
            defaultValue={getResponseValueByIdx(curStepIdx)}
            onResponseChange={handleResponseChanged}
            onQuestionDone={moveToNextQuestion}
          />
        )}
        {showSummary && (
          <SurveyQuestionDisplay 
            questionData={config?.summary}
            onQuestionDone={moveToNextQuestion}
          />
        )}
      </Grid>
      {showActionButtons && (
        <Grid item className={classes.footerArea}>
          <Grid 
            container 
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.actions}
            >
            <Grid item xs={6}>
              {showBackButton && (
                <NavButton 
                  className={classes.actionButton} 
                  accentColor={AppThemeColorKeys.white}
                  size="large"
                  variant="outlined"
                  darkFilledBackground={false}
                  fullWidth={true}
                  disabled={!canMoveBack}
                  label={backBtnLabel}
                  trackName="survey back"
                  trackLocation="body"
                  onClick={handleBackClicked}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <NavButton 
                className={classes.actionButton}
                accentColor={AppThemeColorKeys.white}
                size="large"
                variant="outlined"
                darkFilledBackground={false}
                fullWidth={true}
                disabled={!canMoveNext}
                label={nextBtnLabel}
                trackName="survey next"
                trackLocation="body"
                onClick={handleNextClicked}
              />
            </Grid>
          </Grid>
        </Grid>
      )}      
    </Grid>
  );
};

SurveyPanel.defaultProps = {
};

export default SurveyPanel;

