import moment, { Moment } from "moment";
import { store } from "../../app/store";
import { DefaultLanguageIsoCode } from "../../data/languageData";
import { ApptState, findBestAppointment, SingleApptState } from "../../features/appt/apptSlice";
import { PatientState } from "../../features/patient/patientSlice";
import { parseToMoment, splitFullNameIntoFhirNameParts } from "../../utility/utilityFunctions";
import { PdfFormDataSchema } from "./PdfFormDataSchema";


export interface SignatureData {
  signature: string;
  relation: string;
}

export const getPrefLanguageIsoCode = (): string => {
  const state: any = store.getState();
  return state?.preferences?.languageIsoCode || DefaultLanguageIsoCode;
};

export const collectPdfSaveData = (): any => {

  const state: any = store.getState();
  let appt: ApptState = state.appt || {};
  let patient: PatientState = state?.patient || {};
  let bestAppt: SingleApptState = findBestAppointment(appt.allAppointmentInfos) || {};

  let data: any = {};
  data.patientId = patient.patientId;
  data.appointmentId = bestAppt.apptId || "";
  data.appointmentTypeId = bestAppt.appointmentTypeId || "";
  return data;
};

/**
 * collect and generate custom PDF data from redux 
 * and other application resources
 * The data should match the PdfFormDataSchema interface
 */
export const collectPdfCustomData = (signatureData: SignatureData|undefined = undefined): PdfFormDataSchema => {

  let now = moment();
  let nowDateStr = now.format("MM/DD/YYYY");

  const state: any = store.getState();
  let patient: PatientState = state?.patient || {};
  let patientDob: Moment|undefined = parseToMoment(patient.dob);
  let patientAdvDirSelection: string = patient?.advancedDirective?.selection || "";

  // check if a non-self relation is signing the form
  let isSelf: boolean = true;
  if (signatureData?.relation && !signatureData.relation.trim().match(/^self/i)) { 
    isSelf = false;
  }

  const data: PdfFormDataSchema = {} as PdfFormDataSchema;

  data.date = nowDateStr;
  data.dateObj = now.toDate();
  data.dateMonth = now.format("MM");
  data.dateDay = now.format("DD");
  data.dateYear = now.format("YYYY");
  
  data.digitalSignature = signatureData?.signature || patient.full_name || "";

  data.patientFullName = patient.full_name || "";
  data.patientFirstName = patient.first_name || "";
  data.patientLastName = patient.last_name || "";
  data.patientIdNumber = "";
  data.patientBirthDate = patientDob? patientDob.format("MM/DD/YYYY"): "";
  data.patientBirthMonth = patientDob? patientDob.format("MM"): "";
  data.patientBirthDay = patientDob? patientDob.format("DD"): "";
  data.patientBirthYear = patientDob? patientDob.format("YYYY"): "";
  data.patientMRN = "";
  data.patientSignature = signatureData?.signature || patient.full_name || "";
  data.patientDate = nowDateStr;

  data.guardianFullName = "";       
  data.guardianFirstName = "";
  data.guardianLastName = "";
  data.guardianRelation = "";
  data.guardianIdNumber = "";
  data.guardianSignature = "";
  data.guardianDate = "";

  if (isSelf) { 
    data.guardianRelation = signatureData?.relation || "";
  } else if (signatureData?.signature) { 
    // if a relation is present and doesn't appear to be self, populate the guardian data
    let fullName: string = signatureData.signature || "";
    let nameParts: any = splitFullNameIntoFhirNameParts(fullName);
    data.guardianFullName = fullName;       
    data.guardianFirstName = nameParts.given.join(" ") || "";
    data.guardianLastName = nameParts.family || "";
    data.guardianRelation = signatureData.relation || "";
    data.guardianIdNumber = "";
    data.guardianSignature = fullName;
    data.guardianDate = nowDateStr;    
    data.digitalSignature = data.guardianSignature || data.patientSignature || data.digitalSignature || "";
  }

  data.interpreterFullName = "";       
  data.interpreterFirstName = "";
  data.interpreterLastName = "";
  data.interpreterTitle = "";
  data.interpreterIdNumber = "";
  data.interpreterSignature = "";
  data.interpreterDate = "";

  data.witnessFullName = "";       
  data.witnessFirstName = "";
  data.witnessLastName = "";
  data.witnessTitle = "";
  data.witnessIdNumber = "";
  data.witnessSignature = "";
  data.witnessDate = "";

  data.employeeIdNumber = "";
  data.employeeFullName = "";       
  data.employeeFirstName = "";
  data.employeeLastName = "";
  data.employeeTitle = "";
  data.employeeSignature = "";
  data.employeeDate = "";

  data.consentConfirmed = "X";
  data.consentDeclined = "";
  data.consentEmergency = "";
  data.consentCustom = "";
  data.consentCustomText = "";
  data.consentDate = "";

  data.advDirOption1 = (patientAdvDirSelection === "option1")? "X": "";
  data.advDirOption1 = (patientAdvDirSelection === "option2")? "X": "";
  data.advDirOption1 = (patientAdvDirSelection === "option3")? "X": "";
  data.advDirRecvOption1 = "";
  data.advDirRecvOption2 = "";
  data.advDirRecvComments = "";

  data.facilityName = "";
  data.facilityIdNumber = "";         
  data.facilityAddressFull = "";
  data.facilityAddressStreet = "";
  data.facilityAddressStreet2 = "";
  data.facilityAddressUnit = "";
  data.facilityAddressCity = "";
  data.facilityAddressState = "";
  data.facilityAddressZip = "";
  data.facilityPhone = "";

  if (isSelf) { 
    // primary signer is the patient.  copy values to primary fields
    data.primaryFullName = data.patientFullName;
    data.primaryFirstName = data.patientFirstName;
    data.primaryLastName = data.patientLastName;
    data.primaryIdNumber = data.patientIdNumber;
    data.primarySignature = data.patientSignature;
    data.primaryDate = data.patientDate;
  } else {
    // primary signer is the representative.  copy values to primary fields
    data.primaryFullName = data.guardianFullName;
    data.primaryFirstName = data.guardianFirstName;
    data.primaryLastName = data.guardianLastName;
    data.primaryIdNumber = data.guardianIdNumber;
    data.primarySignature = data.guardianSignature;
    data.primaryDate = data.guardianDate;
  }

  return data;
};

