import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppThemeColorKeys, appFonts } from "../../theme";
import { NavButton } from "../Buttons";
import { clearAppStateAndRestart } from "../../appInit";
import { getInactivityTimeoutConfig } from "../../appInactivityTimer";
import { secsToMS } from "../../utility/utilityFunctions";
import useIsMounted from "../../lib/hooks/useIsMounted";
import useIsFirstRender from "../../lib/hooks/useIsFirstRender";
import { InactivityTimeoutManager } from "../../lib/inactivity-timeout";
import { updateUIErrorPopupContext } from "../../features/ui/uiSlice";
import { gotoTaskRoute, setTaskTransitionPending } from "../../features/task/taskSlice";
import { SingleApptState, findFirstAppointmentWithinTimeWindow } from "../../features/appt/apptSlice";
import { AppointmentArrivalTimeRange } from "../../data/apptData";
import { UpdateArriveAppointmentAPI } from "../../api/UpdateArriveAppointmentAPI";
import { Aem } from "../../lib/aem";


const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px",
  },
  startOverText: {
    margin: "22px 0 16px 0",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    textAlign: "center",
  },
  startOverActionsArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
    margin: "22px 0 0 0",
  },
  startOverActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  startOverButton: {
    margin: "0 8px",
  },
}));

interface KioskStartOverPromptProps {
  checkArrivalStatus?: boolean;
  showMesssageText?: boolean;
  showYesStartOverButton?: boolean;
  showNoContinueButton?: boolean;
  showIAmHereButton?: boolean;
  enableSessionTimeoutCheck?: boolean;    // only used in kioskMode for now.
  autoHideAfterActions?: boolean;
  messageAemId?: string;
  messageDefaultText?: string;
  defaultNoContinueRoute?: string;
  defaultIAmHereRoute?: string;
  arrivedNextRoute?: string;
  sessionExpiredRoute?: string;
}

export const KioskStartOverPrompt: React.FC<KioskStartOverPromptProps> = (props: KioskStartOverPromptProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  const isFirstRender = useIsFirstRender();

  const { 
    messageAemId,
    messageDefaultText,  
    showMesssageText,
    showYesStartOverButton,
    showNoContinueButton,
    showIAmHereButton,
    checkArrivalStatus,
    enableSessionTimeoutCheck,
    autoHideAfterActions,
    defaultNoContinueRoute,
    defaultIAmHereRoute,    
    arrivedNextRoute,
    sessionExpiredRoute,
  } = props;

  const routeThemeColor: string = useAppSelector((state) => state.task.routeInfo?.themeColor) || AppThemeColorKeys.blue;
  const allAppointmentItems: SingleApptState[] = useAppSelector<SingleApptState[]>((state) => state.appt.allAppointmentInfos);
  const confirmedPreRegDoneApptItems = allAppointmentItems.filter(v => (v.isConfirmed && v.preRegDone));

  const isKioskMode: boolean = useAppSelector<boolean>((state) => state.preferences.isKioskMode);
  const isPublicDevice: boolean = useAppSelector<boolean>((state) => state.preferences.isPublicDevice);
  
  // only show the start over button when in kiosk or public mode
  const showScreen: boolean = !!((isKioskMode || isPublicDevice) && (showYesStartOverButton || showNoContinueButton || showIAmHereButton));

  // the session timeout is only enabled for publicDevice mode
  const sessionTimeoutEnabled: boolean = !!(showScreen && enableSessionTimeoutCheck && isPublicDevice);

  const [hidden, setHidden] = useState<boolean>(false);
  const [sessionTimeoutMgr, setSessionTimeoutMgr] = useState<InactivityTimeoutManager | undefined>(undefined);

  const messageText: string = Aem.get(messageAemId, messageDefaultText);
  const yesText: string = Aem.get("ACTION_YESBUTTON_TEXT_1", "Yes");
  const noText: string = Aem.get("ACTION_NOBUTTON_TEXT_1", "No");
  const iAmHereText: string = Aem.get("ACTION_IAMHERE_TEXT_1", "I'm Here");

  const handleKioskStartOverClick = async (): Promise<void> => {
    if (autoHideAfterActions) { 
      setHidden(true);
    }

    await clearAppStateAndRestart();
  };

  const handleKioskContinueClick = async (): Promise<void> => {
    let nextRoute: string = defaultNoContinueRoute as string;

    if (checkArrivalStatus) {
      let arrivedApptItem = findFirstAppointmentWithinTimeWindow(
        confirmedPreRegDoneApptItems, 
        AppointmentArrivalTimeRange.earlyTimeOffsetMins, 
        AppointmentArrivalTimeRange.lateTimeOffsetMins);
      if (arrivedApptItem) {
        if (nextRoute || arrivedNextRoute) {
          dispatch(setTaskTransitionPending(true));
        }
        let arrivedSuccess = await UpdateArriveAppointmentAPI(arrivedApptItem.apptId)
          .then(() => true)
          .catch(() => false);
        if (arrivedSuccess) {
          nextRoute = arrivedNextRoute as string;
        }
      }
    }

    if (autoHideAfterActions) { 
      setHidden(true);
    }

    if (nextRoute) {
      await dispatch(gotoTaskRoute(nextRoute));
    }
  };

  const handleKioskIAmHereClick = async (): Promise<void> => {
    let nextRoute: string = defaultIAmHereRoute as string;

    if (checkArrivalStatus) {
      let arrivedApptItem = findFirstAppointmentWithinTimeWindow(
        confirmedPreRegDoneApptItems, 
        AppointmentArrivalTimeRange.earlyTimeOffsetMins, 
        AppointmentArrivalTimeRange.lateTimeOffsetMins);
      if (arrivedApptItem) {
        if (nextRoute || arrivedNextRoute) {
          dispatch(setTaskTransitionPending(true));
        }
        let arrivedSuccess = await UpdateArriveAppointmentAPI(arrivedApptItem.apptId)
          .then(() => true)
          .catch(() => false);
        if (arrivedSuccess) {
          nextRoute = arrivedNextRoute as string;
        }
      }
    }

    if (autoHideAfterActions) { 
      setHidden(true);
    }

    if (nextRoute) {
      await dispatch(gotoTaskRoute(nextRoute));
    }
  };

  const timeoutFinalHandler = useCallback(() => {
    if (!isMounted()) { return; }
    dispatch(updateUIErrorPopupContext(null));
    dispatch(gotoTaskRoute(sessionExpiredRoute));
  }, [dispatch, isMounted, sessionExpiredRoute]);

  useEffect(() => {
    if (isFirstRender && sessionTimeoutEnabled) {
      if (sessionTimeoutMgr) {
        sessionTimeoutMgr.reset();
        sessionTimeoutMgr.start();
      } else {
        let timeoutCfg = getInactivityTimeoutConfig(isPublicDevice);
        let timeoutOpts = { 
          timeoutTimeMS: secsToMS(timeoutCfg.timeoutSecs),
          warningTimeMS: -1,
          autoDisableOnTimeout: true,
          verbose: false,
        };
        const newTimeoutMgr = new InactivityTimeoutManager(timeoutOpts);
        newTimeoutMgr.logPrefix = "KioskTimer ";
        newTimeoutMgr.setTimeoutHandler(timeoutFinalHandler);
        newTimeoutMgr.start();

        setSessionTimeoutMgr(newTimeoutMgr);
      }
    }
    if (sessionTimeoutMgr?.started && !sessionTimeoutEnabled) {
      sessionTimeoutMgr.stop();
    }

    // clean up any potential timers when component unmounts
    return () => {
      if (sessionTimeoutMgr?.started) {
        sessionTimeoutMgr.stop();
      }
    };
  }, [isFirstRender, isPublicDevice, sessionTimeoutEnabled, sessionTimeoutMgr, timeoutFinalHandler]);


  if (!showScreen || hidden) {
    return null;
  }

  return (
    <Grid 
      container 
      direction="column" 
      justifyContent="center"
      alignItems="center"                    
      className={classes.root}
    >
      {showMesssageText && (
        <Grid item className={classes.startOverText}>
          {messageText}
        </Grid>
      )}

      <Grid item className={classes.startOverActionsArea}>
        <Grid 
          container 
          direction="row" 
          justifyContent="center"
          alignItems="center"
          className={classes.startOverActions}>
          {showIAmHereButton && (
            <Grid item xs={6}>
              <NavButton
                className={classes.startOverButton}
                accentColor={routeThemeColor}
                label={iAmHereText}
                fullWidth={true}
                trackName="i am here"
                trackLocation="body"
                onClick={handleKioskIAmHereClick}
              />
            </Grid>
          )}
          {showNoContinueButton && (
            <Grid item xs={6}>
              <NavButton
                className={classes.startOverButton}
                accentColor={routeThemeColor}
                label={noText}
                fullWidth={true}
                trackName="no"
                trackLocation="body"
                onClick={handleKioskContinueClick}
              />
            </Grid>
          )}
          {showYesStartOverButton && (
            <Grid item xs={6}>
              <NavButton
                className={classes.startOverButton}
                accentColor={routeThemeColor}
                label={yesText}
                fullWidth={true}
                trackName="yes"
                trackLocation="body"
                onClick={handleKioskStartOverClick}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

KioskStartOverPrompt.defaultProps = {
  checkArrivalStatus: false,
  showMesssageText: true,
  showYesStartOverButton: true,
  showNoContinueButton: false,
  showIAmHereButton: false,
  enableSessionTimeoutCheck: false,
  autoHideAfterActions: false,
  messageAemId: "BODY_MULTIAPPTFINISH_TEXT_4",
  messageDefaultText: "Would you like to checkin another patient?",
  defaultNoContinueRoute: "kioskFinish",
  defaultIAmHereRoute: "kioskFinish",
  arrivedNextRoute: "kioskArrivedFinish",
  sessionExpiredRoute: "sessionExpiredError",
};

export default KioskStartOverPrompt;

