import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BackButton, NavButton } from "../../Components/Buttons";
import Header from "../../Components/Header";
import ProgressStepper from "../../Components/ProgressStepper";
import { appFonts, useAppTheme, AppThemeColorKeys, appColors } from "../../theme";
import {
  gotoNextTaskStep,
  gotoTaskRoute,
} from "../../features/task/taskSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CardSize,
  InsuranceInfoCard,
} from "../../Components/InsuranceInfoCard";
import HDivider from "../../Components/HDivider";
import Aem, { AemContentTypes } from "../../lib/aem/components/Aem";
import { ReactComponent as CheckmarkSvg } from "../../assets/icons/check-circlegreen.svg";
import { ReactComponent as ExclamationmarkSvg } from "../../assets/icons/exclamation.svg";
import config from "../../config";

// NOTE: this flag will show/hide the copay balance section
// we decided to turn it off since Cali wouldn't be able to provide us 
// with accurate copay information during the initial integration
const SHOW_COPAY: boolean = false;

const useStyles = makeStyles({
  root: {
    // backgroundColor: "#ffffff",
    height: "100%",
    display: "flex",
    // padding: "16px",
    overflowY: "auto",
  },
  navContent: {
    paddingBottom: "24px",
  },
  title: {
    fontFamily: appFonts.semibold,
    fontSize: "18px",
  },
  headerTitle: {
    marginTop: "0px",
  },
  section: {
    marginTop: "30px",
    maxWidth: "992px",
  },
  sectionDetails: {
    maxWidth: "992px",
  },
  cardHeaderPad: {
    // this should account for approx 50% of the card height.  subtract 30 for the theme.navHeader paddingBottom
    height: `${CardSize.h / 2 - 30}px`,
  },
  cardContainer: {
    position: "relative",
    marginTop: "16px",
    flexWrap: "nowrap",
  },
  amountInfo: {
    fontFamily: appFonts.regular,
    fontSize: "12px",
  },
  insuranceTitle: {
    marginTop: "24px",
    fontSize: "20px",
    fontFamily: appFonts.bold,
  },
  dividerLine: {
    marginTop: "26px",
  },
  pgFunc: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  eligibleSuccessTitle: {
    fontFamily: appFonts.regular,
    fontSize: "15px",
    margin: "4px 0",
    color: appColors.success1,  
    "& span": {
      margin: "0 5px",
    }
  },
  eligibleFailedTitle: {
    fontFamily: appFonts.regular,
    fontSize: "15px",
    margin: "4px 0",
    color: appColors.darkError,
    "& span": {
      margin: "0 5px",
    }
  },
});

export default function ReviewInsuranceCopayBalance() {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const routeThemeColor: string = useAppSelector(
    (state) => state.task.routeInfo?.themeColor
  );
  const hasCoverage2 = useAppSelector((state) => state.insurance.hasCoverage2);
  const coverage1 = useAppSelector((state) => state.insurance.coverage1);
  const coverage2 = useAppSelector((state) => state.insurance.coverage2);
  //Check Eligibility
  const hasSelfCoverage = useAppSelector((state) => state.insurance.hasSelfCoverage);
  const enableEligibilityFeature: boolean = config.enableEligibilityFeature;
  const eligibilityAttempts = useAppSelector(
    (state) => state.appt.eligibilityAttempts
  );
  const eligibilityVerified = useAppSelector(
    (state) => state.appt.eligibilityVerified
  );
  let isEligibilityChecked: boolean = false;
  if ((eligibilityAttempts > 0)) {
    isEligibilityChecked = true;
  }
  const insuranceVerifiedText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_16", "Insurance verified.");
  const NotVerifiedContactProvText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_17", "Your insurance could not be verified. Please contact your insurance provider for further information.");
 
  // Copay and Balance Info
  // config flag to show/hide the account balance section.
  // $SHOW_ACCOUNT_BALANCE$ keep in sync with other uses
  const showAccountBalance = false;
  const [office, set_office] = useState(30);
  const [urgentCare, set_urgentCare] = useState(30);
  const [erVisit, set_erVisit] = useState(250);
  const [speciality, set_speciality] = useState(50);
  const accountBalance = useAppSelector(
    (state) => state.patient.accountBalance
  );

  const pendingNextRoute: string = useAppSelector(
    (state) => state.task.pendingNextRoute
  );

  const editMode: boolean = !!pendingNextRoute;
  const navButtonName: string = editMode ? "save": "next";
  const navButtonLabel: string = editMode
    ? Aem.get("ACTION_SAVEBUTTON_TEXT_1", "Save")
    : Aem.get("ACTION_NEXTBUTTON_TEXT_1", "Next");

  const moveToPrevStep = async () => {
    // skip any pending routes for this case
    if (hasCoverage2) {
      await dispatch(gotoTaskRoute("reviewPatient/cardImage2"));
    } else {
      await dispatch(gotoTaskRoute("reviewPatient/cardImage"));
    }
  };

  const moveToNextStep = async () => {
    await dispatch(gotoNextTaskStep());
  };

  useEffect(() => {
    //copay and balance
    set_office(40);
    set_urgentCare(45);
    set_erVisit(300);
    set_speciality(60);
  }, []);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        direction="column"
        className={clsx(theme.nowrap, theme.navContent, classes.navContent)}
      >
        <Grid
          container
          direction="column"
          className={clsx(theme.nowrap, theme.navHeader, theme.bgGreenGrad)}
        >
          <Header showMenuButton={true} />
          <ProgressStepper step={4} totalSteps={4} />

          <Grid container className={classes.pgFunc}>
            <Grid item>
              <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
                <Aem cid="HEADER_INSURANCEREVIEW_TEXT_1">
                  Your insurance information
                </Aem>
              </h1>
            </Grid>
            <Grid item>
              {/* <InsSVG/> */}
              <Aem
                cid="HEADER_PATIENT_INSURANCE_COPAY_LOGO_1"
                type={AemContentTypes.imageUrl}
              ></Aem>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" className={theme.navSection}>
        {!hasSelfCoverage && isEligibilityChecked  && enableEligibilityFeature &&(<>
            {eligibilityVerified && (
              <Grid item className={classes.eligibleSuccessTitle}>
                <CheckmarkSvg /><span>{insuranceVerifiedText}</span>
              </Grid>
            )}
            {!eligibilityVerified && (
              <Grid item className={classes.eligibleFailedTitle}>
                <ExclamationmarkSvg /><span>{NotVerifiedContactProvText}</span>
              </Grid>
            )}
          </>)}
          <Grid item className={classes.insuranceTitle}>
            <Aem cid="BODY_INSURANCEREVIEW_TEXT_8">Primary Insurance</Aem>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            className={classes.cardContainer}
          >
            <InsuranceInfoCard coverage={coverage1} isPrimary={true} />
          </Grid>

          {/* Copays info start */}
          {SHOW_COPAY && (
            <Grid container direction="row" className={classes.section}>
              <Grid item>
                <span
                  className={classes.title}
                  style={{
                    height: "24px",
                    width: "60px",
                  }}
                >
                  <Aem cid="BODY_INSURANCEREVIEW_TEXT_1">Copays</Aem>
                </span>
              </Grid>
    
              <Grid
                container
                direction="column"
                className={clsx(classes.sectionDetails, classes.amountInfo)}
                style={{
                  height: "40px",
                  width: "99%",
                }}
              >
                <Grid
                  item
                  style={{
                    height: "39px",
                    width: "18%",
                    borderRight: "1px solid rgba(21,40,49,0.25)",
                    paddingRight: "8%",
                  }}
                >
                  <span style={{ fontSize: "22px" }}>${office}</span>
                  <br />
                  <span>
                    <Aem cid="BODY_INSURANCEREVIEW_TEXT_2">Office</Aem>
                  </span>
                </Grid>
                <Grid
                  item
                  style={{
                    height: "39px",
                    width: "30%",
                    borderRight: "1px solid rgba(21,40,49,0.25)",
                    paddingLeft: "8%",
                    paddingRight: "8%",
                  }}
                >
                  <span style={{ fontSize: "22px" }}>${urgentCare}</span>
                  <br />
                  <span>
                    <Aem cid="BODY_INSURANCEREVIEW_TEXT_3">UrgentCare</Aem>
                  </span>
                </Grid>
                <Grid
                  item
                  style={{
                    height: "39px",
                    width: "29.6%",
                    borderRight: "1px solid rgba(21,40,49,0.25)",
                    paddingLeft: "8%",
                    paddingRight: "8%",
                  }}
                >
                  <span style={{ fontSize: "22px" }}>${erVisit}</span>
                  <br />
                  <span>
                    <Aem cid="BODY_INSURANCEREVIEW_TEXT_4">ER Visit</Aem>
                  </span>
                </Grid>
                <Grid
                  item
                  style={{ height: "39px", width: "19.5%", paddingLeft: "8%" }}
                >
                  <span style={{ fontSize: "22px" }}>${speciality}</span>
                  <br />
                  <span>
                    <Aem cid="BODY_INSURANCEREVIEW_TEXT_5">Speciality</Aem>
                  </span>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* copay info end */}

          {/* Account Balance info begin */}
          {showAccountBalance && (
            <Grid container direction="column" className={classes.section}>
              <Grid item>
                <span className={classes.title} style={{ height: "24px" }}>
                  <Aem cid="BODY_INSURANCEREVIEW_TEXT_6">Account Balance</Aem>
                </span>
              </Grid>
              <Grid
                item
                className={classes.amountInfo}
                style={{
                  fontSize: "22px",
                  height: "20px",
                }}
              >
                <span>${accountBalance}</span>
              </Grid>
              <Grid
                item
                style={{
                  marginTop: "16px",
                  height: "32px",
                }}
              >
                <span
                  style={{
                    fontFamily: appFonts.regular,
                    fontSize: "12px",
                  }}
                >
                  <Aem cid="BODY_INSURANCEREVIEW_TEXT_7">
                    This is your current account balance. This number may include
                    any unpaid copays, visit costs, and other associated fees for
                    your account.
                  </Aem>
                </span>
              </Grid>
            </Grid>
          )}
          {hasCoverage2 && (
            <React.Fragment>
              <Grid item className={classes.dividerLine}>
                <HDivider color={AppThemeColorKeys.green} />
              </Grid>
              <Grid item className={classes.insuranceTitle}>
                <Aem cid="BODY_INSURANCEREVIEW_TEXT_9">Secondary Insurance</Aem>
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                className={classes.cardContainer}
              >
                <InsuranceInfoCard coverage={coverage2} />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        className={theme.navStickyFooter}
        style={{
          alignItems: "center",
        }}
      >
        <Grid item xs={3}>
          <BackButton
            trackName="prev"
            trackLocation="nav footer"
            onClick={moveToPrevStep}
          />
        </Grid>
        <Grid item xs={6}>
          <NavButton
            accentColor={routeThemeColor || "green"}
            label={navButtonLabel}
            fullWidth={true}
            disabled={false}
            trackName={navButtonName}
            trackLocation="nav footer"                
            onClick={moveToNextStep}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
