import React, { useCallback } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { appFonts } from "../../theme";
import { Aem } from "../../lib/aem";
import { SurveyQuestionTypes, SurveyResponseValue } from "./SurveyDefs";
import LikertResponse from "./LikertResponse";
import ShortAnswerResponse from "./ShortAnswerResponse";


const useStyles = makeStyles({
  root: {
    position: "relative",
    fontFamily: appFonts.regular,
    fontSize: "14px",
    flexWrap: "nowrap",
    height: "100%",
  },
  title: {
    fontFamily: appFonts.semibold,
    fontSize: "18px",
    marginBottom: "15px",
  },
  question: {
    fontFamily: appFonts.semibold,
    fontSize: "16px",
    marginBottom: "8px",
  },
  questionBody: {
    fontFamily: appFonts.semibold,
    fontSize: "16px",
    marginBottom: "8px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  details: {
    fontFamily: appFonts.medium,
    fontSize: "16px",
    marginBottom: "8px",
  },
  hint: {
    fontFamily: appFonts.regular,
    fontSize: "14px",
    marginBottom: "4px",
    width: "auto",
    alignSelf: "center",
    textAlign: "center",
  },
  vspacer: {
    minHeight: "8px",
    height: "100%",
    flexWrap: "nowrap",
  },
});

interface SurveyQuestionDisplayProps {
  questionData?: any;
  defaultValue?: SurveyResponseValue;
  onResponseChange?: (value: SurveyResponseValue, type: SurveyQuestionTypes) => void;
  onQuestionDone?: () => void;
}

export const SurveyQuestionDisplay: React.FC<SurveyQuestionDisplayProps> = (props: SurveyQuestionDisplayProps) => {
  const classes = useStyles();

  const renderSurveyTextItem = useCallback((item) => (
    <Aem cid={item?.aemId}>{item?.text}</Aem>
  ), []);

  const { questionData, defaultValue, onResponseChange, onQuestionDone } = props;
  if (!questionData) { return null; }

  const handleResponseChanged = (value: SurveyResponseValue) => {
    if (onResponseChange) {
      onResponseChange(value, questionData.type);
    }
  };

  let questionBody;
  switch (questionData.type) {
    case SurveyQuestionTypes.Likert:
      questionBody = 
        (<LikertResponse 
          questionData={questionData}
          defaultValue={defaultValue}
          onChange={handleResponseChanged}
          onDone={onQuestionDone}
        />);
      break;
    case SurveyQuestionTypes.ShortAnswer:
      questionBody = 
        (<ShortAnswerResponse 
          questionData={questionData}
          defaultValue={defaultValue}
          onChange={v => onResponseChange?.(v, questionData.type)}
          onDone={onQuestionDone}
        />);
      break;
    default:
      break;
  }
  
  return(
    <Grid 
      container 
      direction="column" 
      justifyContent="flex-start" 
      alignItems="flex-start" 
      className={classes.root}>
      {questionData.title && (
        <Grid container item className={classes.title}>
          {renderSurveyTextItem(questionData.title)}
        </Grid>
      )}
      {questionData.question && (
        <Grid container item className={classes.question}>
          {renderSurveyTextItem(questionData.question)}
        </Grid>
      )}
      {questionData.details && (
        <Grid container item className={classes.details}>
          {renderSurveyTextItem(questionData.details)}
        </Grid>
      )}
      {questionBody && (
        <Grid container item className={classes.questionBody}>
          {questionBody}
        </Grid>
      )}
      <Grid container item className={classes.vspacer}>&nbsp;</Grid>
      {questionData.hint && (
        <Grid container item className={classes.hint}>
          {renderSurveyTextItem(questionData.hint)}
        </Grid>
      )}
      {false && (
        <Grid container item>
          <pre>{JSON.stringify(questionData, null, 2)}</pre>
        </Grid>          
      )}
    </Grid>
  );
};

SurveyQuestionDisplay.defaultProps = {
};

export default SurveyQuestionDisplay;

