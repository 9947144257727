import { isMobile } from "react-device-detect";
import { getLanguageShortCodeFromIsoCode } from "../../data/languageData";
import { 
  getTrackerActiveRouteName,
  getTrackerIsProtectedMode,
  getTrackerLanguageIsoCode,
} from "./TrackerHelpers";


type IAdobeEvent = Object;
type IAdobeDataLayer = Array<IAdobeEvent>;

export type FieldAlertError = {
  field: string;
  alert: string;
};

export type FieldAlertWatcher = {
  value: (() => boolean) | boolean;   // can be a function that evaluates or a boolean value
  field: string;
  alert: string;
}


let _dayaLayerInit: boolean = false;
const _initAdobeDataLayerOnce = () => {
  if (_dayaLayerInit) { return; }
  _dayaLayerInit = true;

  // init the dataLayer if it doesn't exist
  // once the adobe analytics scripts initializes, it will send any events already queueued, 
  // then periodically send the other events
  window.adobeDataLayer = window.adobeDataLayer || [];
};

// Adobe DataLayer specific types
const AdobeEventTypes = {
  pageTrack: "page track",
  linkTrack: "link track",
  formLoaded: "form loaded",
  formStarted: "form started", 
  formStep: "form step",
  formComplete: "form complete",
  formError: "form error",
};

const AdobeExperienceTypes = {
  mobile: "mobile",
  desktop: "desktop",
};

const AdobeLinkTypes = {
  internal: "internal",
  external: "external",
};


// singleton instance reference
let _instance: Tracker|undefined = undefined;

/**
 * The Tracker (Adobe Analytics Tracker) is responsible 
 * for initializing Adobe Analytics and managing related events and actions logic
 * 
 * Track Internal Click or Link Event
 * tracker.click("next", "footer");
 * 
 * Track Page Loaded Event
 * tracker.page("pageName");
 *
 * Track External Link Clicked Event
 * tracker.externalLink("https://example.com", { ... });
 */
 export class Tracker {
  
  static getInstance(): Tracker {
    if (_instance === undefined) { _instance = new Tracker(); }
    return _instance;
  }

  public formName: string = "";
  protected _enabled: boolean = true;
  protected _verbose: boolean = false;
  protected _disposed: boolean = false;


  constructor() { 
    _initAdobeDataLayerOnce();
  }

  public get adobeDataLayer(): IAdobeDataLayer {
    return window.adobeDataLayer;
  }

  public get enabled(): boolean {
    return this._enabled;
  }
  public get disabled(): boolean {
    return !this._enabled;
  }
  public setEnabled(val: boolean): void { 
    this._enabled = !!val;
  }
  
  public get verbose(): boolean {
    return this._verbose;
  }
  public setVerbose(val: boolean): void { 
    this._verbose = !!val;
  }
  protected log(...args): void {
    if (this._verbose) { 
      let prefix: string = "Tracker:";
      console.log(prefix, ...args);
    }
  }

  // page should be triggered for every page or view that is displayed
  public page(routeName: string|undefined = undefined): void {
    if (!this._enabled) { return; }
    
    if (routeName === undefined) { 
      routeName = getTrackerActiveRouteName();
    }
    return this._addPageBlock(routeName, false);
  }

  public pageNotFound(routeName: string|undefined = undefined): void {
    if (!this._enabled) { return; }
    
    if (routeName === undefined) { 
      routeName = getTrackerActiveRouteName();
    }
    return this._addPageBlock(routeName, true);
  }
  
  protected _addPageBlock(routeName: string, is404: boolean = false): void {
    if (!this._enabled) { return; }

    // all values sould be lowercase
    routeName = this._normalize(routeName);

    let parentRoute: string = this._getParentRouteName(routeName);
    let childRoute: string = this._getChildRouteName(routeName);
    let experienceType: string = isMobile? AdobeExperienceTypes.mobile: AdobeExperienceTypes.desktop;
    let isSecure: boolean = getTrackerIsProtectedMode();
    let langIsoCode: string = this._normalize(getTrackerLanguageIsoCode());
    let lang: string = this._normalize(getLanguageShortCodeFromIsoCode(langIsoCode));

    let block: any = {
      event: AdobeEventTypes.pageTrack,
      data: {
        page: {
          experienceType,
          is404,
          lang,
          name: childRoute,
          sitesection1: isSecure? "secure": "public",
          sitesection2: parentRoute,
          sitesection3: "",
          sitesection4: "",
          test: "",
        }
      }
    };
    this.adobeDataLayer.push(block);

    if (this.verbose) { 
      this.log("pageBlock: ", block);
    }
  }

  // click is short for any internal linkTrack event
  public click(buttonName: string, location: string): void {
    return this._addLinkBlock(buttonName, location, AdobeLinkTypes.internal);
  }

  // click is short for any internal linkTrack event
  public externalLink(buttonName: string, location: string): void {
    return this._addLinkBlock(buttonName, location, AdobeLinkTypes.external);
  }

  protected _addLinkBlock(buttonName: string, location: string, type: string): void {
    if (!this._enabled) { return; }

    // all values sould be lowercase
    buttonName = this._normalize(buttonName);
    location = this._normalize(location);
    type = this._normalize(type);

    let block: any = {
      event: AdobeEventTypes.linkTrack,
      data: {
        link: {
          name: buttonName,             // if PII or no text value exists please request an alternate value
          location,                     // examples:"header nav", "hero", "find your plan", etc
          type,                         // examples:"internal", "external", "phone", "exit".  internal should used when staying inside the application.
        }
      }
    };
    this.adobeDataLayer.push(block);
    
    if (this.verbose) { 
      this.log("linkBlock: ", block);
    }
  }

  // form loaded should be first, before formStarted or formStep
  public formLoaded(step: string): void {
    return this._addFormBlock(AdobeEventTypes.formLoaded, step, null);    
  }

  // formStarted should come after formLoaded but before formStep
  public formStarted(step: string): void {
    return this._addFormBlock(AdobeEventTypes.formStarted, step, null);    
  }

  // formStep shoud come after formLoaded and formStarted
  public formStep(step: string): void {
    return this._addFormBlock(AdobeEventTypes.formStep, step, null);    
  }

  // formCompleted should come last.  it is assumed that formLoaded and formStarted were already sent
  public formCompleted(step: string): void {
    return this._addFormBlock(AdobeEventTypes.formComplete, step, null);    
  }

  // formError should usually occur between formStarted and formCompleted
  public formError(fieldAlerts: FieldAlertError[]): void {
    if (!this._enabled) { return; }
    
    if (!fieldAlerts || fieldAlerts.length === 0) { return; }
    if (!Array.isArray(fieldAlerts)) { fieldAlerts = [ fieldAlerts ]; }
    let step: string = getTrackerActiveRouteName();
    return this._addFormBlock(AdobeEventTypes.formError, step, fieldAlerts);    
  }
  
  protected _addFormBlock(event: string, step: string, fieldAlerts: FieldAlertError[] | null): void {
    if (!this._enabled) { return; }

    // all values sould be lowercase
    step = this._normalize(step);
    
    let field: string = "";
    let alert: string[] = [];
    if (fieldAlerts && fieldAlerts.length > 0) {
      field = fieldAlerts.map(v => v.field).join(", ");
      alert = fieldAlerts.map(v => v.alert);

      // all values sould be lowercase
      field = this._normalize(field);
      alert = alert.map(v => this._normalize(v));
    }

    let block: any = {
      event,
      data: {
        form: { 
          step,
          name: this.formName,
          field,                // comma delimited list of fields with errors.  max limit of 255 chars
          alert,                // array of strings with short error codes for each field with an error.  max total limit of 255 chars
        }
      }
    };
    this.adobeDataLayer.push(block);

    if (this.verbose) { 
      this.log("formBlock: ", block);
    }
  }

  // 
  /**
   * convienience helper to watch for form errors and track them
   * @param watchers 
   * ex:
   * useEffect(() => {
   *   tracker.watchFormErrors([
   *     { field: "dob", alert: "dob invalid", value: dobFieldError },
   *     { field: "address", alert: "address invalid", value: addressFieldError },
   *   ]);
   * }, [dobFieldError, addressFieldError]);
   */
  public watchFormErrors(watchers: FieldAlertWatcher[]) {
    if (!this._enabled) { return; }

    if (watchers && watchers.length > 0) { 
      let alerts: FieldAlertError[] = [];
      watchers.map(watcher => {
        let value = watcher?.value;
        if (typeof value === 'function') { 
          try {
            value = value();
          } catch (ex) { 
            value = false; 
          }
        }
        if (value) { 
          alerts.push({ field: watcher.field, alert: watcher.alert });
        }
        return undefined;
      });
      if (alerts.length > 0) { 
        this.formError(alerts);
      }
    }
  }

  protected _getParentRouteName(fullRouteName: string): string {
    let parts: string[] = (fullRouteName || "").split("/");
    parts.pop();
    return parts.join("/");
  }

  protected _getChildRouteName(fullRouteName: string): string {
    return (fullRouteName || "").split("/").pop() || "";
  }

  protected _normalize(str: string): string {
    // All the data layer values should be in lowercase, including the form.name
    if (str) { str = str.toLowerCase(); }
    return str;
  }

}


// export the singleton instance by default
const tracker: Tracker = Tracker.getInstance();
export { tracker };


