import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useIsFirstRender from "../lib/hooks/useIsFirstRender";
import { useAppSelector } from "../app/hooks";
import { appFonts, useAppTheme } from "../theme";
import { ReactComponent as DoctorsSvg } from "../assets/illustration_doctors.svg";
import { groupAppointmentsByDay, hasConfirmedPreRegDoneAppointmentWithinTimeWindow, SingleApptState } from "../features/appt/apptSlice";
import KioskStartOverPrompt from "../Components/Kiosk/KioskStartOverPrompt";
import { AppointmentCard } from "../Components/Appointments";
import { SurveyConfig, SurveyOverlay, SurveyPanel } from "../Components/Survey";
import Header from "../Components/Header";
import Aem from "../lib/aem/components/Aem";
import { AppointmentArrivalTimeRange } from "../data/apptData";


const imageVerticalShift: number = 25;
const maxCardW: number = 450;

const useStyles = makeStyles({
  root: {
    position: "relative",
    color: "#ffffff",
    width: "100%",
    height: "100%",
    fontFamily: appFonts.regular,
  },
  graphicArea: {
    marginTop: `${imageVerticalShift + 15}px`,
    marginBottom: `-${imageVerticalShift}px`,
  },  
  graphic: {
    width: "100%",
    height: "100%",
    maxWidth: 372,
    maxHeight: 188,
    textAlign: "center",
    display: "inherit",
  },
  content: {
    marginTop: "25px",
    padding: "0 16px 0 16px",
  },
  multiApptCards: {
    padding: "0",
    textAlign: "left",
  },
  label1: {
    fontFamily: appFonts.bold,
    fontSize: "22px",
    textAlign: "center",
    marginBottom: "16px",
  },
  label2: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    textAlign: "center",
  },
  label3: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    textAlign: "center",
  },
  label4: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    textAlign: "left",
  },
  labelBold: {
    fontFamily: appFonts.bold,
  },
  allCardsArea: {
    maxWidth: `${maxCardW}px`,
    marginTop: "45px",
  },
  groupedCardsArea: {
    margin: "15px auto 45px auto",
    "& > *": {
      marginBottom: "16px",
    },
  },
  link:{
    color:"#ff612b",
    '&:hover':{
      color:'white',
    }
  },
  cardArea: {
  },
  startOverText: {
    margin: "22px 0 16px 0",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    textAlign: "center",
  },
  startOverActionsArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
    margin: "22px 0 0 0",
  },
  startOverActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  startOverButton: {
    margin: "0 8px",
  },
});

interface FinishScreenProps {
  apptId?: string;
}

const MultiAppointmentsFinishScreen: React.FC<FinishScreenProps> = (props: FinishScreenProps) => {
  const classes = useStyles();
  const theme = useAppTheme();
  const isFirstRender = useIsFirstRender();

  const [showSurvey, setShowSurvey] = useState<boolean>(false);
  
  const patientFirstName: string = useAppSelector<string>((state) => state.patient.first_name);
  const patientNameStr = patientFirstName ? " " + patientFirstName : "";

  // only show confirmed appointments
  const allAppointmentItems: SingleApptState[] = useAppSelector<SingleApptState[]>((state) => state.appt.allAppointmentInfos);
  const [dayGroupedApptItems, setDayGroupedApptItems] = useState<any[]>([]);

  // only show the start over button when in kiosk or publicDevice mode
  const isKioskOrPublicDeviceMode: boolean = useAppSelector<boolean>((state) => (state.preferences.isKioskMode || state.preferences.isPublicDevice));

  const hasApptWithinWindow = hasConfirmedPreRegDoneAppointmentWithinTimeWindow(
    allAppointmentItems, 
    AppointmentArrivalTimeRange.earlyTimeOffsetMins, 
    AppointmentArrivalTimeRange.lateTimeOffsetMins
  );

  const isApptConfirmed = useCallback((item: any): boolean => {
    return !!item.isConfirmed;
  }, []);  
  const isApptCancelled = useCallback((item: any): boolean => {
    return !!item.isCancelled;
  }, []);

  const handleCloseSurvey = (): void => {
    setShowSurvey(false);
  };

  const apptsTextDayPrefix: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_36", "Appointments");
  const apptsTextDay0: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_37", "Today");
  const apptsTextDay1: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_38", "Tomorrow");
  const apptsTextDay2: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_39", "in 2 Days");
  const apptsTextDay3: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_40", "in 3+ Days");
  const apptTextDayLabels: string[] = [ apptsTextDay0, apptsTextDay1, apptsTextDay2, apptsTextDay3 ];
  

  useEffect(() => {
    // watch the appointmentItems and update the visible dayGroupedApptItems
    // only show confirmed appointments
    const visibleApptItems: SingleApptState[] = allAppointmentItems.filter(v => v?.isConfirmed);
    const newDayGroupedApptItems: any[] = groupAppointmentsByDay(visibleApptItems, 0, 3);
    setDayGroupedApptItems(newDayGroupedApptItems);  
  }, [allAppointmentItems]);

  // show the survey after a short delay
  // if delay is negative, then do not show the survey
  const autoShowSurveyDelayMS = 500; 
  useEffect(() => {
    if (isFirstRender && autoShowSurveyDelayMS >= 0) {
      setTimeout(() => {
        setShowSurvey(true);
      }, autoShowSurveyDelayMS);
    }
  }, [isFirstRender, autoShowSurveyDelayMS]);  

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column" className={theme.navContent}>
        <Grid container direction="column" className={clsx(theme.navHeader, theme.bgBlueGrad)}>
          <Header center={true} showMenuButton={true} />
          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <Grid item className={classes.label1}>
                <Aem cid="BODY_MULTIAPPTFINISH_TEXT_1">
                  We look forward to seeing you, {patientNameStr}!
                </Aem>              
              </Grid>
              <Grid item className={classes.label2}>
                <Aem cid="BODY_MULTIAPPTFINISH_TEXT_2">
                  You're checked in for the appointments below.
                </Aem>
              </Grid>
              <Grid item className={classes.graphicArea}>
                <DoctorsSvg className={classes.graphic} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.content}
          >
            <Grid item className={classes.label3}>
              <Aem cid="BODY_MULTIAPPTFINISH_TEXT_3">
              Don't forget to prepare for your appointment by reviewing the visit reminder.
              </Aem>
            </Grid>

            <Grid item className={classes.label3}>
             <Aem cid="BODY_MULTIAPPTFINISH_TEXT_4">
              Log-in to your Optum Patient Portal to pay your copay today!
             </Aem>
            </Grid>

            <Grid item className={classes.label3}>
              <a href="https://patient.optum.com" target="_blank" rel="noopener noreferrer" className={classes.link}>Optum Patient Portal</a>
            </Grid>


            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="flex-start"
                className={classes.allCardsArea}
              >
                {dayGroupedApptItems.map((group, groupIdx) => {
                  if (group?.items?.length > 0) {
                    const groupKey = `g${groupIdx}`;
                    return (
                      <React.Fragment key={groupKey}>
                        <Grid item className={classes.label4}>
                          <span>{apptsTextDayPrefix}</span>
                          &nbsp;
                          <span className={classes.labelBold}>{apptTextDayLabels[group?.dayOffset]}</span>
                        </Grid>                  
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            className={classes.multiApptCards}
                          >                    
                            <Grid item className={theme.fillx}>
                              <Grid 
                                container 
                                direction="column" 
                                className={classes.groupedCardsArea}>
                                {group.items.map((item, itemIdx) => {
                                  const itemKey: string = `g${groupIdx}-i${itemIdx}`;
                                  const itemConfirmed: boolean = isApptConfirmed(item);
                                  const itemCancelled: boolean = isApptCancelled(item);
                                  return (
                                    <Grid item key={itemKey} className={classes.cardArea}>
                                      <AppointmentCard 
                                        item={item}
                                        confirmed={itemConfirmed}
                                        cancelled={itemCancelled}
                                        defaultCollapsed={false}
                                        showReminder={true}
                                        showHeaderStatus={true}
                                        showFooterStatus={false}
                                        showActionButtons={false}
                                        showCollapseButton={true}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Grid>

            {/* show the I am here button with no message */}
            {isKioskOrPublicDeviceMode && hasApptWithinWindow && (
              <KioskStartOverPrompt
                showMesssageText={false}
                showYesStartOverButton={false}
                showNoContinueButton={false}
                showIAmHereButton={true}
                checkArrivalStatus={true}
                autoHideAfterActions={true}
                enableSessionTimeoutCheck={true}
                defaultNoContinueRoute={""}
                defaultIAmHereRoute={""}
                arrivedNextRoute={"kioskArrivedFinish"}
              />
            )}
        
            {/* show the start over button with a message */}
            {isKioskOrPublicDeviceMode && (
              <KioskStartOverPrompt
                showMesssageText={true}
                showYesStartOverButton={true}
                showNoContinueButton={true}
                showIAmHereButton={false}
                checkArrivalStatus={true}
                autoHideAfterActions={false}
                enableSessionTimeoutCheck={true}
                defaultNoContinueRoute={"kioskFinish"}
                defaultIAmHereRoute={"kioskFinish"}
                arrivedNextRoute={"kioskArrivedFinish"}
              />
            )}

          </Grid>
        </Grid>
      </Grid>
      <SurveyOverlay show={showSurvey} onClickAway={handleCloseSurvey}>
        <SurveyPanel config={SurveyConfig} onClose={handleCloseSurvey} />
      </SurveyOverlay>
    </Grid>
  );
};

export default MultiAppointmentsFinishScreen;
