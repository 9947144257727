import axios from "../interceptors/interceptors";
import { AxiosRequestConfig } from "axios";
import { KioskContact} from "../features/kiosk/kioskContact";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import { GetCdoShortName } from "../data/CDO";
import config from "../config";


const handleError = (err: any, suppressError: boolean = false) => {
  return HandleApiErrorMiddleware("GetPatientIDByECKioskAPI", err, suppressError);
};

export const GetPatientIDByECKioskAPI = async (kioskContactData: KioskContact): Promise<any | undefined> => {
  let apiCfg = config.api10;
  let url: string = apiCfg.url + "easycheckKiosk?code=" + apiCfg.code;

  const cdoShortNameLowered = GetCdoShortName().toLowerCase();
  const body = {
    cdo_name: cdoShortNameLowered,
    ...kioskContactData,
  };

  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(body),
  };

  return axios(req)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      handleError(error, true);
      return undefined;
    });
};
