import _isEmpty from "lodash/isEmpty";
import config from "../../config";

type Entry = [any, any];

/**
  * convert the graphql JSON data into a flat JSON key=value object.
  * 
  * graphql JSON =>
  * {
  *   "PROP_1": "Value 1",
  *   "PROP_2": "Value 2",
  *   ...
  * } 
  */
export class AemGraphTransformer {
  public showWarnings: boolean = false;

  async convert(graphData: any): Promise<any> {
    
    const aemMediaUrl = config.aemMediaUrl?.url || "";
    
    let data: any = undefined;
    
    if (!_isEmpty(graphData)) {
      data = {};
      try {
        // loop through all items and pull out the key/value pairs
        let input: any = graphData?.data || graphData || {};
        Object.entries(input).forEach(([k1, v1]: Entry) => {
          Object.entries(v1?.items || []).forEach(([k2, v2]: Entry) => {
            Object.entries(v2 || {}).forEach(([k3, v3]: Entry) => {
              let skipAssign: boolean = false;
              if (this.showWarnings && data[k3]) { 
                console.warn("AemGraphTransformer: Duplicate Graph key found: " + k3);
              }
              if (v3 && v3.hasOwnProperty("FIELD_NAME") && v3.hasOwnProperty("CDO_VALUES")) {
                let codesList: string[] = v3.CDO_VALUES.map(v4 => v4?.Key || "");
                let valsList: string[] = v3.CDO_VALUES.map(v4 => v4?.Value || "");
                if (codesList?.length >= 0 && valsList?.length >= 0) {
                  let keyCodes: string = k3.replace(/_LIST/i, '_CODES');
                  data[keyCodes] = codesList;
                  data[k3] = valsList;
                  skipAssign = true;
                } else {
                  v3 = "";
                }
              } else if (v3 && v3.hasOwnProperty("plaintext")) { 
                v3 = String(v3.plaintext) || ""; 
              } else if (v3 && v3.hasOwnProperty("_path")) {
                v3 = v3._path? (aemMediaUrl + v3._path): "";
              } else if (v3 === undefined || v3 === null) {
                v3 = ""; 
              } else {
                v3 = String(v3) || "";  
              }
              if (!skipAssign) { 
                data[k3] = v3 || data[k3] || "";
              }
            });
          });
        });
      } catch (ex) {}
    }

    return data;    
  }  

}

export default AemGraphTransformer;

