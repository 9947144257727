import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import EventEmitter from "eventemitter3";
import {
  getNextTaskRoute,
  getPrevTaskRoute,
} from "../../Components/TaskStepper/TaskStepperHelpers";
import { invalidateDeltaTrackedState } from "../delta/deltaSlice";
import { invalidatePersistedState } from "../persisted/persistedSlice";


export interface TaskState {
  // these will changes as user navigates around applicatoin
  route: string;
  routeInfo: any;
  pendingNextRoute: any;          // set when there is a next step route pending (ex: return to review screen)
  pendingStartRoute: any;         // set when resuming a route from persisted redux state
  transitionPending: boolean;     // true when transitioning between screens.  
                                  // this only needs to be set when a loading delay is expected. 
                                  // flag is cleared after successful transition

  showMovePrev: boolean;
  showMoveNext: boolean;
  canMovePrev: boolean;
  canMoveNext: boolean;

  // these are created during app init and won't normally change
  init: boolean;
  routeInit: boolean;
  refreshConfig: number;    // defaults to 0.  increment value to re-initalize TaskStepper config.
  routeMap: any;
  allRoutes: string[];
  visibleRoutes: string[],
  parentSteps: any[];
}

const initialState: TaskState = {
  route: "",
  routeInfo: undefined,
  pendingNextRoute: "",
  pendingStartRoute: "",
  transitionPending: false,

  showMovePrev: true,
  showMoveNext: true,
  canMovePrev: true,
  canMoveNext: true,

  init: false,
  routeInit: false,
  refreshConfig: 0,
  routeMap: {},
  allRoutes: [],
  visibleRoutes: [],
  parentSteps: [],
};

const TaskEventTypes = {
  RouteChange: "RouteChange",
  RouteNotFound: "RouteNotFound",
};

const _events = new EventEmitter();
let _onBeforeRouteChangeHandler: any = null;
let _lastRoute: string|null = null;

export const addBeforeTaskRouteChangeHandler = (handler: any) => {
  _onBeforeRouteChangeHandler = handler;
};
export const removeBeforeTaskRouteChangeHandler = (_handler: any) => {
  if (_onBeforeRouteChangeHandler === _handler) {
    _onBeforeRouteChangeHandler = null;
  }
};

export const addTaskRouteChangeHandler = (handler) => {
  _events.addListener(TaskEventTypes.RouteChange, handler);
};
export const removeTaskRouteChangeHandler = (handler) => {
  _events.removeListener(TaskEventTypes.RouteChange, handler);
};

export const addTaskRouteNotFoundHandler = (handler) => {
  _events.addListener(TaskEventTypes.RouteNotFound, handler);
};
export const removeTaskRouteNotFoundHandler = (handler) => {
  _events.removeListener(TaskEventTypes.RouteNotFound, handler);
};

const _gotoTaskRoute = async (
  state: TaskState,
  dispatch: any,
  newRoute: string,
  triggerRouteChangeEvents: boolean = true,
  triggerRouteNotFoundEvents: boolean = true,
  skipIfOnErrorRoute: boolean = false,
) => {
  let updated: boolean = false;
  if (state?.init && newRoute && newRoute !== state.route) {

    // if the last route is set, but hasn't been updated in redux state yet, 
    // complete the actions, but do not trigger the route change events
    // we want to avoid triggering the same route twice in a row
    let routeChanging: boolean = (_lastRoute === undefined || newRoute !== _lastRoute)
    _lastRoute = newRoute;
        
    let oldRoute: string = state.route;
    let oldRouteInfo: any = (oldRoute && state.routeMap[oldRoute]) || null;
    
    // change the route and clear any pending routes
    let newRouteInfo: any = state.routeMap[newRoute];
    let newRouteExists: boolean = !!newRouteInfo;

    if (newRouteExists) {
    
      let canContinue = true;
      if (skipIfOnErrorRoute && oldRouteInfo && oldRouteInfo.isError) {
        canContinue = false;        
      }  
    
      if (canContinue) {
        if (routeChanging && _onBeforeRouteChangeHandler) {
          await _onBeforeRouteChangeHandler(newRoute, oldRoute, newRouteInfo, oldRouteInfo);
        }

        await dispatch(_setTaskRouteState(newRoute));
        updated = true;

        if (routeChanging && triggerRouteChangeEvents) {
          // trigger the after task route change event
          _events.emit(TaskEventTypes.RouteChange, newRoute, oldRoute, newRouteInfo, oldRouteInfo);
        }

        if (!state.init) {
          await dispatch(_setTaskRouteInit(true));
        }

        // check for changed completion data deltas
        dispatch(invalidateDeltaTrackedState());
        
        // save the new route to the persisted redux state
        dispatch(invalidatePersistedState());

        if (state.transitionPending) { 
          await dispatch(setTaskTransitionPending(false));
        }
      }
      
    } else {
      if (triggerRouteNotFoundEvents) {
        // trigger the task route not found  event
        _events.emit(TaskEventTypes.RouteNotFound, newRoute, oldRoute, newRouteInfo, oldRouteInfo);
      }

      if (state.transitionPending) { 
        await dispatch(setTaskTransitionPending(false));
      }
    }
  }
  return updated;
};

export const setTaskRoute: any = createAsyncThunk('tasks/setTaskRoute', async (route: string, thunkAPI) => {
  const state = (thunkAPI.getState() as any)?.task;
  await _gotoTaskRoute(state, thunkAPI.dispatch, route, false, true, false);  
});

export const gotoTaskRoute: any = createAsyncThunk('tasks/gotoTaskRoute', async (route: string, thunkAPI) => {
  const state = (thunkAPI.getState() as any)?.task;
  await _gotoTaskRoute(state, thunkAPI.dispatch, route, true, true, false);
});

export const gotoTaskRouteIfNoErrors: any = createAsyncThunk('tasks/gotoTaskRoute', async (route: string, thunkAPI) => {
  const state = (thunkAPI.getState() as any)?.task;
  await _gotoTaskRoute(state, thunkAPI.dispatch, route, true, true, true);
});

export const gotoPendingTaskStep: any = createAsyncThunk<boolean, string>('tasks/gotoPendingTaskStep', async (fallbackRoute: string, thunkAPI): Promise<boolean> => {
  const state = (thunkAPI.getState() as any)?.task;

  let updated: boolean = false;
  let pendingRoute: string = state.pendingNextRoute;
  let nextRoute: string = pendingRoute || fallbackRoute || "";
  if (nextRoute) {
    if (pendingRoute) {
      thunkAPI.dispatch(setPendingTaskRoute(""));
    }
    await _gotoTaskRoute(state, thunkAPI.dispatch, nextRoute, true, true, false);  
    updated = true;
  }

  return Promise.resolve(updated);
});

export const gotoNextTaskStep: any = createAsyncThunk('tasks/gotoNextTaskStep', async (_ignored: string, thunkAPI) => {
  const state = (thunkAPI.getState() as any)?.task;

  let newRoute: string = state.pendingNextRoute;
  if (!newRoute) {
    newRoute = getNextTaskRoute(state.visibleRoutes, state.allRoutes, state.route);
  }
  if (state.pendingNextRoute) {
    thunkAPI.dispatch(setPendingTaskRoute(""));
  }

  await _gotoTaskRoute(state, thunkAPI.dispatch, newRoute, true, true, false);
});

export const gotoPrevTaskStep: any = createAsyncThunk('tasks/gotoPrevTaskStep', async (_ignored: string, thunkAPI) => {
  const state = (thunkAPI.getState() as any)?.task;

  let newRoute = getPrevTaskRoute(state.visibleRoutes, state.allRoutes, state.route);
  await _gotoTaskRoute(state, thunkAPI.dispatch, newRoute, true, true, false);
});

export const gotoPrevPendingTaskStep: any = createAsyncThunk('tasks/gotoPrevPendingTaskStep', async (route: string, thunkAPI) => {
  const state = (thunkAPI.getState() as any)?.task;

  let newRoute: string = state.pendingNextRoute;
  if (!newRoute) {
    newRoute = getPrevTaskRoute(state.visibleRoutes, state.allRoutes, state.route);
  }
  if (state.pendingNextRoute) {
    thunkAPI.dispatch(setPendingTaskRoute(""));
  }

  await _gotoTaskRoute(state, thunkAPI.dispatch, newRoute, true, true, false);
});

const taskSlice = createSlice({
  name: "task",
  initialState,

  reducers: {
    _setTaskRouteState(state, action: PayloadAction<string>) {
      let newRoute: string = action.payload;
      let newRouteInfo: any = state.routeMap[newRoute];
      state.route = newRoute;
      state.routeInfo = newRouteInfo;
    },
    _setTaskRouteInit(state, action: PayloadAction<boolean>) {
      state.routeInit = true;
    },
    setTaskRouteInitState(state, action: PayloadAction<any>) {
      state.init = true;
      if (action?.payload) {
        Object.keys(action.payload).forEach((k) => {
          state[k] = action.payload[k];
        });
      }
    },
    setPendingTaskRoute(state, action: PayloadAction<string>) {
      if (!state.init) {
        return;
      }
      state.pendingNextRoute = action.payload;
    },
    setPendingStartRoute(state, action: PayloadAction<string>) {
      state.pendingStartRoute = action.payload;
    },
    setPendingStartRouteIfUnset(state, action: PayloadAction<string>) {
      if (!state.pendingStartRoute) { 
        state.pendingStartRoute = action.payload;
      }
    },    
    setTaskTransitionPending(state, action: PayloadAction<boolean>) {
      state.transitionPending = action.payload;
    },
    setTaskShowMovePrev(state, action: PayloadAction<boolean>) {
      state.showMovePrev = action.payload;
    },
    setTaskShowMoveNext(state, action: PayloadAction<boolean>) {
      state.showMoveNext = action.payload;
    },
    setTaskCanMovePrev(state, action: PayloadAction<boolean>) {
      state.canMovePrev = action.payload;
    },
    setTaskCanMoveNext(state, action: PayloadAction<boolean>) {
      state.canMoveNext = action.payload;
    },
    setRefreshTaskConfig(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.refreshConfig = state.refreshConfig + 1;
      }
    },
  },
});

const { _setTaskRouteState, _setTaskRouteInit } = taskSlice.actions;

export const {
  setTaskRouteInitState,
  setPendingTaskRoute,
  setPendingStartRoute,
  setPendingStartRouteIfUnset,
  setTaskTransitionPending,
  setTaskShowMovePrev,
  setTaskShowMoveNext,
  setTaskCanMovePrev,
  setTaskCanMoveNext,
  setRefreshTaskConfig,
} = taskSlice.actions;

export default taskSlice.reducer;
