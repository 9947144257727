// These are helpers to assist in filtering various text inputs 
// within the application

export const filterFullNameInput = (str) => {
  if (!str || typeof str !== "string") { return str; }
  return (str || "").replace(/[^\w\dÀ-ÿ\-.,' ]/g, "");
};

// filter a first, last or middle name
export const filterNamePartInput = (str) => {
  if (!str || typeof str !== "string") { return str; }
  return (str || "").replace(/[^\w\dÀ-ÿ\-.,' ]/g, "");
};

export const filterMemberIDInput = (str) => {
  if (!str || typeof str !== "string") { return str; }
  return (str || "").replace(/[^\w\d\-.]/g, "");
};

export const filterGroupNumberInput = (str) => {
  if (!str || typeof str !== "string") { return str; }
  return (str || "").replace(/[^\w\d\-.]/g, "");
};

export const filterEmailInput = (str) => {
  return str;
};

export const filterPhoneInput = (str) => {
  if (!str || typeof str !== "string") { return str; }
  return (str || "").replace(/[^\d()\-. ]/g, "");
};

export const filterAddressInput = (str) => {
  return str;
};

export const filterCityInput = (str) => {
  if (!str || typeof str !== "string") { return str; }
  return (str || "").replace(/[^\w\d\-., ]/g, "");
};

export const filterStateInput = (str) => {
  if (!str || typeof str !== "string") { return str; }
  return (str || "").replace(/[^\w\d\-., ]/g, "");
};

export const filterZipInput = (str) => {
  if (!str || typeof str !== "string") { return str; }
  if (str.length > 0) {
    let retValue = str.replace('-', '');
    if (!(retValue.match(/^\d+$/) == null)) {
      if (retValue.length >= 5 || retValue.length <= 9) {
        return retValue.replace(/(\d{5})(\d{4})/, "$1-$2");
      }
    }
    else {
      return retValue.slice(0, -1) + "";
    }
  }
  return str;
};

export const filterPhoneExtnInput = (str) => {
  if (!str || typeof str !== "string") { return str; }
  return (str || "").replace(/[^\w\d, ]/g, "");
};
