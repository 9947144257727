import React from "react";
import clsx from "clsx";
import { Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as CheckmarkSvg } from "../assets/icons/checkmark.svg";
import { appColors, appFonts } from "../theme";

const useStyle = makeStyles({
  checkboxButton: {
    padding: "0 12px",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    color: appColors.white,
  },
  checkboxChecked: {
    color: appColors.white,
  },  
  checkboxDisabled: {
    opacity: 0.5,
  },
  checkmarkArea: {
    width: "18px",
    height: "18px",
    margin: "2px",
    padding: "2px",
    border: "2px solid #ffffff",
    borderRadius: "3px",
    boxSizing: "content-box",
    "& svg": {
      transform: "translateY(-1px)",
    },
  },
  checkmarkAreaChecked: {
    borderColor: "transparent",
    backgroundColor: appColors.blueMid,
  },
  checkmarkAreaDisabled: {
    backgroundColor: appColors.mediumGray2,
  },
});

const CustomCheckIcon: React.FC<any> = (props: any) => {
  const classes = useStyle();
  const { checked, disabled } = (props || {});

  return (
    <div className={clsx(classes.checkmarkArea, { [classes.checkmarkAreaChecked]: checked, [classes.checkmarkAreaDisabled]: disabled })}>
      {checked && <CheckmarkSvg />}
    </div>
  );
};


interface CustomCheckboxProps {
  checkboxProps?: any;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = (props: CustomCheckboxProps) => {
  const classes = useStyle();
  const { checkboxProps } = props;
  const disabled = !!checkboxProps?.disabled;

  return (
    <Checkbox 
      classes={{ 
        root: classes.checkboxButton,
        checked: classes.checkboxChecked,
        disabled: classes.checkboxDisabled,
      }} 
      icon={<CustomCheckIcon checked={false} disabled={disabled} />}
      checkedIcon={<CustomCheckIcon checked={true} disabled={disabled} />}
      color="default" 
      {...checkboxProps} />
  );
};

export default CustomCheckbox;

