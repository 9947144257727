
export const RelationshipsList: string[] = [
  "Spouse",
  "Parent",
  "Child",
  "Domestic Partner",
  "Guarantor",
  "Other",
];


export const EmergencyRelationshipsList: string[] = [
  "Spouse",
  "Parent",
  "Child",
  "Sibling",
  "Friend",
  "Cousin",
  "Guardian",
  "Other",
];
