import React from "react";
import clsx from "clsx";
import { ClickAwayListener, Fade, Grid, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { appColors, appStyles, AppThemeColorKeys, getThemeBGClass, useAppTheme } from "../../theme";


const defaultScrimBgColor: string = AppThemeColorKeys.scrimBlack;

const useStyles = makeStyles({
  root: {
    ...appStyles.absxy,
    overflow: "hidden",
    pointerEvents: "none",
  },
  panelContainer: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
  },
  scrim: {
    ...appStyles.absxy,
    overflow: "hidden",
    backgroundColor: appColors.blackScrim,
    pointerEvents: "none",
    opacity: 0, 
    zIndex: 101,
    transition: "opacity 50ms ease-in-out",

    "&.show": {
      opacity: 1,
      pointerEvents: "auto",
    },
    "&.disabled": {
      pointerEvents: "none",
    },
    "&.readonly": {
      pointerEvents: "none",
    },
  },
  content: {
    position: "relative",
  },
});

interface SurveyOverlayProps {
  show?: boolean;
  scrimBgColor?: string,
  showDelayMS?: number;
  hideDelayMS?: number;
  children?: React.ReactElement<any, any> | string[] | string;
  onClickAway?: (e: MouseEvent | TouchEvent) => void;
}

export const SurveyOverlay: React.FC<SurveyOverlayProps> = (props: SurveyOverlayProps) => {
  const classes = useStyles();
  const theme = useAppTheme();
  
  const { children, show, scrimBgColor, onClickAway } = props;
  const readonly = !show;

  const scrimColorClass: any = show? getThemeBGClass(theme, scrimBgColor || defaultScrimBgColor): null;

  const handleClickAway = (e: MouseEvent | TouchEvent) => {
    if (onClickAway) { 
      onClickAway(e); 
    }
  };

  return (
    <div className={classes.root}>
      <Fade in={show}>
        <Grid 
          container 
          direction="column" 
          justifyContent="flex-end" 
          alignItems="center" 
          className={clsx(classes.scrim, scrimColorClass, { show, readonly })}>
          <Slide direction="up" in={show}>
            <div className={classes.panelContainer}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Grid container direction="column" className={classes.content}>
                  {children}
                </Grid>
              </ClickAwayListener>
            </div>
          </Slide>
        </Grid>
      </Fade>
    </div>
  );
};

SurveyOverlay.defaultProps = {
  show: false,
  scrimBgColor: defaultScrimBgColor,
  showDelayMS: 150,
  hideDelayMS: 0,
};

export default SurveyOverlay;

