import React, { useState } from "react";
import clsx from "clsx";
import useId from "@mui/utils/useId";
import { FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as QuestionCircleSvg } from "../assets/icons/question_circle_icon.svg";
import { AppButton, NavButton } from "../Components/Buttons";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  appColors,
  appFonts,
  useAppTheme,
} from "../theme";
import { SelectOption } from "../data/TypeDefs";
import { Header } from "../Components/Header"; 
import { BackButton } from "../Components/Buttons";
import { MessagePopupProps } from "../Components/Popups/MessagePopupProvider";
import { updateUIMessagePopupContext } from "../features/ui/uiSlice";
import { gotoNextTaskStep, gotoPrevTaskStep } from "../features/task/taskSlice";
import { updateAdvancedDirectiveSelectionValue } from "../features/patient/patientSlice";
import Aem, { AemContentTypes } from "../lib/aem/components/Aem";


const infoButtonSize = "28px";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  headerTitle: {
    marginTop: "0px",
  },
  navSection: {
    marginTop: "24px",
    paddingBottom: "24px",
  },
  heading: {
    fontFamily: appFonts.bold,
    fontSize: "22px",
  },
  title: {
    fontFamily: appFonts.regular,
    fontSize: "18px",
    marginTop: "20px",
  },
  sectionContent: {
    fontSize: "18px",
    fontFamily: appFonts.medium,
  },
  selectionTitle: {
    fontFamily: appFonts.bold,
    fontSize: "18px",
    marginBottom: "16px",
  },
  infoIconArea: {
    marginTop: "-4px",
    marginLeft: "16px",
    marginRight: "16px",
  },
  infoIcon: {
    fontSize: "24px",
  },
  btnInfo: {
    margin: 0,
    padding: 0,
    width: infoButtonSize,
    height: infoButtonSize,
    maxWidth: infoButtonSize,
    maxHeight: infoButtonSize,
    minWidth: infoButtonSize,
    minHeight: infoButtonSize,
    borderRadius: "50%",
    color: appColors.white,
    backgroundColor: "rgba(255,255,255,0.1)",
    border: `1px solid ${appColors.white}`,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
  },
  optionRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: "12px",
  },
  optionLabel: {
    display: "flex",
    alignItems: "center",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    fontWeight: 400,
    color: appColors.white,    
    marginLeft: "5px",
    marginRight: "5px",
  },
  selectedOptionLabel: {
    // color: appColors.darkGray1,    
    // fontFamily: appFonts.fontSemibold,
    fontWeight: 700,
  },
  radioButton: {
    marginTop: "-4px",
    "& .MuiSvgIcon-root": {
      fontSize: "28px",
    },
    color: "white",
  },
  radioChecked: {
    "&.Mui-checked": {
      color: appColors.white,
      // color: appColors.orangeMid,
    },
  },
}));

const AdvancedDirectiveScreen: React.FC = () => {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();

  const rbGroupInputId = useId();
  const rbLabelId = useId();
  const rbOptionInputIdPrefix = useId();

  const defaultSelectedValue: string = useAppSelector<string>(state => state.patient.advancedDirective.selection || "");
  const [selectedValue, setSelectedValue] = useState<string>(defaultSelectedValue);
  const hasSelection = !!selectedValue;

  const option1Text: string = Aem.get(
    "BODY_ADVANCED_DIRECTIVE_TEXT_3",
    "I have provided a copy of my Advance Healthcare Directive Form to Optum to be placed in my chart."
  );

  const option2Text: string = Aem.get(
    "BODY_ADVANCED_DIRECTIVE_TEXT_4",
    "I will provide a copy of my Advance Healthcare Directive to Optum."
  );

  const option3Text: string = Aem.get(
    "BODY_ADVANCED_DIRECTIVE_TEXT_5",
    "I do not have an Advance Healthcare Directive at this time. I understand that it is my responsibility to discuss this matter with my primary care provider."
  );

  const optionItems: SelectOption[] = [
    {
      value: "option1", 
      text: option1Text,
    },
    {
      value: "option2", 
      text: option2Text,
    },
    {
      value: "option3", 
      text: option3Text,
    },    
  ];

  const handleInfoClicked = () => {
    const titleText: string = Aem.get(
      "BODY_ADVANCED_DIRECTIVE_TEXT_6", 
      "Advance Directive Status"
    );
    const messageText: string = Aem.get(
      "BODY_ADVANCED_DIRECTIVE_TEXT_7", 
      "Optum in compliance with the Patient Self Determination Act of 1990, ensures a patient's right to self-determination by inviting patients to participate in decisions about their health care. This is accomplished through the planning and communication of their medical treatment wishes through an Advance Healthcare Directive Acknowledgement Form."
    );
    const closeText: string = Aem.get("ACTION_CLOSEBUTTON_TEXT_1", "Close");

    const popupProps: MessagePopupProps = {
      size: "medium",
      icon: null,
      title: titleText,
      message: messageText,
      actions: [
        { value: "yes", title: closeText },
      ],
      onClose: () => {},
    };
    dispatch(updateUIMessagePopupContext({ component: "MessagePopup", props: popupProps }));
  };

  const handleBackClick = async () => {
    await dispatch(gotoPrevTaskStep());
  };
    
  const handleNextClick = async () => {
    // update redux with the selected value
    dispatch(updateAdvancedDirectiveSelectionValue(selectedValue));

    return dispatch(gotoNextTaskStep());
  };

  return (
    <Grid container direction="column" className={clsx(classes.root, theme.nowrap)}>
      <Grid
        item
        className={clsx(theme.navContent, theme.nowrap)}
      >
        <Grid
          container
          direction="column"
          className={clsx(theme.navHeader, theme.bgBlueGrad)}
        >
          <Header showMenuButton={true} />

          <Grid
            container 
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
          >
            <Grid item>  
              <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
                <Aem cid="BODY_ADVANCED_DIRECTIVE_TEXT_1">
                  Advanced Healthcare Directive Acknowledgement
                </Aem>
              </h1>
            </Grid>
            <Grid item>
              <Aem cid="HEADER_PATIENTFORMSCONSENT_LOGO_1" type={AemContentTypes.imageUrl}></Aem>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          className={clsx(theme.navSection, theme.nowrap, classes.navSection)}
        >
          <Grid item className={classes.sectionContent}>
            <RadioGroup
              id={rbGroupInputId}
              aria-labelledby={rbLabelId}
              name="selectLanguage"
              value={selectedValue}
            >
              <Grid container direction="column">
                <Grid item>
                  <Grid 
                    container 
                    direction="row" 
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid id={rbLabelId} item className={classes.selectionTitle}>
                      <Aem cid="BODY_ADVANCED_DIRECTIVE_TEXT_2">
                        Advance Directive Status
                      </Aem>
                    </Grid>
                    <Grid item className={classes.infoIconArea}>
                      <AppButton 
                        className={classes.btnInfo}
                        size="small"
                        variant="outlined"
                        disableElevation={true}
                        trackName="advanced directive info"
                        trackLocation="body"
                        onClick={handleInfoClicked}
                      >
                        <QuestionCircleSvg className={classes.infoIcon} />
                      </AppButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {optionItems.map((optionItem, itemIdx) => {
                const rbInputItemId = `${rbOptionInputIdPrefix}-rb-${itemIdx}`;
                const isChecked: boolean = (selectedValue === optionItem.value);
                return (
                  <Grid key={itemIdx} item className={classes.optionRow}>
                    <Radio
                      id={rbInputItemId}
                      classes={{
                        root: classes.radioButton,
                        checked: classes.radioChecked,
                      }}
                      color="default"
                      value={optionItem.value}
                      checked={isChecked}
                      onChange={() => {
                        setSelectedValue(optionItem.value);
                      }}
                    />
                    <FormLabel
                      htmlFor={rbInputItemId}
                      className={clsx(classes.optionLabel, { [classes.selectedOptionLabel]: isChecked })}
                    >
                      {optionItem.text}
                    </FormLabel>
                  </Grid>
                );
              })}
            </RadioGroup>
          </Grid>          
        </Grid>
      </Grid>

      {/* footer section start */}
      <Grid
        container
        direction="row"
        alignItems="center"
        className={theme.navStickyFooter}
      >
        <Grid item xs={3}>
          <BackButton
            trackName="prev"
            trackLocation="nav footer"
            onClick={handleBackClick}
          />
        </Grid>
        <Grid item xs={6}>
          <NavButton
            disabled={!hasSelection}
            accentColor={"blue"}
            label={Aem.get("ACTION_NEXTBUTTON_TEXT_1", "Next")}
            fullWidth={true}
            trackName="next"
            trackLocation="nav footer"
            onClick={handleNextClick}
          />
        </Grid>
      </Grid>
      {/* footer section end */}

    </Grid>
  );
};

export default AdvancedDirectiveScreen;
