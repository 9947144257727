import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useAppSelector } from "../app/hooks";
import { appFonts, useAppTheme } from "../theme";
import { ReactComponent as DoctorsSvg } from "../assets/illustration_doctors.svg";
import KioskStartOverPrompt from "../Components/Kiosk/KioskStartOverPrompt";
import Header from "../Components/Header";
import Aem from "../lib/aem/components/Aem";


const imageVerticalShift = 25;

const useStyles = makeStyles({
  root: {
    position: "relative",
    color: "#ffffff",
    width: "100%",
    height: "100%",
    fontFamily: appFonts.regular,
  },
  apptText: {
    marginTop: "24px",
    marginBottom: "30px",
    fontFamily: appFonts.bold,
    fontSize: "22px",
    textAlign: "center",
    padding: "0 36px",
  },
  graphicArea: {
    marginBottom: `-${imageVerticalShift}px`,
  },  
  graphic: {
    width: "100%",
    height: "100%",
    maxWidth: 372,
    maxHeight: 188,
    textAlign: "center",
    display: "inherit",
  },
  content: {
    marginTop: "55px", 
    padding: "0 16px 0 16px",
  },
  label1: {
    fontFamily: appFonts.bold,
    fontSize: "22px",
    textAlign: "center",
    marginBottom: "25px",
  },
  label2: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    textAlign: "center",
    marginBottom: "25px",
  },
});

interface KioskArrivedFinishScreenProps {
}

const KioskArrivedFinishScreen: React.FC<KioskArrivedFinishScreenProps> = (props: KioskArrivedFinishScreenProps) => {
  const classes = useStyles();
  const theme = useAppTheme();
  
  const patientFirstName: string = useAppSelector((state) => state.patient.first_name);
  const patientNameStr = patientFirstName ? " " + patientFirstName : "";

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column">
        <Grid container direction="column" className={clsx(theme.navHeader, theme.bgBlueGrad)}>
          <Header center={true} showMenuButton={true} />
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={theme.textCenter}
          >
            <Grid item className={classes.graphicArea}>
              <DoctorsSvg className={classes.graphic} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="start"
          alignItems="center"
          className={clsx(theme.textCenter, classes.content)}
        >
          <Grid item className={classes.label1}>
            <Aem cid="BODY_KIOSK_FINISH_TEXT_3">
              Thank you, {patientNameStr}!
            </Aem>
          </Grid>

          <Grid item className={classes.label2}>
            <Aem cid="BODY_KIOSK_FINISH_TEXT_4">
              Please have a seat in the lobby and a clinician will be with you shortly.
            </Aem>
          </Grid>
        </Grid>

        <KioskStartOverPrompt
          showMesssageText={true}
          showYesStartOverButton={true}
          showNoContinueButton={false}
          showIAmHereButton={false}
          checkArrivalStatus={false}
          autoHideAfterActions={false}
          enableSessionTimeoutCheck={true}
          defaultNoContinueRoute={""}
          defaultIAmHereRoute={""}
          arrivedNextRoute={""}
        />

      </Grid>
    </Grid>
  );
};

export default KioskArrivedFinishScreen;
