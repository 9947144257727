import React, { useCallback, useState } from "react";
// import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomTextField from "../../Components/CustomTextField";
import { ISurveyQuestion, SurveyResponseValue } from "./SurveyDefs";
import { appFonts } from "../../theme";
import { Aem } from "../../lib/aem";


const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    fontFamily: appFonts.regular,
    fontSize: "14px",
  },
  textContainer: {
    position: "relative",
    width: "100%",
  },
  textInput: {
  },
  inputBox: {
    position: "relative",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
    minHeight: "60px",
  },
});

interface ShortAnswerResponseProps {
  questionData?: ISurveyQuestion;
  label?: string;
  defaultValue?: SurveyResponseValue;
  onChange?: (value: SurveyResponseValue) => void;
  onDone?: () => void;
};

export const ShortAnswerResponse: React.FC<ShortAnswerResponseProps> = (props: ShortAnswerResponseProps) => {
  const classes = useStyles();

  const { questionData, label, defaultValue, onChange, onDone } = props;
  const [value, setValue] = useState<string>(String(defaultValue || ""));

  const placeholder = questionData?.placeholder;
  const plaaceholderText = placeholder? Aem.get(placeholder.aemId, placeholder.text): "";

  const handleValueChanged = useCallback((newValue: string) => {
    if (newValue !== value) { 
      setValue(newValue);
    }
    if (onChange) {
      onChange(newValue);
    }
    if (onDone) {
      onDone();
    }
  }, [value, setValue, onChange, onDone]);

  return (
    <Grid 
      container 
      item
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.textContainer}
    >
      <Grid item className={classes.inputBox}>  
        <CustomTextField
          label={label}
          type="text"
          fullWidth={true}
          variant="outlined"
          color="primary"
          value={value}
          multiline={true}
          minRows={3}
          maxRows={8}
          placeholder={plaaceholderText || ""}
          onChange={(e) => handleValueChanged(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

ShortAnswerResponse.defaultProps = {
  questionData: undefined,
  label: "",
  defaultValue: "",
};

export default ShortAnswerResponse;
