import uniq from "lodash/uniq";
import {
  AemGetOptions,
  AemKVPair,
  AemLanguageCodes,
  AemPersonaTypes,
  AemCategoryVisitTypes,
  DefaultAemGetOptions,
  IAemDebugFlags,
  IAemLoader,
} from "./AemDefs";
import AemGraphqlLoader from "./AemGraphqlLoader";
import AemMockLoader from "./AemMockLoader";
import { 
  TransformAemTokenList,
  TransformAemTokenListOrItem,
  TransformAemTokens
} from "../aem/aem-utility/AemUtility";


const AEM_LOG_CACHED_DATA = (false && process.env.NODE_ENV === "development");
const AEM_LOG_FLAT_JSON_DATA = true;    // applies to logging. should list values should be converted to a single string
const AEM_LOG_FLAT_CSV_DATA = false;     // applies to logging. should aem items should be converted to CSV before logging


// subvariant keys are joined using this naming convention
// STANDARD_KEY{$personaInfix}{$categoryVisitTypeInfix}
// STANDARD_KEY         
// STANDARD_KEY_REP 
// STANDARD_KEY_REP_PHONE
// STANDARD_KEY_PHONE
const AemPersonaKeyInfixes = {
  [AemPersonaTypes.patient]: "",
  [AemPersonaTypes.representative]: "_REP",
};

const AemCategoryVisitTypeKeyInfixes = {
  [AemCategoryVisitTypes.inperson]: "",
  [AemCategoryVisitTypes.inperson]: "_PHONE",
  [AemCategoryVisitTypes.inperson]: "_VIDEO"
};

const AllSubvariantKeyInfixes = [ ...Object.values(AemPersonaKeyInfixes), ...Object.values(AemCategoryVisitTypeKeyInfixes) ].filter(v => !!v);

const DefaultAemCdoShortName: string = "";
const DefaultAemLanguageCode: AemLanguageCodes = AemLanguageCodes.eng;
const DefaultAemPersonaType: AemPersonaTypes = AemPersonaTypes.patient;
const DefaulAemCategoryVisitType: AemCategoryVisitTypes = AemCategoryVisitTypes.inperson;
const defaultDebugFlags: IAemDebugFlags = {
  error: true,
  warn: true,
  success: true,
};

let _instance: AemClient | undefined = undefined;

/**
 * The AemClient is responsible for loading AEM variations from the AEM graphql API.
 * This solution enables us to display dynamic content variations based on various properties such as the
 * CDO, persona, languageCode, etc.
 *
 * The AemClient shoudl be init by calling loadAndInitialize once during application init.  The data will be loaded and cached automatically.
 * If variation properties change, then a new call to loadAndInitialize should be made.
 *
 * Example
 * aemClient.loadAndInitialize("CDO", "self", "eng", "");
 *
 * Get Text
 * aemClient.get("PROP_ID", "Fallback Content");
 *
 * Get List
 * aemClient.getList("LIST_ID", ["A", "B", "C"]);
 */
export class AemClient {
  
  static getInstance(): AemClient {
    if (_instance === undefined) {
      _instance = new AemClient();
    }
    return _instance;
  }

  protected _enabled: boolean = true;
  protected _debugMode: boolean = false;
  protected _debugFlags: IAemDebugFlags = defaultDebugFlags;

  protected _loaderClass: any = AemGraphqlLoader;

  public setLoaderClass(val: string) {
    switch (val) {
      case "mock":
        this._loaderClass = AemMockLoader;
        break;
      default:
        this._loaderClass = AemGraphqlLoader;
        break;
    }
    this._clearCache();
  }

  // NOTE: load does not care about persona, but AEM local query helpers do
  protected _pendingAttrs: any = undefined;     // includes cdoShortName, languageCode
  protected _pendingLoad: Promise<any> | undefined = undefined;
  protected _loadCount: number = 0;

  protected _cachedAttrs: any = {};             // includes cdoShortName, languageCode
  protected _cachedData: any = {};
  protected _cacheCount: number = 1;

  // these fields are not part of the pending or cached state
  protected _persona: AemPersonaTypes = DefaultAemPersonaType;
  protected _categoryVisitType: AemCategoryVisitTypes = DefaulAemCategoryVisitType;

  public get cdoShortName(): string | undefined {
    return this._cachedAttrs.cdoShortName;
  }

  public get languageCode(): string | undefined {
    return this._cachedAttrs.languageCode;
  }

  public get persona(): string | undefined {
    // persona is not part of the loaded or cached data
    return this._persona;
  }
  
  public get categoryVisitType(): string | undefined {
    // categoryVisitType is not part of the loaded or cached data
    return this._categoryVisitType;
  }

  public get cacheCount(): number {
    return this._cacheCount;
  }

  public get enabled(): boolean {
    return this._enabled;
  }
  public get disabled(): boolean {
    return !this._enabled;
  }
  public setEnabled(val: boolean): void {
    val = !!val;
    if (this._enabled !== val) {
      this._enabled = val;
      if (!this._enabled) {
        this._clearCache();
      }
    }
  }

  public get debugMode(): boolean {
    return this._debugMode;
  }
  public setDebugMode(val: boolean): void {
    this._debugMode = !!val;
  }

  public get debugFlags(): any {
    return this._debugFlags;
  }
  public setDebuFlag(flag: string, val: boolean): void {
    this._debugFlags[flag] = !!val;
  }
  public setAllDebuFlags(flags: IAemDebugFlags): void {
    this._debugFlags = flags;
  }

  public async loadAndInitialize(
    cdoShortName: string,
    languageCode: string,
    persona: AemPersonaTypes,
    categoryVisitType: AemCategoryVisitTypes,
    forceUpdate: boolean = false
  ): Promise<boolean> {
    if (!this._enabled) {
      return false;
    }

    // assume defaults
    if (!cdoShortName) {
      cdoShortName = DefaultAemCdoShortName;
    }
    if (!languageCode) {
      languageCode = DefaultAemLanguageCode;
    }
    if (!persona) {
      persona = DefaultAemPersonaType;
    }
    if (!categoryVisitType) { 
      categoryVisitType = DefaulAemCategoryVisitType;
    }

    let updated: boolean = false;

    // keep track of whether the persona changed independent of the load state
    if (this._persona !== persona) {
      this._persona = persona;
      updated = true;
    }
    // keep track of whether the categoryVisitType changed independent of the load state
    if (this._categoryVisitType !== categoryVisitType) {
      this._categoryVisitType = categoryVisitType;
      updated = true;
    }

    // NOTE: persona and categoryVisitType do not affect load. only cdoShortName and language do
    if (this.cdoShortName !== cdoShortName || this.languageCode !== languageCode || forceUpdate) {
      let loaded = await this._loadAndInitialize(cdoShortName, languageCode, forceUpdate);
      if (loaded) { 
        updated = true;
      }
    }
    return updated;
  }

  protected _clearCache(): void {
    this._pendingAttrs = undefined;
    this._pendingLoad = undefined;
    this._cachedAttrs = {};
    this._cachedData = {};
    this._cacheCount = 0;
  }

  protected async _loadAndInitialize(
    cdoShortName: string,
    languageCode: string,
    forceUpdate: boolean = false
  ): Promise<boolean> {
    
    if (forceUpdate) { 
      // force a new load request without checking previous value
      this._pendingAttrs = undefined;
      this._pendingLoad = undefined;
    }

    if (this._pendingLoad) {
      if (this._pendingAttrs?.cdoShortName === cdoShortName && this._pendingAttrs?.languageCode === languageCode) {
        // our pending load attrs are the same, no need to update
        return false;
      }
      // we have a new load request, clear the old pending load and start a new one
      this._pendingAttrs = undefined;
      this._pendingLoad = undefined;
    }

    // use loadCount to prevent async out of order responses from clobbering the latest results
    let curLoadCount: number = ++this._loadCount;

    this._pendingAttrs = { cdoShortName, languageCode };
    this._pendingLoad = this._loadData(cdoShortName, languageCode);
    let data: any = await this._pendingLoad;

    let success: boolean = false;
    if (curLoadCount === this._loadCount) {
      this._pendingAttrs = undefined;
      this._pendingLoad = undefined;

      success = !!data;
      if (success) {
        this._cacheCount = curLoadCount;
        this._cachedAttrs = { cdoShortName, languageCode };
        this._cachedData = data;

        if (AEM_LOG_CACHED_DATA) {
          let aemData = this._cachedData;
          if (AEM_LOG_FLAT_JSON_DATA || AEM_LOG_FLAT_CSV_DATA) { 
            aemData = { ...aemData };
            Object.entries(aemData).forEach(([k, v]) => {
              if (Array.isArray(v)) { v = v.join(" | "); }
              aemData[k] = v;
            });
            if (AEM_LOG_FLAT_CSV_DATA) {
              const delim = "\t";
              aemData = Object.entries(aemData).map(([k, v]) => {
                if (!k) { k = ""; }
                if (!v) { v = ""; }
                return k + delim + v;
              })
              .sort()
              .join("\n");
            }
          }
          if (!AEM_LOG_FLAT_CSV_DATA) {
            aemData = JSON.stringify(aemData, null, 2);
          }
          console.log("aem content loaded");
          console.log("aem attributes: ", JSON.stringify(this._cachedAttrs));
          console.log(aemData);
          console.log("\n");
        }
      }
    }
    return success;
  }

  protected async _loadData(
    cdoShortName: string,
    languageCode: string,
  ): Promise<any> {
    if (!this._loaderClass) {
      return undefined;
    }

    let loader: IAemLoader = new this._loaderClass();
    loader.debugMode = this._debugMode;
    return loader.load(cdoShortName, languageCode);
  }

  protected _isSubvariantKey(propertyKey: string): boolean {
    let retval: boolean = false;
    if (propertyKey) {
      retval = !!AllSubvariantKeyInfixes.find(v => propertyKey.endsWith(v));
    }
    return retval;
  }

  protected _getPrioritizedSubvariantKeys(primaryKey: string, opts: AemGetOptions): string[] {
    let priorityKeys: string[] = [];
    if (primaryKey) { 
      priorityKeys.unshift(primaryKey);
      if (!this._isSubvariantKey(primaryKey)) {
        // if the opt value is treue, then grab the currently saved value, otherwise use the option value
        let persona: string = ((opts.persona === true)? this.persona: opts.persona) || "";
        let categoryVisitType: string = ((opts.categoryVisitType === true)? this.categoryVisitType: opts.categoryVisitType) || "";

        let personaInfix: string = "";
        let catVisitTypeInfix: string = "";
        if (persona) {
          personaInfix = AemPersonaKeyInfixes[persona] || "";
        }
        if (categoryVisitType) {
          catVisitTypeInfix = AemCategoryVisitTypeKeyInfixes[categoryVisitType] || "";
        }

        // add potential keys in reverse order so highest priority with most subvarations will be first
        if (personaInfix) { 
          priorityKeys.unshift(`${primaryKey}${personaInfix}`);    
        } 
        if (catVisitTypeInfix) { 
          priorityKeys.unshift(`${primaryKey}${catVisitTypeInfix}`);
        }
        if (personaInfix && catVisitTypeInfix) { 
          priorityKeys.unshift(`${primaryKey}${personaInfix}${catVisitTypeInfix}`);    
        } 

        // remove any potential duplicates preserving order
        priorityKeys = uniq(priorityKeys);
      }
    }
    return priorityKeys;
  }   

  public has(
    propertyKey: string,
    opts: AemGetOptions = DefaultAemGetOptions
  ): boolean {
    opts = { ...DefaultAemGetOptions, ...opts };

    let priorityKeys = this._getPrioritizedSubvariantKeys(propertyKey, opts);
    return !!priorityKeys.find(k => this.hasCachedDataKey(k));
  }

  protected hasCachedDataKey(propertyKey: string): boolean {
    return propertyKey? this._cachedData.hasOwnProperty(propertyKey): false;
  }

  public get(
    propertyKey: string,
    defaultValue: string | null = "",
    opts: AemGetOptions = DefaultAemGetOptions
  ): string | null | undefined {
    if (!this._enabled) {
      return defaultValue;
    }
    
    opts = { ...DefaultAemGetOptions, ...opts };

    let val: string | null | undefined = defaultValue;

    // create a list of potential subvariant keys sorted by priority
    let priorityKeys: string[] = this._getPrioritizedSubvariantKeys(propertyKey, opts);

    // scan through all potential keys and look for the first matching value
    // eslint-disable-next-line
    priorityKeys.find(key => {
      let tmp: string | null | undefined = key ? this._cachedData[key] : undefined;
      if (tmp !== undefined && tmp !== null) { 
        val = tmp;
        return true;
      }
    });
    
    if (opts.transformTokens && val) {
      val = TransformAemTokenListOrItem(val) as string;
    }
    return val;
  }

  public getList(
    propertyKey: string,
    defaultValue: string[] | null = [],
    opts: AemGetOptions = DefaultAemGetOptions
  ): string[] | null | undefined {
    if (!this._enabled) {
      return defaultValue;
    }

    opts = { ...DefaultAemGetOptions, ...opts };

    let items: string[] | undefined = undefined;
    let listStr: string | null | undefined = this.get(propertyKey, null, opts);
    if (listStr) {
      items = this._parseStringList(listStr);
    }
    let val: string[] | null | undefined = (items !== undefined) ? items : defaultValue;
    if (opts.transformTokens && Array.isArray(val) && val?.length > 0) { 
      val = TransformAemTokenList(val);
    }
    return val;
  }

  public getListItem(
    propertyKey: string,
    pos: number = 0,
    defaultValue: string | null = "",
    opts: AemGetOptions = DefaultAemGetOptions
  ): string | null | undefined {
    if (!this._enabled) {
      return defaultValue;
    }

    opts = { ...DefaultAemGetOptions, ...opts };

    let tmp: string | undefined = undefined;
    if (Number.isFinite(pos)) {
      let items: string[] = this.getList(propertyKey, [], opts) as string[];
      let size = items?.length;
      if (size > 0) {
        if (pos < 0) {
          // grab from end of array
          pos = size + pos;
        }
        if (pos >= 0 && pos < size) {
          tmp = items[pos];
        }
      }
    }
    let val: string | null | undefined = (tmp !== undefined && tmp !== null) ? tmp : defaultValue;
    if (opts.transformTokens) { 
      val = TransformAemTokens(val);
    }
    return val;
  }

  // convert a array of strings into AemKVPairs
  public toKvPairs(list: string[]): AemKVPair[] {
    return (list || []).map((v) => ({ Key: v, Value: v }));
  }

  public getListKVPairs(
    codesKey: string,
    propertyKey: string,
    defaultValue: any[] | null = [],
    opts: AemGetOptions = DefaultAemGetOptions
  ): AemKVPair[] | null | undefined {
    if (!this._enabled) {
      return defaultValue;
    }

    opts = { ...DefaultAemGetOptions, ...opts };

    let items: any[] | undefined = undefined;
    let tmpListStr: string[] | string | null | undefined = this.get(propertyKey, null, opts);
    let tmpCodesStr: string[] | string | null | undefined = this.get(codesKey, null, opts);
    if (tmpListStr && tmpCodesStr) {
      let listStr: string[] = this._parseStringList(tmpListStr);
      let codesStr: string[] = this._parseStringList(tmpCodesStr);
      items = codesStr.map((code, idx) => {
        let Value: string | null | undefined = listStr[idx] || "";
        if (opts.transformTokens) { 
          Value = TransformAemTokens(Value);
        }
        return { Key: code, Value };
      });
    }
    return (items !== undefined) ? items : defaultValue;
  }

  public getListKVPairItem(
    items: AemKVPair[] | null | undefined,
    key: string,
    defaultValue: any | null = null,
    ignoreCase: boolean = false
  ): AemKVPair | null | undefined {
    if (!this._enabled) {
      return defaultValue;
    }

    let item: any | null | undefined = defaultValue;
    if (items && key) {
      let srchKey: string = ignoreCase? key.toLowerCase(): key;
      for (let index in items) {
        let tmpItem: any = items[index];
        if (this._strMatches(tmpItem?.Key, srchKey, ignoreCase)) {
          item = tmpItem;
          break;
        }
      }
    }
    return item;
  }

  public getListKVPairValue(
    items: AemKVPair[] | null | undefined,
    key: string,
    defaultValue: string | null = "",
    ignoreCase: boolean = false
  ): string | null | undefined {
    if (!this._enabled) {
      return defaultValue;
    }

    let val: string | null | undefined = defaultValue;
    if (items && key) {
      let srchKey: string = ignoreCase? key.toLowerCase(): key;
      for (let index in items) {
        let item: any = items[index];
        if (this._strMatches(item?.Key, srchKey, ignoreCase)) {
          val = item.Value;
          break;
        }
      }
    }
    return val;
  }

  public getListKVPairKey(
    items: AemKVPair[] | null | undefined,
    val: string,
    defaultValue: string | null = "",
    ignoreCase: boolean = false
  ): string | null | undefined {
    if (!this._enabled) {
      return defaultValue;
    }

    let key: string | null | undefined = defaultValue;
    if (items && val) {
      let srchVal: string = ignoreCase? val.toLowerCase(): val;
      for (let index in items) {
        let item: any = items[index];
        if (this._strMatches(item?.Value, srchVal, ignoreCase)) {
          key = item.Key;
          break;
        }
      }
    }
    return key;
  }

  public transformContentTokens(
    str: string[] | string | null | undefined
  ): string[] | string | null | undefined { 
    if (str) { 
      str = TransformAemTokenListOrItem(str);
    }
    return str;
  }

  public parseListKVPairKey(
    items: AemKVPair[] | null | undefined,
    val: string,
    defaultValue: string | null = ""
  ): string | null | undefined {
    if (!this._enabled) {
      return defaultValue;
    }

    let key: string | null | undefined = defaultValue;
    if (items && val) {
      for (let index in items) {
        let item: any = items[index];
        if (item?.Value) {
          let re: RegExp = new RegExp("^" + item.Value, "gi");
          if (val.match(re)) {
            key = item.Key;
            break;
          }
        }
      }
    }
    return key;
  }

  public parseEmailListValue(
    items: AemKVPair[] | null | undefined,
    key: string,
    defaultValue: string = "false"
  ): string | null | undefined {
    if (!this._enabled) {
      return defaultValue;
    }

    let val: string = defaultValue;
    if (items && key) {
      for (let index in items) {
        let item: any = items[index];
        if (item?.Key) {
          let re = new RegExp("^" + item.Key, "gi");
          if (key.match(re) && item.Key === "A") {
            val = "true";
            break;
          }
        }
      }
    }
    return val;
  }

  protected _parseStringList(
    val: string[] | string | null | undefined,
    delim: string = ",",
    trim: boolean = true
  ): string[] {
    if (Array.isArray(val)) {
      return val;
    }

    let items: string[] = (val || "").split(delim);
    if (trim) {
      items = items.map((v) => v.trim());
    }
    return items;
  }

  protected _tryParseInt(
    val: any,
    defaultValue: number | undefined = undefined
  ): number | undefined {
    let retval: number | undefined = defaultValue;
    try {
      let tmp: any = Number.parseInt(val);
      if (Number.isFinite(tmp)) {
        retval = tmp;
      }
    } catch (ex) {}
    return retval;
  }

  protected _strMatches(str: string | undefined, preCasedCompareStr: string, ignoreCase: boolean): boolean {
    // assumes preCasedStr is already lowered if igoreCase is true
    // if str is not a valid non-empty string, then return false
    if (!str) { return false; }

    if (ignoreCase) { 
      str = str.toLowerCase();
    }
    return (str === preCasedCompareStr);
  }
  
}

export default AemClient;
