

export enum SurveyQuestionTypes {
  Likert = "likert",
  ShortAnswer = "shortAnswer",
}

export enum SurveyButtonTypes {
  Back = "back",
  Submit = "submit",
}

export interface ISurveyConfig {
  questions: ISurveyQuestion[];
  summary: ISurveySummary;
}

export interface ISurveyQuestion {
  type: SurveyQuestionTypes;
  required: boolean;
  question: ISurveyText;
  details?: ISurveyText;
  hint?: ISurveyText;
  placeholder?: ISurveyText;
}

export interface ISurveySummary {
  title: ISurveyText;
  details: ISurveyText;
}

export interface ISurveyText {
  aemId: string;
  text: string;
}

export type SurveyResponseValue = string | number | null;

export interface ISurveyResponse {
  value: SurveyResponseValue;
}

