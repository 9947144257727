import axios from "../interceptors/interceptors";
import { AxiosRequestConfig } from "axios";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import config from "../config";


const handleError = (err: any) => {
  HandleApiErrorMiddleware("UpdateFhirAPI", err);
};

export const UpdateGuarantorAPI = async (body: any) => {
  let url: string = config.saveGuarantorApi.url;
  let data = JSON.stringify(body);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(req)
    .then((response) => !!response)
    .catch(handleError);
};

export const UpdatePatientDemographicsAPI = async (body: any) => {
  let apiCfg = config.api11;
  let url: string = apiCfg.url + "SavePatientDemoGraphics?code=" + apiCfg.code;
  let data = JSON.stringify(body);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(req)
    .then((response) => !!response)
    .catch(handleError);
};

export const SaveInsuranceCoverageAPI = async (body: any) => {
  let apiCfg = config.api11;
  let url: string = apiCfg.url + "SaveInsurance?code=" + apiCfg.code;
  let data = JSON.stringify(body);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(req)
    .then((response) => !!response)
    .catch(handleError);
};

export const UpdateInsuranceCoverageAPI = async (body: any) => {
  let apiCfg = config.api11;
  let url: string = apiCfg.url + "SaveInsurance?code=" + apiCfg.code;
  let data = JSON.stringify(body);
  let req: AxiosRequestConfig = {
    method: "PATCH",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(req)
    .then((response) => !!response)
    .catch(handleError);
};
