import axios from "../interceptors/interceptors";
import { AxiosRequestConfig } from "axios";
import {
  CompletionCheckinDataSchema,
  CompletionInsuranceDataSchema, 
  CompletionPatientDataSchema,
  CompletionSignedFormsDataSchema
} from "../lib/save-state/CompletionDataSchema";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import config from "../config";


const handleError = (err: any) => {
  return HandleApiErrorMiddleware("CheckInCompleteMultiApptAPI", err);
};

export const CheckInCompleteMultiApptAPI = async (completionData: CompletionCheckinDataSchema) : Promise<any> => {
  let url: string = config.updatePMSApi20.url + "/CheckInCompleteMultiAppt";
  let data = JSON.stringify(completionData);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(req)
    .then((res) => !!res)
    .catch((error) => {
      handleError(error);      
      return false;
    });
};

export const CheckInCompleteUpdatePatientData = async (patientData: CompletionPatientDataSchema) : Promise<any> => {
  let url: string = config.updatePMSApi10.url + "/UpdatePatientData";
  let data = JSON.stringify(patientData);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(req)
    .then((res) => !!res)
    .catch((error) => {
      handleError(error);      
      return false;
    });
};

export const CheckInCompleteUpdateInsuranceData = async (insuranceData: CompletionInsuranceDataSchema) : Promise<any> => {
  let url: string = config.updatePMSApi10.url + "/UpdateInsuranceData";
  let data = JSON.stringify(insuranceData);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(req)
    .then((res) => !!res)
    .catch((error) => {
      handleError(error);      
      return false;
    });
};

export const CheckInCompleteUpdatePatientSignedForms = async (formData: CompletionSignedFormsDataSchema) : Promise<any> => {
  let url: string = config.updatePMSApi10.url + "/UpdatePatientSignedForms";
  let data = JSON.stringify(formData);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(req)
    .then((res) => !!res)
    .catch((error) => {
      handleError(error);      
      return false;
    });
};


