import axios from "../interceptors/interceptors";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import config from "../config";


const handleError = (err: any, suppressError: boolean = false) => {
  return HandleApiErrorMiddleware("GetAddressAPI", err, suppressError);
};

export const GetAddress = async (searchText = "", size = 10) => {
  let apiCfg: any = config.addressLookup;
  let url = apiCfg.url + "?auth-id=" + apiCfg.id + "&auth-token=" + apiCfg.token;
  
  return axios
    .get(url, {
      params: {
        search: searchText,
        max_results: size,
      },
    })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      handleError(error, true);
      return undefined;
    });
};


export const GetUSPSValidatedAddress =  async (address1, address2, city, state, zipcode ) => {
  let apiCfg: any = config.uspsAddressValidationLink
  let headers: any = {};

  let data: any = {
    address1: address1,
    address2: address2,
    city: city,
    state: state,
    zipcode: zipcode
  };

  let req: any = {
    method: "POST",
    url:  apiCfg.url,
    headers: headers,
    data: data
  };

   return axios(req)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      handleError(error, true);
      return undefined;
    });
};