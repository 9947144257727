import axios from "../interceptors/interceptors";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import { GetCdoShortName } from "../data/CDO";
import config from "../config";


const handleError = (err: any, suppressError: boolean = false) => {
  return HandleApiErrorMiddleware("PatientRepresentAttestAPI", err, suppressError);
};

/*
// example request payload
{
  "cdoName": "Cali",
  "patientId": "123",
  "representativeName": "John Doe's Dad",
  "timeStamp": "2022-07-12T16:49:33.3395214-05:00"
}
*/

export const patientRepresentAttestAPI = async (
  patientId: string,
  representativeName: string,
): Promise<any> => {
  let route: string = "SaveAgreement?agreementType=RepresentativeAttestation";
  let apiCfg: any = config.cosmosApi20;
  let url: string = apiCfg.url + route;
  if (apiCfg.code) { 
    url += "?code=" + apiCfg.code;
  }

  const cdoShortName = GetCdoShortName();
  const timeStamp: string = (new Date()).toISOString();

  let params: any = {
    cdoName: cdoShortName,
    patientId,
    representativeName,
    timeStamp,
  };

  let headers: any = {
    "Content-Type": "application/json",
  };

  let req: any = {
    method: "POST",
    url: url,
    headers: headers,
    data: JSON.stringify(params),
  };

  return axios(req)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      handleError(error, true);
      // NOTE: return false on API error to let the user continue
      return false;
    });
};
