import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Grid } from "@mui/material";
import { appColors, appStyles } from "../theme";
import { useLoadingState } from "../lib/loadState";

const useStyles = makeStyles({
  root: {
    ...appStyles.absxy,
    backgroundColor: appColors.blackScrim,
    pointerEvents: "none",
    opacity: 0,
    zIndex: 101,
    transition: "opacity 150ms ease-in-out",

    "&.show": {
      pointerEvents: "auto",

      "&.showSpinner": {
        opacity: 1,      
        transitionDuration: "450ms",
      },
    },
  },
  content: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  spinner: {
    "&.MuiCircularProgress-colorPrimary": {
      color: appColors.orangeMid,
  	},
  },
});

interface LoadingScrimProps {
  show?: boolean;
  showDelayMS?: number;
  hideDelayMS?: number;
  onLoadingChange?: (val:boolean)=>void;
}

const LoadingScrim: React.FC<LoadingScrimProps> = (props: LoadingScrimProps) => {
  const classes = useStyles();

  const { show, showDelayMS, hideDelayMS } = props;
  const [loadState, loadActions] = useLoadingState({
    autoUpdateSpinner: true,
    showSpinnerDelayMS: showDelayMS,
    hideSpinnerDelayMS: hideDelayMS,
  });
  const showSpinner = loadState.showSpinner;

  useEffect(() => {
    if (show !== loadState.loading) { 
      loadActions.setLoading(show);
    }
  }, [loadActions, loadState.loading, show]);

  return (
    <div className={clsx(classes.root, { show, showSpinner })}>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.content}>
        <CircularProgress color="primary" size={72} className={clsx(classes.spinner, { showSpinner })} />
      </Grid>
    </div>
  );
};

LoadingScrim.defaultProps = {
  show: true,
  showDelayMS: 1500,
  hideDelayMS: 0,
};

export default LoadingScrim;
