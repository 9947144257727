import {
  Action,
  configureStore,
  combineReducers,
  ThunkAction,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import expireReducer from "redux-persist-expire";
import uiReducer from "../features/ui/uiSlice";
import apptReducer from "../features/appt/apptSlice";
import deltaReducer from "../features/delta/deltaSlice";
import patientReducer from "../features/patient/patientSlice";
import persistedReducer from "../features/persisted/persistedSlice";
import insuranceReducer from "../features/insurance/insuranceSlice";
import taskReducer from "../features/task/taskSlice";
import prefReducer from "../features/preferences/prefSlice";
import aemReducer from "../features/aem/aemSlice";


const isDevEnv: boolean = (process.env.NODE_ENV === "development");

const middleware = (getDefaultMiddleware) => getDefaultMiddleware({
  serializableCheck: false,
});

const rawReducers = {
  ui: uiReducer,
  appt: apptReducer,
  delta: deltaReducer,
  patient: patientReducer,
  preferences: prefReducer,
  persisted: persistedReducer,
  insurance: insuranceReducer,
  task: taskReducer,
  aem: aemReducer,
};

// init the redux-persist reducer
const expireConfig = {
  expireSeconds: 30*60,   // 30 minutes
  autoExpire: true,
};

// set up the persisted state config.
// only these reducers in the whitelist will be persisted.
const whitelist = ["persisted"];
const expireTransforms = whitelist.map(key => expireReducer(key, expireConfig)); 

const persistConfig = {
  key: "easycheck-root",    // easycheck persisted state key name (should be somewhat unique)
  storage,
  transforms: [ ...expireTransforms ],
  whitelist,
};

const storagePersistedReducers = persistReducer(persistConfig, combineReducers(rawReducers));

export const store = configureStore({
  reducer: storagePersistedReducers,
  devTools: isDevEnv,
  middleware,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
