import React from "react";
import { Alert, AlertColor, Grid, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { splitByLinebreaks } from "../../utility/utilityFunctions";
import { appColors, appFonts } from "../../theme";

const useStyles = makeStyles({
  snackbar: {
    // position: "relative",
    minWidth: "250px",
  },
  alert: {
    width: "100%",
  },
  msgTitle: {
    fontFamily: appFonts.bold,
    fontSize: "16px",
    color: appColors.darkGray2,
  },
  msgText: {
    fontFamily: appFonts.medium,
    fontSize: "16px",
    color: appColors.darkGray2,
  },
});

interface AppointmentSnackbarProps {
  open: boolean;
  status: "confirmed" | "cancelled" | "" | undefined;
  title?: string;
  message?: string[] | string;
  snackbarProps?: any;
  onClose?: () => void;
}

export const AppointmentSnackbar: React.FC<AppointmentSnackbarProps> = (props: AppointmentSnackbarProps) => {
  const classes = useStyles();
 
  const { open, status, title, message, snackbarProps, onClose } = props;

  const anchorOrigin: any = { vertical: "top", horizontal: "center" };
  const severity: AlertColor | undefined = (status === "confirmed")? "success": (status === "cancelled")? "warning": undefined;
  const titleItems: string[] = splitByLinebreaks(title);
  const messageItems: string[] = splitByLinebreaks(message);

  const handleSnackbarClose = (): void => {
    if (onClose) { 
      onClose();
    }
  };
  const handleAlertClose = (): void => {
    if (onClose) { 
      onClose();
    }
  };

  return(
    <Snackbar 
      className={classes.snackbar}
      autoHideDuration={6000} 
      anchorOrigin={anchorOrigin}
      {...snackbarProps}
      open={open}
      onClose={handleSnackbarClose}
    >
      <Alert 
        className={classes.alert}
        severity={severity}
        onClose={handleAlertClose}>
        <Grid container direction="column">
          {titleItems.map((titleStr, idx) => (
            <Grid item key={idx} className={classes.msgTitle}>{titleStr}</Grid>
          ))}
          {messageItems.map((msgStr, idx) => (
            <Grid item key={idx} className={classes.msgText}>{msgStr}</Grid>
          ))}
        </Grid>
      </Alert>
    </Snackbar>
  );
};

AppointmentSnackbar.defaultProps = {
  open: false,
  // status: "",
  snackbarProps: {
    // open: true,
    // anchorOrigin: { vertical: 'top', horizontal: 'center' },
    // message: "I love snacks",
    // key: "topcenter",
  },
};
export default AppointmentSnackbar;


