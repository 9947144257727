import { AemKVPair } from "../lib/aem";


export const SexAssignedBirthList: string[] = [
  "Female",
  "Male",
  "Choose not to disclose",
  "Other",
];

export const GenderIdentityList: string[] = [
  "Female",
  "Male",
  "Genderqueer",
  "Choose not to disclose",
];

export const PreferredPronounsList: string[] = [
  "She/Her",
  "He/Him",
  "They/Them",
];

export const MaritalStatusList: string[] = [
  "Single",
  "Married",
  "Divorced",
  "Separated",
  "Partner",
  "Unknown",
];

export const RaceList: string[] = [
  "American Indian/Alaska Native",
  "Asian",
  "Black/African-American",
  "Native Hawaiian/Pacific Islander",
  "White/Caucasian",
  "Choose not to disclose",
  "Other",
];

export const EthnicityList: string[] = [
  "Hispanic/Latino",
  "Not Hispanic/Latino",
  "Choose not to disclose",
];

export const ReligiousAffiliationKVPairs: AemKVPair[] = [
  { Key: "christianity",    Value: "Christianity" },
  { Key: "islam",           Value: "Islam" },
  { Key: "judaism",         Value: "Judaism" },
  { Key: "hinduism",        Value: "Hinduism" },
  { Key: "buddhism",        Value: "Buddhism" },
  { Key: "other",           Value: "Other" },
];
