import React, { useCallback, useEffect } from "react";
import { ClickAwayListener, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import useIsMounted from "../../lib/hooks/useIsMounted";
import { appColors, appFonts } from "../../theme";
import { ErrorPopupProps } from "./ErrorPopupProvider";
import Aem from "../../lib/aem/components/Aem";
import { updatePrefLanguageIsoCode, updatePrefLanguageSet } from "../../features/preferences/prefSlice";
import { invalidateAemClientData } from "../../appInit";
import { useAppDispatch } from "../../app/hooks";
import { DefaultLanguageIsoCode } from "../../data/languageData";


const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: "355px",
    minHeight: "385px",
    padding: "24px",
    borderRadius: "16px",
    backgroundColor: appColors.darkGray1,
    color: appColors.white,
    justifyContent: "center",
  },
  title: {
    fontFamily: appFonts.medium,
    fontSize: "22px",
    textAlign: "center",
    marginBottom: "20px",
  },
  description: {
    fontFamily: appFonts.regular,
    fontSize: "18px",
    textAlign: "center",
    marginBottom: "45px",
  },
  errorIcon: {
    fontSize: "72px",
    color: appColors.darkError,
    marginBottom: "20px",
  },
  button: {
    height: "60px",
    width: "100%",
  },
});


export const KioskErrorPopup: React.FC<ErrorPopupProps> = (props: ErrorPopupProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  
  const { ariaTitleId, onClose } = props;
  
  const doCloseActions = useCallback(async (resetLanguage: boolean) => {
    if (resetLanguage) {
      dispatch(updatePrefLanguageIsoCode(DefaultLanguageIsoCode));
      dispatch(updatePrefLanguageSet(true));
      await invalidateAemClientData(dispatch, false);
    }
    if (onClose) {
      onClose();
    }
  }, [dispatch, onClose]);

  useEffect(() => {
    let timeoutId: any = setTimeout(async () => {
      timeoutId = undefined;
      if (!isMounted()) { return; }
      // if we timed out, then reset the language
      doCloseActions(true);
    }, 5000);

    return () => {
      if (timeoutId) { 
        clearTimeout(timeoutId);
        timeoutId = undefined;
      }
    };
  }, [isMounted, doCloseActions]);

  const handleOnClick = async () => {
    // if they clicked, then do not reset the language
    doCloseActions(false);
  };
  

  return (
    <ClickAwayListener onClickAway={handleOnClick}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.root}
        onClick={handleOnClick}
      >
        <ErrorOutlinedIcon className={classes.errorIcon} />
        <Grid item id={ariaTitleId} className={classes.title}>
          <Aem cid="BODY_KIOSK_ERROR_TEXT_1">
            Sorry, we couldn't find your appointment.
          </Aem>
        </Grid>
        <Grid item className={classes.description}>
          <Aem cid="BODY_KIOSK_ERROR_TEXT_2">
            Please try again or see the front desk.
          </Aem>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
};

export default KioskErrorPopup;

