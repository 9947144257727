import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { Grid, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { BackButton, CircleButton, NavButton } from "../../Components/Buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Header } from "../../Components/Header";
import { appColors, appFonts, appStyles, useAppTheme } from "../../theme";
import ProgressStepper from "../../Components/ProgressStepper";
import { gotoTaskRoute, gotoPrevTaskStep, gotoPendingTaskStep, setTaskTransitionPending } from "../../features/task/taskSlice";
import CustomCheckbox from "../../Components/CustomCheckbox";
import InsuranceCoverageForm from "./InsuranceCoverageForm";
import {
  CreateNewInsuranceCoverageItem,
  InsuranceCoverageItem,
  mergeInsuranceCoverage1,
  mergeInsuranceCoverage2,
  setHasCoverage1,
  setHasCoverage2,
  setHasSelfCoverage,
  updateInsuranceCoverage1FrontCardImage,
  updateInsuranceCoverage1BackCardImage,
  updateInsuranceCoverage2FrontCardImage,
  updateInsuranceCoverage2BackCardImage,
} from "../../features/insurance/insuranceSlice";
import { cloneDeep, formatDate } from "../../utility/utilityFunctions";
import { SaveInsuranceCoverageAPI } from "../../api/UpdateFhirAPI";
import {
  FhirInsuranceCoverageStatusCodes,
  FhirInsuranceCoverageTypeCodes,
} from "../../data/Fhir";
import Aem, { AemContentTypes, AemKVPair } from "../../lib/aem/components/Aem";
import { titleCase } from "../../utility/utilityFunctions";

import { updateUIMessagePopupContext } from "../../features/ui/uiSlice";
import { ReactComponent as EligibilityPlusSvg } from "../../assets/page_icons/icon_eligibility_plus.svg";
import { ReactComponent as EligibilityCheckSvg } from "../../assets/page_icons/icon_eligibility_check.svg";
import { ReactComponent as CheckmarkSvg } from "../../assets/icons/check-circlegreen.svg";
import { ReactComponent as ExclamationmarkSvg } from "../../assets/icons/exclamation.svg";
import { ApptState, SingleApptState, updateEligibilityAttempts, updateEligibilityVerified } from "../../features/appt/apptSlice";
import { CheckInsuranceEligibilityAPI } from "../../api/CheckInsuranceEligibilityAPI";
import { InitializeVerificationRequestData, InitialzeSubscriberData } from "../../lib/eligibility/verificationRequestDataSchema";
import LoadingSpinner from "../../Components/LoadingSpinner";
import config from "../../config";
import { PatientState } from "../../features/patient/patientSlice";
import { PayerCodeNameKVPairs } from "../../data";


const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  headerTitle: {
    marginTop: "0px",
  },
  manageButtonArea: {
    margin: "0 0 4px 0",
  },
  overlayScrim: {
    position: "fixed",
    height: "100%",
    backgroundColor: appColors.blackScrim,
    overflow: "auto",
  },
  overlayHeader: {
    position: "relative",
    marginTop: "30px",
    marginBottom: "5px",
  },
  choicesBox: {
    maxHheight: "100%",
    paddingLeft: "60px",
    paddingBottom: "20px",
  },
  choicesRow: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "49px",
    padding: "5px 16px",
    color: appColors.white,
    fontFamily: appFonts.bold,
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.3)",
    },
  },
  searchIcon: {
    width: "22px",
    height: "22px",
    margin: "10px 22px 0 22px",
    color: appColors.white,
  },
  cancelButtonContainer: {
    margin: "0 15px",
    justifyContent: "center",
  },
  cancelButton: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    minWidth: "80px",
    minHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
  },
  // foll classes for the dob input box
  formTextLabel: {
    marginLeft: "5px",
    marginRight: "5px",
    fontSize: "14px",
    fontFamily: appFonts.semibold,
    color: "rgb(255,255,255,0.7)",
  },
  inputContainer: {
    position: "relative",
    width: "100%",
    minHeight: "60px",
    padding: "5px",
    fontSize: "18px",
    color: appColors.white,
    fontFamily: appFonts.medium,
    marginBottom: "12px",
  },
  dobContainer: {
    // shift to align with other inputs
    "& span": {
      margin: "0 -5px",
      padding: "0 10px",
    },
    "& input": {
      margin: "0 -5px",
      padding: "0 10px",
    },
  },
  masked: {
    position: "relative",
    width: "100%",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    backgroundColor: "transparent",
    color: appColors.white,
    outline: "none",
    boxSizing: "border-box",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    textTransform: "uppercase",
  },
  searchInputArea: {
    width: "100%",
  },
  searchInputTextField: {
    width: "100%",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    color: appColors.white,
  },
  searchInputInput: {
    color: appColors.white,
    ...appStyles.inputUnderlineBorder,

    "&$root": {
      color: appStyles.white,
    },
    "&.MuiInputBase-root": {
      color: appStyles.white,
    },
  },
  checkboxFormLabel: {
    padding: "0",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    color: appColors.whiteA07,
  },
  pgFunc: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  headerText: {
    marginTop: "24px",
    marginBottom: "20px",
  },
  insuranceText:{
    marginTop: "24px",
    marginBottom: "20px",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    color:"#ff612b !important",
  },
  eligibleSuccessTitle: {
    fontFamily: appFonts.regular,
    fontSize: "15px",
    margin: "4px 0",
    color: appColors.success1,  
    "& span": {
      margin: "0 5px",
    }
  },
  eligibleFailedTitle: {
    fontFamily: appFonts.regular,
    fontSize: "15px",
    margin: "4px 0",
    color: appColors.darkError,
    "& span": {
      margin: "0 5px",
    }
  },
}));

const ReviewInsuranceScreen: React.FC = () => {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  
  const selfPayInputId = useId();

  const routeThemeColor: string = useAppSelector(
    (state) => state.task.routeInfo?.themeColor
  );

  const patientId: string = useAppSelector((state) => state.patient.patientId);
  const hasSelfCoverage: boolean = useAppSelector(
    (state) => state.insurance.hasSelfCoverage
  );
  const hasCoverage2: boolean = useAppSelector(
    (state) => state.insurance.hasCoverage2
  );
  const coverage1: InsuranceCoverageItem = useAppSelector(
    (state) => state.insurance.coverage1
  );
  const coverage2: InsuranceCoverageItem = useAppSelector(
    (state) => state.insurance.coverage2
  );
  const preferPersona: string = useAppSelector(
    (state) => state.preferences.persona
  );
  const [
    pendingCoverage1,
    setPendingCoverage1,
  ] = useState<InsuranceCoverageItem>(coverage1);
  const [
    pendingCoverage2,
    setPendingCoverage2,
  ] = useState<InsuranceCoverageItem>(coverage2);
  const [selfPayChecked, setSelfPayChecked] = useState<boolean>(
    hasSelfCoverage
  );
  const [showCoverage2, setShowCoverage2] = useState<boolean>(hasCoverage2);

  const [disabled, setDisabled] = useState<boolean>(false);
  const [coverage1Disabled, setCoverage1Disabled] = useState<boolean>(false);
  const [coverage2Disabled, setCoverage2Disabled] = useState<boolean>(false);

  const pendingNextRoute: string = useAppSelector(
    (state) => state.task.pendingNextRoute
  );
  const editMode: boolean = !!pendingNextRoute;

  const addInsuranceMsg: string = Aem.get(
    "BODY_INSURANCE_INFO_TEXT_10",
    "Add secondary insurance"
  );
  const removeInsuranceMsg: string = Aem.get(
    "BODY_INSURANCE_INFO_TEXT_9",
    "Remove secondary insurance"
  );

  const selfPayLabel = Aem.get(
    "BODY_INSURANCE_INFO_TEXT_2",
    "I don't have access to this information or plan to pay using other means."
  );
  const primaryInsuranceLabel = Aem.get(
    "BODY_INSURANCEREVIEW_TEXT_8",
    "Primary Insurance"
  );
  const secondaryInsuranceLabel = Aem.get(
    "BODY_INSURANCEREVIEW_TEXT_9",
    "Secondary Insurance"
  );
  // Check Eligibility
  const enableEligibilityFeature: boolean = config.enableEligibilityFeature;  
  const appt: ApptState = useAppSelector((state) => state.appt);
  const patient: PatientState = useAppSelector((state) => state.patient);
  const [hasEligibilityChecked, sethasEligibilityChecked] = useState<boolean>(false);

  const eligibilityAttempts = useAppSelector(
    (state) => state.appt.eligibilityAttempts
  );
  const eligibilityVerified = useAppSelector(
    (state) => state.appt.eligibilityVerified
  );

  const MAX_FAILED_VERIFY_ATTEMPTS: number = 1;
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);  
  const plsReEnterSubTitleText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_13", "Please re-enter your primary insurance information.");
  const insuranceVerifiedText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_16", "Insurance verified.");
  const NotVerifiedContactProvText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_17", "Your insurance could not be verified. Please contact your insurance provider for further information.");


  const handleFormCoverageChanged = (data: any, coverageNum: number) => {
    // console.log("handleFormCoverageChanged: ", { coverageNum, data });
    if (coverageNum === 1) {
      setPendingCoverage1(data);
    } else if (coverageNum === 2) {
      setPendingCoverage2(data);
    }
  };

  const handleFormDisabledChanged = (
    disabled: boolean,
    coverageNum: number
  ) => {
    if (coverageNum === 1) {
      setCoverage1Disabled(disabled);
    } else if (coverageNum === 2) {
      setCoverage2Disabled(disabled);
    }
  };

  const moveToPrevStep = async () => {
    if (editMode) {
      await dispatch(gotoPendingTaskStep("consent/review"));
    } else {
      if (preferPersona === "representative") { 
        await dispatch(gotoPrevTaskStep());
      } else {
        await dispatch(gotoTaskRoute("reviewPatient/patientPhotoID"));
      }
    }
  };

  const moveToNextStep = async () => {
    let canContinue = true;
    if (!selfPayChecked && !coverage1Disabled && enableEligibilityFeature && !eligibilityVerified && eligibilityAttempts < MAX_FAILED_VERIFY_ATTEMPTS) {
      canContinue = await CheckEligibility();
    }
    
    if (canContinue) {
      dispatch(setTaskTransitionPending(true));
      let nextTaskStep: string = await updateDetailsToAPI();
      if (editMode && nextTaskStep === "consent") {
        await dispatch(gotoPendingTaskStep(nextTaskStep));
      } else {
        await dispatch(gotoTaskRoute(nextTaskStep));
      }
    }
  };

  useEffect(() => {
    let newDisabled: boolean = false;
    if (!selfPayChecked) {
      if (coverage1Disabled || (showCoverage2 && coverage2Disabled)) {
        newDisabled = true;
      }
    }
    if (newDisabled !== disabled) {
      setDisabled(newDisabled);
    }
  }, [
    disabled,
    selfPayChecked,
    showCoverage2,
    coverage1Disabled,
    coverage2Disabled,
  ]);
  
  const clearInsuranceImages = (coverageNum: number) => {
    if (coverageNum === 1) {
      dispatch(updateInsuranceCoverage1FrontCardImage(""));
      dispatch(updateInsuranceCoverage1BackCardImage(""));
    }
    if (coverageNum === 2) {
      dispatch(updateInsuranceCoverage2FrontCardImage(""));
      dispatch(updateInsuranceCoverage2BackCardImage(""));
    }
  };

  const getSetEmptyInsuranceImagesData = (coverageNum: number): any[] => {
    let setImage: any[] = [];
    if (coverageNum === 1) {
      setImage = [
        {
          imageContents: "",
          title: "PrimaryInsuranceFront",
        },
        {
          imageContents: "",
          title: "PrimaryInsuranceBack",
        },
      ];
    }
    if (coverageNum === 2) {
      setImage = [
        {
          imageContents: "",
          title: "SecondaryInsuranceFront",
        },
        {
          imageContents: "",
          title: "SecondaryInsuranceBack",
        },
      ];
    }
    return setImage;
  };
  
  const createSelfPayCoverageApiBody = (
    coverageNum: number,
    isActive: boolean
  ) => {
    // NOTE: $CLEAR_COVERAGE_IF_SELF_PAY$
    // due to changes requested by Christian
    // if the coverage is self pay, then coverage1 and coverage2 should be blank
    let body: any = {};
    body.patientId = patientId || "";
    body.payorDisplay = "";
    body.memberId = "";
    body.groupNumber = "";
    body.policyHolder = "";
    body.relationshipToInsured = {
      system: null,
      code: null,
      display: null,
      codingSystemName: null,
    };
    body.type = FhirInsuranceCoverageTypeCodes.SelfPay;
    body.status = isActive
      ? FhirInsuranceCoverageStatusCodes.Active
      : FhirInsuranceCoverageStatusCodes.Draft;
    body.order = coverageNum;
    body.insuranceCardImages = getSetEmptyInsuranceImagesData(coverageNum);
    return body;
  };

  const convertCoverageToApiBody = (
    coverage: any,
    isActive: boolean,
    isSelfPay: boolean,
    coverageNum: number
  ) => {
    let body: any = null;
    if (coverage || isActive || isSelfPay) {
      coverage = coverage || {};
      body = {};
      body.patientId = patientId || "";
      body.payorDisplay = coverage.payorDisplay || "";
      body.memberId = coverage.memberId || "";
      body.groupNumber = coverage.groupNumber || "";
      body.policyHolder = titleCase(coverage.policyHolderName) || "";
      body.relationshipToInsured = {
        system: null,
        code: coverage.policyHolderRelationToPatientCode || "",
        display: coverage.policyHolderRelationToPatientValue || "",
        codingSystemName: null,
      };
      body.type = isSelfPay ? FhirInsuranceCoverageTypeCodes.SelfPay : ""; // NOTE: APi only allows "pay" or ""|null.  No "HIP"
      body.status = isActive
        ? FhirInsuranceCoverageStatusCodes.Active
        : FhirInsuranceCoverageStatusCodes.Draft;
      body.order = Number.isFinite(coverage.order) ? coverage.order : coverageNum;
      if (!isActive) {
        // remove insurance image when not selected
        body.insuranceCardImages = getSetEmptyInsuranceImagesData(coverageNum);
      }
    }
    return body;
  };

  const updateDetailsToAPI = async () => {
    // update state details to redux and API
    let newHasSelfCoverage: boolean = selfPayChecked;
    let newHasCoverage1: boolean = !selfPayChecked;
    let newHasCoverage2: boolean = !selfPayChecked && showCoverage2;
    let newCoverage1: InsuranceCoverageItem | null = cloneDeep(
      pendingCoverage1
    );
    let newCoverage2: InsuranceCoverageItem | null = cloneDeep(
      pendingCoverage2
    );

    // NOTE: $CLEAR_COVERAGE_IF_SELF_PAY$
    // due to changes requested by Christian
    // if the coverage is self pay, then coverage1 and coverage2 should be blank
    if (newHasSelfCoverage) {
      newCoverage1 = newCoverage1 ? CreateNewInsuranceCoverageItem() : null;
      newCoverage2 = newCoverage2 ? CreateNewInsuranceCoverageItem() : null;
    } else {
      if (!newHasCoverage1 || !newCoverage1) {
        newCoverage1 = CreateNewInsuranceCoverageItem();
      }
      if (!newHasCoverage2 || !newCoverage2) {
        newCoverage2 = CreateNewInsuranceCoverageItem();
      }
    }

    if (newCoverage1) {
      newCoverage1.order = 1;
    }
    if (newCoverage2) {
      newCoverage2.order = 2;
    }

    dispatch(setHasSelfCoverage(newHasSelfCoverage));
    dispatch(setHasCoverage1(newHasCoverage1));
    dispatch(setHasCoverage2(newHasCoverage2));
    dispatch(mergeInsuranceCoverage1(newCoverage1));
    dispatch(mergeInsuranceCoverage2(newCoverage2));

    let apiCoverage1: any;
    let apiCoverage2: any;
    if (newHasSelfCoverage) {
      apiCoverage1 = createSelfPayCoverageApiBody(1, true);
      apiCoverage2 = createSelfPayCoverageApiBody(2, false);
    } else {
      apiCoverage1 = convertCoverageToApiBody(
        newCoverage1,
        newHasCoverage1,
        newHasSelfCoverage,
        1
      );
      apiCoverage2 = convertCoverageToApiBody(
        newCoverage2,
        newHasCoverage2,
        newHasSelfCoverage,
        2
      );
    }

    // NOTE: we cannot call both API requests at the same time.
    // we must wait for the first call to finish before sending the second
    // to avoid a race condition where one of the calls clobbers the insurance
    // coverage update bundle of the other on the server.
    if (apiCoverage1) {
      await SaveInsuranceCoverageAPI(apiCoverage1);
    }
    if (apiCoverage2) {
      await SaveInsuranceCoverageAPI(apiCoverage2);
    }
    
    if (selfPayChecked === true) {
      clearInsuranceImages(1);
      clearInsuranceImages(2);
    } else if (!showCoverage2) { 
      clearInsuranceImages(2);
    }

    // if we have self coverage, then we should skip some route steps
    let nextTaskStep: string = "reviewPatient/cardImage";
    if (newHasSelfCoverage) {
      nextTaskStep = "consent";
    }
    return nextTaskStep;
  };

  // Check Eligibility
  const CheckEligibility = async (): Promise<boolean> => {

    let isEligible: boolean = false;
    
    dispatch(updateEligibilityAttempts(eligibilityAttempts + 1));

    const payerCodeNameList: AemKVPair[] = Aem.getListKVPairValue(
      "BODY_INSURANCE_INFO_PAYER_CODE_LIST_CODES",
      "BODY_INSURANCE_INFO_PAYER_CODE_LIST",
      PayerCodeNameKVPairs
    );

    let coverage: InsuranceCoverageItem | null = cloneDeep(
      pendingCoverage1
    );

    if (coverage) {
      let payerCode: string = "";
      let payerName: string = "";
      let providerKeyCode = payerCodeNameList.find((v: AemKVPair) => (v?.Value === coverage?.payorDisplay));

      if (providerKeyCode !== undefined) {
        let payerKeyCode = providerKeyCode.Key.split('||');
        if (payerKeyCode !== undefined) {
          payerCode = payerKeyCode[0];
          payerName = payerKeyCode[1];
        }
      }

      let appointmentItems: SingleApptState[] = appt.allAppointmentInfos;
      const apptDateTime: any = appointmentItems && appointmentItems.length > 0 ? formatDate(appointmentItems[0].apptDateTime, "MM/DD/YYYY") : "";

      const subscriberData = InitialzeSubscriberData(patient.first_name, patient.dob, patient.last_name, coverage.memberId);
      const data = InitializeVerificationRequestData(payerCode, payerName, subscriberData, apptDateTime, apptDateTime);

      if (enableEligibilityFeature) {
        setShowLoadingSpinner(true);
        isEligible = await CheckInsuranceEligibilityAPI(data).then(res => !!res).catch(() => false);
        setShowLoadingSpinner(false);

        dispatch(updateEligibilityVerified(isEligible));
        sethasEligibilityChecked(true);
        await showEligibilityPromptPopup(isEligible);
      }
    }

    return isEligible;
  };
 
  const showEligibilityPromptPopup = async (eligible: boolean) => {
    const verifiedTitleText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_15", "Your insurance information has been verified.");
    const sorryNotVerifiedTitleText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_11", "Sorry, the insurance information you provided could not be verified.");
    const dontHaveAccessSubTitleText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_12", "If you don't have this information you can check the box \"I don't have access.\"");
    const plsContactProvSubTitleText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_14", "To verify your insurance information and eligibility, please contact your insurance provider.");

    const okayText: string = Aem.get("ACTION_OKAYBUTTON_TEXT_1", "Okay");

    if (eligible) {
      const popupProps: any = {
        icon: <EligibilityCheckSvg />,
        title: verifiedTitleText,
        actions: [
          { value: "okay", title: okayText },
        ],
        showCancelButton: false
      };
      dispatch(updateUIMessagePopupContext({ component: "MessagePopup", props: popupProps }));
    } else if (eligibilityAttempts < MAX_FAILED_VERIFY_ATTEMPTS) {
      const popupProps: any = {
        icon: <EligibilityPlusSvg />,
        title: sorryNotVerifiedTitleText,
        message: plsReEnterSubTitleText + " \n" + dontHaveAccessSubTitleText,
        actions: [
          { value: "okay", title: okayText },
        ],
        showCancelButton: false
      };
      dispatch(updateUIMessagePopupContext({ component: "MessagePopup", props: popupProps }));
    } else {
      const popupProps: any = {
        icon: <EligibilityPlusSvg />,
        title: sorryNotVerifiedTitleText,
        message: plsContactProvSubTitleText,
        actions: [
          { value: "okay", title: okayText },
        ],
        showCancelButton: false
      };
      dispatch(updateUIMessagePopupContext({ component: "MessagePopup", props: popupProps }));
    }
  };


  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        direction="column"
        className={clsx(theme.navContent, theme.nowrap)}
      >
        <Grid
          container
          direction="column"
          className={clsx(theme.navHeader, theme.bgGreenGrad)}
        >
          <Header showMenuButton={true} />

          {!editMode && <ProgressStepper step={2} totalSteps={4} />}

          <Grid container className={classes.pgFunc}>
            <Grid item xs={10}>
              <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
                <Aem cid="HEADER_INSURANCE_INFO_TEXT_1">
                  Insurance Information
                </Aem>
              </h1>
            </Grid>
            <Grid item>
              {/* <InsSVG/> */}
              <Aem
                cid="HEADER_PATIENT_INSURANCE_LOGO_1"
                type={AemContentTypes.imageUrl}
              ></Aem>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="column" className={theme.navSection}>
          {enableEligibilityFeature && !selfPayChecked && hasEligibilityChecked && (
            <React.Fragment>
              {(eligibilityAttempts >= 1) && eligibilityVerified && (
                <Grid item className={classes.eligibleSuccessTitle}>
                  <CheckmarkSvg /><span>{insuranceVerifiedText}</span>
                </Grid>
              )}
              {(eligibilityAttempts === 1) && !eligibilityVerified && (
                <Grid item className={classes.eligibleFailedTitle}>
                  <ExclamationmarkSvg /><span>{plsReEnterSubTitleText}</span>
                </Grid>
              )}
              {(eligibilityAttempts > 1) && !eligibilityVerified && (
                <Grid item className={classes.eligibleFailedTitle}>
                  <ExclamationmarkSvg /><span>{NotVerifiedContactProvText}</span>
                </Grid>
              )}
            </React.Fragment>
          )}
          {showLoadingSpinner && (
            <Grid item><LoadingSpinner size={40} accentColor="white" /></Grid>
          )}
          <Grid item className={clsx(classes.headerText, theme.headerText)}>
            <Aem cid="HEADER_INSURANCE_INFO_TEXT_2">
              Verify that your insurance matches the information shown below. If
              it is incorrect, please update it.
            </Aem>
          </Grid>
          <Grid item className={clsx(classes.insuranceText, theme.insuranceText)}>
           <Aem cid="HEADER_INSURANCE_INFO_TEXT_3">
               If your insurance information has not changed since your last visit, 
               you do not need to update this section. 
            </Aem>
          </Grid>
          <Grid item>
            <FormControlLabel
              className={classes.checkboxFormLabel}
              control={
                <CustomCheckbox
                  checkboxProps={{
                    id: selfPayInputId,
                    checked: selfPayChecked,
                    required: false,
                    onChange: (e) => setSelfPayChecked(e.target.checked),
                  }}
                />
              }
              label={selfPayLabel}
            />
          </Grid>

          {!selfPayChecked && (
            <React.Fragment>
              <InsuranceCoverageForm
                title={primaryInsuranceLabel}
                coverage={coverage1}
                onChange={(v) => handleFormCoverageChanged(v, 1)}
                onDisabledChange={(v) => handleFormDisabledChanged(v, 1)}
              />

              {showCoverage2 && (
                <InsuranceCoverageForm
                  title={secondaryInsuranceLabel}
                  coverage={coverage2}
                  onChange={(v) => handleFormCoverageChanged(v, 2)}
                  onDisabledChange={(v) => handleFormDisabledChanged(v, 2)}
                />
              )}

              <Grid item className={classes.manageButtonArea}>
                <CircleButton
                  icon={showCoverage2 ? <RemoveIcon /> : <AddIcon />}
                  label={showCoverage2 ? removeInsuranceMsg : addInsuranceMsg}
                  trackName="add"
                  trackLocation="body"
                  onClick={() => setShowCoverage2(!showCoverage2)}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        className={theme.navStickyFooter}
        style={{
          alignItems: "center",
        }}
      >
        <Grid item xs={3}> 
        <BackButton 
            trackName="prev"
            trackLocation="nav footer"
            onClick={moveToPrevStep} />        
        </Grid>
        <Grid item xs={6}>
          <NavButton
            accentColor={routeThemeColor || "green"}
            label={Aem.get("ACTION_NEXTBUTTON_TEXT_1", "Next")}
            fullWidth={true}
            disabled={disabled}
            trackName="next"
            trackLocation="nav footer"                
            onClick={moveToNextStep}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewInsuranceScreen;
