import axios from "../interceptors/interceptors";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import config from "../config";


const handleError = (err: any, suppressError: boolean = false) => {
  return HandleApiErrorMiddleware("ValidationAPI", err, suppressError);
}; 

export const GetLinkValidation = async (apptID): Promise<any> => {
  // check status of the link
  let apiCfg: any = config.validationApi;
  let url: string = apiCfg.url + "checkLinkValidation?apptId=" + apptID;

  return axios
    .get(url)
    .then((response) => {
      return !!response?.data?.status;
    })
    .catch((error) => {
      handleError(error, true);
      return false;
    });
};

export const UpdateLinkStatus = async (apptID, status): Promise<any> => {
  // update status of the link
  let apiCfg: any = config.validationApi;
  let url: string = apiCfg.url + "updateLinkValidation?apptId=" + apptID + "&status=" + status;
  
  return axios
    .get(url)
    .then((response) => {
      // console.log("in update link status - ", response?.data);
      return !!response;
    })
    .catch((error) => {
      handleError(error, true);
      return false;
    });
};

export const GetApptDetails = async (apptID): Promise<any> => {
  // get appointment details to pass it to loading screen
  let apiCfg: any = config.validationApi;
  let url: string = apiCfg.url + "getAppointmentDetails?apptId=" + apptID;

  return axios
    .get(url)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      handleError(error, true);
      return undefined;
    });
};

export const CheckPatientDob = async (pid, dob): Promise<any> => {
  // check patient dob
  let apiCfg: any = config.validationApi;
  let url: string = apiCfg.url + "validation/dob?pid=" + pid + "&value=" + dob;
  
  return axios
    .get(url)
    .then((response) => {
      return !!response?.data?.validated;
    })
    .catch((error) => {
      handleError(error, true);
      return false;
    });
};

export const CheckPatientAddress = async (pid, address): Promise<any> => {
  // get patient address
  let apiCfg: any = config.validationApi;
  let url: string = apiCfg.url + "validation/address?pid=" + pid + "&value=" + encodeURIComponent(address);

  return axios
    .get(url)
    .then((response) => {
      return !!response?.data?.validated;
    })
    .catch((error) => {
      handleError(error, true);
      return false;
    });
};

export const GetAddressOptions = async (pid): Promise<any> => {
  // get patient address options
  let apiCfg: any = config.validationApi;
  let url: string = apiCfg.url + "getStreetOptions?pid=" + pid;
  
  return axios
    .get(url)
    .then((response) => {
      return response?.data?.addresses;
    })
    .catch((error) => {
      handleError(error, true);
      return undefined;
    });
};

export const GetPatientDetails = async (pId): Promise<any> => {
  // get patient details to update the redux
  let apiCfg: any = config.validationApi;
  let url: string = apiCfg.url + "getPatientDetails?pId=" + pId;
  
  return axios
    .get(url)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      handleError(error, true);
      return undefined;
    });
};

// GetSignedToken API call will return true or false and does not return any data
// if successfull, the SignedToken cookie should be set by the browser.
// NOTE: on localhost, a CORS issue is currently preventing this cookie from
// getting set automatically by the chrome browser.  
// But as a workaround subsequent API requests that usually expect the 
// SignedToken cookie have that authentication check disabled for the dev env only.
export const GetSignedToken = async (): Promise<boolean> => {
  // get signed token when patient is verified
  let apiCfg: any = config.validationApi;
  let url: string = apiCfg.url + "createSignedToken";
  return axios
    .get(url)
    .then((response) => {
      // this API does not return any data.
      // if successful, the response contents will be empty, but the status should be 200.
      // This API response should have a response header for Set-Cookie with
      // SignedToken=XXX where XXX is the secret.
      // The browswer should set that token automatically for us and should be 
      // attached to future API calls.
      let success = false;
      if (response?.data) {
        success = true;
      } else if (response?.data === "") {
        success = !!((+response.status) === 200);
      }
      return success;
    })
    .catch((error) => {
      // something went wrong, so log the error and return 
      // false so caller knows the call failed.
      handleError(error, true);
      return false;
    });
};

