import React from "react";
import isString from "lodash/isString";
import useId from "@mui/material/utils/useId";
import { InputLabelProps, InputProps } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { AccAutoFillTokens } from "../data/accessibility/AccessibilityData";
import { appColors, appStyles } from "../theme";


const useLabelStyles = makeStyles((theme) => ({
  root: {
    color: "rgba(255,255,255,0.7)",
    transform: "translate(0, 16px) scale(1)",

    "&.MuiFormLabel-root.Mui-focused": {
      color: "rgba(255,255,255,0.7)",
    },
    "&.MuiInputLabel-filled.MuiInputLabel-shrink": {
      color: "rgba(255,255,255,0.7)",
      transform: "translate(0, 7px) scale(0.75)",
    },
    "&.MuiFormLabel-root.Mui-disabled": {
      color: "rgba(255,255,255,0.5)",
    },
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    color: appColors.white,
    backgroundColor: "transparent",

    "&.MuiInput-input": {
      color: appColors.white,
    },
    "& .MuiInput-input.MuiInputBase-input.Mui-disabled": {
      "-webkit-text-fill-color": "rgba(255,255,255,0.5) !important",
      color: "rgba(255,255,255,0.5)",

      paddingTop: "10px",
    },
    "& .MuiInput-input.MuiInputBase-input": {
      paddingTop: "10px",
    },
    "&.MuiInput-underline": {
      ...appStyles.inputUnderlineColors,
    },
    "&.MuiFilledInput-underline": {
      ...appStyles.inputUnderlineColors,
    },
    "& .MuiFilledInput-input": {
      paddingLeft: "0",
    },

    // variation="outlined" styles
    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: appColors.white,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: appColors.white,
    },
    "&.MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: appColors.whiteA03,
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: appColors.whiteA03,
    },
  },

  focused: {},
}));

type CustomTextFieldProps = TextFieldProps & {
};

const CustomTextField: React.FC<CustomTextFieldProps> = (props: CustomTextFieldProps) => {
  const labelClasses = useLabelStyles();
  const inputClasses = useInputStyles();
  
  const inputId = useId();

  const { id, label, ...textFieldProps } = props;

  // remove any trailing * that indicate the field is required.  
  // these are added by the component when the required prop is true.
  const patchedLabel = isString(label)? String(label).replace(/[*\s]+$/, ""): label;

  const inputLabelProps: Partial<InputLabelProps> = {
    classes: labelClasses,
  };
  const inputProps: Partial<InputProps> = {
    classes: inputClasses,
  };

  return (
    <TextField
      id={id || inputId}
      variant="standard"
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
      label={patchedLabel}
      {...textFieldProps}
    />
  );
};

CustomTextField.defaultProps = {
  autoComplete: AccAutoFillTokens.off,
};

export default CustomTextField;
