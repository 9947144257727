import axios from "../interceptors/interceptors";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import config from "../config";


const handleError = (err: any, suppressError: boolean = false) => {
  return HandleApiErrorMiddleware("AggreeToTermsOfUseAPI", err, suppressError);
};

/*
// example request payloads
Sample request with NO text comment from patient:
{
  "cdo": "Cali",
  "patientId": "78901",
  "score": 8,
  "comment": null,
  "timeStamp": "02/16/2023 14:18"
}
Sample request with text comment from patient:
{
  "cdo": "Cali",
  "patientId": "89012",
  "score": 8,
  "comment": "I love easycheck!",
  "timeStamp": "02/16/2023 14:18"
}
Responses:
Success: HTTP 200 OK, no text response
Failure: HTTP 400 Bad Request, no text response
*/

export const SaveNpsSurveyResponseDataAPI = async (
  cdoShortName: string,
  patientId: string,
  likertScore: number | null,
  comment: string | null,
): Promise<any> => {
  let route: string = "/SaveNpsData";
  let apiCfg: any = config.appointmentApi;
  let url: string = apiCfg.url + route;
if (apiCfg.code) { 
    url += "?code=" + apiCfg.code;
  }

  if (!Number.isFinite(likertScore)) { likertScore = null; }
  if (!comment) { comment = null; }
  const timeStamp: string = (new Date()).toISOString();

  let params: any = {
    cdo: cdoShortName,
    patientId,
    score: likertScore,
    comment,
    timeStamp,
  };

  let headers: any = {
    "Content-Type": "application/json",
  };

  let req: any = {
    method: "POST",
    url: url,
    headers: headers,
    data: JSON.stringify(params),
  };

  return axios(req)
    .then((res) => !!res)
    .catch((error) => {
      handleError(error, true);
      // NOTE: return false on API error to let the user continue
      return false;
    });
};
