import axios from "axios";
import config from "../config";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";


const handleError = (err: any, suppressError: boolean = false) => {
  return HandleApiErrorMiddleware("AemGraphqlAPI", err, suppressError);
};

export const QueryAemContentGraphqlApi = async (
  cdoShortName: string = "Cali",
  languageCode: string = "eng",
): Promise<any> => {
  
  // english => master
  // spanish => spanish
  // NOTE: variation MUST be lowercase or it will not work
  let aemLanguage: string = _getAemFullLanguage(languageCode);
  let variation: string = _isMasterLanguageCode(languageCode)? "master": aemLanguage;

  let apiCfg = config.aemGraphqlApi;
  let url = apiCfg.url + "?cdoId=" + cdoShortName + "&variation=" + variation;
  let headers: any = {
    "Content-Type": "application/json",
  };
  if (apiCfg.code) {
    headers["Authorization"] = `Basic ${apiCfg.code}`;
  }

  let req: any = {
    method: "GET",
    url: url,
    headers: headers,
    // data: { query },   // NOTE: I turned the query param off because it did not seem to be working. all data gets returned reqardless of the query.
  };

  return axios(req)
    .then((res) => res)
    .catch((error) => {
      handleError(error, true);
      console.warn("The call to AEM has failed.");
      return {};
    });
};

const _isMasterLanguageCode = (languageCode: string): boolean => {
  return (languageCode === "eng");
};

const _getAemFullLanguage = (languageCode: string): string => {
  let retval: string = "english";
  if ((languageCode || "").match(/^spa/i)) {
    retval = "spanish";
  }
  return retval;
};
