import React, { useState, useEffect } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { Grid, Dialog, Slide, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  appColors,
  appDialogTransparentPaperProps,
  appFonts,
  useAppTheme,
} from "../theme";
import Header from "../Components/Header";
import FormErrorBanner from "../Components/Errors/FormErrorBanner";
import CustomCheckbox from "../Components/CustomCheckbox";
import ProgressStepper from "../Components/ProgressStepper";
import { AppButton, NavButton } from "../Components/Buttons";
import CustomTextField from "../Components/CustomTextField";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { gotoNextTaskStep, gotoPendingTaskStep, gotoTaskRoute, setTaskTransitionPending } from "../features/task/taskSlice";
import { TransitionProps } from "@mui/material/transitions";
import FormLabel from "@mui/material/FormLabel";
import {
  updatePrefLanguageIsoCode,
  updatePrefLanguageSet,
  updatePrefPersona,
  updatePrefPersonaInit,
  updatePrefRelationship,
  updateRepresentativeConsent,
  updateRepresentativeFullName,
} from "../features/preferences/prefSlice";
import { invalidateAemClientData } from "../appInit";
import { filterFullNameInput } from "../utility/inputFilterFunctions";
import Aem, { AemContentTypes } from "../lib/aem/components/Aem";
import { AccAutoFillTokens, AriaLive } from "../data/accessibility/AccessibilityData";
import { LanguageIsoCodes } from "../data/languageData";
import { RelationshipsList } from "../data/relationshipsData";
import { patientRepresentAttestAPI } from "../api/PatientRepresentAttestAPI";
import { tracker } from "../appTracker";


const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  headerTitle: {
    marginTop: "0px",
  },
  inpBox: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    color: "#152831",
    "& .MuiInputLabel-shrink": {
      fontSize: "14px",
      transform: "translate(0, 1.5px)",
    },
    "& .MuiFormLabel-root": {
      fontFamily: appFonts.semibold,
      letterSpacing: 0,
    },
    marginBottom: "13px",
  },
  formBody: {
    paddingLeft: "16px",
    paddingRight: "16px",
    fontSize: "18px",
    display: "flex",
    marginTop: "30px",
  },
  selectedOption: {
    marginTop: "4px",
    backgroundColor: "white",
    height: "48px",
    borderRadius: "24px",
    display: "flex",
    justifyItems: "center",
  },
  unselectedOption: {
    marginTop: "4px",
    backgroundColor: "rgba(0,0,0,0.2)",
    color: "white",
    height: "48px",
    border: "1px solid rgba(255,255,255,0.15)",
    borderRadius: "24px",
    display: "flex",
    justifyItems: "center",
  },
  selectedLabel: {
    color: "#222222",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
  },
  unselectedLabel: {
    color: appColors.white,
    fontFamily: appFonts.regular,
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
  },
  radioButton: {
    "& .MuiSvgIcon-root": {
      fontSize: "28px",
    },
    color: "white",
  },
  radioChecked: {
    "&.Mui-checked": {
      color: appColors.orangeMid,
    },
  },
  textField: {
    color: "rgba(255,255,255,0.75)",
  },
  overlayScrim: {
    position: "fixed",
    height: "100%",
    backgroundColor: appColors.blackScrim,
    overflow: "auto",
  },
  overlayHeader: {
    position: "relative",
    marginTop: "30px",
    marginBottom: "5px",
  },
  choicesBox: {
    maxHheight: "100%",
    paddingLeft: "60px",
    paddingBottom: "20px",
  },
  choicesRow: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "49px",
    padding: "5px 16px",
    color: appColors.white,
    fontFamily: appFonts.semibold,
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.3)",
    },
  },
  cancelButtonContainer: {
    margin: "0 15px",
    justifyContent: "center",
  },
  cancelButton: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    minWidth: "80px",
    minHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
  },
  overlayHeading: {
    fontFamily: appFonts.bold,
    color: appColors.white,
    paddingLeft: "16px",
    fontSize: "20px",
  },
  checkboxFormLabel: {
    padding: "0",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    color: appColors.whiteA07,

    // align the checkbox to the top of the consent text label
    "& .MuiCheckbox-root": {
      alignSelf: "flex-start",
    },
  },
  pgFunc: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  repFormArea: {
    marginTop: "21px",
  },
  repInputArea: {
    marginBottom: "20px",
  },
  repInputError: {
    fontSize: "14px",
    color: appColors.white,
    backgroundColor: "rgba(240, 89, 115, 0.2)",
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelfIdentificationScreen: React.FC = () => {
  const theme = useAppTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const repRelInputId = useId();
  const repRelErrorId = useId();
  const repNameInputId = useId();
  const repNameErrorId = useId();
  const repAgreeInputId = useId();
  const repAgreeErrorId = useId();
  const rbLangGroupInputId = useId();
  const rbLangLabelId = useId();
  const rbLangEngInputId = useId();
  const rbLangSpaInputId = useId();
  const rbRelationGroupInputId = useId();
  const rbRelationLabelId = useId();
  const rbRelationPatientInputId = useId();
  const rbRelationRepresentativeInputId = useId();
  const dialogTitleId = useId();

  const patientId: string = useAppSelector((state) => state.patient.patientId);
  const identityVerified: boolean = useAppSelector((state) => state.preferences.identityVerified);
  const statePrefPersona: string = useAppSelector((state) => state.preferences.persona);
  const statePrefPersonaInit: boolean = useAppSelector((state) => state.preferences.personaInit);
  const defaultPersona: string = statePrefPersonaInit ? statePrefPersona: "";

  const [prefLangIsoCode, setPrefLangIsoCode] = useState(
    useAppSelector((state) => state.preferences.languageIsoCode)
  );
  const [prefPersona, setPrefPersona] = useState(
    defaultPersona
  );
  const [prefRelationship, setPrefRelationship] = useState(
    useAppSelector((state) => state.preferences.relationship)
  );
  const [repFullName, setRepFullName] = useState<string>(
    useAppSelector((state) => state.preferences.representativeFullName)
  );
  const [repConsentChecked, setRepConsentChecked] = useState<boolean>(
    useAppSelector((state) => state.preferences.representativeConsent)
  );

  // used to force re-render after aem content gets reloaded when the language changes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _aemRefresh: any = useAppSelector((state) => state?.aem?.refresh);

  const [overlay, setOverlay] = useState<boolean>(false);
  
  const errorRepRelationship = (prefPersona === "representative" && !prefRelationship);
  const errorRepFullName = (prefPersona === "representative" && !repFullName);
  const errorRepConsent = (prefPersona === "representative" && !repConsentChecked);
  const hasErrors = (errorRepRelationship || errorRepFullName || errorRepConsent);
  const canMoveNext: boolean = !!prefPersona;
  
  const selectLanguageText: string = Aem.get(
    "BODY_SELFIDENTIFY_TEXT_2",
    "Select your language / Elige un idioma"
  );
  const selectRelationshipText: string = Aem.get(
    "BODY_SELFIDENTIFY_TEXT_6", 
    "I am a:"
  );

  const consentFallbackLabelText: string = 
  `I agree and confirm that I am the parent, legal guardian, or power of attorney of the patient I am registering.<br />
  <br />
  The Children’s Online Privacy Protection Act (COPPA) protects children under the age of 13 using the internet. This website  is not intended for use by children under the age of 13. UnitedHealthcare is committed to protecting your child’s privacy and does not accept or collect any information from a child under the age of 13.<br />
  <br />
  All information pertaining to children under the age of 13 is entered by the child’s parent or legal guardian.`;<br />
  
  let repConsentLabelText = Aem.get(
    "BODY_SELFIDENTIFY_TEXT_7", 
    consentFallbackLabelText,
  );
  repConsentLabelText = (repConsentLabelText || "")
    .replace(/<br\s*\/?>\n?/gmi, "<br />")
    .replace(/\n/gm, "<br />\n");
  const repConsentLabel = <div dangerouslySetInnerHTML={{ __html: repConsentLabelText }}></div>;

  const [relList, setRelList] = useState<string[]>(
    Aem.getList("BODY_SELFIDENTIFY_TEXT_LIST_1", RelationshipsList)
  );

  const [prefRelationshipId, setPrefRelationshipId] = useState(
    relList.indexOf(prefRelationship)
  );
  
  const isReturningPatient: boolean = useAppSelector(
    (state) => state.appt.isReturningPatient
  );
  const patientHasAllForms: boolean = useAppSelector(
    (state) => state.appt.patientHasAllForms
  );
  const handleNextClick = async () => {
    dispatch(setTaskTransitionPending(true));
    dispatch(updatePrefLanguageIsoCode(prefLangIsoCode));
    dispatch(updatePrefLanguageSet(true));
    dispatch(updatePrefPersona(prefPersona));
    if (prefPersona) {
      dispatch(updatePrefPersonaInit(true));
    }
    dispatch(updatePrefRelationship(prefRelationship));
    await invalidateAemClientData(dispatch, false);

    if (prefPersona === "representative" && repConsentChecked) {
      // if we are in representative mode, save their state to redux 
      // and send the API call that they attested      
      dispatch(updateRepresentativeFullName(repFullName));
      dispatch(updateRepresentativeConsent(repConsentChecked));
      await patientRepresentAttestAPI(patientId, repFullName).catch(() => false);
    }

    if (identityVerified) {      
      if (isReturningPatient) {
        if (patientHasAllForms) {
          await dispatch(gotoPendingTaskStep("consent/review"));
        } else {
          await dispatch(gotoPendingTaskStep("consent"));
        }
      } else {
        await dispatch(gotoTaskRoute("reviewPatient"));
      }
    } else {
      await dispatch(gotoNextTaskStep());
    }
  };

  const updateAemData = async (
    newPersona: string | undefined,
    newRelationship: string | undefined,
    newLangIsoCode: string | undefined
  ) => {
    if (newPersona === undefined) {
      newPersona = prefPersona;
    }
    if (newRelationship === undefined) {
      newRelationship = prefRelationship;
    }
    if (newLangIsoCode === undefined) {
      newLangIsoCode = prefLangIsoCode;
    }

    dispatch(updatePrefPersona(newPersona || ""));
    if (newPersona) { 
      dispatch(updatePrefPersonaInit(true));
    }
    dispatch(updatePrefRelationship(newRelationship || ""));
    dispatch(updatePrefLanguageIsoCode(newLangIsoCode || ""));
    dispatch(updatePrefLanguageSet(true));
    await invalidateAemClientData(dispatch, false);
    if (newLangIsoCode) {
      setRelList(
        Aem.getList("BODY_SELFIDENTIFY_TEXT_LIST_1", RelationshipsList)
      );
    }
  };

  useEffect(() => {
    if (prefRelationshipId !== -1) {
      setPrefRelationship(relList[prefRelationshipId]);
    }
  }, [prefRelationshipId, relList]);
  
  // watch for form errors and send them to the analytics tracker
  useEffect(() => {
    tracker.watchFormErrors([
      { field: "reprelationship", alert: "relationship error", value: errorRepRelationship },
      { field: "repname", alert: "name error", value: errorRepFullName },
      { field: "repconsent", alert: "consent error", value: errorRepConsent },
    ]);
  }, [errorRepRelationship, errorRepFullName, errorRepConsent]);

  const renderRepresentativeOverlay = () => {
    return (
      <Grid
        container
        direction="column"
        className={clsx(classes.overlayScrim, theme.nowrap)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={clsx(classes.overlayHeader, theme.nowrap)}
        >
          <Grid item id={dialogTitleId} className={classes.overlayHeading}>
            <Aem cid="BODY_SELFIDENTIFY_TEXT_1">
              Relationship to Patient
            </Aem>
          </Grid>
          <Grid item className={classes.cancelButtonContainer}>
            <AppButton
              variant="outlined"
              disableElevation
              className={classes.cancelButton}
              trackName="cancel"
              trackLocation="overlay"
              onClick={() => {
                setOverlay(false);
              }}
            >
              <Aem cid="ACTION_CANCELBUTTON_TEXT_1">Cancel</Aem>
            </AppButton>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          className={clsx(classes.choicesBox, theme.nowrap)}
        >
          {relList.map((option: string, idx: number) => {
            return (
              <Grid
                key={idx}
                item
                xs={12}
                className={classes.choicesRow}
                onClick={() => {
                  setOverlay(false);
                  setPrefPersona("representative");
                  setPrefRelationship(option);
                  setPrefRelationshipId(idx);
                  updateAemData("representative", option, undefined);
                }}
              >
                {option}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column" className={theme.navContent}>
        <Grid
          container
          direction="column"
          className={clsx(theme.navHeader, theme.bgOrangeGrad)}
          style={{ marginBottom: 0 }}
        >
          <Header showMenuButton={true} />
          <ProgressStepper step={1} totalSteps={3} />
          <Grid container className={classes.pgFunc}>
            <Grid item>
              <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
                <Aem cid="HEADER_SELFIDENTIFY_TEXT_1">Self-Identification</Aem>
              </h1>
            </Grid>
            <Grid item>
              {/* <ContactSVG/> */}
              <Aem
                cid="HEADER_SELFIDENTIFY_LOGO_1"
                type={AemContentTypes.imageUrl}
              ></Aem>
            </Grid>
          </Grid>
        </Grid>
        {hasErrors ? <FormErrorBanner aemId="BODY_SELFIDENTIFY_TEXT_4" /> : null}

        <Grid container direction="column" className={classes.formBody}>
          <RadioGroup
            id={rbLangGroupInputId}
            aria-labelledby={rbLangLabelId}
            name="selectLanguage"
            value={prefLangIsoCode}
          >
            <Grid item id={rbLangLabelId} style={{ fontFamily: appFonts.bold }}>
              {selectLanguageText}
            </Grid>
            <Grid
              item
              onClick={() => {
                setPrefLangIsoCode(LanguageIsoCodes.eng);
                updateAemData(undefined, undefined, LanguageIsoCodes.eng);
              }}
              className={
                prefLangIsoCode === LanguageIsoCodes.eng
                  ? classes.selectedOption
                  : classes.unselectedOption
              }
            >
              <Radio
                id={rbLangEngInputId}
                classes={{
                  root: classes.radioButton,
                  checked: classes.radioChecked,
                }}
                color="default"
                value={LanguageIsoCodes.eng}
                checked={prefLangIsoCode === LanguageIsoCodes.eng}
                onChange={(value) => {
                  setPrefLangIsoCode(LanguageIsoCodes.eng);
                  updateAemData(undefined, undefined, LanguageIsoCodes.eng);
                }}              
              />
              <FormLabel
                htmlFor={rbLangEngInputId}
                className={
                  prefLangIsoCode === LanguageIsoCodes.eng
                    ? classes.selectedLabel
                    : classes.unselectedLabel
                }
              >
                <Aem cid="BODY_SELFIDENTIFY_TEXT_LIST_2" listPos={0}>
                  English
                </Aem>
              </FormLabel>
            </Grid>
            <Grid
              item
              onClick={() => {
                setPrefLangIsoCode(LanguageIsoCodes.spa);
                updateAemData(undefined, undefined, LanguageIsoCodes.spa);
              }}
              className={
                prefLangIsoCode === LanguageIsoCodes.spa
                  ? classes.selectedOption
                  : classes.unselectedOption
              }
            >
              <Radio
                id={rbLangSpaInputId}
                classes={{
                  root: classes.radioButton,
                  checked: classes.radioChecked,
                }}
                color="default"
                value={LanguageIsoCodes.spa}
                checked={prefLangIsoCode === LanguageIsoCodes.spa}
                onChange={(value) => {
                  setPrefLangIsoCode(LanguageIsoCodes.spa);
                  updateAemData(undefined, undefined, LanguageIsoCodes.spa);
                }}
              />
              <FormLabel
                htmlFor={rbLangSpaInputId}
                className={
                  prefLangIsoCode === LanguageIsoCodes.spa
                    ? classes.selectedLabel
                    : classes.unselectedLabel
                }
              >
                <Aem cid="BODY_SELFIDENTIFY_TEXT_LIST_2" listPos={1}>
                  Español
                </Aem>
              </FormLabel>
            </Grid>
          </RadioGroup>

          <RadioGroup
            id={rbRelationGroupInputId}
            aria-labelledby={rbRelationLabelId}
            name="selectRelationship"
            value={prefPersona}
          >
            <Grid item id={rbRelationLabelId} style={{ fontFamily: appFonts.bold, marginTop: "30px" }}>
              {selectRelationshipText}
            </Grid>
            <Grid
              item
              onClick={() => {
                setPrefPersona("patient");
                updateAemData("patient", undefined, undefined);
              }}
              className={
                prefPersona === "patient"
                  ? classes.selectedOption
                  : classes.unselectedOption
              }
            >
              <Radio
                id={rbRelationPatientInputId}
                classes={{
                  root: classes.radioButton,
                  checked: classes.radioChecked,
                }}
                color="default"
                value="patient"
                checked={prefPersona === "patient"}
                onChange={(value) => {
                  setPrefPersona("patient");
                  updateAemData("patient", undefined, undefined);
                }}
              />
              <FormLabel
                htmlFor={rbRelationPatientInputId}
                className={
                  prefPersona === "patient"
                    ? classes.selectedLabel
                    : classes.unselectedLabel
                }
              >
                <Aem cid="BODY_SELFIDENTIFY_TEXT_LIST_3" listPos={0}>
                  Patient
                </Aem>
              </FormLabel>
            </Grid>
            <Grid
              item
              onClick={() => {
                setPrefPersona("representative");
                updateAemData("representative", undefined, undefined);
              }}
              className={
                prefPersona === "representative"
                  ? classes.selectedOption
                  : classes.unselectedOption
              }
            >
              <Radio
                id={rbRelationRepresentativeInputId}
                classes={{
                  root: classes.radioButton,
                  checked: classes.radioChecked,
                }}
                color="default"
                value={LanguageIsoCodes.spa}
                checked={prefPersona === "representative"}
                onChange={(value) => {
                  setPrefPersona("representative");
                  updateAemData("representative", undefined, undefined);
                }}
              />
              <FormLabel
                htmlFor={rbRelationRepresentativeInputId}
                className={
                  prefPersona === "representative"
                    ? classes.selectedLabel
                    : classes.unselectedLabel
                }
              >
                <Aem cid="BODY_SELFIDENTIFY_TEXT_LIST_3" listPos={1}>
                  Patient's Representative
                </Aem>
              </FormLabel>
            </Grid>
          </RadioGroup>

          {prefPersona === "representative" ? (
            <Grid className={classes.repFormArea}>
              <Grid className={classes.repInputArea}>
                <CustomTextField
                  id={repRelInputId}
                  className={classes.inpBox}
                  required={true}
                  error={errorRepRelationship}
                  label={
                    <Aem cid="BODY_SELFIDENTIFY_TEXT_1">
                      Relationship to Patient
                    </Aem>
                  }
                  autoComplete={AccAutoFillTokens.off}
                  type="text"
                  fullWidth={true}
                  variant="standard"
                  value={prefRelationship}
                  inputProps={{ 
                    "aria-errormessage": repRelErrorId,
                  }}
                  onClick={(e) => {
                    setOverlay(true);
                  }}
                  style={{ color: "#f05973" }}                  
                />
                {errorRepRelationship ? (
                  <Grid
                    className={classes.repInputError}
                    container
                    direction="row"
                  >
                    <Grid item id={repRelErrorId} aria-live={AriaLive.polite} style={{ padding: "11px" }}>
                      <Aem cid="BODY_SELFIDENTIFY_TEXT_5">
                        Please select your relationship to the patient.
                      </Aem>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>

              <Grid className={classes.repInputArea}>
                <CustomTextField
                  id={repNameInputId}
                  className={classes.inpBox}
                  label={Aem.get(
                    "BODY_SELFIDENTIFY_TEXT_8",
                    "Representative Name"
                  )}
                  autoComplete={AccAutoFillTokens.off}
                  name="Representative Name"
                  fullWidth={true}
                  variant="filled"
                  value={repFullName}
                  required={true}
                  inputProps={{ 
                    "aria-errormessage": repNameErrorId,
                  }}
                  onChange={(e) => {
                    setRepFullName(filterFullNameInput(e.target.value));
                  }}
                />
                {errorRepFullName ? (
                  <Grid
                    className={classes.repInputError}
                    container
                    direction="row"
                  >
                    <Grid item id={repNameErrorId} aria-live={AriaLive.polite} style={{ padding: "11px" }}>
                      <Aem cid="BODY_SELFIDENTIFY_TEXT_9">
                        Please enter your name.
                      </Aem>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>

              <Grid className={classes.repInputArea}>
                <FormControlLabel
                  className={classes.checkboxFormLabel}
                  control={
                    <CustomCheckbox
                      checkboxProps={{
                        id: repAgreeInputId,
                        checked: repConsentChecked,
                        required: true,
                        inputProps: {
                          "aria-errormessage": repAgreeErrorId,
                        },                        
                        onChange: (e) => setRepConsentChecked(e.target.checked),
                      }}
                    />
                  }
                  label={repConsentLabel}
                />
                {errorRepConsent && (
                  <Grid
                    container
                    direction="row"
                    className={classes.repInputError}
                  >
                    <Grid item id={repAgreeErrorId} aria-live={AriaLive.polite} style={{ padding: "11px" }}>
                      <Aem cid="BODY_SELFIDENTIFY_TEXT_10">
                        Please check the box to continue.
                      </Aem>
                    </Grid>
                  </Grid>
                )}
              </Grid>

            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={theme.navStickyFooter}
      >
        <Grid item xs={6}>
          <NavButton
            accentColor={"orange"}
            label={<Aem cid="ACTION_NEXTBUTTON_TEXT_1">Next</Aem>}
            fullWidth={true}
            disabled={!canMoveNext || hasErrors}
            trackName="next"
            trackLocation="nav footer"
            onClick={handleNextClick}
          />
        </Grid>
      </Grid>

      <Dialog
        fullScreen={true}
        disableRestoreFocus={true}
        open={overlay}
        onClose={() => setOverlay(false)}
        TransitionComponent={Transition}
        aria-labelledby={dialogTitleId}
        PaperProps={appDialogTransparentPaperProps}
      >
        {renderRepresentativeOverlay()}
      </Dialog>
    </Grid>
  );
};

export default SelfIdentificationScreen;
