import { Promise } from "bluebird";
import { Moment } from "moment";
import { GetSignedToken } from "./api/ValidationAPI";
import {
  GetPreferredLanguageAPI,
  GetRepresetativeDetailsAPI,
  GetAllPatientDemographicsAPI,
  GetInsuranceCoverageAPI,
} from "./api/GetFhirAPIs";
import { 
  getAllFutureAppointmentsByPatientIdAPI,
  getPatientByWebAccessCodeAPI,
} from "./api/GetAppointmentAPI";
import {
  DefaultAppointmentTypeId,
  FhirInsuranceCoverageStatusCodes,
  FhirInsuranceCoverageTypeCodes,
} from "./data/Fhir";
import { 
  initDeltaKeyValues,
  invalidateDeltaTrackedState,
  resetDeltaTrackedData,
  setDeltaTrackingEnabled,
} from "./features/delta/deltaSlice";
import {
  initialSingleApptState,  
  findBestAppointment,
  updateIsReturningPatient,
  updatePatientHasAllForms,
  updateLinkValid,
  updateLinkVerified,
  updateLinkIgnored,
  updateApptVisitType,
  updateApptCdoVisitType,
  updateApptCategoryVisitType,
  updateMultiApptAllAppointments,
  SingleApptState,
  updateNeedsAdvancedDirectiveForm,
  resetApptState,
} from "./features/appt/apptSlice";
import {
  mergeInsuranceCoverage1,
  mergeInsuranceCoverage2,
  resetInsuranceState,
  setHasCoverage1,
  setHasCoverage2,
  setHasSelfCoverage,
  updateInsuranceCoverage1BackCardImage,
  updateInsuranceCoverage1FrontCardImage,
  updateInsuranceCoverage2BackCardImage,
  updateInsuranceCoverage2FrontCardImage,
} from "./features/insurance/insuranceSlice";
import { 
  clearAndDisablePersistedState,
} from "./features/persisted/persistedSlice";
import {
  updatePatientId,
  updateAddress,
  updateCellPhone,
  updateDob,
  updateEmail,
  updateConsentToEmail,
  updateConsentToText,
  updateEmergencyContactInfo,
  updateFname,
  updateFullName,
  updateHomePhone,
  updateLname,
  updatePhotoIDBack,
  updatePhotoIDFront,
  updatePreferredPhoneType,
  updateWorkPhone,
  updateWorkPhoneExtn,
  updateDemographics,
  updateRepresentative,
  Representative,
  updateAccountBalance,
  updateCdoLanguageKey,
  updateCdoLanguageName,
  updateAdvancedDirectiveSelectionValue,
  resetPatientState,
  updateIsAdultPatient,
} from "./features/patient/patientSlice";
import { updateAemRefresh } from "./features/aem/aemSlice";
import {
  resetPrefState,
  updatePrefIdentityVerified,
  updatePrefLanguageIsoCode,
  updatePrefLanguageSet,
  updatePrefPersona,
  updatePrefProtectedMode,
  updatePrefRelationship,
} from "./features/preferences/prefSlice";
import { 
  gotoTaskRoute,
  setPendingStartRouteIfUnset,
  setRefreshTaskConfig,
} from "./features/task/taskSlice";
import {
  deleteCookie,
  eighteenYearsAgeCheck,
  getQueryStringParams,
  joinFullName,
  parseBool,
  parseToMoment,
  titleCase,
} from "./utility/utilityFunctions";
import { filterPhoneExtnInput, filterPhoneInput } from "./utility/inputFilterFunctions";
import {
  DoesCdoSupportDocCounts,
  DoesCdoSupportPciDocType,
  GetAppointmentTypeFromCdoVisitType,
  GetCdoShortName,
  GetVisitTypeFromCdoVisitType,
  IsReturningPatientAppointmentByCdoVisitType,
  ParseDocCounts,
} from "./data/CDO";
import { DocCounts, HasAllExpectedFormsForAllPciDocTypes, HasAllExpectedFormsForAppointmentTypeId, PciDocTypes } from "./data/docData";
import { DefaultLanguageIsoCode, parseLanguageNameToSupportedIsoCode, setGlobalMomentLocale } from "./data/languageData";
import { populatePatientDemographicValues } from "./lib/patient-demographics/PatientDemographicsHeleprs";
import { AddressObj, CreateNewAddressObj } from "./lib/addressUtils";
import { AemCategoryVisitTypes, AemPersonaTypes, parseAemPersonaType, parseAemCategoryVisitType } from "./lib/aem/AemDefs";
import AemClient from "./lib/aem/AemClient";
import { store } from "./app/store";
import config from "./config";


const parseValueFromParams = (
  varName: string,
  params: any,
  useQueryParams: boolean = true
): string => {
  params = params || {};
  
  let retval: string = params[varName] || "";
  if (!retval && useQueryParams) {
    // if the route did not match, then try to grab one from the query string
    let queryParams: any = getQueryStringParams() || {};
    if (queryParams[varName]) {
      retval = queryParams[varName] || "";
    }
  }
  return retval;
};

export const parsePatientIdFromParams = (
  params: any,
  useQueryParams: boolean = true
): string => {
  return parseValueFromParams("pid", params, useQueryParams);
};

export const parseWebAccessCodeFromParams = (
  params: any,
  useQueryParams: boolean = true
): string => {
  return parseValueFromParams("code", params, useQueryParams);
};

export const convertApptItemToSingleApptState = (item: any): SingleApptState => {
  if (!item) { item = {}; }
  const apptId: string = item.appt_id || "";
  const patientId: string = item.patient_id || "";

  // parse the appointment date time to a Date object
  // strip the UTC timezone and assume appointment is in local time
  let apptDateTimeCosmos = (item.appt_datetime || "").split("Z");
  let apptDateTimeCosmosLocal = apptDateTimeCosmos[0];
  let m = apptDateTimeCosmosLocal? parseToMoment(apptDateTimeCosmosLocal): undefined;
  const apptDateTime: Date | undefined = (m && m.isValid())? m.toDate(): undefined;

  const location: string = titleCase(item.location || "");
  const deptPhone: string = item.dept_phone || "";
  const deptAddress: string = titleCase(item.dept_address || "");
  const deptWebsite: string = item.dept_website || "";
  const providerName: string = item.provider_name || "";
  const patientFirstName: string = item.first_name || "";
  const patientLastName: string = item.last_name || "";
  
  const deptAddressObj: AddressObj = CreateNewAddressObj(
    item.dept_address_1,
    item.dept_address_2,
    item.dept_address_city,
    item.dept_address_state,
    item.dept_address_zip,
    item.dept_address_zip_4,
  );

  let patientBalance: number | undefined = parseFloat(item.patient_balance || "");
  if (patientBalance === undefined || patientBalance === null || !isFinite(patientBalance)) {
    patientBalance = undefined;
  }
        
  let visitType: string = item.visit_type || "";
  let cdoVisitType: string = item.visit_type || "";
  let categoryVisitType: string = item.category_visit_type || "";
  let appointmentTypeId: string = DefaultAppointmentTypeId;
  let aemCategoryVisitType: AemCategoryVisitTypes = parseAemCategoryVisitType(categoryVisitType);

  if (visitType) {
    cdoVisitType = visitType;
    visitType = GetVisitTypeFromCdoVisitType(cdoVisitType);
    appointmentTypeId = GetAppointmentTypeFromCdoVisitType(cdoVisitType, DefaultAppointmentTypeId);
  }
  
  const docCounts: DocCounts = ParseDocCounts(item.documentCounts);

  const consentToText: boolean = (item.consenttotext === true);

  // confirmation field
  // true: confirmed via easycheck or SMS
  // false: cancelled via easycheck or SMS
  // null: no action taken yet, pending or new appointment
  const isConfirmed: boolean = (item.confirmation === true);
  const isCancelled: boolean = (item.confirmation === false);

  // parse pre_reg fields
  const preRegDone: boolean = (item.pre_reg === true);
  const preRegRequired: boolean = (item.pre_reg_required === true);

  // sms fields
  const mobilePhoneNumber: string = item.mobile_phone;
  const smsSentTo: string = item.sms_sentto;

  return { 
    ...initialSingleApptState,
    apptId,
    patientId,
    location,
    deptPhone,
    deptAddress,
    deptAddressObj,
    deptWebsite,
    apptDateTime,
    visitType,
    cdoVisitType,
    categoryVisitType,
    aemCategoryVisitType,
    appointmentTypeId,
    providerName,
    patientBalance,
    consentToText,
    docCounts,
    preRegDone,
    preRegRequired,
    isConfirmed,
    isCancelled,
    mobilePhoneNumber,
    smsSentTo,
    patientFirstName,
    patientLastName,
  };
};

const convertApptItemToPatientDetails = (appt: SingleApptState): any => {
  // grab the patient details
  let patientId: string = appt?.patientId || "";
  let firstName: string = appt?.patientFirstName || "";
  let lastName: string = appt?.patientLastName || "";
  let fullName: string = joinFullName(firstName, lastName);

  let location: string = appt?.location || "";
  let providerName: string = appt?.providerName || "";

  let mobilePhoneNumber: string = appt?.mobilePhoneNumber || "";
  let smsSentTo: string = appt?.smsSentTo || "";

  return {
    patientId,
    firstName,
    lastName,
    fullName,
    location,
    providerName,
    mobilePhoneNumber,
    smsSentTo,
  };
};


let _defaultLanguageSet: boolean = false;

export const initDefaultGlobalMomentLocaleOnce = () => {
  if (_defaultLanguageSet) { return; }
  _defaultLanguageSet =  true;

  setGlobalMomentLocale(DefaultLanguageIsoCode);
};


const initAemQueryParams = async (dispatch: any) => {
  // if AEM debug mode has been disabled in the env config,
  // then we do not want to honor any AEM URL params
  if (!config.debugAem) {
    return;
  }

  // init some preference params (used by aem client)
  // convert to expected format before saving to redux
  let queryParams: any = getQueryStringParams() || {};
  if (queryParams.persona) {
    let persona: string = queryParams.persona.trim();
    if (persona.match(/^(representative|guardian)/i)) {
      persona = "representative";
    } else {
      persona = "patient";
    }
    dispatch(updatePrefPersona(persona));
    if (persona === "patient") {
      dispatch(updatePrefRelationship(""));
    } else {
      dispatch(updatePrefRelationship("Other"));
    }
  }
  if (queryParams.languageCode) {
    let languageIsoCode: string = parseLanguageNameToSupportedIsoCode(
      queryParams.languageCode.trim()
    );
    if (languageIsoCode) {
      dispatch(updatePrefLanguageIsoCode(languageIsoCode));

      // don't clobber the preferred language later if we are setting it here
      dispatch(updatePrefLanguageSet(true));
    }
  }

  let aemClient: AemClient = AemClient.getInstance();
  if (queryParams.hasOwnProperty("aem")) {
    let aemEnabled: boolean = parseBool(queryParams.aem, aemClient.enabled);
    aemClient.setEnabled(aemEnabled);
  }
  if (queryParams.hasOwnProperty("aemDebug")) {
    let aemDebugMode: boolean = parseBool(
      queryParams.aemDebug,
      aemClient.debugMode
    );
    aemClient.setDebugMode(aemDebugMode);
  }
  if (queryParams.aemLoader) {
    aemClient.setLoaderClass(queryParams.aemLoader);
  }
};

export const invalidateAemClientData = async (
  dispatch: any = undefined,
  forceUpdate: boolean = false
): Promise<boolean> => {
  // initialize AEM client data
  // do some quick fuzzy parsing to make sure our attributes are valid
  let appt: any = store.getState()?.appt || {};
  let prefs: any = store.getState()?.preferences || {};
  let cdoShortName: string = GetCdoShortName();
  let languageCode: string = prefs.languageIsoCode || ""; // AEM expects the iso code
  let persona: AemPersonaTypes = parseAemPersonaType((prefs.persona || "").trim());
  let categoryVisitType = parseAemCategoryVisitType(appt.categoryVisitType);
  let oldAemRefresh: any = store.getState()?.aem?.refresh;

  let aemClient: AemClient = AemClient.getInstance();
  let res = await aemClient.loadAndInitialize(
    cdoShortName,
    languageCode,
    persona,
    categoryVisitType,
    forceUpdate
  );

  let newAemRefresh: any = aemClient.cacheCount;
  if (newAemRefresh !== oldAemRefresh && dispatch) {
    dispatch(updateAemRefresh(newAemRefresh));
  }

  return res;
};

let _lastPatientId: string = "";
let _lastWebAccessCode: string = "";
let _lastKioskMode: boolean | undefined = undefined;
let _lastWebAccessCodeMode: boolean | undefined = undefined;
let _lastPublicDevice: boolean | undefined = undefined;


// load appliation data from the APIs and set appropriate redux state
// and apply any application init logic here.
// application specific init logic should be handled here, not in the individual screens
// patientId or apptId should be valid, but both are not required.
export const loadAndInitApplicationData = async (
  patientId: string,        // optional.  may be "" if we have a webAccessCode.
  webAccessCode: string,    // optoinal.  should be "" if we have a patientId.  this is the code which will be present when we are in webAccessCodeMode
  kioskMode: boolean,
  webAccessCodeMode: boolean,
  publicDevice: boolean,
  dispatch: any
): Promise<boolean> => {
  if (!patientId) { patientId = ""; }
  if (!webAccessCode) { webAccessCode = ""; }
  
  let updated: boolean = false;
  let payorsPromise: any = false;
  
  // use this flag to ensure we only trigger one error screen during init
  // in case if we encounter additional error scenarios later on
  let errorPending: boolean = false;    

  if (config.enableMaintenancePage) {
    dispatch(updateLinkIgnored(true));
    dispatch(gotoTaskRoute("maintError"));
    errorPending = true;
  } else {
    if (webAccessCode) {
      if (webAccessCode !== _lastWebAccessCode) {
        _lastWebAccessCode = webAccessCode;

        let patientInfo: any = await getPatientByWebAccessCodeAPI(webAccessCode).catch(() => undefined);
        if (!!patientInfo?.patient_id) { 
          // we got a valid patientId back from the API for the given web access code.
          // we can consider this patientId valid.
          patientId = patientInfo.patient_id;

          // update the linkValid and linkVerified now.
          dispatch(updateLinkValid(true));
          dispatch(updateLinkVerified(true));
        }

        if (patientId && _lastPatientId !== patientId) {
          // if our patientId changed from input params, then update our state now
          _lastPatientId = "";
          _lastKioskMode = undefined;    
          _lastWebAccessCodeMode = undefined;
          _lastPublicDevice = undefined;
        }

        if (!patientId) {
          // we were not able to find the patientId from the webAccessCode
          if (!errorPending) {
            dispatch(gotoTaskRoute("linkInvalidError"));
            errorPending = true;
          }
        }
      } else if (!patientId && _lastPatientId) {
        // use the cached _lastPatientId
        patientId = _lastPatientId;
      }
    }

    // avoid endless loop when patientId is invalid or the same
    if (patientId && patientId !== _lastPatientId) {
      _lastPatientId = patientId;
      _lastKioskMode = undefined;
      _lastWebAccessCodeMode = undefined;
      _lastPublicDevice = undefined;
      updated = true;

      // set the patient id in redux
      dispatch(updatePatientId(patientId));

      // TODO: link validation has been ignored until it is ready for multi appointments
      let linkValid: boolean = true;    

      // let multiApptMode: boolean = true;
      // if (multiApptMode) {
      //   // TODO: $MULTIAPPT$ handle multi appt link valiation
      //   linkValid = true;
      // } else {
      //   // check if the appointment id link is valid
      //   linkValid = await GetLinkValidation(apptId);
      // }
      // if (!linkValid && config.debugMode) {
      //   // NOTE: debug mode feature to forceValidLink
      //   // only allow this URL param feature if debugMode is enabled in env config
      //   let queryParams: any = getQueryStringParams();
      //   if (parseBool(queryParams?.forceValidLink)) {
      //     linkValid = true;
      //   }
      // }
      dispatch(updateLinkValid(linkValid));

      let allApptInfos: SingleApptState[] = [];
        
      if (linkValid) {
        let multiApptsRes = await getAllFutureAppointmentsByPatientIdAPI(patientId,kioskMode);
        if (multiApptsRes && multiApptsRes.length > 0) {
          allApptInfos = multiApptsRes.map(convertApptItemToSingleApptState);
        }
        
        dispatch(updateMultiApptAllAppointments(allApptInfos));
        _invalidateBestAppointmentDetails(dispatch, allApptInfos);
      } else {
        // if the link is not valid, then show the linkInvalidError page
        dispatch(updateLinkVerified(false));
        if (!errorPending) {
          dispatch(gotoTaskRoute("linkInvalidError"));
          errorPending = true;
        }
      }

      if (allApptInfos.length > 0) {
        let isReturningPatient: boolean = checkIsReturningPatientCdoAndAllAppointments(allApptInfos);
        let hasAllExpectedForms: boolean = checkPatientHasAllExpectedFormsByMultiAppointments(allApptInfos);
        let needsAdvancedDirectiveForm: boolean = false;
        if (!hasAllExpectedForms) {
          needsAdvancedDirectiveForm = checkPatientNeedsFormByDocType(allApptInfos, PciDocTypes.ADVANCE_DIRECTIVES);
        }

        // set patient.consentToText if any appointment has consentToText set
        let consentToText: boolean = !!allApptInfos.find(v => v.consentToText);

        dispatch(updateIsReturningPatient(isReturningPatient));
        dispatch(updatePatientHasAllForms(hasAllExpectedForms));
        dispatch(updateNeedsAdvancedDirectiveForm(needsAdvancedDirectiveForm));
        dispatch(updateConsentToText(consentToText));
      } else {
        // we didn't find any appointments, so send the user to the matching error page
        // we still want to allow the other init logic to run so they have their 
        // language preference and other patient state loaded correctly
        if (!errorPending) {
          dispatch(gotoTaskRoute("noAppointmentsError"));      
          errorPending = true;
        }
      }

      // since we are updating patient and appt state, 
      // let the task stepper know it needs to update it's config
      await dispatch(setRefreshTaskConfig(true));

      // if we have persisted redux state with a valid route, then use it as the starting route
      // validation checks are present to make sure it's the same appointment, etc.
      const taskState = store.getState()?.task || {};
      const persistedState: any = store.getState()?.persisted || {};
      if (persistedState.valid && persistedState.patientId === patientId) {
        if (persistedState.languageIsoCode) {
          // the user has selected a preferred display language
          // restore it now          
          let persLangIsoCode: string = parseLanguageNameToSupportedIsoCode(
            (persistedState.languageIsoCode || "").trim()
          );
          if (persLangIsoCode && !isPrefLanguageSet()) {
            dispatch(updatePrefLanguageIsoCode(persLangIsoCode));

            // don't clobber the preferred language later if we are setting it here
            dispatch(updatePrefLanguageSet(true));
          }
        }
        if (persistedState.route && taskState?.route !== persistedState.route) {
          // user was previosly validated and can access protected mode now
          // then jump the last route once init has finished
          dispatch(updatePrefIdentityVerified(true));
          dispatch(updatePrefProtectedMode(true));
          dispatch(setPendingStartRouteIfUnset(persistedState.route));
        }
        if (persistedState.advDirSelection) { 
          dispatch(updateAdvancedDirectiveSelectionValue(persistedState.advDirSelection));
        }
        // restore any delta change flags
        let deltaKeys = persistedState.deltaKeys || [];
        if (deltaKeys.length > 0) {
          dispatch(initDeltaKeyValues(deltaKeys));
        }
      }

      if (!publicDevice) {
        // get preferences
        let prefCdoLangKey: string = await GetPreferredLanguageAPI(patientId);
        let prefLangIsoCode: string = parseLanguageNameToSupportedIsoCode(
          (prefCdoLangKey || "").trim()
        );
        if (prefLangIsoCode && !isPrefLanguageSet()) {
          dispatch(updatePrefLanguageIsoCode(prefLangIsoCode));
        }

        if (prefCdoLangKey) {
          dispatch(updateCdoLanguageKey(prefCdoLangKey));
          dispatch(updateCdoLanguageName("")); // clear now, update later in invalidateCdoLanguageName after aem data has loaded
        }
      }

      if (!errorPending && !webAccessCodeMode) {
        dispatch(updateLinkValid(true));
        dispatch(updateLinkVerified(true));
      }

    } else if (kioskMode || webAccessCodeMode || publicDevice) {
      if (
        _lastKioskMode !== kioskMode ||
        _lastWebAccessCodeMode !== webAccessCodeMode ||
        _lastPublicDevice !== publicDevice
      ) {
        _lastKioskMode = kioskMode;
        _lastWebAccessCodeMode = webAccessCodeMode;
        _lastPublicDevice = publicDevice;
      }
    } else {
      if (!errorPending) {
        // if the link is not valid, and we aren't showing another error, then show the linkInvalidError page
        dispatch(updateLinkValid(false));
        dispatch(updateLinkVerified(false));
        dispatch(gotoTaskRoute("linkInvalidError"));
        errorPending = true;
      }
    }
  }

  // force init some config params (used by aem client)
  await initAemQueryParams(dispatch);

  // start updating the aem client data
  // this needs to happen after preferred language has been set
  let aemLoadPromise = invalidateAemClientData(dispatch, false);

  // wait for any pending payors data to finish loading
  await payorsPromise;

  // after setting both payors list and coverages, then invalidate the provider name for each coverage
  // dispatch(invalidateInsuranceCoverageProviderNames(false));

  // wait for aem client data to finish
  await aemLoadPromise;

  // after aem data has loaded, we can update the display language
  await invalidateCdoLanguageName(dispatch);
  
  return updated;
};

let _protectedDataLoaded: boolean = false;

// load protected application data from the APIs
// and set appropriate redux state
// NOTE: this should be called after the appointment
// has been verified, but before any protected routes have been displayed
export const loadProtectedApplicationDataOnce = async (dispatch: any) => {
  if (_protectedDataLoaded) {
    return false;
  }

  // if the identity has not been verified, then we cannot load any protected data
  let identityVerified: boolean = !!store.getState()?.preferences?.identityVerified;
  if (!identityVerified) { 
    return false; 
  }

  _protectedDataLoaded = true;

  // let the app know that we've entered protected mode
  dispatch(updatePrefProtectedMode(true));

  // Make call to GetSignedToken once before calling any APIs
  let tokenSuccess = await GetSignedToken();
  if (!tokenSuccess) {
    // since loadProtectedApplicationDataOnce is called during a route change, 
    // let this change finish before triggering the error route
    setTimeout(() => dispatch(gotoTaskRoute("techDifficultiesError")), 10);
    return false;
  }

  let languageChanged: boolean = false;
  let refreshTaskConfig: boolean = false;

  let patientId: string = store.getState()?.patient?.patientId || "";
  if (patientId) {
    let coverage1FrontImage: string = "";
    let coverage1BackImage: string = "";
    let coverage2FrontImage: string = "";
    let coverage2BackImage: string = "";

    // get patient demographics
    let [demographicsRes, insuranceCoveragesRes] = await Promise.all([
      GetAllPatientDemographicsAPI(patientId),
      GetInsuranceCoverageAPI(patientId),
    ]);

    if (demographicsRes) {
      let patient: any = demographicsRes?.patient;

      if (patient) {
        dispatch(updateFname(patient.firstName));
        dispatch(updateLname(patient.lastName));
        dispatch(updateFullName(patient.fullName || joinFullName(patient.firstName, patient.lastName)));
        let dt = patient.dob.split("-");
        dt = dt[1] + "/" + dt[2] + "/" + dt[0];
        dispatch(updateDob(dt));

        // only the advanced directive form requires an age check. other forms do not.
        let patientDob: Moment | undefined = parseToMoment(dt);
        let isAdultPatient: boolean = eighteenYearsAgeCheck(patientDob);
        dispatch(updateIsAdultPatient(isAdultPatient));

        // if we update the patient age, then refresh the task config also.
        refreshTaskConfig = true;

        // NOTE: be very specific with language information
        // do not mix up these values throughout the aplication
        // keep languageIsoCode (ISO code) seperate from patient cdoLanguageName and cdoLanguageKey
        // languageIsoCode must be an ISO language code and is used throughout the application
        if (!_lastKioskMode && !_lastPublicDevice) {
          let prefCdoLanguageKey: string = patient.preferredLanguage;
          let languageIsoCode: string = parseLanguageNameToSupportedIsoCode(
            (prefCdoLanguageKey || "").trim()
          );
          if (languageIsoCode && !isPrefLanguageSet()) {
            dispatch(updatePrefLanguageIsoCode(languageIsoCode));
          }

          // set the patient language code
          dispatch(updateCdoLanguageKey(prefCdoLanguageKey));
          dispatch(updateCdoLanguageName("")); // clear now, update later in invalidateCdoLanguageName after aem data has loaded
          languageChanged = true;
        }

        dispatch(updateAddress(patient.address));
        dispatch(updateEmail(patient.address?.email));
        dispatch(updateConsentToEmail(patient.address?.consentToEmail));
        dispatch(updateHomePhone(filterPhoneInput(patient.address?.homePhone || "")));       
        dispatch(updateWorkPhone(filterPhoneInput(patient.address?.workPhone || "")));
        dispatch(updateWorkPhoneExtn(filterPhoneExtnInput(patient.address?.workPhoneExtn || "")));
        dispatch(updateCellPhone(filterPhoneInput(patient.address?.cellPhone || "")));
        dispatch(updatePreferredPhoneType(patient.address?.preferredPhoneType));

        let photos = patient.photo || [];
        let cardFrontImage: string = "";
        let cardBackImage: string = "";
        for (let index = 0; index < photos.length; index++) {
          const element = photos[index];
          if (element?.title === "PhotoIDFront") {
            cardFrontImage =
              "data:" + element.contentType + ";base64," + element.data;
          }
          if (element?.title === "PhotoIDBack") {
            cardBackImage =
              "data:" + element.contentType + ";base64," + element.data;
          }
          if (element?.title === "PrimaryInsuranceFront") {
            coverage1FrontImage =
              "data:" + element.contentType + ";base64," + element.data;
          }
          if (element?.title === "PrimaryInsuranceBack") {
            coverage1BackImage =
              "data:" + element.contentType + ";base64," + element.data;
          }
          if (element?.title === "SecondaryInsuranceFront") {
            coverage2FrontImage =
              "data:" + element.contentType + ";base64," + element.data;
          }
          if (element?.title === "SecondaryInsuranceBack") {
            coverage2BackImage =
              "data:" + element.contentType + ";base64," + element.data;
          }
        }
        dispatch(updatePhotoIDFront(cardFrontImage));
        dispatch(updatePhotoIDBack(cardBackImage));

        let demographics = {
          // codes
          sexAtBirth: patient.sexAssignedAtBirth,
          genderIdentity: patient.genderIdentity,
          preferredPronoun: patient.preferredPronoun,
          religiousAffiliation: patient.religiousAffiliation,
          maritalStatus: patient.maritalStatus,
          race: patient.race,
          ethnicity: patient.ethnicity,
          // values
          sexAtBirthValue: "",
          genderIdentityValue: "",
          preferredPronounValue: "",
          religiousAffiliationValue: "",
          maritalStatusValue: "",
          raceValue: "",
          ethnicityValue: "",
        };
        demographics = populatePatientDemographicValues(demographics);
        dispatch(updateDemographics(demographics));
      }

      if (demographicsRes.emergencyContact) {
        dispatch(updateEmergencyContactInfo(demographicsRes.emergencyContact));
      }
    }

    if (insuranceCoveragesRes && insuranceCoveragesRes.length > 0) {
      let coverage1: any = insuranceCoveragesRes.find(
        (v) => Number(v?.order) === 1
      );
      let coverage2: any = insuranceCoveragesRes.find(
        (v) => Number(v?.order) === 2
      );
      let hasCoverage1: boolean = !!(
        coverage1?.status === FhirInsuranceCoverageStatusCodes.Active
      );
      let hasCoverage2: boolean = !!(
        coverage2?.status === FhirInsuranceCoverageStatusCodes.Active
      );
      let hasSelfCoverage: boolean = !!(
        coverage1?.type === FhirInsuranceCoverageTypeCodes.SelfPay
      );

      if (hasSelfCoverage) {
        // NOTE: $CLEAR_COVERAGE_IF_SELF_PAY$
        // due to changes requested by Christian
        // if the coverage is self pay, then coverage1 and coverage2 should be blank
        hasCoverage1 = false;
        hasCoverage2 = false;
        coverage1 = null;
        coverage2 = null;
      } else {
        hasCoverage1 = true;
      }

      dispatch(setHasSelfCoverage(hasSelfCoverage));
      dispatch(setHasCoverage1(hasCoverage1));
      dispatch(setHasCoverage2(hasCoverage2));

      // set the coverages if we have them
      if (hasCoverage1 && coverage1) {
        dispatch(mergeInsuranceCoverage1(coverage1));
        dispatch(updateInsuranceCoverage1FrontCardImage(coverage1FrontImage));
        dispatch(updateInsuranceCoverage1BackCardImage(coverage1BackImage));
      }
      if (hasCoverage2 && coverage2) {
        dispatch(mergeInsuranceCoverage2(coverage2));
        dispatch(updateInsuranceCoverage2FrontCardImage(coverage2FrontImage));
        dispatch(updateInsuranceCoverage2BackCardImage(coverage2BackImage));
      }
    } else {
      // revert to the default state if we don't get valid coverage data from the API
      dispatch(setHasSelfCoverage(false));
      dispatch(setHasCoverage1(true));
      dispatch(setHasCoverage2(false));
    }

    let repDetails = await GetRepresetativeDetailsAPI(patientId);

    if (repDetails) {
      let photos = repDetails.photo || [];
      let cardFrontImage: string = "";
      let cardBackImage: string = "";
      for (let index = 0; index < photos.length; index++) {
        const element = photos[index];
        if (element?.title === "PhotoIDFront") {
          cardFrontImage =
            "data:" + element.contentType + ";base64," + element.data;
        }
        if (element?.title === "PhotoIDBack") {
          cardBackImage =
            "data:" + element.contentType + ";base64," + element.data;
        }
      }

      let rep: Representative = {
        name: repDetails?.fullName || "",
        dob: "",
        address: {
          address1: repDetails?.address?.address1 || "",
          address2: repDetails?.address?.address2 || "",
          city: repDetails?.address?.city || "",
          state: repDetails?.address?.state || "",
          zipcode: repDetails?.address?.zipcode || "",
        },
        homePhone: filterPhoneInput(repDetails?.homePhone || ""),
        photoid_front: cardFrontImage,
        photoid_back: cardBackImage,
      };

      dispatch(updateRepresentative(rep));
    }
  }

  if (refreshTaskConfig) {
    // let the task stepper know it needs to update it's config
    await dispatch(setRefreshTaskConfig(true));
  }
        
  // since the preferred language may have changed, trigger an invalidation
  // which will check the aem properties to determine whether aem data needs to be reloaded
  // this needs to happen after preferred language has been set
  if (languageChanged || !_lastPublicDevice) {
    await invalidateAemClientData(dispatch, false);
    await invalidateCdoLanguageName(dispatch);
  }

  // tell the delta redux state to start watching 
  // for completion data changes
  // NOTE: we want to enable delta tracking after protected
  // patient data is loaded.  
  // we do not need to enable tracking for
  // non-protected patient data to avoid trigger a false change.
  dispatch(resetDeltaTrackedData(true));
  dispatch(setDeltaTrackingEnabled(true));
  dispatch(invalidateDeltaTrackedState(true));
  
  return true;
};

export const clearPatientLoadedApplicationData = async (dispatch: any): Promise<void> => {
  _lastPatientId = "";
  _lastWebAccessCode = "";
  _lastKioskMode = undefined;
  _lastWebAccessCodeMode = undefined;
  _lastPublicDevice = undefined;

  await clearProtectedApplicationData(dispatch);

  // we do not need to clear aem or task state
  dispatch(resetPrefState(true));
  dispatch(resetApptState(true));
  dispatch(resetPatientState(true));
  dispatch(resetInsuranceState(true));
  await dispatch(clearAndDisablePersistedState());  

  await invalidateAemClientData(dispatch, false);
};

const clearProtectedApplicationData = async (dispatch: any): Promise<void> => {
  _protectedDataLoaded = false;
  
  deleteCookie('SignedToken');  

  dispatch(updatePrefProtectedMode(false));
  dispatch(updatePrefIdentityVerified(false));  
};

export const invalidateBestAppointmentDetails = async (dispatch) => {
  let allAppointmentInfos: SingleApptState[] = store.getState()?.appt?.allAppointmentInfos || [];
  _invalidateBestAppointmentDetails(dispatch, allAppointmentInfos);
  await invalidateAemClientData(dispatch, false);
};

const _invalidateBestAppointmentDetails = (dispatch, apptInfos: SingleApptState[]) => {
  let bestApptInfo: SingleApptState | undefined = findBestAppointment(apptInfos);
  if (bestApptInfo) {
    let apptPatientDetails = convertApptItemToPatientDetails(bestApptInfo);
    if (apptPatientDetails) {
      dispatch(updateFname(apptPatientDetails.firstName));
      dispatch(updateLname(apptPatientDetails.lastName));
      dispatch(updateFullName(apptPatientDetails.fullName));
      dispatch(updateCellPhone(apptPatientDetails.mobilePhoneNumber));
      dispatch(updateHomePhone(apptPatientDetails.homePhoneNumber));
    }

    // if we have a valid bestApptInfo, set some appointment details
    if (bestApptInfo) {
      let balance: number | undefined = bestApptInfo.patientBalance;
      if (balance !== undefined && balance !== null) {
        dispatch(updateAccountBalance(balance));
      }

      let categoryVisitType = bestApptInfo.categoryVisitType;
      dispatch(updateApptCategoryVisitType(categoryVisitType));

      if (bestApptInfo.visitType || bestApptInfo.cdoVisitType) {
        dispatch(updateApptVisitType(bestApptInfo.visitType || ""));
        dispatch(updateApptCdoVisitType(bestApptInfo.cdoVisitType || ""));
      }
    }
  }
};

// returns true if all appointments are returning appointments
// if any appointements are not a returning appointment, then we need to show the correct flow.
const checkIsReturningPatientCdoAndAllAppointments = (apptItems: SingleApptState[]): boolean => {
  let retval: boolean = false;
  if (DoesCdoSupportDocCounts() && DoesCdoSupportPciDocType(PciDocTypes.REGISTRATION)) {
    retval = checkPatientHasFormByDocType(apptItems, PciDocTypes.REGISTRATION);
  } else {
    if (apptItems?.length > 0) {
      retval = apptItems.every((apptItem: SingleApptState) => {
        return IsReturningPatientAppointmentByCdoVisitType(apptItem.cdoVisitType, apptItem.appointmentTypeId);
      });
    }
  }
  return retval;
};

// returns true if docDount exist for all filtered forms for all appointments
const checkPatientHasFormByDocType = (apptItems: SingleApptState[], filterDocType: PciDocTypes): boolean => {
  let retval: boolean = false;
  if (apptItems?.length > 0) {
    retval = apptItems.every((apptItem: SingleApptState) => {
      return HasAllExpectedFormsForAllPciDocTypes(apptItem.docCounts, [filterDocType]);
    });
  }
  return retval;
};

// checks all appointments and determines whether all required forms have already been counted
// if any appointment needs a form, then we need to show the form steps
const checkPatientHasAllExpectedFormsByMultiAppointments = (apptItems: SingleApptState[]): boolean => {
  let retval: boolean = false;
  if (apptItems?.length > 0) {
    retval = apptItems.every((apptItem: SingleApptState) => {
      return checkPatientHasAllExpectedFormsBySingleAppointment(apptItem.appointmentTypeId, apptItem.docCounts);
    });
  }
  return retval;
};

// only returns true if docDounts exist for all filtered forms for all appointments
const checkPatientNeedsFormByDocType = (apptItems: SingleApptState[], filterDocType: PciDocTypes): boolean => {
  let retval: boolean = false;
  if (apptItems?.length > 0) {
    apptItems.find((apptItem: SingleApptState) => {
      const hasForm = checkPatientHasAllExpectedFormsBySingleAppointment(apptItem.appointmentTypeId, apptItem.docCounts, [filterDocType]);
      if (!hasForm) {
        retval = true;
        return true;
      }
      return false;
    });
  }
  return retval;
};

const checkPatientHasAllExpectedFormsBySingleAppointment = (
  appointmentTypeId: string, 
  docCounts: any,
  filterDocTypes: PciDocTypes[] | undefined = undefined
): boolean => {
  return !!(
    DoesCdoSupportDocCounts() && 
    HasAllExpectedFormsForAppointmentTypeId(docCounts, appointmentTypeId, filterDocTypes)
  );
};

const invalidateCdoLanguageName = async (dispatch: any): Promise<boolean> => {
  let patient: any = store.getState()?.patient || {};
  let cdoLanguageKey: string = patient.cdoLanguageKey || "";
  let cdoLanguageName: string = "";
  if (cdoLanguageKey) {
    let aemClient: AemClient = AemClient.getInstance();
    const aemLanguageOptions = aemClient.getListKVPairs(
      "BODY_DEMOGRAPHICS_PATIENT_NAME_PRI_LANG_CODES",
      "BODY_DEMOGRAPHICS_PATIENT_NAME_PRI_LANG_LIST",
      null
    );

    if (aemLanguageOptions && aemLanguageOptions.length > 0) {
      cdoLanguageName =
        aemClient.getListKVPairValue(aemLanguageOptions, cdoLanguageKey) || "";
    }
  }
  dispatch(updateCdoLanguageName(cdoLanguageName));
  return true;
};

// used to keep track of whether we have set the preferred language yet
// to avoid clobbering a previous choice by the user
const isPrefLanguageSet = (): boolean => {
  return !!store.getState()?.preferences?.languageSet;
};

export const clearAppStateAndRestart = async (): Promise<void> => {
  const dispatch = store.dispatch;
  
  deleteCookie('SignedToken');

  // clear any persistent or residual app state and start over
  await dispatch(clearAndDisablePersistedState());  
  
  // reload the browser window
  window.location.reload();
};
