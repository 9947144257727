import { IAemLoader } from "./AemDefs";
// import patient_eng_data from "./data/cached/master.json";
const patient_eng_data: any = {};

export class AemMockLoader implements IAemLoader {
    
  public debugMode: boolean = false;

  public async load(cdoShortName: string, languageCode: string): Promise<any> {
    // for local use to bypass API, use a static JSON file instead
    let data: any = patient_eng_data;
    return data;    
  }

}

export default AemMockLoader;
