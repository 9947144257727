import { IAemLoader } from "./AemDefs";
import AemGraphqlTransformer from "./AemGraphTransformer";
import { QueryAemContentGraphqlApi } from "../../api/AemGraphqlApi";

export class AemGraphqlLoader implements IAemLoader {
  public debugMode: boolean = false;

  public async load(
    cdoShortName: string,
    languageCode: string,
  ): Promise<any> {
    // use API to load data from AEM graphql endpoint, then convert to internal aem format
    let rawData: any = await QueryAemContentGraphqlApi(cdoShortName, languageCode);

    let transformer: AemGraphqlTransformer = new AemGraphqlTransformer();
    transformer.showWarnings = this.debugMode;
    return transformer.convert(rawData?.data);
  }
}

export default AemGraphqlLoader;
