import React from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as CloseTimesSvg } from "../../assets/icons/close_times.svg";
import { appColors, appFonts, useAppTheme } from "../../theme";
import { NavButton } from "../Buttons";
import { MessagePopupProps } from "./MessagePopupProvider";
import { Aem } from "../../lib/aem";


const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    maxWidth: "355px",
    minHeight: "385px",
    padding: "24px",
    borderRadius: "16px",
    backgroundColor: appColors.darkGray1,
    color: appColors.white,
    justifyContent: "center",
  },
  sizeSmall: {
    // default size. no change
  },
  sizeMedium: {
    maxWidth: "65%",
  },
  sizeLarge: {
    maxWidth: "100%",
  },
  cancelButtonArea: {
    position: "absolute",
    top: "8px",
    right: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  cancelButton: {
    alignSelf: "flex-end",
  },
  title: {
    fontFamily: appFonts.medium,
    fontSize: "22px",
    textAlign: "center",
    marginBottom: "20px",
  },
  message: {
    fontFamily: appFonts.regular,
    fontSize: "18px",
    textAlign: "center",
    marginBottom: "45px",
  },
  actions: {
    "& > div": {
      marginLeft: "8px",
      marginRight: "8px",
    },
  },
  icon: {
    marginBottom: "20px",
  },
  button: {
    height: "60px",
    width: "100%",
    minWidth: "120px",
  },
});

export const MessagePopup: React.FC<MessagePopupProps> = (props: MessagePopupProps) => {
  const classes = useStyles();
  const theme = useAppTheme();
  const { size, icon, title, message, showCancelButton, ariaTitleId, onClose } = props;
  
  let sizeClass: any = null;
  switch (size) { 
    case "large":
      sizeClass = classes.sizeLarge;
      break;
    case "medium":
      sizeClass = classes.sizeMedium;
      break;
    case "small":
    default:
      sizeClass = classes.sizeSmall;
      break;
  }

  let actions = props.actions;
  if (actions === undefined) { 
    const okText: string = Aem.get("ACTION_OKBUTTON_TEXT_1", "OK");
    actions = [
      { value: "ok", title: okText },
    ];
  }

  const handleClick = (val: any) => {
    if (onClose) { onClose(val); }
  };
  
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={clsx(classes.root, sizeClass, theme.nowrap)}
    >
      {showCancelButton && (
        <Grid item className={classes.cancelButtonArea}>
          <NavButton
            className={classes.cancelButton}
            label={<CloseTimesSvg />}
            size="small"
            variant="text"
            fullWidth={false}
            trackName="cancel"
            trackLocation="popup"
            onClick={() => handleClick("cancel")}
          />
        </Grid>
      )}
      {icon && (
        <Grid item className={classes.icon}>
          {icon}
        </Grid>
      )}
      {title && (
        <Grid item id={ariaTitleId} className={classes.title}>
          {title}
        </Grid>
      )}
      {message && (
        <Grid item className={classes.message}>
          {message}
        </Grid>
      )}
      {actions && actions.length > 0 && (
        <Grid item>
            <Grid 
              container 
              direction="row"
              justifyContent="space-around" 
              alignItems="center" 
              className={classes.actions}>
            {actions.map((action, idx) => (
              <Grid item key={idx}>
                <NavButton
                  className={classes.button}
                  label={action.title}
                  size="large"
                  variant="outlined"
                  fullWidth={true}
                  trackName={action.value}
                  trackLocation="popup"
                  onClick={() => handleClick(action.value)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

MessagePopup.defaultProps = {
  showCancelButton: true,
  actions: undefined,
};

export default MessagePopup;
