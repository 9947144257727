import { ISurveyConfig, SurveyQuestionTypes } from "./SurveyDefs";


export const SurveyConfig: ISurveyConfig = {
  questions: [
    {
      type: SurveyQuestionTypes.Likert,
      required: true,
      question: {
        aemId: "BODY_NPS_SURVEY_Q1_TEXT_1",
        text: "How likely are you to recommend this easycheck application to others?",
      },
      hint: {
        aemId: "BODY_NPS_SURVEY_Q1_TEXT_2",
        text: "From 0 (not likely) to 10 (very likely)",
      },
    },
    {
      type: SurveyQuestionTypes.ShortAnswer,
      required: false,
      question: {
        aemId: "BODY_NPS_SURVEY_Q2_TEXT_1",
        text: "What could we do to improve your experience with this easycheck application?"
      },
      details: {
        aemId: "BODY_NPS_SURVEY_Q2_TEXT_2",
        text: "This feedback will be sent to the easycheck technical team and not to your provider.",
      },
      placeholder: {
        aemId: "BODY_NPS_SURVEY_Q2_TEXT_3",
        text: "Your feedback is optional",
      },
    },
  ],
  summary: {
    title: {
      aemId: "BODY_NPS_SURVEY_SUMMARY_TEXT_1",
      text: "Thank you for your response!",
    },
    details: {
      aemId: "BODY_NPS_SURVEY_SUMMARY_TEXT_2",
      text: "This feedback will help us uncover new ways to improve, so we can give you the best possible easycheck experience.",
    },
  },
};

