import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { groupAppointmentsByDay, SingleApptState, updateSingleApptValue } from "../features/appt/apptSlice";
import { gotoTaskRoute, setTaskTransitionPending } from "../features/task/taskSlice";
import { updateUIMessagePopupContext } from "../features/ui/uiSlice";
import { appColors, appFonts, useAppTheme } from "../theme";
import { ReactComponent as CalendarBlankLgSvg } from "../assets/icons/calendar_blank_lg.svg";
import Header from "../Components/Header";
import NavButton from "../Components/Buttons/NavButton";
import { AppointmentCard, AppointmentSnackbar } from "../Components/Appointments";
import { collectAemTokensDataWithAppointment } from "../lib/save-state/collectAemTokensData";
import { TransformAemTokens } from "../lib/aem/aem-utility/AemUtility";
import { InitializeSmsModelData } from "../lib/save-state/smsModelData";
import { InitializeConfirmAppointmentsData } from "../lib/save-state/confirmAppointmentData";
import { InitializeCancelAppointmentData } from "../lib/save-state/cancelAppointmentData";
import { SendSMSMessageAPI, UpdateCancelAppointmentAPI, UpdateConfirmAppointmentAPI } from "../api/UpdatePMSAPI";
import { invalidateBestAppointmentDetails } from "../appInit";
import { AemCategoryVisitTypes } from "../lib/aem/AemDefs";
import Aem from "../lib/aem/components/Aem";
 

const useStyles = makeStyles({
  root: {
    position: "relative",
    color: "#ffffff",
    width: "100%",
    height: "100%",
    fontFamily: appFonts.regular,
  },
  content: {
    marginTop: "25px",
    padding: "0 16px 0 16px",
  },
  label1: {
    marginBottom: "23px",
    fontFamily: appFonts.bold,
    fontSize: "26px",
  },
  label2: {
    fontFamily: appFonts.medium,
    fontSize: "20px",
  },
  label3: {
    alignSelf: "flex-start",
    maxWidth: "450px",
    fontFamily: appFonts.medium,
    fontSize: "18px",
  },
  labelBold: {
    fontFamily: appFonts.bold,
  },
  vspacer: {
    height: "16px",
  },
  continueWarnText: {
    marginTop: "4px",
    marginBottom: "8px",
    fontFamily: appFonts.medium,
    fontSize: "14px",
    color: appColors.warn,
    maxWidth: "450px",
  },
  hintText: {
    marginTop: "45px",
    marginBottom: "65px",
    fontFamily: appFonts.medium,
    fontSize: "14px",
    maxWidth: "350px !important",
  },
  logo: {
    height: "24px",
    width: "82.37px",
    alignItems: "center",
    minHeight: "70px",
    paddingTop: "16px",
    paddingBottom: "30px",
  },
  cardsArea: {
    margin: "5px auto 30px auto",
    maxWidth: "450px",
    "& > *": {
      marginBottom: "15px",
    },
  },
  cardArea: {
  },
  navActionsArea: {
    width: "75%",
    minWidth: "150px",
    maxWidth: "450px !important",
  },
  navButtonsVerticalArea: {
    "& > div": {
      "&:not(:first-child)": {
        marginTop: "15px",
      },
    },
  },
  navButtonWidth: {
    minWidth: "280px",
  },
  footer: {
    // background: "linear-gradient(0deg, rgba(40,40,40,1) 5%,rgba(40,40,40,0.7) 50%, rgba(40,40,40,0.3) 100%) !important",
  },
  unConfirmedApptCountLabel: {
    color: "#F05973",
    alignSelf: "flex-start",
    maxWidth: "450px",
    fontFamily: appFonts.medium,
    fontSize: "16px",
    marginBottom: "24px",
  }
});

const _emptySnackbarProps = {
  status: "",
  title: "",
  message: "",
};

interface MultiAppointmentsPageProps {
  apptId?: string;
}

const noTransformAemOpts: any = { transformTokens: false };
  
const MultiAppointmentsPage: React.FC<MultiAppointmentsPageProps> = (props: MultiAppointmentsPageProps) => {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();

  const patientId: string = useAppSelector<string>((state) => state.patient.patientId);
  const appointmentItems: SingleApptState[] = useAppSelector<SingleApptState[]>((state) => state.appt.allAppointmentInfos);
  const patientFirstName: string = useAppSelector<string>((state) => state.patient.first_name);
  
  const [dayGroupedApptItems, setDayGroupedApptItems] = useState<any[]>([]);
  const [apptStatus, setApptStatus] = useState<any>({});
  const hasValidAppts: boolean = (appointmentItems.length >= 1);
  const hasPendingAppt: boolean = !!appointmentItems.find((v: SingleApptState) => {
    return (!v.isConfirmed && !v.isCancelled);
  });

  const [continueEnabled, setContinueEnabled] = useState<boolean>(false);
  const [skipManageContinuePopup, setSkipManageContinuePopup] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarProps, _setSnackbarProps] = useState<any>(_emptySnackbarProps);
  const [unConfirmedApptCountLabel, setUnconfirmedApptCountLabel] = useState<string>("");

  const setSnackbarProps = useCallback((newProps: any = {}): void => {
    _setSnackbarProps({ ..._emptySnackbarProps, ...newProps });
  }, [_setSnackbarProps]);

  const continueButtonLabel: string = Aem.get("ACTION_CONTINUEBUTTON_TEXT_1", "Continue");
  const apptConfirmedTitle: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_11", "Appointment confirmed:");
  const apptCancelledTitle: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_12", "Appointment cancelled:");
  const apptDateMsgRawStr: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_13", "{{VisitDate}} at {{VisitTime}}", noTransformAemOpts);

  const hintText: string = Aem.get(
    "BODY_MULTI_APPOINTMENTS_TEXT_3",
    "Appointments shown are within the next 3 days. If you need to make changes to an appointment, please call the location.",
  );

  const continueWarnText: string = Aem.get(
    "BODY_MULTI_APPOINTMENTS_TEXT_23",
    "Please confirm an appointment before continuing"
  );

  const apptsTextDayPrefix: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_36", "Appointments");
  const apptsTextDay0: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_37", "Today");
  const apptsTextDay1: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_38", "Tomorrow");
  const apptsTextDay2: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_39", "in 2 Days");
  const apptsTextDay3: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_40", "in 3+ Days");
  const apptTextDayLabels: string[] = [ apptsTextDay0, apptsTextDay1, apptsTextDay2, apptsTextDay3 ];
  
  // NOTE: some UI has been hidden as per design review
  // we can probably remove these sections later once UI design has settled down.
  const showContinueDisabledWarning: boolean = false;
  const showHintText: boolean = false;

  // status should be "confirmed" or "cancelled"
  const showStatusChangedSnackbarPopup = useCallback((apptId: string, status: string): void => {
    const apptItem: SingleApptState | undefined = appointmentItems.find(v => v.apptId === apptId);
    if (!apptItem) { return; }

    const title: string = (status === "cancelled") ? apptCancelledTitle : apptConfirmedTitle;

    const aemData: any = collectAemTokensDataWithAppointment(apptItem);
    const message: string = TransformAemTokens(apptDateMsgRawStr, aemData);

    setSnackbarProps({
      status,
      title,
      message,
    });
    setSnackbarOpen(true);
  }, [setSnackbarProps, setSnackbarOpen, appointmentItems, apptConfirmedTitle, apptCancelledTitle, apptDateMsgRawStr]);

  const _getApptStatus = useCallback((apptId: string | undefined, key: string): boolean => {
    let retval: boolean = false;
    if (apptId) {
      let statusItem: any = apptStatus[apptId];
      if (statusItem && statusItem.hasOwnProperty(key)) {
        retval = !!apptStatus[apptId][key];
      } else {
        let apptItem: SingleApptState | undefined = appointmentItems.find(v => v.apptId === apptId);
        if (apptItem) {
          if (key === "confirmed") {
            retval = apptItem.isConfirmed;
          } else if (key === "cancelled") {
            retval = apptItem.isCancelled;
          }
        }
      }
    }
    return retval;
  }, [apptStatus, appointmentItems]);

  const _setApptStatus = useCallback((apptId: string | undefined, key: string, val: boolean): void => {
    if (apptId) {
      let newApptStatus: any = { ...apptStatus };
      if (!newApptStatus[apptId]) { newApptStatus[apptId] = {}; }

      let oldVal: boolean = !!newApptStatus[apptId][key];
      newApptStatus[apptId][key] = val;

      if (val && (key === "confirmed" || key === "cancelled")) {
        // item cannot be both confirmed and cancelled at the same time
        if (newApptStatus.confirmed && newApptStatus.cancelled) {
          if (key === "confirmed") {
            newApptStatus.cancelled = false;
          } else if (key === "cancelled") {
            newApptStatus.confirmed = false;
          }
        }

        // if item has become confirmed or cancelled, then trigger the toast popup message
        if (!oldVal && (key === "confirmed" || key === "cancelled")) {
          showStatusChangedSnackbarPopup(apptId, key);
        }
      }
      setApptStatus(newApptStatus);
    }
  }, [apptStatus, showStatusChangedSnackbarPopup]);

  const isApptConfirmed = useCallback((item: SingleApptState): boolean => {
    return _getApptStatus(item?.apptId, "confirmed");
  }, [_getApptStatus]);
  const isApptCancelled = useCallback((item: SingleApptState): boolean => {
    return _getApptStatus(item?.apptId, "cancelled");
  }, [_getApptStatus]);
  const isApptPending = useCallback((item: SingleApptState): boolean => {
    return (!isApptConfirmed(item) && !isApptCancelled(item))
  }, [isApptConfirmed, isApptCancelled]);

  const updateUnconfirmedAppointmentCountText = useCallback(() => {
    // Following code to calculate unconfirmed appointment
    let count: number = 0;
    appointmentItems.forEach((v: SingleApptState) => {
      if (!v.isCancelled && !v.isConfirmed) {
        count++;
      }
    });

    const aemUnConfirmedApptCountText: string = Aem.get(
      "BODY_MULTI_APPOINTMENTS_TEXT_33",
      "You currently have {{UnconfirmedApptCount}} unconfirmed appointments",
      noTransformAemOpts
    );

    const newUnConfirmedApptCountText: string = (count > 0) ?
      aemUnConfirmedApptCountText.replace("{{UnconfirmedApptCount}}", count.toString()) :
      "";
    setUnconfirmedApptCountLabel(newUnConfirmedApptCountText);
  }, [appointmentItems])

  const confirmAppointment = useCallback(async (apptItem: SingleApptState): Promise<boolean> => {
    // call confirm api for single appointment and check response
    const apptId: string = apptItem.apptId;
    const apptIds: string[] = apptId ? [apptId] : [];
    const data = InitializeConfirmAppointmentsData(patientId, apptIds);
    const success: boolean = await UpdateConfirmAppointmentAPI(data).then(res => !!res).catch(() => false);
    if (success) {
      let smsPhoneNumber: string = (apptItem.smsSentTo || apptItem.mobilePhoneNumber);
      if (!smsPhoneNumber || smsPhoneNumber.length === 0) {
        smsPhoneNumber = apptItem.homePhoneNumber;
      }

      // send the SMS message if we have a valid number to send it to
      if (smsPhoneNumber) {
        let smsMsgConfirmApptStr: string = Aem.get(
          "BODY_MULTI_APPOINTMENTS_TEXT_21",
          "Thanks for confirming the {{VisitType}} appointment on {{VisitDateTime}}. If you need additional assistance, please feel free to call us at {{LocationPhone}}.",
          noTransformAemOpts
        );
        let smsVisitType: string = "";
        if(apptItem.aemCategoryVisitType === AemCategoryVisitTypes.video)
        {
          smsVisitType = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_6","Virtual visit", noTransformAemOpts);
        }
        else if(apptItem.aemCategoryVisitType === AemCategoryVisitTypes.inperson)
        {
          smsVisitType = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_5","Office visit", noTransformAemOpts);
        }
        else if(apptItem.aemCategoryVisitType === AemCategoryVisitTypes.phone)
        {
          smsVisitType = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_7","Phone Call", noTransformAemOpts);
        }

        smsMsgConfirmApptStr = smsMsgConfirmApptStr.replace("{{VisitType}}",smsVisitType.toLowerCase());

        const aemData: any = collectAemTokensDataWithAppointment(apptItem);
        const smsMessage: string = TransformAemTokens(smsMsgConfirmApptStr, aemData);
        
        // call sms api to send sms
        const smsModelData = InitializeSmsModelData(smsPhoneNumber, smsMessage, apptItem.apptId);
        await SendSMSMessageAPI(smsModelData).then(res => !!res).catch(() => false);
      }

      // if the response is succesfull, then update redux state for affected appointments
      dispatch(updateSingleApptValue({ apptId, key: "isConfirmed", val: true }));
      dispatch(updateSingleApptValue({ apptId, key: "isCancelled", val: false }));

      _setApptStatus(apptId, "confirmed", true);
      _setApptStatus(apptId, "cancelled", false);
    }
    return success;
  }, [patientId, dispatch, _setApptStatus]);

  const cancelAppointment = useCallback(async (apptItem: SingleApptState): Promise<boolean> => {
    // call cancel api for a single appointment and check response
    const apptId: string = apptItem.apptId;
    const data = InitializeCancelAppointmentData(patientId, apptId);
    const success: boolean = await UpdateCancelAppointmentAPI(data).then(res => !!res).catch(() => false);
    if (success) {
      let smsPhoneNumber: string = (apptItem.smsSentTo || apptItem.mobilePhoneNumber);
      if (!smsPhoneNumber || smsPhoneNumber.length === 0) {
        smsPhoneNumber = apptItem.homePhoneNumber;
      } 
      
      // send the SMS message if we have a valid number to send it to
      if (smsPhoneNumber) {
        let smsMsgCancelApptStr: string = Aem.get(
          "BODY_MULTI_APPOINTMENTS_TEXT_22",
          "The {{VisitType}} appointment on {{VisitDateTime}} has been cancelled. You can call us at {{LocationPhone}} if you have any questions or need to reschedule.",
          noTransformAemOpts
        );
        let smsVisitType: string = "";
        if(apptItem.aemCategoryVisitType === AemCategoryVisitTypes.video)
        {
          smsVisitType = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_6","Virtual visit", noTransformAemOpts);
        }
        else if(apptItem.aemCategoryVisitType === AemCategoryVisitTypes.inperson)
        {
          smsVisitType = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_5","Office visit", noTransformAemOpts);
        }
        else if(apptItem.aemCategoryVisitType === AemCategoryVisitTypes.phone)
        {
          smsVisitType = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_7","Phone Call", noTransformAemOpts);
        }
        
        smsMsgCancelApptStr = smsMsgCancelApptStr.replace("{{VisitType}}",smsVisitType.toLowerCase());

        const aemData: any = collectAemTokensDataWithAppointment(apptItem);
        const smsMessage: string = TransformAemTokens(smsMsgCancelApptStr, aemData);
        
        // call sms api to send sms
        const smsModelData = InitializeSmsModelData(smsPhoneNumber, smsMessage, apptItem.apptId);
        await SendSMSMessageAPI(smsModelData).then(res => !!res).catch(() => false);
      }

      // if the response is succesfull, then update redux state for affected appointments
      // update the item to be cancelled now and revert any possible confirmed state
      dispatch(updateSingleApptValue({ apptId, key: "isConfirmed", val: false }));
      dispatch(updateSingleApptValue({ apptId, key: "isCancelled", val: true }));

      // update apptStatus if API call was successfull
      _setApptStatus(apptId, "confirmed", false);
      _setApptStatus(apptId, "cancelled", true);
    }
    return success;
  }, [patientId, dispatch, _setApptStatus]);

  const handleApptConfirmedChange = useCallback(async (item: SingleApptState, val: boolean): Promise<void> => {
    let apptId: string = item?.apptId;
    if (apptId) {
      if (val) {
        if (!item.isConfirmed) {
          // call the API to cancel the appointment and update appointment item state
          let res = await confirmAppointment(item);

          // update the app state since the best appointment may have just changed
          invalidateBestAppointmentDetails(dispatch);

          if (!res) {
            await dispatch(gotoTaskRoute("techDifficultiesError"));
          }
        }
      } else {
        // NOTE: currently, there is no way to unconfirm an appointment.
        // the appointment card shouldn't allow this, but if it happens, then skip the API call 
        // and update the view state to reflect the change.
        dispatch(updateSingleApptValue({ apptId, key: "isConfirmed", val: false }));
        _setApptStatus(apptId, "confirmed", false);
      }
    }
  }, [_setApptStatus, confirmAppointment, dispatch]);

  const handleApptCancelledChange = useCallback(async (item: SingleApptState, val: boolean): Promise<void> => {
    let apptId: string = item?.apptId;
    if (apptId) {
      if (val) {
        if (!item.isCancelled) {
          // call the API to cancel the appointment and update appointment item state
          let res = await cancelAppointment(item);

          // update the app state since the best appointment may have just changed
          invalidateBestAppointmentDetails(dispatch);

          if (!res) {
            await dispatch(gotoTaskRoute("techDifficultiesError"));
          }
        }
      } else {
        // NOTE: currently, there is no way to uncancel an appointment.
        // the appointment card shouldn't allow this, but if it happens, then skip the API call 
        // and update the view state to reflect the change.
        dispatch(updateSingleApptValue({ apptId, key: "isCancelled", val: false }));
        _setApptStatus(apptId, "cancelled", false);
      }
    }
  }, [_setApptStatus, cancelAppointment, dispatch]);

  const isApptPreRegNeeded = useCallback((item: SingleApptState): boolean => {
    // true when preRegRequired is true && preRegDone is false
    return (item.preRegRequired && !item.preRegDone);
  }, []);

  const hasAnyConfirmedAppts = useCallback((): boolean => {
    if (!appointmentItems || appointmentItems.length === 0) { return false; }

    // scan through all appointments and look for any which have been confirmed 
    // during this session which might require the registration flow.
    // NOTE: use local component appStatus instead of redux appt state because redux may not have finished updating yet.
    return !!appointmentItems.find((v: SingleApptState) => {
      return isApptConfirmed(v);
    });
  }, [appointmentItems, isApptConfirmed]);

  const hasAnyRecentlyConfirmedApptsWithRegistrationNeeded = useCallback((checkPending: boolean = false): boolean => {
    if (!appointmentItems || appointmentItems.length === 0) { return false; }

    // scan through all appointments and look for any which have been confirmed 
    // during this session which might require the registration flow.
    // this is used to determine whether we can skip the the preRegisteredFinish view early.
    // NOTE: use local component appStatus instead of redux appt state because redux may not have finished updating yet.
    return !!appointmentItems.find((v: SingleApptState) => {
      return (isApptPreRegNeeded(v) && (isApptConfirmed(v) || (checkPending && isApptPending(v))));
    });
  }, [appointmentItems, isApptPreRegNeeded, isApptConfirmed, isApptPending]);

  const handleContinueClick = async () => {
    if(!hasPendingAppt && !hasAnyConfirmedAppts()){
      await dispatch(gotoTaskRoute("allApptsCancel"));
    }
    else if (!skipManageContinuePopup && hasPendingAppt) {
      // prompt before navigating to the next route
      await showManageContinuePromptPopup();
    } else {
      await navToNextRouteAfterConfirmation(false);
    }
  };

  const navToNextRouteAfterConfirmation = async (checkPending: boolean = false) => {
    // navigate to the next route
    let registrationNeeded: boolean = hasAnyRecentlyConfirmedApptsWithRegistrationNeeded(checkPending);
    if (registrationNeeded) {
      dispatch(setTaskTransitionPending(true));
      await dispatch(gotoTaskRoute("verify/identify"));
    } else {
      dispatch(setTaskTransitionPending(true));
      await dispatch(gotoTaskRoute("preRegisteredFinish"));
    }
  };

  const showManageContinuePromptPopup = async () => {
    const titleText: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_20", "Do you want to continue managing your remaining appointments?");
    const yesText: string = Aem.get("ACTION_YESMANAGEBUTTON_TEXT_1", "Yes, manage");
    const noText: string = Aem.get("ACTION_NOCONTINUEBUTTON_TEXT_1", "No, continue");

    const handlePopupClosed = (val: any) => {
      let resYes: boolean = (val === "yes" || val === "ok");
      let resNo: boolean = (val === "no");
      if (resYes) {
        // This popup has double negative phrasing in the text
        // in this case, yes means no.  take them back to this same screen.
        setSkipManageContinuePopup(false);
      } else if (resNo) {
        setSkipManageContinuePopup(true);
        navToNextRouteAfterConfirmation(false);
      } // else cancel was clicked
    };

    const popupProps: any = {
      icon: <CalendarBlankLgSvg />,
      title: titleText,
      actions: [
        { value: "yes", title: yesText },
        { value: "no", title: noText },
      ],
      onClose: handlePopupClosed,
    };
    dispatch(updateUIMessagePopupContext({ component: "MessagePopup", props: popupProps }));
  };

  const handleSnackbarClose = (): void => {
    setSnackbarOpen(false);
    setSnackbarProps();
  };

  useEffect(() => {
    // watch the appointmentItems and update the dayGroupedApptItems
    const newDayGroupedApptItems: any[] = groupAppointmentsByDay(appointmentItems, 0, 3);
    setDayGroupedApptItems(newDayGroupedApptItems);  
  }, [appointmentItems]);

  useEffect(() => {
    // watch the appointment status and automatically update continueEnabled
    let newContinueEnabled: boolean = false;
    if (!hasValidAppts || !hasPendingAppt || hasAnyConfirmedAppts()) {
      newContinueEnabled = true;
    }
    if (continueEnabled !== newContinueEnabled) {
      setContinueEnabled(newContinueEnabled);
    }
    updateUnconfirmedAppointmentCountText();
  }, [hasValidAppts, hasPendingAppt, continueEnabled, hasAnyConfirmedAppts, updateUnconfirmedAppointmentCountText]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={theme.navContent}>
        <Grid container direction="column">
          <Grid
            container
            direction="column"
            className={clsx(theme.navHeader, theme.bgOrangeGrad)}
          >
            <Header showMenuButton={true} />

            <Grid item className={classes.label1}>
              <Aem cid="BODY_MULTI_APPOINTMENTS_TEXT_1">
                Hi {patientFirstName}, Please confirm or cancel your upcoming appointments before continuing.
              </Aem>
            </Grid>
            <Grid item className={classes.label2}>
              <Aem cid="BODY_MULTI_APPOINTMENTS_TEXT_24">
                If you need to make changes to an appointment, please call the location.
              </Aem>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={clsx(theme.textCenter, classes.content)}
          >
            <Grid item className={theme.fillx}>
              <Grid container direction="column" className={classes.cardsArea}>
                {unConfirmedApptCountLabel && (
                  <Grid item className={classes.unConfirmedApptCountLabel}>
                    {unConfirmedApptCountLabel}
                  </Grid>
                )}
                
                {dayGroupedApptItems.map((group, groupIdx) => {
                  if (group?.items?.length > 0) {
                    const groupKey = `g${groupIdx}`;
                    return (
                      <React.Fragment key={groupKey}>
                        <Grid item className={classes.label3}>
                          <span>{apptsTextDayPrefix}</span>
                          &nbsp;
                          <span className={classes.labelBold}>{apptTextDayLabels[group?.dayOffset]}</span>
                        </Grid>
                        {group.items.map((item, itemIdx) => {
                          const itemKey: string = `g${groupIdx}-i${itemIdx}`;
                          const itemPending: boolean = isApptPending(item);
                          const itemConfirmed: boolean = isApptConfirmed(item);
                          const itemCancelled: boolean = isApptCancelled(item);
                          return (
                            <Grid item key={itemKey} className={classes.cardArea}>
                              <AppointmentCard
                                item={item}
                                confirmed={itemConfirmed}
                                cancelled={itemCancelled}
                                collapsed={!itemPending}
                                defaultCollapsed={!itemPending}
                                showReminder={!itemPending}
                                showHeaderStatus={!itemPending}
                                showFooterStatus={itemPending}
                                showActionButtons={itemPending}
                                showCollapseButton={!itemPending}
                                onConfirmedChange={(v: boolean) => handleApptConfirmedChange(item, v)}
                                onCancelledChange={(v: boolean) => handleApptCancelledChange(item, v)}
                              />
                            </Grid>
                          );
                        })}
                        <Grid item className={classes.vspacer}>&nbsp;</Grid>
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Grid>
            {showHintText && (
              <Grid item className={classes.hintText}>
                <div dangerouslySetInnerHTML={{ __html: hintText }}></div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* footer section start */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={clsx(theme.navStickyFooter, classes.footer)}
      >
        {showContinueDisabledWarning && !continueEnabled && (
          <Grid item className={classes.continueWarnText}>
            {continueWarnText}
          </Grid>
        )}
        <Grid item className={classes.navActionsArea}>
          <NavButton
            accentColor={"orange"}
            label={continueButtonLabel}
            disabled={!continueEnabled}
            fullWidth={true}
            trackName="continue"
            trackLocation="nav footer"
            onClick={handleContinueClick}
          />
        </Grid>
      </Grid>
      {/* footer section end */}

      <AppointmentSnackbar
        open={snackbarOpen}
        {...snackbarProps}
        onClose={handleSnackbarClose}
      />

    </Grid>
  );
};

export default MultiAppointmentsPage;
