import moment, { Moment } from "moment";
import "moment/locale/es";    // english locale is already loaded by default
import { AemKVPair } from "../lib/aem";
import { makeHashMap, parseToMoment } from "../utility/utilityFunctions";


// NOTE: the display text for each of these items is for English
// The code uses an ISO 3 letter language code (standard across all languages)
// the shortCode uses an ISO 2 letter language code.
// by default we use the 3 letter code within the applicatoin
// these codes should also match the Azure Cosmos DB patient-check-db language_data records

export const LanguageIsoDefs = [
  { 
    code: "eng", 
    shortCode: "en",
    text: "English",
  },
  { 
    code: "spa", 
    shortCode: "es",
    text: "Spanish",
  },
  { 
    code: "zho", 
    shortCode: "zh",
    text: "Chinese",
  },
  { 
    code: "fra", 
    shortCode: "fr",
    text: "French",
  },
  { 
    code: "tgl", 
    shortCode: "tl",
    text: "Tagalog",
  },
  { 
    code: "vie", 
    shortCode: "vi",
    text: "Vietnamese",
  },
  { 
    code: "kor", 
    shortCode: "ko",
    text: "Korean",
  },
  { 
    code: "deu", 
    shortCode: "de",
    text: "German",
  },
  { 
    code: "ara", 
    shortCode: "ar",
    text: "Arabic",
  },
  { 
    code: "rus", 
    shortCode: "ru",
    text: "Russian",
  },
  { 
    code: "other", 
    shortCode: "other",
    text: "Other",
  },
];

const DefaultLanguageShortCode: string = "en";

export const LanguageIsoCodes = makeHashMap(LanguageIsoDefs, "code");

// NOTE: we currently only support these languages in the application and AEM content
export const AppSupportedLanguageIsoCodes = [ 
  LanguageIsoCodes.eng,
  LanguageIsoCodes.spa,
];

export const DefaultLanguageIsoCode = LanguageIsoCodes.eng;

export const LanguageIsoCodesList = LanguageIsoDefs.map(v => v.code);
export const LanguageIsoNamesList = LanguageIsoDefs.map(v => v.text);
export const LanguageIsoKvPairs: AemKVPair[] = LanguageIsoDefs.map(v => ({ Key: v.code, Value: v.text }));
const LanguageIsoShortCodesList = LanguageIsoDefs.map(v => v.shortCode);


export const setGlobalMomentLocale = (languageIsoCode: string): void => {
  let locale: string = getLanguageShortCodeFromIsoCode(languageIsoCode) || DefaultLanguageShortCode;
  moment.locale(locale);
  moment().locale(locale)
};

export const getLanguageNameFromIsoCode = (code: string, defaultValue: string = "") => {
  let retval: string = defaultValue;
  let idx: number = LanguageIsoCodesList.indexOf(code);
  if (idx >= 0) { 
    retval = LanguageIsoNamesList[idx];
  }
  return retval;
};

export const getLanguageShortCodeFromIsoCode = (code: string, defaultValue: string = ""): string => {
  let retval: string = defaultValue;
  let idx: number = LanguageIsoCodesList.indexOf(code);
  if (idx >= 0) { 
    retval = LanguageIsoShortCodesList[idx];
  }
  return retval;
};

export const parseLanguageNameToIsoCode = (language: string, defaultValue: string = DefaultLanguageIsoCode): string => {
  let retval: string = defaultValue;
  if (language) { 
    // check for spanish and english first as the are the expected priority languages
    if (language.match(/^(spa|esp)/i)) {
      retval = LanguageIsoCodes.spa;
    } else if (language.match(/^eng/i)) {
      retval = LanguageIsoCodes.eng;
    } else {
      // scan through the list of languages and check each name and code
      LanguageIsoDefs.find(def => {
        let { code, text } = def;
        let reText = new RegExp(text, 'i')
        let reCode = new RegExp(code, 'i');
        if (language.match(reText) || language.match(reCode)) {
          retval = code;
          return true;
        }
        return false;
      });
    }
  }
  return retval;
};

export const isSupportedLanguageIsoCode = (languageIsoCode: string): boolean => {
  let retval: boolean = false;
  if (languageIsoCode) { 
    retval = !!AppSupportedLanguageIsoCodes.find(v => v === languageIsoCode);
  }
  return retval;
};

export const parseLanguageNameToSupportedIsoCode = (language: string, defaultValue: string = DefaultLanguageIsoCode): string => {
  let languageIsoCode: string = parseLanguageNameToIsoCode(language, defaultValue);
  if (!isSupportedLanguageIsoCode(languageIsoCode)) {
    languageIsoCode = defaultValue;
  }
  return languageIsoCode;
};

export const formatLanguageDateTimeString = (date: Date | Moment | string | null | undefined, languageIsoCode: string, formatStr: string = "LLLL"): string  => {
  let str: string = "";
  if (date) {
    let locale: string = getLanguageShortCodeFromIsoCode(languageIsoCode) || DefaultLanguageShortCode;
    let m = parseToMoment(date);
    if (m && m.isValid()) {
      str = m.locale(locale).format(formatStr);
    }
  }
  return str;
};

export const formatLanguageDateTime = (date: Date | Moment | string | null | undefined, languageIsoCode: string, formatStr: string = "dddd, MMMM Do h:mm A"): string  => {
  return formatLanguageDateTimeString(date, languageIsoCode, formatStr);
};

export const formatLanguageDate = (date: Date | Moment | string | null | undefined, languageIsoCode: string, formatStr: string = "dddd, MMMM Do"): string  => {
  return formatLanguageDateTimeString(date, languageIsoCode, formatStr);
};

export const formatLanguageTime = (date: Date | Moment | string | null | undefined, languageIsoCode: string, formatStr: string = "h:mm A"): string => {
  return formatLanguageDateTimeString(date, languageIsoCode, formatStr);
};
