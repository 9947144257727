/* eslint-disable no-useless-escape */
import cloneDeep from "lodash/cloneDeep";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getWorkPhoneNumber, getWorkPhoneExtnNumber, titleCase } from "../../utility/utilityFunctions";
import { filterPhoneInput } from "../../utility/inputFilterFunctions";

export interface Representative {
  name: string;
  dob: string;
  address: Address;
  homePhone: string;
  photoid_front: string;
  photoid_back: string;
}

export interface Demographics {
  //codes
  sexAtBirth: string;
  genderIdentity: string;
  preferredPronoun: string;
  religiousAffiliation: string;   
  maritalStatus: string;
  race: string;
  ethnicity: string;
  //values
  sexAtBirthValue: string;
  genderIdentityValue: string;
  preferredPronounValue: string;
  religiousAffiliationValue: string;   
  maritalStatusValue: string;
  raceValue: string;
  ethnicityValue: string;
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
}

export interface EmergencyContact {
  first_name: string;
  last_name: string;
  full_name: string;
  relationship: string;
  email: string;
  homePhone: string;
  workPhone: string;
  cellPhone: string;
}

export interface AdvancedDirective { 
  selection: string;
}

export interface PatientState {
  patientId: string;
  first_name: string;
  last_name: string;
  full_name: string;
  demographics: Demographics;
  email: string;
  consentToEmail: string;
  consentToText: boolean;
  homePhone: string;
  workPhone: string;
  workPhoneExtn: string,
  cellPhone: string;
  preferredPhoneType: string;
  preferredPhoneTypeCode: string;
  address: Address;
  dob: string;
  isAdultPatient: boolean;  // true if patient has valid DOB and is over 18 years old
  cdoLanguageKey: string;   // this is the CDO specific language key from AEM for the current CDO.  This is not the ISO language code
  cdoLanguageName: string;  // this is the CDO specific display name for the language in the current selected language
  photoid_front: string;
  photoid_back: string;
  emergencyContact: EmergencyContact;
  representative: Representative;
  advancedDirective: AdvancedDirective;
  accountBalance: number;
}

const initialState: PatientState = {
  patientId: "",
  first_name: "",
  last_name: "",
  full_name: "",
  demographics: {
    sexAtBirth: "",
    genderIdentity: "",
    preferredPronoun: "",
    religiousAffiliation: "",
    maritalStatus: "",
    race: "",
    ethnicity: "",
    sexAtBirthValue: "",
    genderIdentityValue: "",
    preferredPronounValue: "",
    religiousAffiliationValue: "",
    maritalStatusValue: "",
    raceValue: "",
    ethnicityValue: "",
  },
  email: "",
  dob: "",
  isAdultPatient: false,
  cdoLanguageKey: "",
  cdoLanguageName: "",
  consentToEmail: "",
  consentToText: false,
  homePhone: "",
  workPhone: "",
  workPhoneExtn: "",
  cellPhone: "",
  preferredPhoneType: "",
  preferredPhoneTypeCode: "",
  address: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
  },
  photoid_front: "",
  photoid_back: "",
  emergencyContact: {
    first_name: "",
    last_name: "",
    full_name: "",
    relationship: "",
    email: "",
    homePhone: "",
    workPhone: "",
    cellPhone: "",
  },
  representative: {
    name: "",
    dob: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
    },
    homePhone: "",
    photoid_front: "",
    photoid_back: "",
  },
  advancedDirective: {
    selection: "",
  },
  accountBalance: 0,
};

const patientSlice = createSlice({
  name: "patient",
  initialState: cloneDeep(initialState),
  reducers: {
    resetPatientState(state, _action: PayloadAction<boolean>) {
      // reset everything
      state = cloneDeep(initialState);
    },    
    updatePatientId(state, action: PayloadAction<string>) {
      state.patientId = action.payload || "";
    },
    updateFname(state, action: PayloadAction<string>) {
      state.first_name = titleCase(action.payload) || "";
    },
    updateLname(state, action: PayloadAction<string>) {
      state.last_name = titleCase(action.payload) || "";
    },
    updateFullName(state, action: PayloadAction<string>) {
      state.full_name = titleCase(action.payload) || "";
    },
    updateDemographics(state, action: PayloadAction<Demographics>) {
      state.demographics = action.payload;
    },
    updateEmail(state, action: PayloadAction<string>) {
      state.email = action.payload || "";
    },
    updateConsentToEmail(state, action: PayloadAction<string>) {
      state.consentToEmail = action.payload || "false";
    },
    updateConsentToText(state, action: PayloadAction<boolean>) {
      state.consentToText = !!action.payload;
    },
    updateHomePhone(state, action: PayloadAction<string>) {
      state.homePhone = action.payload || "";
    },
    updateWorkPhone(state, action: PayloadAction<string>) {
      state.workPhone = getWorkPhoneNumber(action.payload) || "";
    },
    updateWorkPhoneExtn(state, action: PayloadAction<string>) {
      state.workPhoneExtn = getWorkPhoneExtnNumber(action.payload) || "";
    },
    updateCellPhone(state, action: PayloadAction<string>) {
      state.cellPhone = action.payload || "";
    },
    updatePreferredPhoneType(state, action: PayloadAction<string>) {
      let val: string = action.payload || "";
      if (val === "mobilePhone") {
        val = "cellPhone";
      }
      state.preferredPhoneType = val;
    },
    updatePreferredPhoneTypeCode(state, action: PayloadAction<string>) {
      state.preferredPhoneTypeCode = action.payload || "";
    },
    updateDob(state, action: PayloadAction<string>) {
      state.dob = action.payload;
    },
    updateIsAdultPatient(state, action: PayloadAction<boolean>) {
      state.isAdultPatient = action.payload;
      console.log('updateIsAdultPatient: ', { isAdultPatient: state.isAdultPatient });
    },
    updateCdoLanguageKey(state, action: PayloadAction<string>) {
      state.cdoLanguageKey = action.payload;
    },
    updateCdoLanguageName(state, action: PayloadAction<string>) {
      state.cdoLanguageName = action.payload;
    },
    updateAddress(state, action: PayloadAction<Address>) {
      let addressData: any = action.payload || {};
      state.address = {
        address1: titleCase(addressData.address1) || "",
        address2: titleCase(addressData.address2) || "",
        city: titleCase(addressData.city) || "",
        state: addressData.state || "",
        zipcode: addressData.zipcode || "",
      };
    },
    updatePhotoIDFront(state, action: PayloadAction<string>) {
      state.photoid_front = action.payload || "";
    },
    updatePhotoIDBack(state, action: PayloadAction<string>) {
      state.photoid_back = action.payload || "";
    },
    updateEmergencyContactInfo(state, action: PayloadAction<any>) {
      let contact: any = action.payload || {};
      state.emergencyContact = {
        first_name: titleCase(contact.firstName) || "",
        last_name: titleCase(contact.lastName) || "",
        full_name: titleCase(contact.fullName) || "",
        relationship: contact.relationship || "",
        email: contact.email || "",
        homePhone: filterPhoneInput(contact.homePhone || ""),
        workPhone: filterPhoneInput(contact.workPhone || ""),
        cellPhone: filterPhoneInput(contact.cellPhone || ""),
      };
    },
    updateRepresentative(state, action: PayloadAction<any>) {
      let representativeData: any = action.payload || {};
      state.representative = {
        name: representativeData.name || "",
        dob: representativeData.dob || "",
        address: {
          address1: titleCase(representativeData.address1) || "",
          address2: titleCase(representativeData.address2) || "",
          city: titleCase(representativeData.city) || "",
          state: representativeData.state || "",
          zipcode: representativeData.zipcode || "",
        },
        homePhone: representativeData.homePhone || "",
        photoid_front: representativeData.photoid_front || "",
        photoid_back: representativeData.photoid_back || "",
      };
    },
    updateRepresentativePhotoIDFront(state, action: PayloadAction<string>) {
      state.representative.photoid_front = action.payload || "";
    },
    updateRepresentativePhotoIDBack(state, action: PayloadAction<string>) {
      state.representative.photoid_back = action.payload || "";
    },    
    updateAdvancedDirectiveSelectionValue(state, action: PayloadAction<string>) {
      state.advancedDirective.selection = action.payload || "";
    },
    updateAccountBalance(state, action: PayloadAction<number>) {
      state.accountBalance = action.payload || 0;
    },
  },
});

export const {
  resetPatientState,
  updatePatientId,
  updateFname,
  updateLname,
  updateFullName,
  updateEmail,
  updateConsentToEmail,
  updateConsentToText,
  updateDob,
  updateIsAdultPatient,
  updateCdoLanguageKey,
  updateCdoLanguageName,
  updateHomePhone,
  updateWorkPhone,
  updateWorkPhoneExtn,
  updateCellPhone,
  updatePreferredPhoneType,
  updatePreferredPhoneTypeCode,
  updateAddress,
  updatePhotoIDFront,
  updatePhotoIDBack,
  updateEmergencyContactInfo,
  updateDemographics,
  updateRepresentative,
  updateRepresentativePhotoIDFront,
  updateRepresentativePhotoIDBack,
  updateAdvancedDirectiveSelectionValue,
  updateAccountBalance,
} = patientSlice.actions;

export default patientSlice.reducer;
