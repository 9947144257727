import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid } from "@mui/material";
import useIsMounted from "../../lib/hooks/useIsMounted";
import { appColors, appFonts } from "../../theme";
import { tracker } from "../../appTracker";

const useStyles = (disabled) => {
  return makeStyles({
    root: {
      marginTop: "12px",
      marginBottom: "12px",
    },
    button: {
      paddingLeft: "0",
      paddingRight: "0",
      fontFamily: appFonts.semibold,
      fontSize: "18px",
      textTransform: "none",
    },
    icon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "28px",
      height: "28px",
      borderRadius: "50%",
      border: `2px solid ${appColors.white}`,
      fill: appColors.white,
      color: appColors.white,
    },
    label: {
      marginLeft: "12px",
      marginRight: "12px",
      fontFamily: appFonts.bold,
      fontSize: "18px",
      lineHeight: "normal",
      color: appColors.white,
      letterSpacing: "0px"
    },
    label1: {
      marginLeft: "12px",
      marginRight: "12px",
    }
  });
};

interface CircleButtonProps {
  icon?: any;
  label?: any;
  labelClassName?: any;
  disabled?: boolean;
  trackName?: string;
  trackLocation?: string;
  onClick?: (...args) => void;
};

/**
 * This is a full width button for use in mobile views with a cirlce icon and optional label.
 * The icon can be optional (blank) also by passing in null.
 */
export const CircleButton: React.FC<CircleButtonProps> = (props: CircleButtonProps) => {
  const { icon, label, labelClassName, disabled, onClick } = props;
  const classes = useStyles(disabled)();

  // used to disable the button when an async click action is occuring
  // this prevents button mashing
  const [clickPending, setClickPending] = useState<boolean>(false);
  const isMounted = useIsMounted();

  const handleClick = async (...args) => {
    let trackName = props.trackName || "";
    let trackLocation = props.trackLocation || "";
    tracker.click(trackName, trackLocation);
    
    if (onClick) { 
      setClickPending(true);
      
      try {
        await onClick(...args);
      } catch (ex) {}
      
      if (isMounted()) {
        setClickPending(false);
      }
    }
  }; 

  return (
    <Grid container direction="row" alignItems="center" className={classes.root}>
      <Button 
        className={classes.button} 
        fullWidth={true}
        disabled={disabled || clickPending}
        onClick={handleClick}>
        <Grid container direction="row" alignItems="center">
          <Grid item className={classes.icon}>{icon}</Grid>
          {label && (<Grid item className={clsx(classes.label, labelClassName)}>{label}</Grid>)}
        </Grid>
      </Button>
    </Grid>
  );
};

CircleButton.defaultProps = {
  icon: <AddIcon />,
  disabled: false,
  trackName: "circle button",
  trackLocation: "body",  
};

export default CircleButton;
