import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { Grid, FormControl, MenuItem, } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from "@mui/styles";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { appColors, getThemeBGClass, useAppTheme } from "../theme";
import { ReactComponent as MenuIconSvg } from "../assets/icons/menu_icon.svg";
import { SelectOption } from "../data/TypeDefs";
import { updateUIMenuOpenToggle } from "../features/ui/uiSlice";
import { AppButton } from "./Buttons";
import Aem, { AemContentTypes } from "../lib/aem/components/Aem";
import { invalidateAemClientData } from "../appInit";
import {
  updatePrefLanguageIsoCode,
  updatePrefLanguageSet,
} from "../features/preferences/prefSlice";
import { DefaultLanguageIsoCode, LanguageIsoCodes } from "../data/languageData";
import { tracker } from "../appTracker";


const PUBLIC_URL: string = (process?.env?.PUBLIC_URL || "");
const fallbackLogoUrl = `${PUBLIC_URL}/optum_logo_white_new.svg`;

const useStyles = makeStyles({
  root: {
    minHeight: "70px",
    paddingTop: "16px",
    paddingBottom: "30px",
  },
  headerRow: {
  },
  center: {
    alignItems: "center",
  },
  menuCenterPH: {
    width: "64px",  // match size of menu button 
    height: "28px",
  },
  tallHeader: {
    minHeight: "190px",
  },
  logo: {
    height: "24px",
    width: "82.37px",
  },
  menuButtonArea: {
  },
  menuButton: {
    fontSize: "14px",
    lineHeight: "18px",

    "& svg": {
      width: "28px",
      height: "28px",
    },

    "&.MuiButtonBase-root": {
      color: appColors.white,
    },
  },
  menuSelect: {
    color: "white",
    border: "none",
    "&:hover": {
      borderbottom: "none"
    },
    "&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderBottom: "none"
    }
  },
  menuPopover: {
    background: "rgba(50, 51, 52, 0.6)",
    color: "white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px"
  },
  menuInput: {
    background: "#323334",
    boxShadow: "none",
    color: "white",
    "&:selected": {
      background: "rgb(0 0 0 / 26%)"
    }
  }
});

interface HeaderProps {
  className?: any;
  color?: string;
  center?: boolean;
  showLogo?: boolean;
  colorLogo?: boolean;
  showMenuButton?: boolean;
  showSelectLanguage?: boolean;
  tallHeader?: boolean;
}

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const classes = useStyles();
  const theme: any = useAppTheme();
  const colorClass = getThemeBGClass(theme, props.color);
  const dispatch = useAppDispatch();

  const langSelectInputId = "lang-select-" + useId();
  const ariaLanguageLabel: string = Aem.get("ACCESSIBILITY_LANGUAGE_TEXT_1", "Select Language");

  const toggleMenu = () => {
    dispatch(updateUIMenuOpenToggle());
  }
  const languageIsoCode = useAppSelector((state) => state.preferences.languageIsoCode);
  const [prefLangIsoCode, setPrefLangIsoCode] = useState(
    useAppSelector((state) => state.preferences.languageIsoCode)
  );

  useEffect(() => {
    setPrefLangIsoCode(languageIsoCode);
  }, [languageIsoCode, dispatch])

  const handleLanguageSelectChanged = async (event: SelectChangeEvent) => {
    event.stopPropagation();

    let trackName: string = "language";
    let trackLocation: string = "header";
    tracker.click(trackName, trackLocation);

    let languageIsoCode: string = event.target.value || DefaultLanguageIsoCode;
    setPrefLangIsoCode(languageIsoCode);
    dispatch(updatePrefLanguageIsoCode(languageIsoCode));
    dispatch(updatePrefLanguageSet(true));
    await invalidateAemClientData(dispatch, false);
  };

  const languageList: SelectOption[] = [
    {
      value: LanguageIsoCodes.eng, 
      text: Aem.getListItem("BODY_SELFIDENTIFY_TEXT_LIST_2", 0, "English"),
    },
    {
      value: LanguageIsoCodes.spa, 
      text: Aem.getListItem("BODY_SELFIDENTIFY_TEXT_LIST_2", 1, "Español"),
    },
  ];

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      className={clsx(classes.root, colorClass, { [classes.tallHeader]: props.tallHeader }, props.className)}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={classes.headerRow}
        >
          {props.center && (
            <Grid item className={classes.menuCenterPH}>&nbsp;</Grid>
          )}
          {props.showLogo && (
            <Grid item className={clsx(classes.logo, { [classes.center]: props.center })}>
              <Aem cid="OPTUM_LOGO" type={AemContentTypes.imageUrl}>
                {fallbackLogoUrl}
              </Aem>
            </Grid>
          )}
          {props.showMenuButton ? (
            <Grid item className={clsx(theme.headerIcon, classes.menuButtonArea)}>
              <AppButton 
                className={classes.menuButton} 
                trackName="menu"
                trackLocation="header"
                onClick={toggleMenu}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <MenuIconSvg className={classes.logo} />
                  </Grid>
                  <Grid item>
                    <Aem cid="ACTION_MENUBUTTON_TEXT_1"></Aem>
                  </Grid>
                </Grid>
              </AppButton>
            </Grid>
          ) : (
            <Grid item className={classes.menuCenterPH}>&nbsp;</Grid>
          )}
          {props.showSelectLanguage && (
            <Grid
              item
              className={clsx(theme.headerIcon, classes.menuButtonArea)}
            >
              <FormControl>
                <Select
                  id={langSelectInputId}
                  value={prefLangIsoCode}
                  onChange={handleLanguageSelectChanged}
                  className={classes.menuSelect}
                  variant="standard"
                  MenuProps={{
                    classes: {
                      paper: classes.menuPopover
                    }
                  }}
                  inputProps={{
                    classes: {
                      root: classes.menuInput
                    },                    
                    "aria-label": ariaLanguageLabel,
                  }}
                >
                  {languageList.map((item, itemIdx) => (
                    <MenuItem key={itemIdx + "-" + item.value} value={item.value} aria-label={item.text}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

Header.defaultProps = {
  color: undefined,
  center: false,
  showLogo: true,
  colorLogo: false,
  showMenuButton: false,
  showSelectLanguage: false,
  tallHeader: false
};

export default Header;