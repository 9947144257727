import moment from "moment";
import { GetCdoShortName } from "../../data/CDO";


export interface ConfirmAppointmentsData {
  cdo: string;                  // CdoShortName
  patientId: string;
  appointments: string[];
  confirmationStatus: string;
  confirmationDate: string;
};

export const InitializeConfirmAppointmentsData = (patientId: string, appointments: string[]): ConfirmAppointmentsData => {

  const now = moment();
  const nowDateStr = now.format("YYYY-MM-DD");
  const cdoShortName = GetCdoShortName();

  return {
    cdo: cdoShortName,
    patientId: patientId,
    appointments: appointments,
    confirmationStatus: 'Y',
    confirmationDate: nowDateStr,
  };
};
