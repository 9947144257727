import React, { useState } from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useIsMounted from "../../lib/hooks/useIsMounted";
import { tracker } from "../../appTracker";

const useStyles = makeStyles(() => ({
  disabled: {
    opacity: 0.5,
    pinterEvents: "none",
  },
}));

interface BackButtonProps {
  disabled?: boolean;
  trackName?: string;
  trackLocation?: string;
  onClick?: (...args) => void;
}

export const BackButton: React.FC<BackButtonProps> = (
  props: BackButtonProps
) => {
  let { disabled, onClick } = props;
  const classes = useStyles();

  // used to disable the button when an async click action is occuring
  // this prevents button mashing
  const [clickPending, setClickPending] = useState<boolean>(false);
  const isMounted = useIsMounted();

  const handleClick = async (...args) => {
    let trackName = props.trackName || "";
    let trackLocation = props.trackLocation || "";
    tracker.click(trackName, trackLocation);
    
    if (onClick) {
      setClickPending(true);

      try {
        await onClick(...args);
      } catch (ex) {}

      if (isMounted()) {
        setClickPending(false);
      }
    }
  };

  return (
    <Grid style={{ marginRight: "24px" }}>
      <div
        className={clsx({ [classes.disabled]: (disabled || clickPending) })}
        style={{
          backgroundColor: "#3B3B3B",
          borderRadius: "30px",
          height: "48px",
          width: "48px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <ArrowBackIcon />
      </div>
    </Grid>
  );
};

BackButton.defaultProps = {
  disabled: false,
  trackName: "prev",
  trackLocation: "nav footer",
};

export default BackButton;
