import { useCallback, useEffect, useRef, useState } from "react";
import useIsMounted from "../hooks/useIsMounted";
import { TimeoutHelper } from "./TimeoutHelper";

interface LoadingState {
  loading: boolean;
  showSpinner: boolean;
}

// same as LoadingState, but all properties are optional.  
interface ILoadingState {
  loading?: boolean;
  showSpinner?: boolean;
}

interface LoadingActions {
  setLoading: (val?: boolean) => void;
  setShowSpinner: (val?: boolean, delayMS?: number) => void;
  setState: (val: ILoadingState) => void;
  cancelTimeout: () => void;
  dispose: () => void;
}

interface LoadingOptions {
  loading?: boolean;                // flag to indicate that loading is active
  showSpinner?: boolean;            // flag to indicate that a loading spinner should be shown
  showSpinnerDelayMS?: number;      // delay to wait before showing the spinner after loading is started
  hideSpinnerDelayMS?: number;      // delay to wait after loading has finished before hiding the spinner
  autoUpdateSpinner?: boolean;      // should the spinner state be updated automatically based on the loading state.  set to false if you want to manage the spinner directly
}

const defaultLoadingOptions: LoadingOptions = {
  loading: true,
  showSpinner: false,
  showSpinnerDelayMS: 1500,
  hideSpinnerDelayMS: 0,
  autoUpdateSpinner: true,
};

type LoadingReturn = [LoadingState, LoadingActions];

/**
 * A utility hook to manage loading state with a delayed spinner display
 *
 * usage example:
 * 
 * const MyComponent: React.FC = () => {
 *   const [loadState, loadActions] = useLoadingState();
 *   useEffect(() => {
 *     asyncAction().then(() => loadActions.setLoading(false));
 *   });
 *   return (<div>loading {loadState.loading}, spinner: {loadState.spinner}</div>);
 * };
 * 
 * @param options                         -- see LoadingOptions
 * @returns [ loadState, loadActions ]    -- see LoadActions
 */
export const useLoadingState = (options: LoadingOptions): LoadingReturn => {
  options = { ...defaultLoadingOptions, ...(options || {}) };

  const initState: LoadingState = {
    loading: !!options.loading,
    showSpinner: !!options.showSpinner,
  };

  const [state, _setState] = useState<LoadingState>(initState);

  const isMounted = useIsMounted();
  const initDoneRef = useRef<boolean>(false);
  const timerRef = useRef<TimeoutHelper>();
  TimeoutHelper.InitTimerRefOnce(timerRef);
  
  const _setDelayedState = useCallback((newState, delayMS: number) => {
    timerRef.current?.start(() => {
      if (!isMounted()) { return; }
      _setState(state => ({ ...state, ...newState }));
    }, delayMS);
  }, [isMounted]);

  const setLoading = useCallback((val: boolean = true) => {
    if (!isMounted()) { return; }

    let loading: boolean= !!val;
    _setState(state => {
      if (state.loading !== loading) { 
        state = { ...state, loading };

        // update the showSpinner value as needed
        if (options.autoUpdateSpinner) { 
          let showSpinner = loading;
          if (showSpinner !== state.showSpinner) {
            let delayMS: number = (showSpinner? options.showSpinnerDelayMS: options.hideSpinnerDelayMS) as number;
            if (showSpinner && delayMS > 0) {
              // set timer to set showSpinner later
              _setDelayedState({ showSpinner }, delayMS);
            } else {
              state.showSpinner = loading;
              timerRef.current?.clear();
            }
          }
        }
      }
      return state;
    });
  }, [_setDelayedState, isMounted, options.autoUpdateSpinner, options.hideSpinnerDelayMS, options.showSpinnerDelayMS]);

  const setShowSpinner = useCallback((val: boolean = true, delayMS?: number) => {
    if (!isMounted()) { return; }

    let showSpinner: boolean = !!val;
    if (delayMS === undefined) { 
      delayMS = showSpinner? options.showSpinnerDelayMS: options.hideSpinnerDelayMS;
    }

    _setDelayedState({ showSpinner }, delayMS as number);
  }, [_setDelayedState, isMounted, options.hideSpinnerDelayMS, options.showSpinnerDelayMS]);

  const setState = useCallback((newState: ILoadingState) => {
    if (!isMounted()) { return; }

    timerRef.current?.clear();
    _setState(state => ({ ...state, ...newState }));
  }, [isMounted]);

  const cancelTimeout = useCallback(() => {
    if (!isMounted()) { return; }
    timerRef.current?.clear();
  }, [isMounted]);

  const dispose = useCallback(() => {
    if (!isMounted()) { return; }
    timerRef.current?.clear();
  }, [isMounted]);

  // clean up any pending timers when the component disposes
  useEffect(() => {
    return () => {
      if (timerRef.current) { 
        timerRef.current.dispose();
        timerRef.current = undefined;
      }
    };
  }, []);

  useEffect(() => {
    if (initDoneRef.current) { return; }
    initDoneRef.current = true;

    // check whether we need to update the showSpinner state based on init state
    let shouldUpdateSpinner: boolean = !!(isMounted() && options.autoUpdateSpinner && initState.loading && !initState.showSpinner);
    if (shouldUpdateSpinner) { 
      _setDelayedState({ showSpinner: true }, options.showSpinnerDelayMS as number);
    }
  }, [_setDelayedState, initState.loading, initState.showSpinner, isMounted, options.autoUpdateSpinner, options.showSpinnerDelayMS]);

  const actions: LoadingActions = {
    setLoading,
    setShowSpinner,
    cancelTimeout,
    setState,
    dispose,
  };
  return [ state, actions ];
};

