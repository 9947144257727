import React from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as IconSvg } from "../../assets/graphic_lantern.svg";
import Header from "../../Components/Header";
import { appFonts, appStyles, useAppTheme } from "../../theme";
import Aem from "../../lib/aem/components/Aem";

const imageVerticalShift = 55;
const imageGapOffset = imageVerticalShift - 35;

const useStyles = makeStyles({
  root: {
    position: "relative",
    height: "100%",
    color: appStyles.white,
  },
  navContent: {
    flexWrap: "nowrap",
  },
  pageContent: {
    padding: "15px 30px",
    textAlign: "center",
  },
  imageWrapper: {
    marginBottom: `-${imageVerticalShift}px`,
  },
  image: {
    width: "100%",
    height: "100%",
    maxWidth: "400px",
    maxHeight: "400px",
  },
  imageGap: {
    marginTop: `${imageGapOffset}px`,
  },
  errorTitle: {
    marginTop: "15px",
    fontFamily: appFonts.semibold,
    fontSize: "22px",
    lineHeight: "28px",
  },
  errorMessage: {
    marginTop: "15px",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    lineHeight: "24px",
  },  
});

const NoAppointmentsErrorPage: React.FC = () => {
  const classes = useStyles();
  const theme = useAppTheme();

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column" className={clsx(classes.navContent, theme.navContent)}>
        <Grid container direction="column" className={clsx(theme.navHeader, theme.bgMaroonGrad)}>
      
          <Header center={true} showMenuButton={false} />

          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item className={classes.imageWrapper}>
              <IconSvg className={classes.image} />
            </Grid>
          </Grid>

        </Grid>

        <div className={classes.imageGap}></div>

        <Grid container direction="column" alignItems="center" className={classes.pageContent}>
          <Grid item className={classes.errorTitle}>
            <Aem cid="BODY_NOAPPOINTMENTS_ERROR_TEXT_1">
              No appointments found.
            </Aem>
          </Grid>
          <Grid item className={classes.errorMessage}>
            <Aem cid="BODY_NOAPPOINTMENTS_ERROR_TEXT_2">
            Uh-oh! It doesn't look like you have any upcoming appointments. Please contact your provider if you have further questions.
            </Aem>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NoAppointmentsErrorPage;
