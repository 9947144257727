import { AemClient, AemKVPair } from "../aem";
import { 
  EthnicityList, 
  GenderIdentityList, 
  MaritalStatusList,
  PreferredPronounsList,
  RaceList, 
  ReligiousAffiliationKVPairs,
  SexAssignedBirthList,
} from "../../data/demographicsData";


// This helper will check for demographic codes and values
// if any values are not set yet, it will grab them from the 
// corresponding Aem list based on the code.
export const populatePatientDemographicValues = (demographics: any): any => {
  if (!demographics) { return demographics; }

  const aemClient = AemClient.getInstance();
  let tmpKVItems: AemKVPair[] | undefined | null;

  // assuming we have these codes, find the matching valus for each code
  let { 
    // codes
    sexAtBirth, 
    genderIdentity, 
    preferredPronoun,
    religiousAffiliation,
    maritalStatus,
    race,
    ethnicity,
    // values
    sexAtBirthValue,
    genderIdentityValue,
    preferredPronounValue,
    religiousAffiliationValue,
    maritalStatusValue,
    raceValue,
    ethnicityValue,
  } = demographics;

  // for each code, if we have a code, but no value,
  // then lookup the corresponding value

  if (sexAtBirth && !sexAtBirthValue) { 
    tmpKVItems = aemClient.getListKVPairs(
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_1",
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_1",
      aemClient.toKvPairs(SexAssignedBirthList)
    );
    sexAtBirthValue = aemClient.getListKVPairValue(tmpKVItems, sexAtBirth);
  }

  if (genderIdentity && !genderIdentityValue) { 
    tmpKVItems = aemClient.getListKVPairs(
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_2",
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_2",
      aemClient.toKvPairs(GenderIdentityList)
    );
    genderIdentityValue = aemClient.getListKVPairValue(tmpKVItems, genderIdentity);
  }

  if (preferredPronoun && !preferredPronounValue) { 
    tmpKVItems = aemClient.getListKVPairs(
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_3",
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_3",
      aemClient.toKvPairs(PreferredPronounsList)
    );
    preferredPronounValue = aemClient.getListKVPairValue(tmpKVItems, preferredPronoun);
  }

  if (religiousAffiliation && !religiousAffiliationValue) { 
    tmpKVItems = aemClient.getListKVPairs(
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_7",
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_7",
      ReligiousAffiliationKVPairs
    );
    religiousAffiliationValue = aemClient.getListKVPairValue(tmpKVItems, religiousAffiliation);
  }

  if (maritalStatus && !maritalStatusValue) { 
    tmpKVItems = aemClient.getListKVPairs(
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_4",
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_4",
      aemClient.toKvPairs(MaritalStatusList)
    );
    maritalStatusValue = aemClient.getListKVPairValue(tmpKVItems, maritalStatus);
  }
  
  if (race && !raceValue) { 
    tmpKVItems = aemClient.getListKVPairs(
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_5",
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_5",
      aemClient.toKvPairs(RaceList)
    );
    raceValue = aemClient.getListKVPairValue(tmpKVItems, race);
  }
  
  if (ethnicity && !ethnicityValue) { 
      tmpKVItems = aemClient.getListKVPairs(
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_6",
      "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_6",
      aemClient.toKvPairs(EthnicityList)
    );
    ethnicityValue = aemClient.getListKVPairValue(tmpKVItems, ethnicity);
  }

  // re-assembly and return the updated demographics object,
  // making sure to preserve previous codes and other fields
  // overriding the newly updated values.
  return {
    ...demographics,
    sexAtBirthValue,
    genderIdentityValue,
    preferredPronounValue,
    religiousAffiliationValue,
    maritalStatusValue,
    raceValue,
    ethnicityValue,
  };
};

