import React from "react"; 
import { Dialog, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TransitionProps } from "@mui/material/transitions";
import { appDialogTransparentPaperProps, appMaxWidth } from "../../theme";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateUIMenuOpen } from "../../features/ui/uiSlice";
import MenuOverlay from "./MenuOverlay";
import { Aem } from "../../lib/aem";


const useStyles = makeStyles(()=> ({
  dialog: {
    maxWidth: appMaxWidth,
    margin: "0 auto",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface MenuProviderProps {
}

const MenuProvider: React.FC<MenuProviderProps> = (props: MenuProviderProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const menuOpen: boolean = useAppSelector((state) => state.ui.menuOpen);

  const ariaMenuLabel: string = Aem.get("ACCESSIBILITY_MENU_TEXT_1", "Menu");

  const handleClose = async () => {
    await dispatch(updateUIMenuOpen(false))
  };
      
  return (
    <Dialog 
      className={classes.dialog}
      fullScreen={true}
      disableRestoreFocus={false}
      open={menuOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-label={ariaMenuLabel}
      aria-labelledby=""
      PaperProps={appDialogTransparentPaperProps}
    >
      <MenuOverlay onClose={handleClose} />
    </Dialog>
  );
};

export default MenuProvider;

