import { store } from "../../app/store";


export const getTrackerActiveRouteName = (): string => {
  let state = store.getState();
  return state.task.route;
};

export const getTrackerLanguageIsoCode = (): string => {
  let state = store.getState();
  return state.preferences.languageIsoCode;
};

export const getTrackerIsProtectedMode = (): boolean => {
  let state = store.getState();
  return state.preferences.protectedMode;
};

