import React, { useCallback, useState } from "react";
import range from "lodash/range";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { appColors, appFonts } from "../../theme";
import { AppButton } from "../Buttons";
import { ISurveyQuestion, SurveyResponseValue } from "./SurveyDefs";


const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
  },
  likertContainer: {
    position: "relative",
    width: "100%",
    flexWrap: "wrap",
  },
  likertButtonBox: {
    display: "flex",
    marginTop: "3px",
    marginBottom: "3px",
  },
  likertButton: {
    fontFamily: appFonts.bold,
    fontSize: "22px",
    minWidth: "48px",
    minHeight: "48px",
    lineHeight: "initial",
    letterSpacing: "initial",
    fill: appColors.white,
    color: appColors.white,
    stroke: appColors.white,

    "&.selected": {
      backgroundColor: appColors.whiteA03,
    },

    "&.MuiButton-outlined": {
      borderColor: appColors.white,
      borderRadius: "8px",
      borderWidth: "3px",
    },
    "&.MuiButton-root:hover": {
      backgroundColor: appColors.whiteA05,
    },
  },
  likertContainerSpacer: {
    minHeight: "8px",
    flex: "0 1 auto",
  },
  likertButtonSpacer: {
    minWidth: "8px",
    minHeight: "8px",
    maxWidth: "12px",
    flex: "1 0 48px",
  },
});

interface LikertResponseProps {
  questionData?: ISurveyQuestion;
  min?: number;       // min and max are inclusive, so a min,max of [0,10] includes 0 and 10
  max?: number;
  defaultValue?: SurveyResponseValue;
  onChange?: (likertValue: SurveyResponseValue) => void;
  onDone?: () => void;
};

export const LikertResponse: React.FC<LikertResponseProps> = (props: LikertResponseProps) => {
  const classes = useStyles();

  const { min: propsMin, max: propsMax, defaultValue, onChange, onDone } = props;
  const min = Number.isFinite(propsMin)? propsMin as number: 0;
  const max = Number.isFinite(propsMax)? propsMax as number: 0;

  const [value, setValue] = useState<number | null>(Number.isFinite(defaultValue)? defaultValue as number: null);
  const likertItems: any[] = range(min, max + 1).map(v => ({ value: v, label: String(v) }));

  const handleClick = useCallback((likertValue: number) => {
    let newValue: number | null = null;
    if (Number.isFinite(likertValue) && likertValue >= min && likertValue <= max) { 
      newValue = likertValue;
    }
    setValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
    if (onDone) { 
      onDone();
    }
  }, [min, max, setValue, onChange, onDone]);

  return (
    <Grid 
      container 
      item
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.likertContainer}
    >
      <Grid item className={classes.likertContainerSpacer}>&nbsp;</Grid>  

      {likertItems.map((likertItem, idx) => {
        const selected = !!(likertItem.value === value);
        return (
          <React.Fragment key={idx}>
            {idx > 0 && (
              <Grid item className={classes.likertButtonSpacer}>&nbsp;</Grid>  
            )}
            <Grid item className={classes.likertButtonBox}>
              <AppButton
                variant="outlined" 
                disableElevation
                className={clsx(classes.likertButton, { selected })}
                trackName="survey likert"
                trackLocation="survey"
                onClick={() => handleClick(likertItem.value)}
              >
                {likertItem.label}
              </AppButton>            
            </Grid>
          </React.Fragment>
        );
      })}

      <Grid item className={classes.likertContainerSpacer}>&nbsp;</Grid>  
    </Grid>
  );
};

LikertResponse.defaultProps = {
  questionData: undefined,
  min: 0,
  max: 10,
  defaultValue: undefined,
};

export default LikertResponse;
