import mapKeys from "lodash/mapKeys";


export type CdoUid = string;
export type CdoShortName = string;
export type CdoLongName = string;
export type CdoSystem = string;

export interface CdoDef {
  type: CdoType;
  uid: CdoUid;
  shortName: CdoShortName;
  longName: CdoLongName;
  system: CdoSystem;
}

// NOTE: for consistency, 
// CdoType values should match CdoShortName values
export enum CdoType {
  Cali = "Cali",
  Unknown = "",
};

export const CdoDefs: CdoDef[] = [
  {
    type: CdoType.Cali,
    uid: "e93c86de-66c5-4c72-af62-2bc4564a7ca8",
    shortName: "Cali",
    longName: "california",
    system: "EasyCheckCaliApptId",
  },
];

const CdoTypeToDefMap: {[cdoType: string]: CdoDef} = mapKeys(CdoDefs, "type");
const DefaultCdoType: CdoType = CdoType.Cali;

export const GetCdoUidByCdoType = (cdoType: CdoType): CdoUid => {
  return CdoTypeToDefMap[cdoType]?.uid || "";
};

export const GetCdoShortNameByCdoType = (cdoType: CdoType): CdoShortName => {
  return CdoTypeToDefMap[cdoType]?.shortName || "";
};

export const GetCdoLongNameByCdoType = (cdoType: CdoType): CdoLongName => {
  return CdoTypeToDefMap[cdoType]?.longName || "";
};

export const GetCdoSystemByCdoType = (cdoType: CdoType): CdoSystem => {
  return CdoTypeToDefMap[cdoType]?.system || "";
};

export const FindBestMatchingCdoType = (cdoStr: string): CdoType => {
  let cdoType: CdoType = DefaultCdoType;
  let srchStr: string = (cdoStr || "").toLowerCase();
  if (srchStr) {
    let cdoDef = CdoDefs.find(def => (
      def.type.toLowerCase() === srchStr || 
      def.uid.toLowerCase() === srchStr ||
      def.shortName.toLowerCase() === srchStr || 
      def.longName.toLowerCase() === srchStr
    ));
    if (cdoDef) {
      cdoType = cdoDef.type;
    }
  }
  return cdoType;
};

