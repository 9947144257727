import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { Dialog, Grid, Slide, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TransitionProps } from "@mui/material/transitions";
import { SelectOption } from "../../data/TypeDefs";
import CustomTextField from "../../Components/CustomTextField";
import SearchIcon from "@mui/icons-material/Search";
import {
  appColors,
  appDialogTransparentPaperProps,
  appFonts,
  appStyles,
  useAppTheme,
} from "../../theme";
import { InsuranceProviders, InsuranceRelations, PayerCodeNameKVPairs } from "../../data";
import {
  CreateNewInsuranceCoverageItem,
  InsuranceCoverageItem,
} from "../../features/insurance/insuranceSlice";
import { AccAutoFillTokens } from "../../data/accessibility/AccessibilityData";
import { isEmpty, isEqualObjects } from "../../utility/utilityFunctions";
import { filterFullNameInput, filterGroupNumberInput, filterMemberIDInput } from "../../utility/inputFilterFunctions";
import { Aem, AemKVPair } from "../../lib/aem/components/Aem";
import { useAppSelector } from "../../app/hooks";
import { AppButton } from "../../Components/Buttons";
import config from "../../config";


const useStyles = makeStyles(() => ({
  section: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    flexWrap: "nowrap",
  },
  sectionTitle: {
    fontFamily: appFonts.bold,
    fontSize: "18px",
    lineHeight: "22px",
    margin: "4px 0",
  },
  manageButtonArea: {
    margin: "4px 12px 4px 12px",
  },
  inputBox: {
    position: "relative",
    width: "100%",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    marginBottom: "8px",
    minHeight: "60px",
  },
  formArea: {
    position: "relative",
    marginTop: "16px",
    marginBottom: "8px",
  },
  formStyle: {
    position: "relative",
  },
  overlayScrim: {
    position: "fixed",
    height: "100%",
    backgroundColor: appColors.blackScrim,
    overflow: "auto",
  },
  overlayHeader: {
    position: "relative",
    marginTop: "30px",
    marginBottom: "5px",
  },
  choicesBox: {
    maxHheight: "100%",
    paddingLeft: "60px",
    paddingBottom: "20px",
  },
  choicesRow: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "49px",
    padding: "5px 16px",
    color: appColors.white,
    fontFamily: appFonts.bold,
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.3)",
    },
  },
  searchIcon: {
    width: "22px",
    height: "22px",
    margin: "10px 22px 0 22px",
    color: appColors.white,
  },
  cancelButtonContainer: {
    margin: "0 15px",
    justifyContent: "center",
  },
  cancelButton: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    minWidth: "80px",
    minHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
  },
  formTextLabel: {
    marginLeft: "5px",
    marginRight: "5px",
    fontSize: "14px",
    fontFamily: appFonts.semibold,
    color: "rgb(255,255,255,0.7)",
  },
  inputContainer: {
    position: "relative",
    width: "100%",
    minHeight: "60px",
    padding: "5px",
    fontSize: "18px",
    color: appColors.white,
    fontFamily: appFonts.medium,
    marginBottom: "12px",
  },
  dobContainer: {
    // shift to align with other inputs
    "& span": {
      margin: "0 -5px",
      padding: "0 10px",
    },
    "& input": {
      margin: "0 -5px",
      padding: "0 10px",
    },
  },
  masked: {
    position: "relative",
    width: "100%",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    backgroundColor: "transparent",
    color: appColors.white,
    outline: "none",
    boxSizing: "border-box",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    textTransform: "uppercase",
  },
  searchInputArea: {
    width: "100%",
  },
  searchInputTextField: {
    width: "100%",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    color: appColors.white,
  },
  searchInputInput: {
    color: appColors.white,
    ...appStyles.inputUnderlineBorder,

    "&.MuiInputBase-root": {
      color: appStyles.white,
    },
  },
  checkboxFormLabel: {
    padding: "0 8px",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    color: appColors.whiteA07,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const noTransformAemOpts: any = { transformTokens: false };

interface InsuranceCoverageFormProps {
  title?: string;
  coverage?: InsuranceCoverageItem;
  onChange?: (v: any) => void;
  onDisabledChange?: (v: boolean) => void;
}

const InsuranceCoverageForm: React.FC<InsuranceCoverageFormProps> = (
  props: InsuranceCoverageFormProps
) => {
  const classes = useStyles();
  const theme = useAppTheme();

  const searchInputId = useId();

  let { title, coverage, onChange, onDisabledChange } = props;
  if (!coverage) {
    coverage = CreateNewInsuranceCoverageItem();
  }
  
  const enableEligibilityFeature: boolean = config.enableEligibilityFeature;
    
  const isSelf = (relationCode: string, relationDisplay: string): boolean => {
    let codeLowered: string = (relationCode || "").toLowerCase();
    let displayLowered: string = (relationDisplay || "").toLowerCase();
    return (codeLowered === "self" || displayLowered === "self");
  };

  const ariaSearchLabel: string = Aem.get(
    "ACCESSIBILITY_SEARCH_TEXT_1",
    "Enter Search"
  );
  const selectInsuranceProviderLabel: string = Aem.get(
    "BODY_INSURANCE_INFO_TEXT_1",
    "Insurance Provider"
  );
  const selectRelationLabel: string = Aem.get(
    "BODY_INSURANCE_INFO_TEXT_6",
    "Relation to Policy Holder"
  );

  const policyHolderLabel: string = Aem.get(
    "BODY_INSURANCE_INFO_TEXT_5",
    "Policy Holder"
  );
  
  const useCustomInsuranceRawText: string = Aem.get(
    "BODY_INSURANCE_INFO_TEXT_20",
    "Use \"{{CustomInsurance}}\"",
    noTransformAemOpts
  );

  const relationsList: AemKVPair[] = Aem.getListKVPairs(
    "BODY_INSURANCE_INFO_CODES_1",
    "BODY_INSURANCE_INFO_LIST_1",
    Aem.toKvPairs(InsuranceRelations)
  );
  const relationOptions: SelectOption[] = relationsList.map((v) => ({ 
    value: v?.Key || v?.Value || "", 
    text: v?.Value || v?.Key || "",
  }));

  const payerCodeNameList: AemKVPair[] = Aem.getListKVPairValue(
    "BODY_INSURANCE_INFO_PAYER_CODE_LIST_CODES",
    "BODY_INSURANCE_INFO_PAYER_CODE_LIST",
    PayerCodeNameKVPairs
  );
  const insuranceProviderList: string[] = Aem.getList(
    "BODY_INSURANCE_INFO_LIST_2",
    InsuranceProviders
  );

  const insuranceProviderOptions: SelectOption[] = enableEligibilityFeature ?
    payerCodeNameList.map(v => ({ value: v?.Value || "", text: v?.Value || "" })) :
    insuranceProviderList.map(v => ({ value: v || "", text: v || "" }));

  // NOTE: using samve value for code and display for now
  const [providerDisplay, setProviderDisplay] = useState<string>(coverage?.payorDisplay || "");

  const [memberId, setMemberId] = useState<string>(coverage?.memberId || "");
  const [groupNo, setGroupNo] = useState<string>(coverage?.groupNumber || "");
  const [policyHolderName, setPolicyHolderName] = useState<string>(coverage?.policyHolderName || "");
  
  // use the relationCode to find the relationDisplay
  const defaultRelationCode: string = coverage?.policyHolderRelationToPatientCode || "";
  const defaultRelationDisplay: string = coverage?.policyHolderRelationToPatientValue || "";

  const [relationCode, setRelationCode] = useState<string>(defaultRelationCode);
  const [relationDisplay, setRelationDisplay] = useState<string>(
    Aem.getListKVPairValue(relationsList, relationCode, defaultRelationDisplay, true)
  );

  const [overlay, setOverlay] = useState<string>("");
  const [curDisabled, setDisabled] = useState<boolean>(false);
  const [pendingCoverage, setPendingCoverage] = useState<InsuranceCoverageItem | undefined>(coverage);
  const [providerSearchText, setProviderSearchText] = useState<string>("");
  const patientName = useAppSelector((state) => state.patient.full_name);
  const self = isSelf(relationCode, relationDisplay);

  useEffect(() => {
    const validateAllFields = () => {
      let valid: boolean = true;
      if (
        isEmpty(providerDisplay) ||
        isEmpty(memberId) ||
        isEmpty(groupNo) ||
        isEmpty(relationCode) ||
        isEmpty(relationDisplay) ||
        (isEmpty(policyHolderName) && !self)
      ) {
        valid = false;
      }
      return valid;
    };

    // populate coverage object with new values
    let data: InsuranceCoverageItem = Object.assign({}, coverage);
    data.payorDisplay = providerDisplay;
    data.memberId = memberId;
    data.groupNumber = groupNo;
    data.policyHolderName = self ? patientName: policyHolderName;
    data.policyHolderRelationToPatientCode = relationCode;
    data.policyHolderRelationToPatientValue = relationDisplay;

    if (!isEqualObjects(data, pendingCoverage)) {
      if (onChange) {
        onChange(data);
      }
      setPendingCoverage(data);
    }

    let newDisabled: boolean = !validateAllFields();
    if (curDisabled !== newDisabled) {
      if (onDisabledChange) {
        onDisabledChange(newDisabled);
      }
      setDisabled(newDisabled);
    }
  }, [
    onChange,
    onDisabledChange,
    coverage,
    pendingCoverage,
    curDisabled,
    providerDisplay,
    memberId,
    groupNo,
    relationCode,
    relationDisplay,
    policyHolderName,
    patientName,
    self,
  ]);

  const setSelectedProviderOption = (option: SelectOption): void => {
    setProviderDisplay(option?.value || "");
  };

  const setSelectedRelationOption = (option: SelectOption): void => {   
    setRelationCode(option?.value || "");    
    setRelationDisplay(option?.text || "");
  };

  const handleSearchChange = (val: string): void => {
    if (val.length > 1) {
      val = val.charAt(0).toUpperCase() + val.slice(1);
    } else if (val.length > 0) {
      val = val.charAt(0).toUpperCase();
    }
    setProviderSearchText(val);
  };

  const filterOptions = (list: SelectOption[], searchText: string): SelectOption[] => {
    if (!searchText || searchText.length === 0) {
      return list;
    }

    const searchTextLowered: string = searchText.toLowerCase();
    const hasSearchText: boolean = !!searchTextLowered;

    let newList: SelectOption[] = (list || []).filter((option: SelectOption) => {
      let strLowered: string = (option?.text || "").toLowerCase();
      return strLowered ? strLowered.includes(searchTextLowered): hasSearchText;
    });

    // if the filtered list is nearly empty, then add the search text as an option to select
    if (hasSearchText && newList.length <= 4) {
      const includesValue = newList.some((option: SelectOption) => {
        let strLowered: string = (option?.text || "").toLowerCase();
        return (strLowered === searchTextLowered);
      });
      if (!includesValue) {
        const customStr: string = useCustomInsuranceRawText.replace("{{CustomInsurance}}", searchText);
        if (customStr) {
          newList.push({ value: searchText, text: customStr });
        }
      }
    }

    return newList;
  };
  

  const renderProviderOverlayContent = (list: SelectOption[]) => {
    const filteredOptions = filterOptions(list, providerSearchText);
    return (
      <Grid
        container
        direction="column"
        className={clsx(classes.overlayScrim, theme.nowrap)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={clsx(classes.overlayHeader, theme.nowrap)}
        >
          <Grid item>
            <SearchIcon className={classes.searchIcon} />
          </Grid>
          <Grid item className={classes.searchInputArea}>
            <TextField
              id={searchInputId}
              variant="standard"
              className={classes.searchInputTextField}
              inputProps={{
                "aria-label": ariaSearchLabel,
              }}
              InputProps={{
                classes: {
                  underline: classes.searchInputInput,
                },
              }}
              value={providerSearchText}
              autoComplete={AccAutoFillTokens.off}
              onChange={(e) => {
                handleSearchChange(e.target.value);
              }}
            />
          </Grid>
          <Grid item className={classes.cancelButtonContainer}>
            <AppButton
              variant="outlined"
              disableElevation
              className={classes.cancelButton}
              trackName="cancel"
              trackLocation="overlay"
              onClick={() => {
                setOverlay("");
                setProviderSearchText("");
              }}
            >
              <Aem cid="ACTION_CANCELBUTTON_TEXT_1">Cancel</Aem>
            </AppButton>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          className={clsx(classes.choicesBox, theme.nowrap)}
        >
          {filteredOptions.map((option: SelectOption, idx: number) => {
            return (
              <Grid
                key={idx}
                item
                xs={12}
                className={classes.choicesRow}
                aria-label={option.text}
                onClick={() => {
                  setOverlay("");
                  setProviderSearchText("");
                  setSelectedProviderOption(option);
                }}
              >
                {option.text}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  const renderRelationChoicesOverlayContent = (list: SelectOption[]) => {
    return (
      <Grid container direction="column" className={classes.overlayScrim}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={clsx(classes.overlayHeader, theme.nowrap)}
        >
          <Grid
            container
            direction="column"
            className={clsx(classes.choicesBox, theme.nowrap)}
          >
            {list.map((option: SelectOption, idx: number) => {
              return (
                <Grid
                  key={idx}
                  item
                  xs={12}
                  className={classes.choicesRow}
                  aria-label={option.text}
                  onClick={() => {
                    setOverlay("");
                    setSelectedRelationOption(option);
                  }}
                >
                  {option.text}
                </Grid>
              );
            })}
          </Grid>
          <Grid item className={classes.cancelButtonContainer}>
            <AppButton
              variant="outlined"
              disableElevation
              className={classes.cancelButton}
              trackName="cancel"
              trackLocation="overlay"
              onClick={() => {
                setOverlay("");
              }}
            >
              <Aem cid="ACTION_CANCELBUTTON_TEXT_1">Cancel</Aem>
            </AppButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item className={classes.formArea}>
      <form className={classes.formStyle}>         

        {title && (
          <Grid item className={classes.sectionTitle}>
            {title}
          </Grid>
        )}

        <Grid item className={clsx(classes.inputBox, classes.section)}>
          <CustomTextField
            required={true}
            label={selectInsuranceProviderLabel}
            name="providerDisplayName"
            fullWidth
            variant="filled"
            value={providerDisplay}
            onClick={() => {
              setOverlay("providers");
            }}
          />
        </Grid>
        {/*
        <Grid item className={clsx(classes.inputBox, classes.section)}>
          <CustomTextField
            label="Address of Insurance Provider"
            name="providerAddress"
            fullWidth
            variant="filled"
            value={providerAddress}
            onChange={(e) => {
              setProviderAddress(e.target.value);
            }}
          /> 
        </Grid>
        */}
        <Grid item className={clsx(classes.inputBox, classes.section)}>
          <CustomTextField
            required={true}
            label={Aem.get("BODY_INSURANCE_INFO_TEXT_3", "Member ID")}
            name="memberId"
            fullWidth
            variant="filled"
            value={memberId}
            onChange={(e) => {
              setMemberId(filterMemberIDInput(e.target.value));
            }}
          />
        </Grid>
        <Grid item className={clsx(classes.inputBox, classes.section)}>
          <CustomTextField
            required={true}
            label={Aem.get("BODY_INSURANCE_INFO_TEXT_4", "Group Number")}
            name="groupNo"
            fullWidth
            variant="filled"
            value={groupNo}
            onChange={(e) => {
              setGroupNo(filterGroupNumberInput(e.target.value));
            }}
          />
        </Grid>
        {/*
        <Grid item className={clsx(classes.inputBox, classes.section)}>
          <CustomTextField
            required={true}
            label="Policy Number"
            name="policyNumber"
            fullWidth
            variant="filled"
            value={policyNumber}
            onChange={(e) => {
              setPolicyNumber(filterPolicyNumberInput(e.target.value));
            }}
          /> 
        </Grid>
        */}
        <Grid item className={clsx(classes.inputBox, classes.section)}>
          <CustomTextField
            required={true}
            label={selectRelationLabel}
            name="relation"
            fullWidth
            variant="filled"
            value={relationDisplay}
            onClick={() => {
              setOverlay("relation");
            }}
          />
        </Grid>
        {!self ? (
          <Grid item className={clsx(classes.inputBox, classes.section)}>
            <CustomTextField
              required={true}
              label={policyHolderLabel}
              name="policyHolderName"
              fullWidth
              variant="filled"
              value={policyHolderName}
              onChange={(e) => {
                setPolicyHolderName(filterFullNameInput(e.target.value));
              }}
            />
          </Grid>
        ) : (
          ""
        )}

        {/* 
        <Grid item className={clsx(classes.inputBox, classes.section)}>
          <CustomTextField
            required={true}
            label="Policy Holder's Address"
            name="policyHolderAddress"
            fullWidth
            variant="filled"
            value={policyHolderAddress}
            onChange={(e) => {
              setPolicyHolderAddress(filterAddressInput(e.target.value));
            }}
          /> 
        </Grid>
        <Grid item className={clsx(classes.inputBox, classes.section)}>
          <CustomTextField
            required={true}
            label="Name of Policy Holder's Employer"
            name="policyHolderEmployer"
            fullWidth
            variant="filled"
            value={policyHolderEmployer}
            onChange={(e) => {
              setPolicyHolderEmployer(filterCompanyNameInput(e.target.value));
            }}
          />
        </Grid>
        <Grid item className={clsx(classes.inputBox, classes.section)}>
          <CustomTextField
            label="Address of Policy Holder's Employer"
            name="EmployerAddress"
            fullWidth
            variant="filled"
            value={EmployerAddress}
            onChange={(e) => {
              setEmployerAddress(filterFullAddressInput(e.target.value));
            }}
          /> 
        </Grid>
        */}
      </form>

      <Dialog
        fullScreen={true}
        disableRestoreFocus={true}
        open={overlay === "providers"}
        onClose={() => setOverlay("")}
        TransitionComponent={Transition}
        aria-label={selectInsuranceProviderLabel}
        aria-labelledby=""
        PaperProps={appDialogTransparentPaperProps}
      >
        {renderProviderOverlayContent(insuranceProviderOptions)}
      </Dialog>

      <Dialog
        fullScreen={true}
        disableRestoreFocus={true}
        open={overlay === "relation"}
        onClose={() => setOverlay("")}
        TransitionComponent={Transition}
        aria-label={selectRelationLabel}
        aria-labelledby=""
        PaperProps={appDialogTransparentPaperProps}
      >
        {renderRelationChoicesOverlayContent(relationOptions)}
      </Dialog>
    </Grid>
  );
};

InsuranceCoverageForm.defaultProps = {
  title: "Insurance",
};

export default InsuranceCoverageForm;

