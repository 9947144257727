import every from "lodash/every";
import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { Promise } from "bluebird";
import { makeStyles } from "@mui/styles";
import CheckIcon from '@mui/icons-material/Check';
import { store } from "../app/store";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { NavButton } from "../Components/Buttons";
import { 
  ApptState, 
  SingleApptState, 
  updateEligibilityAttempts, 
  updateEligibilityVerified,
  updateSingleApptValue,
} from "../features/appt/apptSlice";
import {
  Address,
  Demographics,
  PatientState,
  EmergencyContact,
} from "../features/patient/patientSlice";
import {
  InsuranceState,
  InsuranceCoverageItem,
} from "../features/insurance/insuranceSlice";
import { Header } from "../Components/Header";
import { appColors, appFonts, useAppTheme } from "../theme";
import {
  gotoNextTaskStep,
  gotoTaskRoute,
  setPendingTaskRoute,
  setTaskTransitionPending,
} from "../features/task/taskSlice";
import InsuranceInfoCard from "../Components/InsuranceInfoCard";
import { formatPhoneNumber, getWorkPhoneNumber, formatWorkPhoneWithExtn, formatDate, cloneDeep } from "../utility/utilityFunctions";
import Aem, { AemContentTypes, AemKVPair } from "../lib/aem/components/Aem";
import { collectCompletionCheckinData } from "../lib/save-state/collectCompletionData";
import { CompletionCheckinDataSchema } from "../lib/save-state/CompletionDataSchema";
import { 
  CheckInCompleteMultiApptAPI,
  CheckInCompleteUpdateInsuranceData,
  CheckInCompleteUpdatePatientData,
  CheckInCompleteUpdatePatientSignedForms,
} from "../api/CheckinCompleteAPI";
import { formatAddressCityStateZip, formatAddressStreetLines } from "../lib/addressUtils";
import config from "../config";
import { ReactComponent as CheckmarkSvg } from "../assets/icons/check-circlegreen.svg";
import { ReactComponent as ExclamationmarkSvg } from "../assets/icons/exclamation.svg";
import { ReactComponent as EligibilityPlusSvg } from "../assets/page_icons/icon_eligibility_plus.svg";
import { ReactComponent as EligibilityCheckSvg } from "../assets/page_icons/icon_eligibility_check.svg";
import { CheckInsuranceEligibilityAPI } from "../api/CheckInsuranceEligibilityAPI";
import { PayerCodeNameKVPairs } from "../data";
import { updateUIMessagePopupContext } from "../features/ui/uiSlice";
import { InitialzeSubscriberData, InitializeVerificationRequestData } from "../lib/eligibility/verificationRequestDataSchema";
import LoadingSpinner from "../Components/LoadingSpinner";


const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  headerTitle: {
    marginTop: "0px",
  },
  navSection: {
    paddingBottom: "24px",
  },
  heading: {
    fontFamily: appFonts.bold,
    fontSize: "22px",
  },
  title: {
    fontFamily: appFonts.bold,
    fontSize: "20px",
    marginTop: "30px",
  },
  section: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "nowrap",
  },
  sectionHeader: {
    width: "100%",
    fontFamily: appFonts.bold,
    fontSize: "18px",
    justifyContent: "space-between",
    marginBottom: "16px",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  sectionTitle: {
    fontFamily: appFonts.bold,
    fontSize: "18px",
  },
  sectionContent: {
    fontSize: "18px",
    //fontFamily: appFonts.regular,
    fontFamily: appFonts.medium,
    marginTop: "20px",
  },
  sectionFields: {
    fontSize: "16px",
    fontFamily: appFonts.semibold,
    color: "rgba(255,255,255,0.7)",
    paddingTop: "4px",
  },
  sectionValues: {
    fontSize: "18px",
    sontfamily: appFonts.medium,
    paddingLeft: "5px",
  },
  editButtonArea: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  details: {
    marginTop: "15px",
    fontSize: "18px",
    fontFamily: appFonts.regular,
  },
  preferred: {
    fontSize: "12px",
    fontFamily: appFonts.semibolditalic,
    color: "rgba(255,255,255,0.7)",
    paddingTop: "4px",
    paddingLeft: '5px',
    display: "flex",
    justifyContent: "flex-end"
  },
  cardContainer: {
    position: "relative",
    marginRight: "18px",
    flexWrap: "nowrap",
  },
  editButton: {
    "&.MuiButton-outlined": {
      "&.MuiButton-root": {
        fontSize: 16,
      },
    },
  },
  pgFunc: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  eligibleSuccessTitle: {
    fontFamily: appFonts.regular,
    fontSize: "15px",
    margin: "4px 0",
    color: appColors.success1,  
    "& span": {
      margin: "0 5px",
    }
  },
  eligibleFailedTitle: {
    fontFamily: appFonts.regular,
    fontSize: "15px",
    margin: "4px 0",
    color: appColors.darkError,
    "& span": {
      margin: "0 5px",
    }
  },
}));

const ReviewScreen: React.FC =  () => {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();

  const appt: ApptState = useAppSelector((state) => state.appt);
  const patient: PatientState = useAppSelector((state) => state.patient);
  const patientDataChanged: boolean = useAppSelector<boolean>((state) => state.delta.patient);
  const insuranceDataChanged: boolean = useAppSelector<boolean>((state) => state.delta.insurance);
  const signedFormsDataChanged: boolean = useAppSelector<boolean>((state) => state.delta.signedForms);
  const fullName = patient.first_name + " " + patient.last_name;
  const dob = patient.dob;
  const cdoLanguageName = patient.cdoLanguageName;
  const demographics: Demographics = patient.demographics;
  const address: Address = patient.address;
  const addressStreetLines: string[] = formatAddressStreetLines(address);
  const addressCityStateZip: string = formatAddressCityStateZip(address);
  const streetAddress = addressStreetLines.join(" ");
  const cityStateZip = addressCityStateZip;
  const preferredPhoneType = patient.preferredPhoneType;
  const homePhone = patient.homePhone;
  const mobilePhone = patient.cellPhone;
  const workPhone = getWorkPhoneNumber(patient.workPhone);
  const workPhoneExtn = (patient.workPhoneExtn.length > 0) ? patient.workPhoneExtn : "";
  const email = patient.email;
  const emergencyContact: EmergencyContact = patient.emergencyContact;
  const emergencyContactName = emergencyContact.full_name;
  const emergencyRelationship = emergencyContact.relationship;
  const emergencyHomePhone = emergencyContact.homePhone;
  const emergencyMobilePhone = emergencyContact.cellPhone;
  const insuranceState: InsuranceState = useAppSelector(
    (state) => state.insurance
  );
  const hasSelfCoverage = insuranceState.hasSelfCoverage;
  const hasCoverage2 = insuranceState.hasCoverage2;
  const coverage1: InsuranceCoverageItem = insuranceState.coverage1;
  const coverage2: InsuranceCoverageItem = insuranceState.coverage2;
  const activeRoute: string = useAppSelector((state) => state.task.route);
  const routeThemeColor: string = useAppSelector(
    (state) => state.task.routeInfo?.themeColor
  );

  // Copay and Balance Info
  // config flag to show/hide the account balance section.
  // $SHOW_ACCOUNT_BALANCE$ keep in sync with other uses
  const showAccountBalance = false;
  const accountBalance = useAppSelector(
    (state) => state.patient.accountBalance
  );

  // Check Eligibility
  const enableEligibilityFeature: boolean = config.enableEligibilityFeature;
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
  const insuranceVerifiedText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_16", "Insurance verified.");
  const NotVerifiedContactProvText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_17", "Your insurance could not be verified. Please contact your insurance provider for further information.");
  const MAX_FAILED_VERIFY_ATTEMPTS: number = 1;

  const isReturningPatient: boolean = useAppSelector(
    (state) => state.appt.isReturningPatient
  );
 
  const eligibilityVerified = useAppSelector(
    (state) => state.appt.eligibilityVerified
  );

  const eligibilityAttempts = useAppSelector(
    (state) => state.appt.eligibilityAttempts
  );

  let isEligibilityChecked: boolean = false;
  if ((eligibilityAttempts > 0)) {
    isEligibilityChecked = true;
  }

  const showEligibilityPromptPopup = useCallback(async (eligible: boolean) => {
    const verifiedTitleText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_15", "Your insurance information has been verified.");
    const sorryNotVerifiedTitleText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_11", "Sorry, the insurance information you provided could not be verified.");
    const plsReEnterSubTitleText: string = Aem.get("BODY_INSURANCE_INFO_TEXT_13", "Please re-enter your primary insurance information.");

    const okayText: string = Aem.get("ACTION_OKAYBUTTON_TEXT_1", "Okay");

    if (eligible) {
      const popupProps: any = {
        icon: <EligibilityCheckSvg />,
        title: verifiedTitleText,
        actions: [
          { value: "okay", title: okayText },
        ],
        showCancelButton: false
      };
      dispatch(updateUIMessagePopupContext({ component: "MessagePopup", props: popupProps }));
    } else {
      const popupProps: any = {
        icon: <EligibilityPlusSvg />,
        title: sorryNotVerifiedTitleText,
        message: plsReEnterSubTitleText,
        actions: [
          { value: "okay", title: okayText },
        ],
        showCancelButton: false
      };
      dispatch(updateUIMessagePopupContext({ component: "MessagePopup", props: popupProps }));
    }
  }, [dispatch]);

  const preCheckEligibilityForROU = useCallback(async () => {    
    if (!hasSelfCoverage && isReturningPatient && (eligibilityAttempts < MAX_FAILED_VERIFY_ATTEMPTS)) {
      dispatch(updateEligibilityAttempts(eligibilityAttempts + 1));

      const payerCodeNameList: AemKVPair[] = Aem.getListKVPairValue(
        "BODY_INSURANCE_INFO_PAYER_CODE_LIST_CODES",
        "BODY_INSURANCE_INFO_PAYER_CODE_LIST",
        PayerCodeNameKVPairs
      );

      let coverage: InsuranceCoverageItem | null = cloneDeep(
        coverage1
      );
      if (coverage) {
        let payerCode: string = "";
        let payerName: string = "";
        let providerKeyCode = payerCodeNameList.find((v: AemKVPair) => (v?.Value === coverage?.payorDisplay));

        if (providerKeyCode !== undefined) {
          let payerKeyCode = providerKeyCode.Key.split('||');
          if (payerKeyCode !== undefined) {
            payerCode = payerKeyCode[0];
            payerName = payerKeyCode[1];
          }
        }

        let appointmentItems: SingleApptState[] = appt.allAppointmentInfos;
        const apptDateTime: any = appointmentItems && appointmentItems.length > 0 ? formatDate(appointmentItems[0].apptDateTime, "MM/DD/YYYY") : "";

        const subscriberData = InitialzeSubscriberData(patient.first_name, patient.dob, patient.last_name, coverage.memberId);
        const data = InitializeVerificationRequestData(payerCode, payerName, subscriberData, apptDateTime, apptDateTime);

        if (enableEligibilityFeature) {
          setShowLoadingSpinner(true);
          let isEligible: boolean = await CheckInsuranceEligibilityAPI(data).then(res => !!res).catch(() => false);
          setShowLoadingSpinner(false);
        
          dispatch(updateEligibilityVerified(isEligible));
          await showEligibilityPromptPopup(isEligible);
        }
      }
    }
  }, [patient, appt, coverage1, enableEligibilityFeature, eligibilityAttempts, 
    isReturningPatient, hasSelfCoverage, showEligibilityPromptPopup, dispatch]);

  const handleNextClick = async () => {
    // make sure to complete checkin for all appointments confirmed during this session
    // and recently confirmed which have pre_reg = false

    dispatch(setTaskTransitionPending(true));
    let providerName: string = '';
    let registrationApptItems: SingleApptState[] = appt.allAppointmentInfos.filter((apptItem: SingleApptState) => {
      providerName = apptItem.providerName;
      // NOTE: preRegRequired is ignored for this check
      return (!apptItem.preRegDone && (apptItem.isConfirmed && !apptItem.isCancelled));
    });
    let registrationApptIds: string[] = registrationApptItems.map(v => v.apptId);

    console.log("delta flags: ", { patientDataChanged, insuranceDataChanged, signedFormsDataChanged });

    // only update categories of data which have changed during this session.
    let patientState = store.getState().patient;
    let checkinData = collectCompletionCheckinData(patientState, registrationApptIds, providerName);
    let res1 = await Promise.all([
      updatePatientData(checkinData),
      updateInsuranceData(checkinData),
      updateSignedFormsData(checkinData),
    ]).then(resItems => every(resItems))
      .catch(() => false);

    // even if one of the previous update fails, attempt to complete the checkin
    let res2 = await CheckInCompleteMultiApptAPI(checkinData).then(res => !!res).catch(() => false);

    if (res1 && res2) {
      // update preregDone flag for any completed appointments
      // NOTE: the API should update the preregDone flag.  
      // we are trusting the API to update this value if we reload the API data.
      registrationApptIds.forEach(apptId => {
        dispatch(updateSingleApptValue({ apptId, key: "preRegDone", val: true }));        
      });
      dispatch(gotoNextTaskStep());
    } else {
      dispatch(gotoTaskRoute("techDifficultiesError"));
    }
  };

  const updatePatientData = async (completionData: CompletionCheckinDataSchema): Promise<boolean> => {
    let success = true;
    if (patientDataChanged) {
      let patientData = completionData.patient;
      success = await CheckInCompleteUpdatePatientData(patientData)
        .then(res => !!res)
        .catch(() => false);
    }
    return success;
  };

  const updateInsuranceData = async (completionData: CompletionCheckinDataSchema): Promise<boolean> => {
    let success = true;
    if (insuranceDataChanged) {
      // NOTE: we may have multiple appointments which all need to be updated
      let patient = completionData.patient;
      success = await Promise.map(completionData.appointments, appointment => {
        let data = {
          appointment,
          patient,
        };
        return CheckInCompleteUpdateInsuranceData(data)
          .then(res => !!res)
          .catch(() => false);
      }).then(resItems => every(resItems))
        .catch(() => false);
    }
    return success;
  };

  const updateSignedFormsData = async (completionData: CompletionCheckinDataSchema): Promise<boolean> => {
    let success = true;
    if (signedFormsDataChanged) {
      // NOTE: we may have multiple appointments which all need to be updated
      let patientId = completionData.patient.patientId;
      success = await Promise.map(completionData.appointments, appointment => {
        let data = {
          patientId,
          appointmentId: appointment.appointmentId,
        };
        return CheckInCompleteUpdatePatientSignedForms(data)
          .then(res => !!res)
          .catch(() => false);
      }).then(resItems => every(resItems))
        .catch(() => false);
    }
    return success;
  };

  const handleInsuranceEdit = async()=>{
    if (!isReturningPatient) {
      dispatch(updateEligibilityAttempts(0));
    }
    dispatch(updateEligibilityVerified(false));
    dispatch(gotoTaskRoute("reviewPatient/insurance"));
    dispatch(setPendingTaskRoute(activeRoute));
  };
  
  useEffect(()=>{
    preCheckEligibilityForROU();
  },[preCheckEligibilityForROU]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        direction="column"
        className={clsx(theme.navContent, theme.nowrap)}
      >
        <Grid
          container
          direction="column"
          className={clsx(theme.navHeader, theme.bgBlueGrad)}
        >
          <Header showMenuButton={true} />

          <Grid container className={classes.pgFunc}>
            <Grid item>
              <h1
                className={clsx(classes.headerTitle, theme.headerTitle)}
                style={{ marginBottom: "0px" }}
              >
                <Aem cid="HEADER_FINALREVIEW_TEXT_1">Review Information</Aem>
              </h1>
            </Grid>
            <Grid item>
              {/* <HealthProfileSVG/> */}
              <Aem
                cid="HEADER_FINALREVIEW_LOGO_1"
                type={AemContentTypes.imageUrl}
              ></Aem>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          className={clsx(theme.navSection, theme.nowrap, classes.navSection)}
        >
          {enableEligibilityFeature && !hasSelfCoverage && isEligibilityChecked && (
            <React.Fragment>
              {eligibilityVerified && (
                <Grid item className={classes.eligibleSuccessTitle}>
                  <CheckmarkSvg /><span>{insuranceVerifiedText}</span>
                </Grid>
              )}
              {!eligibilityVerified && (
                <Grid item className={classes.eligibleFailedTitle}>
                  <ExclamationmarkSvg /><span>{NotVerifiedContactProvText}</span>
                </Grid>
              )}
            </React.Fragment>
          )}
          
          {showLoadingSpinner && (
            <Grid item><LoadingSpinner size={40} accentColor="white" /></Grid>
          )}

          <Grid item className={classes.sectionContent}>
            <Aem cid="BODY_FINALREVIEW_TEXT_1">
              If you need to make any corrections, please click on the edit
              button.
            </Aem>
          </Grid>
          {!hasSelfCoverage && (
            <React.Fragment>
              <Grid container direction="row" className={classes.section}>
                <Grid container className={classes.sectionHeader}>
                  <Grid item xs={8} className={classes.sectionTitle}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_25">Primary Insurance</Aem>
                  </Grid>
                  <Grid item xs={4} className={classes.editButtonArea}>
                    <NavButton
                      label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                      size="small"
                      variant="outlined"
                      fullWidth={false}
                      className={classes.editButton}
                      trackName="edit"
                      trackLocation="body"
                      onClick={() => {
                      handleInsuranceEdit()
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  className={classes.cardContainer}
                >
                  <InsuranceInfoCard coverage={coverage1} isPrimary={true}/>
                </Grid>
              </Grid>
              {hasCoverage2 && (
                <Grid container direction="row" className={classes.section}>
                  <Grid container className={classes.sectionHeader}>
                    <Grid item xs={8} className={classes.sectionTitle}>
                      <Aem cid="BODY_FINALREVIEW_TEXT_26">
                        Secondary Insurance
                      </Aem>
                    </Grid>
                    <Grid item xs={4} className={classes.editButtonArea}>
                      <NavButton
                        label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                        size="small"
                        variant="outlined"
                        fullWidth={false}
                        className={classes.editButton}
                        trackName="edit"
                        trackLocation="body"
                        onClick={() => {
                          handleInsuranceEdit()
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    className={classes.cardContainer}
                  >
                    <InsuranceInfoCard coverage={coverage2} />
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          )}
          {hasSelfCoverage && (
            <Grid container className={classes.section}>
              <Grid container className={classes.sectionHeader}>
                <Grid item className={classes.sectionTitle}>
                  <Aem cid="BODY_FINALREVIEW_TEXT_25">Primary Insurance</Aem>
                </Grid>
                <Grid item className={classes.editButtonArea}>
                  <NavButton
                    label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                    size="small"
                    variant="outlined"
                    fullWidth={false}
                    className={classes.editButton}
                    trackName="edit"
                    trackLocation="body"
                    onClick={() => {
                      handleInsuranceEdit()
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                className={classes.sectionContent}
              >
                <Grid item xs={8} className={classes.sectionValues}>
                  <Aem cid="BODY_FINALREVIEW_TEXT_29">Self Pay</Aem>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Account Outstanding Balance */}
          {showAccountBalance && (
            <Grid container direction="column" className={classes.section}>
              <Grid item className={classes.sectionTitle}>
                <Aem cid="BODY_FINALREVIEW_TEXT_2">Account Balance</Aem>
              </Grid>
              <Grid
                item
                style={{
                  fontSize: "20px",
                  height: "20px",
                  fontFamily: appFonts.regular,
                  paddingTop: "4px",
                }}
              >
                <span>${accountBalance}</span>
              </Grid>
              <Grid
                item
                style={{
                  marginTop: "12px",
                }}
              >
                <span
                  style={{
                    fontFamily: appFonts.regular,
                    fontSize: "12px",
                  }}
                >
                  <Aem cid="BODY_FINALREVIEW_TEXT_3">
                    This is your current account balance. This number may include
                    any unpaid copays, visit costs, and other associated fees for
                    your account.
                  </Aem>
                </span>
              </Grid>
            </Grid>
          )}

          {/* Demographics section */}
          <Grid container className={classes.section}>
            <Grid container className={classes.sectionHeader}>
              <Grid item className={classes.sectionTitle}>
                <Aem cid="BODY_FINALREVIEW_TEXT_4">Patient Demographics</Aem>
              </Grid>
              <Grid item className={classes.editButtonArea}>
                <NavButton
                  label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                  size="small"
                  variant="outlined"
                  fullWidth={false}
                  className={classes.editButton}
                  trackName="edit"
                  trackLocation="body"
                  onClick={() => {
                    dispatch(gotoTaskRoute("reviewPatient/name"));
                    dispatch(setPendingTaskRoute(activeRoute));
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.sectionContent}>
              <Grid item xs={4} className={classes.sectionFields}>
                <Aem cid="BODY_FINALREVIEW_TEXT_5">Name</Aem>
              </Grid>
              <Grid item xs={8} className={classes.sectionValues}>
                {fullName}
              </Grid>
              <Grid item xs={4} className={classes.sectionFields}>
                <Aem cid="BODY_FINALREVIEW_TEXT_7">Date of Birth</Aem>
              </Grid>
              <Grid item xs={8} className={classes.sectionValues}>
                {dob}
              </Grid>
              {cdoLanguageName && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_8">Language</Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {cdoLanguageName}
                  </Grid>
                </React.Fragment>
              )}
              {demographics.sexAtBirthValue && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_9">
                      Sex Assigned at Birth
                    </Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {demographics.sexAtBirthValue}
                  </Grid>
                </React.Fragment>
              )}
              {demographics.genderIdentityValue && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_10">Gender Identity</Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {demographics.genderIdentityValue}
                  </Grid>
                </React.Fragment>
              )}
              {demographics.preferredPronounValue && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_11">Preferred Pronouns</Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {demographics.preferredPronounValue}
                  </Grid>
                </React.Fragment>
              )}
              {demographics.maritalStatusValue && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_12">Marital Status</Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {demographics.maritalStatusValue}
                  </Grid>
                </React.Fragment>
              )}
              {demographics.raceValue && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_30">Race</Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {demographics.raceValue}
                  </Grid>
                </React.Fragment>
              )}
              {demographics.ethnicityValue && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_31">Ethnicity</Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {demographics.ethnicityValue}
                  </Grid>
                </React.Fragment>
              )}
              {demographics.religiousAffiliationValue && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_32">
                      Religious Affiliation
                    </Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {demographics.religiousAffiliationValue}
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>

          <Grid container className={classes.section}>
            <Grid container className={classes.sectionHeader}>
              <Grid item className={classes.sectionTitle}>
                <Aem cid="BODY_FINALREVIEW_TEXT_13">Patient Address</Aem>
              </Grid>
              <Grid item className={classes.editButtonArea}>
                <NavButton
                  label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                  size="small"
                  variant="outlined"
                  fullWidth={false}
                  className={classes.editButton}
                  trackName="edit"
                  trackLocation="body"
                  onClick={() => {
                    dispatch(gotoTaskRoute("reviewPatient/address"));
                    dispatch(setPendingTaskRoute(activeRoute));
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.sectionContent}>
              <Grid item xs={4} className={classes.sectionFields}>
                <Aem cid="BODY_FINALREVIEW_TEXT_14">Street Address</Aem>
              </Grid>
              <Grid item xs={8} className={classes.sectionValues}>
                {streetAddress}
              </Grid>
              <Grid item xs={4} className={classes.sectionFields}>
                <Aem cid="BODY_FINALREVIEW_TEXT_15">City, State, Zip</Aem>
              </Grid>
              <Grid item xs={8} className={classes.sectionValues}>
                {cityStateZip}
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.section}>
            <Grid container className={classes.sectionHeader}>
              <Grid item className={classes.sectionTitle}>
                <Aem cid="BODY_FINALREVIEW_TEXT_16">
                  Patient Contact Information
                </Aem>
              </Grid>
              <Grid item className={classes.editButtonArea}>
                <NavButton
                  label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                  size="small"
                  variant="outlined"
                  fullWidth={false}
                  className={classes.editButton}
                  trackName="edit"
                  trackLocation="body"
                  onClick={() => {
                    dispatch(gotoTaskRoute("reviewPatient/email"));
                    dispatch(setPendingTaskRoute(activeRoute));
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={12} className={classes.preferred}>
                <Aem cid="BODY_FINALREVIEW_TEXT_27">Preferred</Aem>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.sectionContent}>
              {homePhone && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_17">Home Phone</Aem>
                  </Grid>
                  {preferredPhoneType === "homePhone" ? (
                    <React.Fragment>
                      <Grid item xs={7} className={classes.sectionValues}>
                        {formatPhoneNumber(homePhone)}
                      </Grid>
                      <Grid item xs={1}>
                        <CheckIcon />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item xs={8} className={classes.sectionValues}>
                      {formatPhoneNumber(homePhone)}
                    </Grid>
                  )}
                </React.Fragment>
              )}
              {workPhone && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_28">Work Phone</Aem>
                  </Grid>
                  {preferredPhoneType === "workPhone" ? (
                    <React.Fragment>
                      <Grid item xs={7} className={classes.sectionValues}>
                        {formatWorkPhoneWithExtn(workPhone, workPhoneExtn)}
                      </Grid>
                      <Grid item xs={1}>
                        <CheckIcon />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item xs={8} className={classes.sectionValues}>
                      {formatWorkPhoneWithExtn(workPhone, workPhoneExtn)}
                    </Grid>
                  )}
                </React.Fragment>
              )}
              {mobilePhone && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_18">Mobile Phone</Aem>
                  </Grid>
                  {preferredPhoneType === "cellPhone" ? (
                    <React.Fragment>
                      <Grid item xs={7} className={classes.sectionValues}>
                        {formatPhoneNumber(mobilePhone)}
                      </Grid>
                      <Grid item xs={1}>
                        <CheckIcon />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item xs={8} className={classes.sectionValues}>
                      {formatPhoneNumber(mobilePhone)}
                    </Grid>
                  )}
                </React.Fragment>
              )}
              {email && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_19">Email</Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {email}
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>

          <Grid container className={classes.section}>
            <Grid container className={classes.sectionHeader}>
              <Grid item className={classes.sectionTitle}>
                <Aem cid="BODY_FINALREVIEW_TEXT_20">
                  Emergency Contact Information
                </Aem>
              </Grid>
              <Grid item className={classes.editButtonArea}>
                <NavButton
                  label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                  size="small"
                  variant="outlined"
                  fullWidth={false}
                  className={classes.editButton}
                  trackName="edit"
                  trackLocation="body"
                  onClick={() => {
                    dispatch(gotoTaskRoute("reviewPatient/email"));
                    dispatch(setPendingTaskRoute(activeRoute));
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.sectionContent}>
              {emergencyContactName && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_21">Name</Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {emergencyContactName}
                  </Grid>
                </React.Fragment>
              )}
              {emergencyRelationship && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_22">Relationship</Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {emergencyRelationship}
                  </Grid>
                </React.Fragment>
              )}
              {emergencyHomePhone && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_23">Home Phone</Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {formatPhoneNumber(emergencyHomePhone)}
                  </Grid>
                </React.Fragment>
              )}
              {emergencyMobilePhone && (
                <React.Fragment>
                  <Grid item xs={4} className={classes.sectionFields}>
                    <Aem cid="BODY_FINALREVIEW_TEXT_24">Mobile Phone</Aem>
                  </Grid>
                  <Grid item xs={8} className={classes.sectionValues}>
                    {formatPhoneNumber(emergencyMobilePhone)}
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
         
          {/* Below commented code is to display pami screen which 
         we are not showing now as per the userstory US3953199  */}

          {/* <Grid container direction="row" className={classes.section}>
            <Grid container className={classes.sectionHeader}>
              <Grid item xs={8} className={classes.sectionTitle}>
                Conditions
              </Grid>
              <Grid item xs={4} className={classes.editButtonArea}>
                <NavButton
                  label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                  size="small"
                  variant="outlined"
                  fullWidth={false}
                  trackName="edit"
                  trackLocation="body"
                  onClick={() => {
                    dispatch(gotoTaskRoute("reviewMedical/conditions"));
                    dispatch(setPendingTaskRoute(activeRoute));
                  }}
                />
              </Grid>
      </Grid>
      <Grid item className={classes.sectionContent}>
        <ExistingResultsList items={conditionItems} />
      </Grid>
    </Grid>

          <Grid container direction="row" className={classes.section}>
            <Grid container className={classes.sectionHeader}>
              <Grid item xs={8} className={classes.sectionTitle}>
                Medications
              </Grid>
              <Grid item xs={4} className={classes.editButtonArea}>
                <NavButton
                  label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                  size="small"
                  variant="outlined"
                  fullWidth={false}
                  trackName="edit"
                  trackLocation="body"
                  onClick={() => {
                    dispatch(gotoTaskRoute("reviewMedical/medications"));
                    dispatch(setPendingTaskRoute(activeRoute));
                  }}
                />
              </Grid>
            </Grid>
            <Grid item className={classes.sectionContent}>
              <ExistingResultsList items={medicationItems} />
            </Grid>
          </Grid>

          <Grid container direction="row" className={classes.section}>
            <Grid container className={classes.sectionHeader}>
              <Grid item xs={8} className={classes.sectionTitle}>
                Allergies
              </Grid>
              <Grid item xs={4} className={classes.editButtonArea}>
                <NavButton
                  label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                  size="small"
                  variant="outlined"
                  fullWidth={false}
                  trackName="edit"
                  trackLocation="body"
                  onClick={() => {
                    dispatch(gotoTaskRoute("reviewMedical/allergies"));
                    dispatch(setPendingTaskRoute(activeRoute));
                  }}
                />
              </Grid>
            </Grid>          
            <Grid item className={classes.sectionContent}>
              <ExistingResultsList items={allergyItems} />
            </Grid>
          </Grid>

          <Grid container direction="row" className={classes.section}>
            <Grid container className={classes.sectionHeader}>
              <Grid item xs={8} className={classes.sectionTitle}>
                Immunizations
              </Grid>
              <Grid item xs={4} className={classes.editButtonArea}>
                <NavButton
                  label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                  size="small"
                  variant="outlined"
                  fullWidth={false}
                  trackName="edit"
                  trackLocation="body"
                  onClick={() => {
                    dispatch(gotoTaskRoute("reviewMedical/immunizations"));
                    dispatch(setPendingTaskRoute(activeRoute));
                  }}
                />
              </Grid>
            </Grid>          
            <Grid item className={classes.sectionContent}>
              <ExistingResultsList items={immunizationItems} />
            </Grid>
          </Grid>
       */}
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        className={theme.navStickyFooter}
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={6}>
          <NavButton
            accentColor={routeThemeColor || "blue"}
            label={Aem.get("ACTION_CONFIRMBUTTON_TEXT_1", "Confirm")}
            fullWidth={true}
            trackName="confirm"
            trackLocation="nav footer"
            onClick={handleNextClick}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewScreen;
