

// These are the coverage/insurance status values.  
// http://hl7.org/fhir/R4/codesystem-fm-status.html
// Currently we default everything to active, but with the ability to cancel/delete a
// coverage the following values can be used.
// If nothing gets passed it, then “0” will be the default.
// If you want to delete a coverage then pass in 1 to delete it.
// This value will also subsequently be passed back to the UI in the getCoverage() API.
// from Hl7.Fhir.Model.FinancialResourceStatusCodes
export const FhirInsuranceCoverageStatusCodes = {
  Active:           0,        // Default.  The instance is currently in-force.
  Cancelled:        1,        // The instance is withdrawn, rescinded or reversed.
  Draft:            2,        // A new instance the contents of which is not complete.
  EnteredInError:   3,        // The instance was entered in error.
};


// Fhir insurance coverage self pay codes
// https://www.hl7.org/fhir/valueset-coverage-type.html
export const FhirInsuranceCoverageTypeCodes = {
  SelfPay:    "pay",    // self pay
  HIP:        "HIP",    // health insurance plan
};


// Fhir relationship list. 
// Intended to describe insurance coverage relationship between beneficiary and policy holder.
// https://hl7.org/FHIR/valueset-subscriber-relationship.html
// NOTE: order has been updated for UI display purpososes
export const FhirReleationships = [
  { code: "self",     display: "Self",                definition: "The Beneficiary is the Subscriber" },
  { code: "spouse",   display: "Spouse",              definition: "The Beneficiary is a spouse or equivalent of the Subscriber" },
  { code: "child",    display: "Child",               definition: "The Beneficiary is a child of the Subscriber" },
  { code: "parent",   display: "Parent",              definition: "The Beneficiary is a parent of the Subscriber" },
  { code: "common",   display: "Common Law Spouse",   definition: "The Beneficiary is a common law spouse or equivalent of the Subscriber" },
  { code: "injured",  display: "Injured Party",       definition: "The Beneficiary is covered under insurance of the subscriber due to an injury." },
  { code: "other",    display: "Other",               definition: "The Beneficiary has some other relationship the Subscriber" },
];

export const AppointmentVisitTypeCategories = {
  InPerson: "InPerson",
  Phone: "Phone",
  Video: "Video",
};

// Appointment Type ID indicates whether this is a returning or new patient appointment.
// This is a list of expected appointment type ids from various API and internal data logic.
export const AppointmentTypeIds = {
  NewPatient:           "8",                      // new patient needs forms filled out
  PhysicalExam:         "4",                      // AnnualReview needs forms filled out
  EstablishedPatient:   "924",                    // returning patient
};
const AppointmentTypeIdVals: string[] = Object.values(AppointmentTypeIds);
export const DefaultAppointmentTypeId: string = AppointmentTypeIds.NewPatient;

// returns true if this is a known and expected AppointmentTypeId
export const IsValidAppointmentTypeId = (val: string): boolean => {
  return !!(val && AppointmentTypeIdVals.includes(val));
};

// returns true if this is a returnning patient based on the appointmentTypeId.
export const IsReturningPatientAppointment = (appointmentTypeId: string): boolean => {
  return !!(appointmentTypeId && appointmentTypeId === AppointmentTypeIds.EstablishedPatient);
};

