import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultLanguageIsoCode, setGlobalMomentLocale } from "../../data/languageData";

export interface PrefState {
  languageIsoCode: string;        // used for display language in the application.  not the same as patient language.  NOTE: this is an ISO language code.  ex: eng, spa, ita, ...
  languageSet: boolean;           // keep track of whether the user has set the preferred language yet to avoid clobbering a previous choice by the user
  persona: string;                // "patient" | "representative"
  personaInit: boolean;
  relationship: string;
  representativeFullName: string;
  representativeConsent: boolean;
  identityVerified: boolean;      // has the user identity been verified either through kiosk search form or self-identification form.
  protectedMode: boolean;         // is the user verified and able to access protected APIs.
  isKioskMode: boolean;           // true if the user start on the kiosk screen.
  isWebAccessCodeMode: boolean;   // true if the user started on the web access code screen.
  isPublicDevice: boolean;        // true if this is likely to be a public or shared tablet device.  ex: kiosk mode on a provider location's tablet.
}

const initialState: PrefState = {
  languageIsoCode: DefaultLanguageIsoCode,
  languageSet: false,
  persona: "patient",
  personaInit: false,
  relationship: "",
  representativeFullName: "",
  representativeConsent: false,
  identityVerified: false,
  protectedMode: false,
  isKioskMode: false,
  isWebAccessCodeMode: false,
  isPublicDevice: false,
};

const prefSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    resetPrefState(state, _action: PayloadAction<boolean>) {
      // reset everything except the languageIsoCode and the mode flags
      state = { 
        ...initialState,
        languageIsoCode: state.languageIsoCode,
        languageSet: state.languageSet,
        isKioskMode: state.isKioskMode,
        isWebAccessCodeMode: state.isWebAccessCodeMode,
        isPublicDevice: state.isPublicDevice,
      };
    },
    updatePrefLanguageIsoCode(state, action: PayloadAction<string>) {
      state.languageIsoCode = action.payload || "";
      setGlobalMomentLocale(state.languageIsoCode);
    },
    updatePrefLanguageSet(state, action: PayloadAction<boolean>) {
      state.languageSet = !!action.payload;
    },
    updatePrefPersona(state, action: PayloadAction<string>) {
      state.persona = action.payload || "";
    },
    updatePrefPersonaInit(state, action: PayloadAction<boolean>) {
      state.personaInit = !!action.payload;
    },
    updatePrefRelationship(state, action: PayloadAction<string>) {
      state.relationship = action.payload || "";
    },
    updateRepresentativeFullName(state, action: PayloadAction<string>) {
      state.representativeFullName = action.payload || "";
    },
    updateRepresentativeConsent(state, action: PayloadAction<boolean>) {
      state.representativeConsent = action.payload;
    },
    updatePrefIdentityVerified(state, action: PayloadAction<boolean>) {
      state.identityVerified = action.payload || false;
    },   
    updatePrefProtectedMode(state, action: PayloadAction<boolean>) {
      state.protectedMode = action.payload || false;
    },
    updatePrefIsKioskMode(state, action: PayloadAction<boolean>) {
      state.isKioskMode = action.payload || false;
    },
    updatePrefIsWebAccessCodeMode(state, action: PayloadAction<boolean>) {
      state.isWebAccessCodeMode = action.payload || false;
    },
    updatePrefIsPublicDevice(state, action: PayloadAction<boolean>) {
      state.isPublicDevice = action.payload || false;
    },
  },
});

export const {
  resetPrefState,
  updatePrefLanguageIsoCode, 
  updatePrefLanguageSet,
  updatePrefPersona, 
  updatePrefPersonaInit,
  updatePrefRelationship,
  updateRepresentativeFullName,
  updateRepresentativeConsent,
  updatePrefIdentityVerified,
  updatePrefProtectedMode,
  updatePrefIsKioskMode,
  updatePrefIsWebAccessCodeMode,
  updatePrefIsPublicDevice,
} = prefSlice.actions;

export default prefSlice.reducer;

