

const _optionKeys = [
  'timeoutTimeMS',
  'warningTimeMS',
  'autoDisableOnTimeout',
  'verbose',
];

export class InactivityTimeoutManager {

  // 30 minute timeout. 
  // set to -1 to disable final timeout.
  public timeoutTimeMS: number = 30*60*1000;

  // 1 minute warning time. 
  // warning will be triggered at timeoutTimeMS - warningTimeMS.  
  // set to -1 to disable warning timeout.  
  public warningTimeMS: number =  1*60*1000;

  public autoDisableOnTimeout: boolean = true;
  public verbose: boolean = false;
  public logPrefix: string =  "InactivitTimer ";

  protected _enabled: boolean = true;         // when false, all future timer manipulations will be ignored
  protected _started: boolean = false;        // true when any timer is active
  
  protected _warningFlag: boolean = false;    // true when the first warning tieout period has occured
  protected _timeoutFlag: boolean = false;    // true once the final timeout has occured

  protected _warningTimerId: any = null;      // first timer, when triggered warningFlag will be true
  protected _finalTimerId: any = null;        // final timer, once triggered the timeoutFlag will be true

  protected _warningHandler: Function|null = null;
  protected _timeoutHandler: Function|null = null;


  constructor(opts: any = undefined) {
    if (opts) {
      _optionKeys.forEach((k) => {
        if (opts[k] !== undefined) {
          this[k] = opts[k];
        }
      })
    }
  }


  public get enabled(): boolean {
    return this._enabled;
  }
  public setEnabled(value: boolean): void { 
    this._enabled = !!value;
  }

  public get started(): boolean {
    return this._started;
  }
  public get stopped(): boolean {
    return !this._started;
  }
  public get warningFlag(): boolean {
    return this._warningFlag;
  }
  public get timeoutFlag(): boolean {
    return this._timeoutFlag;
  }

  public setWarningHandler(handler: Function): void {
    this._warningHandler = handler;
  }
  public setTimeoutHandler(handler: Function): void {
    this._timeoutHandler = handler;
  }

  protected dlog(...args): void { 
    if (this.verbose) {
      console.log(this.logPrefix, ...args);
    }
  }

  public start(): void { 
    if (!this._enabled) { return; }

    // if already started, then clear the 
    // timer timeouts and flags before restarting
    let wasStarted = this._started;
    if (wasStarted) {
      this._clearTimers();
      this._clearFlags();
    }
    this._startTimers();
    this._started = true;
    this.dlog(wasStarted? "restarted": "started");
  }

  // reset will reset the warning and final timer flags.
  // if aready started, then the timers will be reset and re-started again.
  // if stopped, then the timers will not be re-started.
  public reset(): void { 
    if (!this._enabled) { return; }

    // if already started, then reset the timer timeouts and restart
    let wasStarted = this._started;
    this._clearTimers();
    this._clearFlags();
    if (wasStarted) {
      this._startTimers();
      this._started = true;
    }
    this.dlog("reset - " + (wasStarted? "restarted": "stopped"));
  }

  public stop(): void { 
    if (!this._enabled) { return; }

    // if we are just stopping, 
    // we do not want to clear the flags
    this._clearTimers();
    this._started = false;
    this.dlog("stopped");
  }

  protected _startTimers(): void {
    // clear any existing timerIds which may be running.
    this._clearTimers();

    let finalTimeMS = this.timeoutTimeMS
    let warningTimeMS = this.warningTimeMS;
    let warningTimeoutMS: number = (finalTimeMS > 0 && warningTimeMS > 0)? (finalTimeMS - warningTimeMS): -1;
    if (warningTimeoutMS > 0) {
      this._warningTimerId = setTimeout(this._handleWarningTimeout.bind(this), warningTimeoutMS);
    }
    if (finalTimeMS > 0) {
      this._finalTimerId = setTimeout(this._handleFinalTimeout.bind(this), finalTimeMS);
    }
  }

  protected _clearTimers(): void {
    if (this._warningTimerId) {
      clearTimeout(this._warningTimerId);
      this._warningTimerId = null;
    }
    if (this._finalTimerId) {
      clearTimeout(this._finalTimerId);
      this._finalTimerId = null;
    }
  }

  protected _clearFlags(): void {
    this._warningFlag = false;
    this._timeoutFlag = false;
  }

  protected _handleWarningTimeout(): void {
    this._warningTimerId = null;
    if (!this._enabled) { return; }
    this.dlog("handleWarningTimeout");

    // enable the warning flag. we do not need to update the _timeoutFlag.
    this._warningFlag = true;
    
    if (this._warningHandler) {
      try {
        this._warningHandler(this);
      } catch (ex) {}
    }
  }

  protected _handleFinalTimeout(): void {
    this._finalTimerId = null;
    // make sure any potential warning timers are killed.  
    this._clearTimers();

    if (!this._enabled) { return; }
    this.dlog("handleFinalTimeout");

    // warning period is over now
    this._warningFlag = false;

    // enable the timeout flag
    this._timeoutFlag = true;

    // not longer started
    this._started = false;
    
    if (this.autoDisableOnTimeout) {
      // we do not want to clear the flags in this case.
      this._enabled = false;
      this.dlog("auto disabled");
    }

    if (this._timeoutHandler) {
      try {
        this._timeoutHandler(this);
      } catch (ex) {}
    }
  }

}

export default InactivityTimeoutManager;
