import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { Grid, Dialog, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BackButton, NavButton } from "../../../Components/Buttons";
import CustomTextField from "../../../Components/CustomTextField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateAddress, Address } from "../../../features/patient/patientSlice";
import {
  appColors,
  appFonts,
  useAppTheme,
  appDialogTransparentPaperProps,
} from "../../../theme";
import ProgressStepper from "../../../Components/ProgressStepper";
import Header from "../../../Components/Header";
import {
  gotoNextTaskStep,
  gotoPendingTaskStep,  
  gotoPrevTaskStep,
  setTaskTransitionPending,
} from "../../../features/task/taskSlice";
import { filterAddressInput, filterCityInput, filterStateInput, filterZipInput } from "../../../utility/inputFilterFunctions";
import { UpdatePatientDemographicsAPI } from "../../../api/UpdateFhirAPI";
import { GetUSPSValidatedAddress } from "../../../api/GetAddressAPI";
import { TransitionProps } from "@mui/material/transitions";
import EditAddressPanel from "./address/EditAddressPanel";
import Aem, { AemContentTypes } from "../../../lib/aem/components/Aem";
import { AddressObj, compareAddressObjects, CreateEmptyAddressObj, isAddressEmpty } from "../../../lib/addressUtils";
import { titleCase } from "../../../utility/utilityFunctions";


const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  headerTitle: {
    marginTop: "0px",
  },
  heading: {
    fontFamily: appFonts.bold,
    fontSize: "22px",
    lineHeight: "40px",
  },
  title: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    color: appColors.white,
    marginTop: "24px",
    marginBottom: "20px"
  },
  inpBox: {
    marginBottom: "12px",
    fontFamily: appFonts.medium,
    height: "60px",
  },
  formStyle: {
    position: "relative",
    marginBottom: "30px",
  },
  pgFunc: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReviewAddressScreen: React.FC = () => {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();

  const addr1InputId = useId();
  const addr2InputId = useId();
  const cityInputId = useId();
  const stateInputId = useId();
  const zipInputId = useId();
  const dialogTitleId = useId();

  const patientAddr: Address = useAppSelector((state) => state.patient?.address);
  const [address1, setAddr1] = useState<string>(patientAddr?.address1 || "");
  const [address2, setAddr2] = useState<string>(patientAddr?.address2 || "");
  const [city, setCity] = useState<string>(patientAddr?.city || "");
  const [state, setState] = useState<string>(patientAddr?.state || "");
  const [zip, setZip] = useState<string>(patientAddr?.zipcode || "");
  const [suggAddressObj, setSuggAddressObj] = useState<AddressObj>(CreateEmptyAddressObj());
  const [addressConfirmed, setAddressConfirmed] = useState<boolean>(false);
  const [addressDialogOpen, setAddressDialogOpen] = useState<boolean>(false);

  // start disabled until valid address has been loaded or entered
  const [disabled, setDisabled] = useState<boolean>(true);

  
  const loadUSPSSuggestedAddress =  async (address1, address2, city, state, zip): Promise<AddressObj> => {
    let res = await GetUSPSValidatedAddress(address1, address2, city, state, zip);
    let addrObj: AddressObj = CreateEmptyAddressObj();
    if (res) {
      addrObj.address1 = titleCase(res.address1 || "");
      addrObj.address2 = titleCase(res.address2 || "");
      addrObj.city = titleCase(res.city || "");
      addrObj.state = res.state || "";
      addrObj.zipcode = res.zipcode || "";
    }
    setSuggAddressObj(addrObj);
    return addrObj;
  };
  
  const isAddressValid = useCallback((): boolean => {
    // NOTE: this should be updated to use form validations
    // for now, it's just a simple check to see if the field values exist
    return !!(address1 && city && state && zip);
  }, [address1, city, state, zip]);
  
  const invalidateAddress = useCallback(() => {
    let newDisable: boolean = !isAddressValid();
    if (disabled !== newDisable) { 
      setDisabled(newDisable);
    }
  }, [disabled, isAddressValid]);

  const handleNextClick = async () => {
    let suggAddrObj: AddressObj = await loadUSPSSuggestedAddress(address1, address2, city, state, zip);

    if (needsSuggestedAddressPrompt(suggAddrObj)) {
      setAddressDialogOpen(true);
    } else {
      await moveToNextStep();
    }
  };
  
  const getFormAddressObj = (): AddressObj => {
    return {
      address1,
      address2,
      city,
      state,
      zipcode: zip,
    } as AddressObj;
  };

  const setFormAddressObj = (addrObj: AddressObj) => {
    if (addrObj) { 
      setAddr1(addrObj.address1 || "");
      setAddr2(addrObj.address2 || "");
      setCity(addrObj.city || "");
      setState(addrObj.state || "");
      setZip(addrObj.zipcode || "");
    }
  };

  const getSuggestedAddressObj = (): AddressObj => {
    return suggAddressObj;
  };

  const needsSuggestedAddressPrompt = (addrObj2: AddressObj): boolean => {
    let retval: boolean = false;
    if (!addressConfirmed) {
      // check if a suggested address exists and it is different than the entered address
      let addrObj1 = getFormAddressObj();

      // check if suggested address is empty 
      const isAddr2Empty = isAddressEmpty(addrObj2);

      // return true if addr2 is empty or does not match addr1
      if (isAddr2Empty || (addrObj1 && addrObj2 && compareAddressObjects(addrObj1, addrObj2, true) !== 0)) {
        retval = true;
      }
    }
    return retval;
  };

  const pendingNextRoute: string = useAppSelector(
    (state) => state.task.pendingNextRoute
  );
  const editMode: boolean = !!pendingNextRoute;
  const navButtonName: string = editMode ? "save" : "next";
  const navButtonLabel: string = editMode
    ? Aem.get("ACTION_SAVEBUTTON_TEXT_1", "Save")
    : Aem.get("ACTION_NEXTBUTTON_TEXT_1", "Next");

  const moveToNextStep = async () => {
    dispatch(setTaskTransitionPending(true));
    await updateRedux();
    await updateDetails();
    await dispatch(gotoNextTaskStep());
  };
    
  const updateRedux = async () => {
    let newAddress: any = {
      address1: address1 || "",
      address2: address2 || "",
      city: city || "",
      state: state || "",
      zipcode: zip || "",
    };
    await dispatch(updateAddress(newAddress));
  };

  const patientId: string = useAppSelector((state) => state.patient.patientId);

  const updateDetails = async () => {
    // update API state
    let data: any = {
      patientId,
    };
    data.address = [
      {
        use: "Home",
        line: [titleCase(address1), titleCase(address2)],
        city: titleCase(city),
        state: state,
        postalCode: zip,
      },
    ];
    await UpdatePatientDemographicsAPI(data);
  };

  useEffect(() => {
    invalidateAddress();
  }, [invalidateAddress]);
  
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        direction="column"
        className={clsx(theme.navContent, theme.nowrap)}
      >
        <Grid
          container
          direction="column"
          className={clsx(theme.navHeader, theme.bgGreenGrad)}
        >
          <Header showMenuButton={true} />

          {!editMode && <ProgressStepper step={1} totalSteps={4} />}

         <Grid container className={classes.pgFunc}>
          <Grid item>  
          <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
              <Aem cid="HEADER_DEMOGRAPHICS_PATIENT_ADDRESS_TEXT_1">
                Patient Address
              </Aem>
            </h1>
          </Grid>
          <Grid item>
            {/* <DemoSVG/> */}
            <Aem cid="HEADER_DEMOGRAPHICS_PATIENT_ADDRESS_LOGO_1" type={AemContentTypes.imageUrl}></Aem>
          </Grid>
          </Grid>

        </Grid>

        <Grid container direction="column" className={theme.navSection}>
        <Grid item className={classes.title}>
            <Aem cid="BODY_DEMOGRAPHICS_PATIENT_ADDRESS_TEXT_1">
              Verify that your current address matches the address shown below.
              If it is incorrect, please update it.
            </Aem>
          </Grid>
          <form className={classes.formStyle}>
            <CustomTextField
              id={addr1InputId}
              required={true}
              className={classes.inpBox}
              label={Aem.get(
                "BODY_DEMOGRAPHICS_PATIENT_ADDRESS_TEXT_2",
                "Street Address"
              )}
              name="address1"
              fullWidth={true}
              // placeholder="Address 1"
              variant="filled"
              value={address1}
              onChange={(e) => {
                setAddr1(filterAddressInput(e.target.value));
                setAddressConfirmed(false);
              }}
            />
            <CustomTextField
              id={addr2InputId}
              className={classes.inpBox}
              label={Aem.get(
                "BODY_DEMOGRAPHICS_PATIENT_ADDRESS_TEXT_3",
                "Apartment/Suite"
              )}
              name="address2"
              fullWidth={true}
              // placeholder="Address 2"
              variant="filled"
              value={address2}
              onChange={(e) => {
                setAddr2(filterAddressInput(e.target.value));
                setAddressConfirmed(false);
              }}
            />
            <CustomTextField
              id={cityInputId}
              className={classes.inpBox}
              required={true}
              label={Aem.get(
                "BODY_DEMOGRAPHICS_PATIENT_ADDRESS_TEXT_4",
                "City"
              )}
              name="city"
              fullWidth={true}
              // placeholder="City"
              variant="filled"
              value={city}
              onChange={(e) => {
                setCity(filterCityInput(e.target.value));
                setAddressConfirmed(false);
              }}
            />
            <CustomTextField
              id={stateInputId}
              required={true}
              className={classes.inpBox}
              label={Aem.get(
                "BODY_DEMOGRAPHICS_PATIENT_ADDRESS_TEXT_5",
                "State"
              )}
              name="state"
              fullWidth={true}
              // placeholder="State"
              variant="filled"
              value={state}
              onChange={(e) => {
                setState(filterStateInput(e.target.value));
                setAddressConfirmed(false);
              }}
            />
            <CustomTextField
              id={zipInputId}
              required={true}
              className={classes.inpBox} 
              label={Aem.get(
                "BODY_DEMOGRAPHICS_PATIENT_ADDRESS_TEXT_6",
                "Zip Code"
              )}
              name="zip"
              fullWidth={true}
              inputProps={{
                maxLength: 10,
              }}
              // placeholder="Zip Code"
              variant="filled"
              value={zip}
              onChange={(e) => {
                setZip(filterZipInput(e.target.value));
                setAddressConfirmed(false);
              }}
            />
          </form>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        className={theme.navStickyFooter}
        style={{
          alignItems: "center",
        }}
      >
        <Grid item xs={3}>
          <BackButton
            trackName="prev"
            trackLocation="nav footer"
            onClick={async () => {
              if (editMode) {
                await dispatch(gotoPendingTaskStep("consent/review"));
              } else {
                await dispatch(gotoPrevTaskStep());
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <NavButton
            accentColor={"green"}
            label={navButtonLabel}
            fullWidth={true}
            disabled={disabled}
            trackName={navButtonName}
            trackLocation="nav footer"
            onClick={async () => {
              await handleNextClick();
            }}
          />
        </Grid>
      </Grid>

      <Dialog
        fullScreen={true}
        disableRestoreFocus={true}
        open={addressDialogOpen}
        onClose={() => { 
          setAddressDialogOpen(false);
        }}
        TransitionComponent={Transition}
        aria-labelledby={dialogTitleId}
        PaperProps={appDialogTransparentPaperProps}
      >
        <EditAddressPanel
          ariaTitleId={dialogTitleId}
          address1={getFormAddressObj()}
          address2={getSuggestedAddressObj()}
          clickAway={true}
          onCancel={() => {
            setAddressDialogOpen(false);
          }}
          onChange={async (addrObj) => {
            // update form address object
            setFormAddressObj(addrObj);
            setAddressConfirmed(true);
            setAddressDialogOpen(false);
          }}
        />
      </Dialog>

    </Grid>
  );
};

export default ReviewAddressScreen;
