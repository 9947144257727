import { bulkSignConsentFormsAPI, getReviewExistingConsentFormsAPIUrl } from "../../api/SignConsentFormsAPI";
import { GetDocAppointmentTypeFromAppointmentType } from "../../data/CDO";
import { collectPdfCustomData, collectPdfSaveData, getPrefLanguageIsoCode, SignatureData } from "./collectPdfCustomData";
import config from "../../config";


// Note: we can remove this debug flag and logic once this feature has been integrated
// but for now, this will provide a way to  to validate the PDF results
const DEBUG_PDF_PREVIW: boolean = true && (config.deployEnv !== "production");

export const signAndSavePdfForms = async (
  templateKeys: string[],
  cdoFormTypes: string[],
  signatureData: SignatureData|undefined = undefined
): Promise<boolean> => {
  let saveData: any = collectPdfSaveData();
  let runtimeData: any = collectPdfCustomData(signatureData);

  let patientId: string = saveData.patientId;
  let appointmentId: string = saveData.appointmentId;
  let appointmentTypeId: string = saveData.appointmentTypeId
  let docAppointmentTypeId: string = GetDocAppointmentTypeFromAppointmentType(appointmentTypeId, appointmentTypeId);
  let languageIsoCode: string = getPrefLanguageIsoCode();

  let templateKeysStr = (templateKeys || []).join(",");
  if (!templateKeysStr) { return false; }

  let cdoFormTypesStr = (cdoFormTypes || []).join(",");

  if (DEBUG_PDF_PREVIW) { 
    console.log("pdf sign and save started...");
  }

  let res = await bulkSignConsentFormsAPI(
    patientId,
    appointmentId,
    docAppointmentTypeId,
    languageIsoCode,
    templateKeysStr,
    cdoFormTypesStr,
    runtimeData
  ).catch(() => false);

  if (DEBUG_PDF_PREVIW) { 
    if (res?.success && res?.bundleId) {
      let url: string = getReviewExistingConsentFormsAPIUrl(res.patientId, appointmentId, res.bundleId, true);
      console.log("pdf sign and save review results");
      console.log("  " + url);
    } else {
      console.warn("pdf sign and save forms API call failed");
    }
  }

  return !!(res?.success);
};
