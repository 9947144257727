import React from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles"; 
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { appFonts, useAppTheme } from "../theme"; 
import Header from "../Components/Header";
import { ReactComponent as CalendarBlankLgSvg } from "../assets/calendar-cancel.svg";
import KioskStartOverPrompt from "../Components/Kiosk/KioskStartOverPrompt";
import { useAppSelector } from "../app/hooks";
import { SingleApptState } from "../features/appt/apptSlice";
import { Aem } from "../lib/aem/components/Aem";


const imageVerticalShift = 64;

const useStyles = makeStyles({
  root: {
    position: "relative",
    color: "#ffffff",
    width: "100%",
    height: "100%",
    fontFamily: appFonts.regular,
  },
  graphicArea: {
    marginBottom: `-${imageVerticalShift}px`,
  },
  graphic: {
    width: "100%",
    height: "100%",
    maxWidth: 487,
    maxHeight: 274,
    textAlign: "center",
  },
  content: {
    marginTop: "75px",
    padding: "0 16px 0 16px",
  },
  contentWidth: {
    maxWidth: "320px",
  },
  label1: {
    marginTop: "15px",
    fontFamily: appFonts.semibold,
    fontSize: "22px",
    lineHeight: "28px",
  },
  label2: {
    marginTop: "15px",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    lineHeight: "24px",
  },
  kioskHeader: {
    minHeight: "0px",
    paddingTop: "16px",
    paddingBottom: "0px",
  },
});

const MultiAppointmentsCancelScreen: React.FC = () => {
  const theme = useAppTheme();
  const classes = useStyles(); 
  
  const appointmentItems: SingleApptState[] = useAppSelector<SingleApptState[]>((state) => state.appt.allAppointmentInfos);
  const locationPhone: string = appointmentItems && appointmentItems.length > 0? appointmentItems[0].deptPhone:"";

  // only show the start over button when in kiosk or publicDevice mode
  const isKioskOrPublicDeviceMode: boolean = useAppSelector<boolean>((state) => (state.preferences.isKioskMode || state.preferences.isPublicDevice));

  const aemAllApptsCancelledSubTitle: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_35", 
  "If you have questions or want to schedule another appointment, please contact the clinic at {{LocationPhone}}.")
  .replace("{{LocationPhone}}", locationPhone) ;   
  
  return (
    <Grid container direction="column" className={clsx(classes.root, theme.navContent)}>
      <Grid container direction="column" className={clsx(theme.navHeader, theme.bgOrangeGrad)}>
        <Header center={false} showMenuButton={false} showSelectLanguage={false}
           />
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={theme.textCenter}
        >
          <Grid item className={classes.graphicArea}>
            <CalendarBlankLgSvg className={classes.graphic} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        className={clsx(theme.textCenter, classes.content)}
      >
        <Grid item className={classes.label1}>         
            <Aem cid="BODY_MULTI_APPOINTMENTS_TEXT_34">
            All appointments have been cancelled.
            </Aem>
        </Grid>
        <Grid item className={classes.label2}>
          <div className={classes.contentWidth}>
            {aemAllApptsCancelledSubTitle}
          </div>
        </Grid>

        {isKioskOrPublicDeviceMode && (
          <KioskStartOverPrompt
            showMesssageText={true}
            showYesStartOverButton={true}
            showNoContinueButton={false}
            showIAmHereButton={false}
            checkArrivalStatus={false}
            autoHideAfterActions={false}
            enableSessionTimeoutCheck={true}
            defaultNoContinueRoute={""}
            defaultIAmHereRoute={""}
            arrivedNextRoute={"kioskArrivedFinish"}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default MultiAppointmentsCancelScreen;
