export interface verificationRequestDataSchema {
  payerCode: string;
  payerName: string;
  subscriber: Subscriber;
  doS_StartDate: string;
  doS_EndDate: string;  
};
export interface Subscriber {
  firstName: string;
  dob: string;
  lastName: string;
  memberID: string;
};

export const InitializeVerificationRequestData = (
  payerCode: string,
  payerName: string,
  subscriber: Subscriber,  
  dos_StartDate: string,
  dos_EndDate: string,
): verificationRequestDataSchema => {

  return {
    payerCode: payerCode,
    payerName: payerName,
    subscriber: subscriber,
    doS_StartDate: dos_StartDate,
    doS_EndDate: dos_EndDate,
  };
};

export const InitialzeSubscriberData = (
  firstName: string,
  dob: string,
  lastName: string,
  memberID: string
): Subscriber => {
  return {
    firstName: firstName,
    dob: dob,
    lastName: lastName,
    memberID: memberID
  };
};
