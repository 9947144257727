import React, { useState } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { ClickAwayListener, Grid, Radio, RadioGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { appColors, appFonts, useAppTheme } from "../../../../theme";
import { NavButton } from "../../../../Components/Buttons";
import { stopEvent } from "../../../../utility/utilityFunctions";
import { formatAddressLines, isAddressEmpty } from "../../../../lib/addressUtils";
import Aem from "../../../../lib/aem/components/Aem";


const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    paddingLeft: "12px",
    paddingRight: "12px",
    color: appColors.white,
  },
  panel: {
    width: "100%",
    maxWidth: "356px",
    minHeight: "250px",
    padding: "24px",
    backgroundColor: appColors.darkGray1,
  },
  contentBox: {},
  editButtonArea: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  editButton: {
    "&.MuiButton-outlined": {
      "&.MuiButton-root": {
        fontSize: 16,
      },
    },
  },
  actionButtonArea: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "36px",
    marginBottom: "36px",
  },
  actionButton: {
    "&.MuiButton-outlined": {
      "&.MuiButton-root": {
        fontSize: 20,
      },
    },
  },
  radioButton: {
    "& .MuiSvgIcon-root": {
      fontSize: "28px",
    },
    color: "white",
  },
  radioChecked: {
    "&.Mui-checked": {
      color: appColors.selection,
    },
  },
  title: {
    marginBottom: "20px",
    fontFamily: appFonts.medium,
    fontSize: "22px",
    textAlign: "center",
  },
  description: {
    position: "relative",
    marginBottom: "20px",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    textAlign: "center",
  },
  section: {
    marginTop: "1px",
    marginBottom: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "nowrap",
  },
  sectionHeader: {
    width: "100%",
    fontFamily: appFonts.bold,
    fontSize: "18px",
    justifyContent: "space-between",
    marginBottom: "4px",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  sectionTitle: {
    fontFamily: appFonts.bold,
    fontSize: "18px",
  },
  sectionContent: {
    fontSize: "18px",
    fontFamily: appFonts.medium,
  },
  addressItem: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    borderRadius: "8px",
    padding: "13px 16px",

    // not selected styles
    backgroundColor: appColors.black,
    color: appColors.white,

    "&.selected": {
      backgroundColor: appColors.white,

      "& $addressLine": {
        color: appColors.black,
      },
    },

    "&:hover": {
      backgroundColor: appColors.whiteA07,
    },
  },
  addressRadioArea: {},
  addressDisplay: {
    // color: "gold",
  },
  addressLine: {},
}));

interface EditAddresspanelProps {
  address1?: any;
  address2?: any;
  clickAway?: boolean;
  ariaTitleId?: string;
  onChange?: (val: any) => void;
  onCancel?: () => void;
}

const EditAddressPanel: React.FC<EditAddresspanelProps> = (
  props: EditAddresspanelProps
) => {
  const classes = useStyles();
  const theme = useAppTheme();

  const rbGroupId = useId();
  const rbLabelId = useId();
  const rbInputIdPrefix = useId();
  
  const { address1, address2, clickAway, ariaTitleId, onChange, onCancel } = props;
  const address2Empty = isAddressEmpty(address2);
  const [selectedVal, setSelectedVal] = useState(address2Empty ? "address1" : "address2");

  const handleClickAway = () => {
    if (clickAway && onCancel) {
      onCancel();
    }
  };

  const renderAddressInfo = (radioVal: string, address: any) => {
    const rbInputId = `${rbInputIdPrefix}-rb-${radioVal}`;
    let selected: boolean = radioVal === selectedVal;
    let lines: string[] = formatAddressLines(address);
    if (!lines || lines.length === 0) {
      lines = ["Missing Address"];
    }

    return (
      <Grid
        container
        direction="row"
        className={clsx(theme.clickable, classes.addressItem, { selected })}
        onClick={(e) => {
          stopEvent(e);
          setSelectedVal(radioVal);
        }}
      >
        <Grid item className={classes.addressRadioArea}>
          <Radio
            id={rbInputId}
            value={radioVal}
            checked={selected}
            onChange={() => {
              setSelectedVal(radioVal);
            }}
            classes={{
              root: classes.radioButton,
              checked: classes.radioChecked,
            }}
          />
        </Grid>
        <Grid item>
          <label htmlFor={rbInputId}>
            <Grid container direction="column" className={classes.addressDisplay}>
              {lines.map((line, idx) => (
                <Grid key={idx} item className={classes.addressLine}>
                  {line}
                </Grid>
              ))}
            </Grid>
          </label>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid
          container
          direction="column"
          justifyContent="start"
          alignItems="center"
          className={classes.panel}
        >
          <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="center"
            className={classes.contentBox}
          >
            <RadioGroup
              id={rbGroupId}
              aria-labelledby={ariaTitleId || rbLabelId}
              name="sex"
              value={selectedVal}
            >
              <Grid item id={ariaTitleId || rbLabelId} className={classes.title}>
                <Aem cid="BODY_DEMOGRAPHICS_PATIENT_VERIFY_ADDRESS_OVERLAY_TITLE">
                  Verify your address
                </Aem>
              </Grid>

              <Grid item className={classes.description}>
                {address2Empty ? (
                  <Aem cid="BODY_DEMOGRAPHICS_PATIENT_VERIFY_ADDRESS_OVERLAY_SUBTITLE2">
                    We did not find a match for your address
                  </Aem>
                ): (
                  <Aem cid="BODY_DEMOGRAPHICS_PATIENT_VERIFY_ADDRESS_OVERLAY_SUBTITLE">
                    Address match using US Postal Service
                  </Aem>
                )}
              </Grid>

              <Grid container className={classes.section}>
                <Grid container className={classes.sectionHeader}>
                  <Grid item className={classes.sectionTitle}>
                    <Aem cid="BODY_DEMOGRAPHICS_PATIENT_VERIFY_ADDRESS_OVERLAY_LABEL1">
                      You Entered
                    </Aem>
                  </Grid>
                  {onCancel && (
                    <Grid item className={classes.editButtonArea}>
                      <NavButton
                        label={Aem.get("ACTION_EDITBUTTON_TEXT_1", "Edit")}
                        size="small"
                        variant="outlined"
                        fullWidth={false}
                        className={classes.editButton}
                        trackName="edit"
                        trackLocation="overlay"
                        onClick={(e) => {
                          stopEvent(e);
                          onCancel();
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.sectionContent}
                >
                  {renderAddressInfo("address1", address1)}
                </Grid>
              </Grid>

              {!address2Empty && (
                <Grid container className={classes.section}>
                  <Grid container className={classes.sectionHeader}>
                    <Grid item className={classes.sectionTitle}>
                      <Aem cid="BODY_DEMOGRAPHICS_PATIENT_VERIFY_ADDRESS_OVERLAY_LABEL2">
                        We Suggest
                      </Aem>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    className={classes.sectionContent}
                  >
                    {renderAddressInfo("address2", address2)}
                  </Grid>
                </Grid>
              )}
            </RadioGroup>

            <Grid container className={classes.actionButtonArea}>
              <NavButton
                label={Aem.get("ACTION_SELECT_ADDRESS_BUTTON_TEXT_1", "Use Selected Address")}
                size="large"
                variant="outlined"
                fullWidth={true}
                className={classes.actionButton}
                trackName="use selected address"
                trackLocation="overlay"
                onClick={(e) => {
                  stopEvent(e);
                  if (onChange) {
                    let selectedAddress: any = selectedVal === "address2" ? address2 : address1;
                    onChange(selectedAddress);
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </Grid>
  );
};

EditAddressPanel.defaultProps = {
  clickAway: true,
  ariaTitleId: undefined,
};

export default EditAddressPanel;
