import React, { useEffect, useState } from "react";
import useId from "@mui/material/utils/useId";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import { AriaLive } from "../../../data/accessibility/AccessibilityData";
import { GetAddressOptions } from "../../../api/ValidationAPI";
import { useAppSelector } from "../../../app/hooks";
import { appFonts } from "../../../theme";
import { tracker } from "../../../appTracker";
import { titleCase } from "../../../utility/utilityFunctions";
import Aem from "../../../lib/aem/components/Aem";


const useStylesQuestionVerificationForm = makeStyles((theme) => ({
  questionVerificationRadioGroup: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    marginTop: "30px",
  },
  radioButtonPadding: {
    padding: "12px",
    color: "#ffffff",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    "&$checked": {
      color: "#ffffff",
    },
  },
  checked: {},
  formHelperText: {
    marginLeft: 0,
    marginRight: 0,
    fontSize: "1rem",
    fontFamily: appFonts.medium,
    lineHeight: "26px",
  },
}));

interface QuestionVerificationFormProps {
  questionVerificationAnswer: string;
  setQuestionVerificationAnswer: React.Dispatch<React.SetStateAction<string>>;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  error: boolean;
}

const QuestionVerificationForm: React.FC<QuestionVerificationFormProps> = ({
  questionVerificationAnswer,
  setQuestionVerificationAnswer,
  setDisableButton,
  error,
}) => {
  const classes = useStylesQuestionVerificationForm();

  const rbGroupInputId = useId();
  const rbInputIdPrefix = useId();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionVerificationAnswer((event.target as HTMLInputElement).value);
  };

  const [addressOptions, setAddressOptions] = useState<string[]>([]);

  const patientId = useAppSelector((state) => state.patient.patientId);

  const verifyAddressText: string = Aem.get(
    "BODY_ADDRESS_VERIFY_TEXT_1",
    "Select the street address that matches the one you have on file with"
  );

  useEffect(() => {
    let mounted: boolean = true;

    //will make the call to get the address options
    GetAddressOptions(patientId).then((options) => {
      if (!mounted) {
        return;
      }

      options = options || [];

      // TODO: debug only.  remove after fixing address verification APIs
      let hasBadAddress = options.find((v: any) => !v);
      if (hasBadAddress) {
        console.warn(
          "GetAddressOptions API returned an invalid address: ",
          options
        );
      }

      options = options.map((v: any) => v || "[MISSING_ADDRESS]"); // TODO: the API is sometimes not returning the correct address in the list ??
      options = options.filter((v: any) => !!v);
      setAddressOptions(options);
    });

    return () => {
      mounted = false;
    };
  }, [patientId]);

  useEffect(() => {
    if (questionVerificationAnswer === "") {
      setDisableButton(true);
      return;
    }
    setDisableButton(false);
  }, [questionVerificationAnswer, setDisableButton]);

  // watch for form errors and send them to the analytics tracker
  useEffect(() => {
    tracker.watchFormErrors([
      { field: "address", alert: "address invalid", value: error },
    ]);
  }, [error]);

  return (
    <FormControl component="fieldset" error={error}>
      <span
        style={{
          color: "#ffffff",
          fontSize: "18px",
          lineHeight: "22px",
          fontFamily: appFonts.regular,
          marginTop: "24px",
        }}
      >
        {verifyAddressText}
      </span>
      <RadioGroup
        id={rbGroupInputId}
        className={classes.questionVerificationRadioGroup}
        aria-label={verifyAddressText}
        name="address"
        value={questionVerificationAnswer}
        onChange={handleChange}
      >
        {addressOptions?.map((securityAnswer, idx) => {
          const rbInputId = `${rbInputIdPrefix}-rb-${idx}`;
          const rbLabelId = `${rbInputIdPrefix}-lbl-${idx}`;          
          return (
            <FormControlLabel
              key={idx}
              value={securityAnswer}
              control={
                <Radio
                  id={rbInputId}
                  color="default"
                  classes={{
                    root: classes.radioButtonPadding,
                    checked: classes.checked,
                  }}
                  inputProps={{
                    "aria-labelledby": rbLabelId
                  }}
                />
              }
              label={
                <span
                  id={rbLabelId}
                  style={{
                    fontSize: "18px",
                    fontFamily: appFonts.medium,
                    color: "#ffffff",
                  }}
                >
                  {titleCase(securityAnswer)}
                </span>
              }
            />
          );
        })}
      </RadioGroup>
      {error && (
        <FormHelperText className={classes.formHelperText} aria-live={AriaLive.polite}>
          <Aem cid="BODY_ADDRESS_VERIFY_ERROR_1">
            Incorrect address. Please try again.
          </Aem>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default QuestionVerificationForm;
