import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import orderBy from "lodash/orderBy";
import set from "lodash/set";
import moment from "moment";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateNewDocCounts, DocCounts } from "../../data/docData";
import { AemCategoryVisitTypes } from "../../lib/aem/AemDefs";
import { AddressObj, CreateEmptyAddressObj } from "../../lib/addressUtils";
import { parseToMoment } from "../../utility/utilityFunctions";


export interface SingleApptState {
  apptId: string;
  patientId: string;
  location: string;
  mode: string;
  deptPhone: string;
  deptAddress: string;
  deptAddressObj: AddressObj,
  deptWebsite: string;
  apptDateTime: Date | undefined;     //  Note: this should be a javascript date object
  visitType: string;
  cdoVisitType: string;
  categoryVisitType: string;
  aemCategoryVisitType: AemCategoryVisitTypes | undefined,
  appointmentTypeId: string;
  providerName: string;
  patientBalance: number | undefined;
  consentToText: boolean;
  docCounts: DocCounts;
  preRegDone: boolean;                // true if the pre-registration is already completed
  preRegRequired: boolean;            // true if another pre-registration is still required
  isConfirmed: boolean;               // isPending is inferred when isConfirmed and isCancelled are both false
  isCancelled: boolean;
  mobilePhoneNumber: string;
  homePhoneNumber: string;
  smsSentTo: string;
  patientFirstName: string;           // patient first name
  patientLastName: string;            // patient 
}

// app appointment state
export interface ApptState {
  linkValid: boolean;                 // true when the original link is valid
  linkVerified: boolean;              // true when the verification has been completed (or skipped)
  linkIgnored: boolean;               // true hwen link verification should be skipped or ignored.
  isReturningPatient: boolean;
  patientHasAllForms: boolean;
  needsAdvancedDirectiveForm: boolean;
  dobAttempts: number;
  verified: boolean;
  visitType: string;                  
  cdoVisitType: string;               
  categoryVisitType: string;          
  allAppointmentInfos: SingleApptState[];
  eligibilityAttempts: number;
  eligibilityVerified: boolean;
}

export const initialSingleApptState: SingleApptState = {
  apptId: "",
  patientId: "",
  mode : "",
  location: "",
  deptAddress: "",
  deptAddressObj: CreateEmptyAddressObj(),
  deptPhone: "",
  deptWebsite: "",
  apptDateTime: undefined,
  visitType: "",
  cdoVisitType: "",
  aemCategoryVisitType: undefined,
  categoryVisitType: "",
  appointmentTypeId: "",
  providerName: "",
  patientBalance: undefined,
  consentToText: false,
  docCounts: CreateNewDocCounts(),  
  preRegDone: false,
  preRegRequired: false,
  isConfirmed: false,
  isCancelled: false,
  mobilePhoneNumber: "",
  homePhoneNumber: "",
  smsSentTo: "",
  patientFirstName: "",
  patientLastName: "",
};

export const initialApptState: ApptState = {
  linkValid: true,                    // to avoid flickering errors, start as true, then update during appInit
  linkVerified: true,                 // to avoid flickering errors, start as true, then update during appInit or verification
  linkIgnored: false,
  isReturningPatient: false,          
  patientHasAllForms: false,
  needsAdvancedDirectiveForm: false,
  dobAttempts: 0,
  verified: false,
  visitType: "",
  cdoVisitType: "",
  categoryVisitType: "",
  allAppointmentInfos: [],
  eligibilityAttempts: 0,
  eligibilityVerified: false,
};

const apptSlice = createSlice({
  name: "appt",
  initialState: cloneDeep(initialApptState),

  reducers: {
    resetApptState(state, _action: PayloadAction<boolean>) {
      // reset everything
      state = cloneDeep(initialApptState);
    },
    updateLinkValid(state, action: PayloadAction<boolean>) {
      state.linkValid = action.payload;
    },
    updateLinkVerified(state, action: PayloadAction<boolean>) {
      state.linkVerified = action.payload;
    },
    updateLinkIgnored(state, action: PayloadAction<boolean>) {
      state.linkIgnored = action.payload;
    },
    updateIsReturningPatient(state, action: PayloadAction<boolean>) {
      state.isReturningPatient = action.payload;
    },
    updatePatientHasAllForms(state, action: PayloadAction<boolean>) {
      state.patientHasAllForms = action.payload;
    },
    updateNeedsAdvancedDirectiveForm(state, action: PayloadAction<boolean>) {
      state.needsAdvancedDirectiveForm = action.payload;
    },
    updateAttempts(state, action: PayloadAction<number>) {
      state.dobAttempts = action.payload;
    },
    updateVerified(state, action: PayloadAction<boolean>) {
      state.verified = action.payload;
    },
    updateApptVisitType(state, action: PayloadAction<any>) {
      state.visitType = action.payload || "";
    },
    updateApptCdoVisitType(state, action: PayloadAction<any>) {
      state.cdoVisitType = action.payload || "";
    },
    updateApptCategoryVisitType(state, action: PayloadAction<any>) {
      state.categoryVisitType = action.payload || "";
    },
    updateMultiApptAllAppointments(state, action: PayloadAction<any[]>) {
      state.allAppointmentInfos = action.payload || [];
    },
    updateSingleApptValue(state, action: PayloadAction<any>) {
      let { apptId, key, val } = action.payload || {};
      let allAppointmentInfos = [...state.allAppointmentInfos];
      let apptItem: SingleApptState | undefined = allAppointmentInfos.find(v => v.apptId === apptId);
      if (apptItem) {
        apptItem[key] = val;
        state.allAppointmentInfos = allAppointmentInfos;
      }
    },
    updateEligibilityAttempts(state, action: PayloadAction<number>) {
      state.eligibilityAttempts = action.payload;  
    },
    updateEligibilityVerified(state, action: PayloadAction<boolean>) {
      state.eligibilityVerified = action.payload;  
    },    
  },
});


// helpers

export const sortAppointments = (apptInfos: SingleApptState[]): SingleApptState[] => {
  if (apptInfos && apptInfos.length > 0) {
    apptInfos = orderBy(apptInfos, ["apptDateTime"], ["asc"]);
  }
  return apptInfos;
};

export const findBestAppointment = (
  apptInfos: SingleApptState[], 
  checkConfirmed: boolean = true, 
  checkPending: boolean = true,
  checkCancelled: boolean = false, 
  checkFirst: boolean = true
): SingleApptState => {
  let bestApptInfo: any = undefined;
  
  // sort by date_time, with most recent near the back
  if (apptInfos && apptInfos.length > 0) {
    apptInfos = sortAppointments(apptInfos);
    
    if (!bestApptInfo && checkConfirmed) {
      bestApptInfo = apptInfos.find(v => v.isConfirmed);
    }
    if (!bestApptInfo && checkPending) {
      bestApptInfo = apptInfos.find(v => !v.isConfirmed && !v.isCancelled);
    }
    if (!bestApptInfo && checkCancelled) {
      bestApptInfo = apptInfos.find(v => v.isCancelled);
    }
    if (!bestApptInfo && checkFirst) { 
      // as a fallback, grab the first appt item
      bestApptInfo = apptInfos[0];
    }
  }

  return bestApptInfo;
};

// Appointments will be grouped by day according to the browser's local timezone.
// NOTE: this may not be the same timezone as the appointment or provider. 
// any appointments outside of the minDayOffset and maxDayOffset
// will be placed into the min or max group
export const groupAppointmentsByDay = (
  apptInfos: SingleApptState[],
  minDayOffset: number = 0,
  maxDayOffset: number = 3
): any[] => {  
  const groups: any = [];
  const today = moment().startOf("day");
  apptInfos?.forEach((apptItem: SingleApptState) => {
    let dayOffset: number = 0;
    if (apptItem?.apptDateTime) {
      let apptMoment = parseToMoment(apptItem?.apptDateTime);
      if (apptMoment?.isValid()) {
        dayOffset = apptMoment.diff(today, "days");
      }
    }
    if (dayOffset <= minDayOffset) { dayOffset = minDayOffset; }
    else if (dayOffset >= maxDayOffset) { dayOffset = maxDayOffset; }

    let group = get(groups, dayOffset) || { dayOffset, items: [] };
    group.items.push(apptItem);
    set(groups, dayOffset, group);    
  });
  return groups;
};

export const findFirstAppointmentWithinTimeWindow = (
  apptInfos: SingleApptState[], 
  earlyTimeOffsetMins: number = 15,   // amount of time the patient can be early
  lateTimeOffsetMins: number = 15,    // amount of time the patient can be late
): SingleApptState | undefined => {

  earlyTimeOffsetMins = Math.abs(earlyTimeOffsetMins);
  lateTimeOffsetMins = Math.abs(lateTimeOffsetMins);

  let now = moment();
  let minDateTime = moment(now).subtract(earlyTimeOffsetMins, 'minutes');
  let maxDateTime = moment(now).add(lateTimeOffsetMins, 'minutes');

  return apptInfos.find((apptItem) => {
    let apptMoment = apptItem?.apptDateTime? parseToMoment(apptItem.apptDateTime): undefined;
    if (apptMoment?.isValid() && apptMoment.isBetween(minDateTime, maxDateTime)) {
      return true;
    }
    return false;
  });
};

export const hasConfirmedPreRegDoneAppointmentWithinTimeWindow = (
  apptInfos: SingleApptState[], 
  earlyTimeOffsetMins: number = 15,   // amount of time the patient can be early
  lateTimeOffsetMins: number = 15,    // amount of time the patient can be late
): boolean => {
  let confirmedApptItems = (apptInfos || []).filter(v => (v.isConfirmed && v.preRegDone));
  let foundAppt = findFirstAppointmentWithinTimeWindow(confirmedApptItems, earlyTimeOffsetMins, lateTimeOffsetMins);
  return !!foundAppt;
};


export const {
  resetApptState,
  updateLinkValid,
  updateLinkVerified,
  updateLinkIgnored,
  updateIsReturningPatient,
  updatePatientHasAllForms,
  updateNeedsAdvancedDirectiveForm,
  updateAttempts,
  updateVerified,
  updateApptVisitType,
  updateApptCdoVisitType,
  updateApptCategoryVisitType,
  updateMultiApptAllAppointments,
  updateSingleApptValue,
  updateEligibilityAttempts,
  updateEligibilityVerified,
} = apptSlice.actions;

export default apptSlice.reducer;

