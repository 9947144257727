import React, { ReactElement } from "react";
import useId from "@mui/material/utils/useId";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { makeStyles } from "@mui/styles";
import { appDialogTransparentPaperPropsCenter, appMaxWidth } from "../../theme";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { PopupContext, updateUIErrorPopupContext } from "../../features/ui/uiSlice";
import AgeErrorPopup from "./AgeErrorPopup";
import SessionExpiringErrorPopup from "./SessionExpiringErrorPopup";
import ConnectivityErrorPopup from "./ConnectivityErrorPopup";
import KioskErrorPopup from "./KioskErrorPopup";


const _componentMap = {
  "KioskErrorPopup":KioskErrorPopup,
  "AgeErrorPopup": AgeErrorPopup,
  "SessionExpiringErrorPopup": SessionExpiringErrorPopup,
  "ConnectivityErrorPopup": ConnectivityErrorPopup,
};

const useStyles = makeStyles(()=> ({
  dialog: {
    maxWidth: appMaxWidth,
    margin: "0 auto",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface ErrorPopupProps {
  ariaTitleId?: string;
  onClose: () => void;
}

interface ErrorPopupProviderProps {
}

export const ErrorPopupProvider: React.FC<ErrorPopupProviderProps> = (props: ErrorPopupProviderProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const dialogTitleId = useId();

  const context = useAppSelector<PopupContext | null>((state) => state.ui.errorPopupContext);
  const open = !!context;

  const handleClose = (...args): void => {
    dispatch(updateUIErrorPopupContext(null));
    if (context?.props?.onClose) {
      context.props.onClose(...args);
    }
  };

  const resolveComponent = (component: any): any => {
    let ComponentClass: any = null;
    if (component) {
      const isComponent: boolean = (typeof component !== 'string' && React.Component.prototype.isPrototypeOf(component.prototype));
      if (isComponent) {
        ComponentClass = component as ReactElement;
      } else {
        const tmp = _componentMap[String(component)] || null;
        if (tmp) { 
          ComponentClass = tmp as ReactElement;
        }
      }
    }
    return ComponentClass;
  };
  
  const ComponentClass = resolveComponent(context?.component);
  if (!ComponentClass) { return null; }

  return (
    <Dialog
      className={classes.dialog}
      fullScreen={true}
      fullWidth={true}
      disableRestoreFocus={true}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby={dialogTitleId}
      PaperProps={appDialogTransparentPaperPropsCenter}
    >
      <ComponentClass {...context?.props} ariaTitleId={dialogTitleId} onClose={handleClose} />
    </Dialog>
  );
};

export default ErrorPopupProvider;
