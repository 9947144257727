import cloneDeep from "lodash/cloneDeep";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filterObject } from "../../utility/utilityFunctions";


export interface InsuranceCardImage {
  title: string;
  imageContents: string;
}

export interface InsuranceCoverageItem {
  fhirInsuranceId: string;
  payorDisplay: string;
  memberId: string;
  groupNumber: string;
  policyHolderName: string;
  policyHolderDOB: string;
  policyHolderRelationToPatientCode: string;
  policyHolderRelationToPatientValue: string;
  insuranceCardFrontImage: string;
  insuranceCardBackImage: string;
  order: number|null|undefined;  // 1-based index, used to differentiaate between coverage #1 and coverage #2
}

/** createas a new InsuranceCoverageItem.  if obj is empty, then an empty item is returned */
export const CreateNewInsuranceCoverageItem = (obj?: any) => {
  let newItem: InsuranceCoverageItem = {
    fhirInsuranceId: "",
    payorDisplay: "",
    memberId: "",
    groupNumber: "",
    policyHolderName: "",
    policyHolderDOB: "",
    policyHolderRelationToPatientCode: "",
    policyHolderRelationToPatientValue: "",
    insuranceCardFrontImage: "",
    insuranceCardBackImage: "",
    order: undefined, 
  };
  if (obj) { 
    let keys = Object.keys(newItem);
    Object.assign(newItem, filterObject(obj, keys));
    keys.forEach(k => {
      if (k !== "order") {
        if (!newItem[k]) { 
          newItem[k] = "";
        }
      }
    });    
  }
  return newItem;
};

const InsuranceCoverageItemKeys = Object.keys(CreateNewInsuranceCoverageItem());

export interface InsuranceCoverageUpdate extends InsuranceCoverageItem {
  coverageIndex: number;
}

export interface InsuranceProviderPayor {
  payorId: string;
  payorName: string;
  payorDisplay: string;
}

export interface InsuranceState {
  coverage1: InsuranceCoverageItem;
  coverage2: InsuranceCoverageItem;
  hasSelfCoverage: boolean;
  hasCoverage1: boolean;
  hasCoverage2: boolean;
  insuranceProviderPayors: InsuranceProviderPayor[];
}

const initialState: InsuranceState = {
  coverage1: CreateNewInsuranceCoverageItem(),
  coverage2: CreateNewInsuranceCoverageItem(),
  hasSelfCoverage: false,
  hasCoverage1: false,
  hasCoverage2: false,
  insuranceProviderPayors: [],
};

export const findInsuranceProviderPayorById = (payors: InsuranceProviderPayor[], payorId: string) => { 
  let foundPayor: InsuranceProviderPayor|undefined = undefined;
  if (payors?.length > 0 && payorId) { 
    foundPayor = payors.find((v: InsuranceProviderPayor) => (v?.payorId === payorId));
  }
  return foundPayor;
};

// const _invalidateInsuranceCoverage1ProviderName = (coverage: InsuranceCoverageItem, payors: InsuranceProviderPayor[], force: boolean = false) => {
//   // no payload value for this call.  payload is a boolean to force the update.
//   // if we have and insuranceProviderId and the insuranceProviderPayors list exists, 
//   // then find the payor.displayName and set it as the new providerName
//   let payor: InsuranceProviderPayor|undefined = findInsuranceProviderPayorById(payors, coverage?.insuranceProviderId);
//   if (payor?.payorDisplay || force) { 
//     let insuranceProviderName: string = payor?.payorDisplay || "";
//     coverage = { ...coverage, insuranceProviderName };
//   }
//   return coverage;
// }

const insuranceSlice = createSlice({
  name: "insurance",
  initialState: cloneDeep(initialState),
  reducers: {
    resetInsuranceState(state, _action: PayloadAction<boolean>) {
      // reset everything
      state = cloneDeep(initialState);
    },    
    setHasSelfCoverage(state, action: PayloadAction<boolean>) {
      state.hasSelfCoverage = action.payload;
    },
    setHasCoverage1(state, action: PayloadAction<boolean>) {
      state.hasCoverage1 = action.payload;
    },
    setHasCoverage2(state, action: PayloadAction<boolean>) {
      state.hasCoverage2 = action.payload;
    },
    mergeInsuranceCoverage1(state, action: PayloadAction<any>) {
      let item: InsuranceCoverageItem = filterObject(action.payload, InsuranceCoverageItemKeys);
      item = CreateNewInsuranceCoverageItem(item);
      state.coverage1 = { ...state.coverage1, ...item };
    },    
    mergeInsuranceCoverage2(state, action: PayloadAction<any>) {
      let item: InsuranceCoverageItem = filterObject(action.payload, InsuranceCoverageItemKeys);
      item = CreateNewInsuranceCoverageItem(item);
      state.coverage2 = { ...state.coverage2, ...item };
    },
    updateInsuranceCoverage1FrontCardImage(state, action: PayloadAction<string>) {
      let insuranceCardFrontImage: string = action.payload || "";
      state.coverage1 = { ...state.coverage1, insuranceCardFrontImage };
    },
    updateInsuranceCoverage1BackCardImage(state, action: PayloadAction<string>) {
      let insuranceCardBackImage: string = action.payload || "";
      state.coverage1 = { ...state.coverage1, insuranceCardBackImage };
    },
    updateInsuranceCoverage2FrontCardImage(state, action: PayloadAction<string>) {
      let insuranceCardFrontImage: string = action.payload || "";
      state.coverage2 = { ...state.coverage2, insuranceCardFrontImage };
    },    
    updateInsuranceCoverage2BackCardImage(state, action: PayloadAction<string>) {
      let insuranceCardBackImage: string = action.payload || "";
      state.coverage2 = { ...state.coverage2, insuranceCardBackImage };
    },
    setInsuranceProviderPayors(state, action: PayloadAction<InsuranceProviderPayor[]>) {
      state.insuranceProviderPayors = action.payload || [];
    },
    // invalidateInsuranceCoverageProviderNames(state, action: PayloadAction<boolean>) {
    //   // no payload value for this call.  payload is a boolean to force the update.
    //   // if we have and insuranceProviderId and the insuranceProviderPayors list exists, 
    //   // then find the payor.displayName and set it as the new providerName
    //   let force: boolean = action.payload || false;
    //   state.coverage1 = _invalidateInsuranceCoverage1ProviderName(state.coverage1, state.insuranceProviderPayors, force);
    //   state.coverage2 = _invalidateInsuranceCoverage1ProviderName(state.coverage2, state.insuranceProviderPayors, force);
    // },
  },
});

export const {
  resetInsuranceState,
  setHasSelfCoverage,
  setHasCoverage1,
  setHasCoverage2,
  mergeInsuranceCoverage1,    
  mergeInsuranceCoverage2,
  updateInsuranceCoverage1FrontCardImage,
  updateInsuranceCoverage1BackCardImage,
  updateInsuranceCoverage2FrontCardImage,
  updateInsuranceCoverage2BackCardImage,
  setInsuranceProviderPayors,
  // invalidateInsuranceCoverageProviderNames,
} = insuranceSlice.actions;

export default insuranceSlice.reducer;
