import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { appColors, appFonts } from "../theme";
import { InsuranceCoverageItem } from "../features/insurance/insuranceSlice";
import { ReactComponent as CheckmarkSvg } from "../assets/icons/check-circlegreen.svg";
import { ReactComponent as ExclamationmarkSvg } from "../assets/icons/exclamation.svg";
import Aem from "../lib/aem/components/Aem";
import clsx from "clsx";
import { useAppSelector } from "../app/hooks";
import config from "../config";

export const CardSize: any = { w: 383, h: 217 };

const useStyles = makeStyles({
  card: {
    position: "relative",
    maxWidth: `${CardSize.w}px`,
    maxHeight: `${CardSize.h}px`,
    borderRadius: "4px",
    background: "#ffffff",
    color: appColors.darkGray1,
    boxShadow: "3px 3px 6px 0 rgba(0,0,0,0.25)",
    flexWrap: "nowrap",
  },
  cardInsuranceHeader: {
    flexWrap: "nowrap",
  },
  insuranceInfo: {
    fontFamily: appFonts.semibold,
    fontSize: "12px",
    lineHeight: "20px",
  },
  insuranceInfo2: {
    fontFamily: appFonts.regular,
    fontSize: "14px",
  },
  insuranceLogoArea: {
    height: "34px",
    maxWidth: "108px",
    marginLeft: "14px",
    marginTop: "16px",
    "& svg": { 
      height: "100%",
    },
  },
  insuranceCompany: {
    fontFamily: appFonts.bold,
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "25px",
    padding: "0 16px",
    color: appColors.darkGray1,
    letterSpacing: ".58px"
  },
  name: {
    fontFamily: appFonts.semibold,
    fontSize: "18px",
    color: appColors.darkGray1,
    marginBottom: "26.5px",
    marginTop: "23.5px",
    padding: "0 16px",
  },
  cardValid: {
    border: `3px solid ${appColors.success}`
  },
  cardInvalid: {
    border: `3px solid ${appColors.danger}`
  },
  cardValidStatus: {
    float: "right",
    fontSize: "15px",
    color: appColors.success,
    "& svg": { 
      fill: appColors.success,
    },
  },
  cardInvalidStatus: {
    float: "right",
    fontSize: "15px",
    color: appColors.danger,
    "& svg": { 
      fill: appColors.danger,
    },
  }
});

interface InsuranceCardProps{
  coverage: InsuranceCoverageItem,
  isPrimary?: boolean
}

export const InsuranceInfoCard: React.FC<InsuranceCardProps> = (props: InsuranceCardProps) => {
  const classes = useStyles();
  
  let { coverage, isPrimary } = props;
  
  const enableEligibilityFeature: boolean = config.enableEligibilityFeature;
  const eligibilityVerified = useAppSelector<boolean>(
    (state) => state.appt.eligibilityVerified
  );
  const eligibilityAttempts = useAppSelector<number>(
    (state) => state.appt.eligibilityAttempts
  );

  let isEligibilityChecked : boolean = false;
  if (enableEligibilityFeature && isPrimary && (eligibilityAttempts > 0)) {
    isEligibilityChecked = true;
  }

  const verifiedTitle: string = Aem.get("BODY_INSURANCE_INFO_TEXT_18", "Verified");
  const unVerifiedTitle: string = Aem.get("BODY_INSURANCE_INFO_TEXT_19", "Unverified");
  
  // Insurance Info
  const providerName = coverage?.payorDisplay || "";
  const memberId = coverage?.memberId || "";
  const groupNumber = coverage?.groupNumber || "";
  const memberName = coverage?.policyHolderName || "";
  // TODO: read payer ID from fhir

  return (
    <Grid 
      container 
      direction="column"
      className={`${isEligibilityChecked ? eligibilityVerified ? clsx(classes.card, classes.cardValid) : clsx(classes.card, classes.cardInvalid) : classes.card}`}>

      {isEligibilityChecked && eligibilityVerified &&
        <Grid item className={classes.insuranceCompany}>
          {providerName.toUpperCase()}
          <div className={classes.cardValidStatus}><CheckmarkSvg /> <span>{verifiedTitle} </span></div>
        </Grid>
      }

      {isEligibilityChecked && !eligibilityVerified &&
        <Grid item className={classes.insuranceCompany}>
          {providerName.toUpperCase()}
          <div className={classes.cardInvalidStatus}><ExclamationmarkSvg /><span> {unVerifiedTitle} </span></div>
        </Grid>
      }

        {/*insurance info  begin*/}
        <Grid item className={classes.name}>
          {memberName}
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.insuranceInfo}
          style={{
            height: "128px",
            width: "100%",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
           <Grid container direction="row">
              <Grid item xs={4}>
                <Aem cid="BODY_INSURANCEREVIEW_TEXT_10">
                  Member ID
                </Aem>
              </Grid>
              <Grid item xs={8} className = {classes.insuranceInfo2}>
                {memberId}
              </Grid>
              <Grid item xs={4}>
                <Aem cid="BODY_INSURANCEREVIEW_TEXT_11">
                  Group Number
                </Aem>
              </Grid>
              <Grid item xs={8} className = {classes.insuranceInfo2}>
                {groupNumber}
              </Grid>
              {/*Kajal: Right now payerId is hardcoded value therefore commented out */}
              {/* <Grid item xs={4}>
                Payer ID
              </Grid>
              <Grid item xs={8} className = {classes.insuranceInfo2}>
                {payerId}
              </Grid> */}
              </Grid>
        </Grid>
      {/*insurance info end*/}

    </Grid>
  );  
};

export default InsuranceInfoCard;

