import { AemKVPair } from "../lib/aem";

export const InsuranceProviders = [
  "Aetna",
  "AHMC",
  "Alignment",
  "Anthem Blue Cross",
  "Blue Shield",
  "Blue Shield Promise",
  "CalOptima",
  "Cigna",
  "Easy Choice",
  "Great West",
  "HealthNet",
  "Humana",
  "Intervalley",
  "LA Care",
  "Medicaid/Medi-Cal",
  "Medicare",
  "Molina",
  "Scan",
  "Shield 65",
  "Tricare West",
  "United Healthcare",
];

// list of relationships to be used in a relationship dropdowns.
// This list came from the Athena DB
export const InsuranceRelations = [
  "Self",
  "Spouse",
  "Child",
  "Grandparent",
  "Grandchild",
  "Nephew or Niece",
  "Foster Child",
  "Ward",
  "Stepson or Stepdaughter",
  "Employee",
  "Handicapped Dependent",
  "Sponsored Dependent",
  "Dependent of a Minor Dependent",
  "Significant Other",
  "Mother",
  "Father",
  "Life Partner",
  "Other",
  "Unknown",
];


export const PayerCodeNameKVPairs: AemKVPair[] = [
  { Key: "00001||Aetna", Value: "Aetna" },
  { Key: "BO00599||AHMC HEALTHCARE BO", Value: "AHMC" },
  { Key: "BO00192||Alignment Health Plan BO", Value: "Alignment" },
  { Key: "00707||ANTHEM BC CALIFORNIA (MEDICAID RECLAMATION)", Value: "Anthem Blue Cross" },
  { Key: "BO00002||Blue Shield of CA - Pending Eligibility", Value: "Blue Shield" },
  { Key: "01405||Blue Shield of CA Promise - All Numeric Member ID", Value: "Blue Shield Promise" },
  { Key: "01029||CalOptima", Value: "CalOptima" },
  { Key: "00004||Cigna", Value: "Cigna" },
  { Key: "00711||EASY CHOICE HEALTH PLAN CALIFORNIA", Value: "Easy Choice" },
  { Key: "00102||Great West Healthcare", Value: "Great West" },
  { Key: "00399||BMC HealthNet Plan", Value: "HealthNet" },
  { Key: "00112||Humana", Value: "Humana" },
  { Key: "00117||InterValley Health Plan", Value: "Intervalley" },
  { Key: "0009650||LA Care Health Plan", Value: "LA Care" },
  { Key: "00059||California Medicaid (Medi-Cal)", Value: "Medicaid/Medi-Cal" },
  { Key: "00007||Medicare Part A and B", Value: "Medicare" },
  { Key: "00148||Molina Healthcare of California", Value: "Molina" },
  { Key: "00177||SCAN HMO", Value: "Scan" },
  { Key: "TBD||TBD", Value: "Shield 65" },
  { Key: "TBD||TBD", Value: "Tricare West" },
  { Key: "00192||UHC", Value: "United Healthcare" },
];
