import moment from "moment";
import { GetCdoShortName } from "../../data/CDO";


export interface CancelAppointmentData {
  cdo: string;          // CdoShortName
  patientId: string;
  appointmentId: string;
  cancellationDateTime: string;
};

export const InitializeCancelAppointmentData = (patientId: string, appointmentId: string): CancelAppointmentData => {

  const now = moment();
  const nowDateStr = now.format("YYYY-MM-DD");
  const cdoShortName = GetCdoShortName();

  return {
    cdo: cdoShortName,
    patientId: patientId,
    appointmentId: appointmentId,
    cancellationDateTime: nowDateStr,
  };
};