import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "@mui/material";
import useIsMounted from "../../lib/hooks/useIsMounted";
import { tracker } from "../../appTracker";
import { VisuallyHidden } from "../Accessibility/VisuallyHidden";
import { Aem } from "../../lib/aem";


const DefaultScreenReaderExtLinkHintText: string = "(Opens a new window)";

type LinkButtonProps = any & {
  className?: any;
  url: string,
  target?: string;
  rel?: string;
  disabled?: boolean;
  linkProps: any;
  trackName?: string;
  trackLocation?: string;
  screenReaderHintText?: string;    // undefined to load AEM value
  children?: React.ReactElement<any, any>|string[]|string;
  // NOTE: make sure to use async/await inside handler to prevent button mashing
  onClick?: (...args) => void;    
}

export const LinkButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
  let { 
    className,
    url,
    target, 
    rel, 
    disabled, 
    linkProps, 
    onClick,
    screenReaderHintText,
    children,
  } = props;

  // used to disable the button when an async click action is occuring
  // this prevents button mashing
  const [clickPending, setClickPending] = useState<boolean>(false);
  const isMounted = useIsMounted();

  if (screenReaderHintText === undefined) {
    // use default text from AEM
    screenReaderHintText = Aem.get("ACCESSIBILITY_EXTLINK_TEXT_1", DefaultScreenReaderExtLinkHintText);
  }

  const handleClick = async (...args) => {
    let trackName = props.trackName || "";
    let trackLocation = props.trackLocation || "";
    tracker.click(trackName, trackLocation);

    if (onClick) { 
      setClickPending(true);

      try {
        await onClick(...args);
      } catch (ex) {}
      
      if (isMounted()) {
        setClickPending(false);
      }
    }
  }; 

  return (
    <Link
      className={clsx("ext-link", className)}
      href={url}
      target={target} 
      rel={rel}
      disabled={disabled || clickPending}
      onClick={handleClick}
      {...linkProps}
    >
      {children}
      {screenReaderHintText && (
        <VisuallyHidden>{screenReaderHintText}</VisuallyHidden>
      )}
    </Link>
  );
};

LinkButton.defaultProps = {
  target: "_blank",
  rel: "noopener",
  disabled: false,
  trackName: "app button",
  trackLocation: "body",
  screenReaderHintText: undefined, // use AEM value,
};

export default LinkButton;
