import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PrefState {
  // the refresh flag is used to redraw components after aem cached data has changed.  
  // refresh will get updated whenever the aem cache data is updated
  refresh: any;
}

const initialState: PrefState = {
  refresh: undefined,
};

const aemSlice = createSlice({
  name: "aem",
  initialState,
  reducers: {
    updateAemRefresh(state, action: PayloadAction<any>) {
      state.refresh = action.payload;
    },
  },
});

export const { updateAemRefresh } = aemSlice.actions;

export default aemSlice.reducer;
