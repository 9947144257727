export const AemConstants =  {
  CONSTANTS_REPLACE: [
    "{{PatientFirstName}}",
    "{{LocationName}}",
    "{{LocationPhone}}",
    "{{LocationAddress}}",
    "{{VisitDate}}",
    "{{VisitTime}}",
    "{{VisitDateTime}}", 
    "{{ProviderFullName}}",
    "{{TermsLink}}",
    "{{PrivacyLink}}"
  ],

  PATIENT_FIRST_NAME: "{{PatientFirstName}}", 
  LOCATION_NAME: "{{LocationName}}",
  LOCATION_PHONE: "{{LocationPhone}}",
  LOCATION_ADDRESS: "{{LocationAddress}}", 
  VISIT_DATE: "{{VisitDate}}",
  VISIT_TIME: "{{VisitTime}}",
  VISIT_DATE_TIME: "{{VisitDateTime}}",
  PROVIDER_FULL_NAME: "{{ProviderFullName}}",
  TERMS_LINK: "{{TermsLink}}",
  PRIVACY_LINK: "{{PrivacyLink}}"
};

