import React, { useCallback, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import useScript from "./lib/hooks/useScript";
import useIsMounted from "./lib/hooks/useIsMounted";
import useIsFirstRender from "./lib/hooks/useIsFirstRender";
import { SetApiErrorHandler } from "./api/ApiErrorHandler";
import { gotoTaskRoute, setTaskTransitionPending } from "./features/task/taskSlice";
import { updateUIErrorPopupContext } from "./features/ui/uiSlice";
import { 
  AppRoutingTaskStepper, 
  AppRoutingTaskStepperPrivateKioskMode, 
  AppRoutingTaskStepperPublicKioskMode,
  AppRoutingTaskStepperPrivateWebAccessCodeMode, 
} from "./Screens/AppRoutingTaskStepper";
import PageNotFoundErrorPage from "./Screens/errors/PageNotFoundErrorPage";
import MessagePopupProvider from "./Components/Popups/MessagePopupProvider";
import ErrorPopupProvider from "./Components/Errors/ErrorPopupProvider";
import TransitionLoadingProvider from "./Components/TaskStepper/TransitionLoadingProvider";
import MenuProvider from "./Components/Menu/MenuProvider";
import { initDefaultGlobalMomentLocaleOnce } from "./appInit";
import { appInactivityTimer, invalidateAppInactivityTimerTimeouts } from './appInactivityTimer';
import { tracker } from "./appTracker";
import config from "./config";
import "./App.css";


// NOTE: Debug only.  do not enable for production.
const DEBUG_TRACKER_VERBOSE = !!(false && process.env.NODE_ENV === "development");


function App() {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  const isFirstRender = useIsFirstRender();

  initDefaultGlobalMomentLocaleOnce();

  let trackerScriptUrl: string = config.analytics.url;
  useScript(trackerScriptUrl);
  tracker.formName = "easy check";
  tracker.setVerbose(DEBUG_TRACKER_VERBOSE);
  
  let taskInit = useAppSelector((state) => state.task.init);
  let routeFinal = useAppSelector((state) => !!state.task.routeInfo?.isFinal);
  let linkValid = useAppSelector((state) => state.appt.linkValid);
  let linkVerified = useAppSelector((state) => state.appt.linkVerified);
  let linkIgnored = useAppSelector((state) => state.appt.linkIgnored);
  let isPublicDevice = useAppSelector((state) => state.preferences.isPublicDevice);

  useEffect(() => {
    if (isFirstRender) {
      console.log("Optum easycheck", config.version);
    }
  }, [isFirstRender, linkValid, linkVerified, taskInit]);

  useEffect(() => {
    if (!taskInit || linkIgnored || routeFinal) { return; }

    // check for error states
    // show a different error page based on the error priority
    if (!linkValid) {
      dispatch(gotoTaskRoute("linkInvalidError"))
    } else if (!linkVerified) {
      dispatch(gotoTaskRoute("verificationError"))
    }  
  }, [dispatch, taskInit, routeFinal, linkValid, linkVerified, linkIgnored]);

  // init the network connectivitiy error triggers once
  const apiErrorHandler = useCallback((err) => {
    const context = err? { component: "ConnectivityErrorPopup" }: null;
    dispatch(updateUIErrorPopupContext(context));
    dispatch(setTaskTransitionPending(false));
  }, [dispatch]);

  useEffect(() => {
    SetApiErrorHandler(apiErrorHandler);
  }, [apiErrorHandler]);

  // init the application inactivity timeout timer and set the handlers
  const timeoutWarningHandler = useCallback(() => {
    if (!isMounted()) { return; }
    const context = { component: "SessionExpiringErrorPopup" };
    dispatch(updateUIErrorPopupContext(context));
    dispatch(setTaskTransitionPending(false));
  }, [dispatch, isMounted]);
  const timeoutFinalHandler = useCallback(() => {
    if (!isMounted()) { return; }
    dispatch(updateUIErrorPopupContext(null));
    dispatch(gotoTaskRoute("sessionExpiredError"));
  }, [dispatch, isMounted]);

  useEffect(() => {
    appInactivityTimer.setWarningHandler(timeoutWarningHandler);
    appInactivityTimer.setTimeoutHandler(timeoutFinalHandler);
  }, [timeoutWarningHandler, timeoutFinalHandler]);

  // if the app mode changes, then update the inactivity timer timeout values
  useEffect(() => {

    // update the inactivity timer timeout values from config 
    invalidateAppInactivityTimerTimeouts(isPublicDevice);

    // on first render, start the timer
    if (isFirstRender) {
      appInactivityTimer.start();
    }
  }, [isFirstRender, isPublicDevice]);

  return (
    <Grid container direction="column" className="App">
      <Router>
        <Switch>
          {/* public tablet devices */}
          <Route
            exact
            path="/kiosktablet"
            component={AppRoutingTaskStepperPublicKioskMode}
          />
          {/* private patient devices */}
          <Route
            exact
            path="/kiosk"
            component={AppRoutingTaskStepperPrivateKioskMode}
          />
          {/* private patient devices with patient and appointment specific web access code */}
          <Route
            exact
            path="/code=:code"
            component={AppRoutingTaskStepperPrivateWebAccessCodeMode}
          />
          {/* private patient devices from SMS text message */}
          <Route
            exact
            path="/pid=:pid"
            component={AppRoutingTaskStepper}
          />
          <Route
            exact
            path="/"
            component={AppRoutingTaskStepper}
          />
          <Route
            path="*" 
            component={PageNotFoundErrorPage}
          />
        </Switch>
      </Router>
      <MenuProvider />
      <MessagePopupProvider />
      <ErrorPopupProvider />
      <TransitionLoadingProvider />
    </Grid>
  );
}

export default App;
