import moment, { Moment } from "moment";


/* Compares two strings and ignores casing */
export const equalsIgnoringCase = (text, other) => {
  return text.localeCompare(other, "en", { sensitivity: "base" }) === 0;
};

/* returns the value passed in */
export const ident = (v?: any) => v;

/* does nothing, can be used as a placeholder or empty function call */
export const noop = (v?: any) => {};

/* is value empty.  checks null, undefined, empty array, empty object or empty string */
export const isEmpty = (v?: any) => {
  let retval: boolean = true;
  if (v !== undefined && v !== null && v !== "" && v) {
    let vtype: string = typeof v;
    if (Array.isArray(v) || vtype === "string" || v.hasOwnProperty("length")) {
      retval = v.length === 0;
    } else if (vtype === "object") {
      for (let k in v) {
        if (v.hasOwnProperty(k)) {
          retval = false;
          break;
        }
      }
    }
  }
  return retval;
};

/* returns a fully deep cloned object */
export const cloneDeep = (obj: any) => {
  let retval: any = undefined;
  try {
    if (obj === null) {
      retval = null;
    } else if (obj === undefined) {
      retval = undefined;
    } else {
      retval = JSON.parse(JSON.stringify(obj));
    }
  } catch (ex) {}
  return retval;
};

/* returns true if both objects are equivalient.  NOTE: only compares shallow keys */
export const isEqualObjects = (a: any, b: any) => {
  let retval: boolean = false;
  if (a === b) {
    retval = true;
  } else if (a && b && a.constructor === b.constructor) {
    let aKeys: string[] = Object.keys(a);
    let bKeys: string[] = Object.keys(b);
    if (aKeys.length === bKeys.length) {
      retval = true;
      for (let i in aKeys) {
        let k = aKeys[i];
        if (a[k] !== b[k]) {
          retval = false;
          break;
        }
      }
    }
  }
  return retval;
};

/**
 * converts defs object to types hash map
 * input = { "A": "aaa", "B": "bbb" } 
 *  =>
 * { "A": "A", "B": "B" }
 */
export const makeKeysMap = (input: any): any => {
  let map: any = {};
  if (input) {
    Object.keys(input).forEach(k => {
      if (k) {
        map[k] = k;
      }
    });
  }
  return map;
};

/**
 * converts defs object to values hash map
 * input = { "A": "aaa", "B": "bbb" } 
 *  =>
 * { "aaa": "A", "bbb": "B" }
 */
export const makeValuesMap = (input: any): any => {
  let map: any = {};
  if (input) {
    Object.values<any>(input).forEach(v => {
      if (v) {
        map[v] = v;
      }
    });
  }
  return map;
};

/**
 * converts defs object array to types hash map
 * input = [{ "code": "A" }, { "code": "B" }] 
 *  =>
 * { "A": "A", "B": "B" }
 */
export const makeHashMap = (input: any[], keyName: string = "code"): any => {
  let map: any = {};
  if (Array.isArray(input)) {
    input.forEach((v, k) => {
      let key = v ? v[keyName] : undefined;
      if (key) {
        map[key] = key;
      }
    });
  }
  return map;
};

/**
 * converts object to lookup hash map
 * input = { "A": "aaa", "B": "bbb" } 
 *  =>
 * { "aaa": "A", "bbb": "B" }
 */
export const makeReverseLookupMap = (input: any): any => {
  let map: any = {};
  Object.entries<any>(input).forEach(([k, v]) => {
    if (k && v) {
      map[v] = k;
    }
  });
  return map;
};

/**
 modifies object in place and renames all specified property keys
 ex: renameObjectProperties({ "a": "a", "b": "b" }, { "a": "AAA" }) 
   => 
   { "AAA": "a", "b": "b" }
*/
export const renameObjectProperties = (obj: any, keyMap: any) => {
  if (obj && keyMap) {
    for (let k in keyMap) {
      let v = keyMap[k];
      if (k && v && k !== v) {
        obj[v] = obj[k];
        delete obj[k];
      }
    }
  }
  return obj;
};

/* returns a neew object containing only keys from original object */
export const filterObject = (obj: any, keys: string[]) => {
  let retval: any = {};
  if (obj && keys && keys.length > 0) {
    retval = Object.keys(obj)
      .filter((k) => keys.includes(k))
      .reduce((res, k) => {
        if (k === "policyHolderName") {
          res[k] = titleCase(obj[k]);
        }
        else {
          res[k] = obj[k];
        }
        return res;
      }, {});
  }
  return retval;
};

/**
 * stop event propagation.  Use this to prevent click through to an item below another item
 * ex: prevent button click from going through to parent element.
 * @param e     event object
 */
export const stopEvent = (e) => {
  try {
    if (e) {
      if (e.stopPropagation) { e.stopPropagation(); }
      if (e.stopImmediatePropagation) { e.stopImmediatePropagation(); }
      if (e.nativeEvent?.stopImmediatePropagation) { e.nativeEvent.stopImmediatePropagation(); }
    }
  } catch (ex) { }
};

export const deleteCookie = (cookieName: string, path: string = "/", domain: string = ""): boolean => {
  if (!cookieName) { return false; }

  let success = false;
  try {
    // NOTE: in order to delete a cookie, we must update the cookie expiration
    let cookieParts = [ 
      `${cookieName}=`,
      "Max-Age=0",
    ];
    if (path) { 
      cookieParts.push(`path=${path}`);
    }
    if (domain) { 
      cookieParts.push(`domain=${domain}`);
    }
    let expiredCookieStr: string = cookieParts.join('; ');
    document.cookie = expiredCookieStr;
    success = true;
  } catch (ex) { 
    success = false;
  }
  return success;
};

/* parse simple query string into json object */
export const parseQueryStringParams = (query: string) => {
  if (!query) {
    return {};
  }

  // trim any ? or # prefix
  query = /^[?#]/.test(query) ? query.slice(1) : query;
  return query.split("&").reduce((params, param) => {
    let [key, value] = param.split("=");
    if (key) {
      key="$"+key;
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
    }
    return params;
  }, {});
};

/* helper to parse and return default query string params (uses a cache object) */
let _cachedQueryParams: any = undefined;
export const getQueryStringParams = () => {
  if (_cachedQueryParams === undefined) {
    _cachedQueryParams = parseQueryStringParams(window?.location?.search);
  }
  return _cachedQueryParams;
};

/* convert the input value (string, boolean, number) into a boolean value */
export const parseBool = (val: any, defaultValue: boolean = false): boolean => {
  let retval: boolean = defaultValue;
  if (val !== null && val !== undefined) {
    let valType: string = typeof val;
    if (valType === "boolean") {
      retval = !!val;
    } else if (valType === "string" && val.length > 0) {
      if (val.match(/^t(rue)?/i) || val.match(/^y(es)?/i)) {
        retval = true;
      } else if (val.match(/^f(alse)?/i) || val.match(/^n(o)?/i)) {
        retval = false;
      } else {
        val = Number(val);
        valType = "number";
      }
    }
    if (valType === "number") {
      retval = val >= 1;
    }
  }
  return retval;
};

/* convert the input value (string, number) into a integer (number) value */
export const parseInt = (val: any, defaultValue: number = 0): number => {
  let retval: number = defaultValue;
  try {
    let tmp: any = Number.parseInt(val);
    if (Number.isFinite(tmp)) {
      retval = tmp;
    }
  } catch (ex) { }
  return retval;
}

/**
 * parse and split a single fullName string into a fhir DB friendly name part object
 * account for middle names by adding additional given names.
 * ensures familyName and at least 1 given name exists as empty string.
 * fullName = “John Smith” =>
 * {
 *   "use": "Official",
 *   "text": null,
 *   "family": "Smith",
 *   "given": [ "John" ]
 * }
 */
export const splitFullNameIntoFhirNameParts = (fullName: string) => {
  let nameParts = (fullName || "").trim().split(/\s+/);
  return convertNamePartsIntoFhirNameParts(...nameParts);
};

/**
 * split name parts into a fhir DB friendly name part object
 * account for middle names by adding additional given names.
 * ensures familyName and at least 1 given name exists as empty string.
 * nameParts = [“John", "Smith”] =>
 * {
 *   "use": "Official",
 *   "text": null,
 *   "family": "Smith",
 *   "given": [ "John" ]
 * }
 */
export const convertNamePartsIntoFhirNameParts = (...nameParts: string[]) => {
  if (!nameParts || nameParts.length === 0) { nameParts = []; }
  let fhirName: any = {
    use: "Official",
    text: null,
    family: null,
    given: [],
  };
  if (nameParts.length > 1) {
    fhirName.family = nameParts.pop() || "";
  } else {
    fhirName.family = "";
  }

  if (nameParts.length === 0) {
    nameParts = [""];
  }
  fhirName.given = nameParts;
  return fhirName;
};

/**
 * format first and last name as the full name with a space between them
 * @param parts string[]
 * @returns fullName string
 */
export const joinFullName = (...parts: string[]) => {
  return parts.map(v => {
    if (v === null || v === undefined) { v = ""; }
    return String(v) || "";
  })
    .join(" ")
    .replace(/\s+/, " ")
    .trim();
};

export const formatPhoneNumber = (phoneNo: string, phoneExt: string = "") => {
  let formattedNumber = phoneNo || "";
  let tmp = formattedNumber.replace(/[^\d]/g, "");
  let tmpExt = (phoneExt || "").replace(/[^\d]/g, "");
  if (tmp.length >= 10) {
    let pre = tmp.substring(0, 3);
    let mid = tmp.substring(3, 6);
    let end = tmp.substring(6, 10);

    formattedNumber = "(" + pre + ") " + mid + "-" + end;

    if (tmpExt) {
      formattedNumber += "x" + tmpExt;
    }
  }
  return formattedNumber;
};

export const formatWebsiteUrl = (url: string, includeProto: boolean = false, defaultProto: string = "http://") => {
  let formattedUrl: string = (url || "").trim();
  if (formattedUrl) {
    let hasProto: boolean = !!formattedUrl.match(/^https?:\/\//i);
    if (includeProto && !hasProto) {
      formattedUrl = defaultProto + formattedUrl;
    } else if (!includeProto && hasProto) {
      formattedUrl = formattedUrl.replace(/^https?:\/\//i, "");
    }
  }
  return formattedUrl;
};

export const parseToMoment = (
  dateStr: string | Date | Moment | null | undefined,
  defaultValue: any = undefined,
  format: any[] | string[] | string | undefined = undefined,
  ignoreTimeZone: boolean = false,
): Moment | undefined => {
  let m: Moment | undefined = defaultValue;
  if (dateStr) {
    try {
      let tmp: any;
      if (format === undefined) {
        format = [moment.ISO_8601, "YYYY-MM-DD", "MM/DD/YYYY", "M/DD/YYYY", "M/D/YYYY"];
      }
      if (ignoreTimeZone && typeof dateStr === 'string') {
        let tmpDateStr = dateStr.replace(/Z[+:\d]*$/, "");
        tmp = moment.utc(tmpDateStr, format);
      } else {
        tmp = moment(dateStr, format);
      }
      if (tmp && tmp.isValid()) {
        m = tmp;
      }
    } catch (ex) { }
  }
  return m;
};

// NOTE: if you are formatting any months or days of week, 
// use languageData formatLanguageDateTimeString instead
export const formatDate = (
  date: string | Date | Moment | null | undefined,
  format: string = "MM/DD/YYYY"
): string => {
  let m: Moment | undefined = parseToMoment(date);
  return m && m.isValid() ? m.format(format) : "";
}; 

export const thirteenYearsAgeCheck = (dobDateValue: Moment | null | undefined): boolean => {
  return numYearsAgeCheck(dobDateValue, 13, 1);
};

export const eighteenYearsAgeCheck = (dobDateValue: Moment | null | undefined): boolean => {
  return numYearsAgeCheck(dobDateValue, 18, 0);
};

const numYearsAgeCheck = (dobDateValue: Moment | null | undefined, numYears: number, numDays: number = 0): boolean => {
  let yearsAgo = moment().startOf("day").subtract(numYears, "years");
  if (numDays !== 0) {
    yearsAgo = moment(yearsAgo).subtract(numDays, "day");
  }
  return dobDateValue ? dobDateValue.isBefore(yearsAgo) : false;
};

export const titleCase = (str: string) => {
  let strs = (str || "").toLowerCase().split(" ");
  for (let i = 0; i < strs.length; i++) {
    strs[i] = strs[i].charAt(0).toUpperCase() + strs[i].slice(1);
  }
  return strs.join(" ");
};


export const getWorkPhoneNumber = (workPhone: string) => {
  let wkPhone = (workPhone || "").trim().split("x");
  if (wkPhone && wkPhone.length > 0) {
    return wkPhone[0];
  }
  return workPhone;
};

export const getWorkPhoneExtnNumber = (workPhone: string) => {
  let wkPhoneExtn = (workPhone || "").trim().split("x");
  if (wkPhoneExtn && wkPhoneExtn.length > 1) {
    return wkPhoneExtn[1];
  }
  return workPhone;
};

export const joinWorkPhoneWithExtn = (workPhone: string, workPhoneExtn: string) => {
  if (workPhoneExtn) {
    return workPhone + "x" + workPhoneExtn;
  }
  return workPhone;
};

export const formatWorkPhoneWithExtn = (phoneNo: string, phoneExt: string = "") => {
  let formattedNumber = phoneNo || "";
  let tmp = formattedNumber.replace(/[^\d]/g, "");
  let tmpExt = (phoneExt || "").replace(/[^\d]/g, "");
  if (tmp.length >= 10) {
    let pre = tmp.substring(0, 3);
    let mid = tmp.substring(3, 6);
    let end = tmp.substring(6, 10);

    formattedNumber = "(" + pre + ") " + mid + "-" + end;

      if (tmpExt) {
      formattedNumber += " x" + tmpExt;
      }
    }
  return formattedNumber;
};

export const splitByLinebreaks = (strs: string[] | string | undefined): string[] => {
  if (!Array.isArray(strs)) { 
    strs = strs? [ strs ]: [];
  }
  return strs.map(v => v.split(/\\r\\n|\\r|\\n/)).flat();
};

export const ln2br = (str: string): string => { 
  return (str || "")
    .replace(/<br\s*\/?>\n?/gmi, "<br />")
    .replace(/\\n/gm, "<br />")
    .replace(/\n/gm, "<br />");
};

export const minsToMS = (mins: number): number => {
  return mins * 60 * 1000;
};

export const secsToMS = (secs: number): number => {
  return secs * 1000;
};
