import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NavButton } from "../Buttons";
import { appColors, appFonts, useAppTheme } from "../../theme";
import Aem from "../../lib/aem/components/Aem";
import clsx from "clsx";

const useStyle = makeStyles({
  root: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  topHeading: {
    minHeight: "84px",
    padding: "16px",
    fontFamily: appFonts.medium,
    fontSize: "22px",
    lineHeight: "40px",
    backgroundColor: "rgba(0,0,0,0.3)",
  },

  cancelButton: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    fontSize: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
    },
  },

  imagePreview: {
    marginTop: "3%",
    marginLeft: "8%",
    maxHeight: "85%",
    maxWidth: "85%",
    outline: "1px solid white",
    outlineOffset: "5px"
  },

  retakeButtonSection: {
    marginTop: "10%",
    marginLeft: "10%",
    display: "flex"
  },

  retakeButton: {
    width: "150px",
    height: "45px",
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    fontSize: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.black,     
    "&.MuiButton-outlined": {
      textTransform: "initial",
      background: appColors.white,
    },    
  },

  saveButton: {
    width: "150px",
    height: "45px",
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    fontSize: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.black,
    backgroundColor: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      background: appColors.white,
    },
    marginLeft: "20%"
  }
});

interface PhotoRetakeScreenProps {
  title: string;
  cancelLabel?: string;
  orientation: string;
  imageSrc: string;
  onChangeShowCaptureScreen: (val: boolean) => void;
  onChangeShowRetakeScreen: (val: boolean) => void;
  onSaveCapturedPhoto: (val: boolean) => void;
  onCancel: () => void;
}

const PhotoRetakeScreen: React.FC<PhotoRetakeScreenProps> = (props: PhotoRetakeScreenProps) => {
  const classes = useStyle();
  const theme = useAppTheme();
   
  const retakePhoto = () => {
    props.onChangeShowCaptureScreen(true);
    props.onChangeShowRetakeScreen(false);
  };
  const savePhoto = () => {
    props.onSaveCapturedPhoto(true);
    props.onChangeShowCaptureScreen(false);
    props.onChangeShowRetakeScreen(false);
  };
  const cancelRetakePhoto = () => {
    props.onCancel();
  };

  let cancelLabelStr: string | undefined = props.cancelLabel;
  if (cancelLabelStr === undefined) {
    cancelLabelStr = Aem.get(
      "ACTION_CANCELBUTTON_TEXT_1", 
      "Cancel"
    );
  }

  const navRetakePhotoButtonLabel: string = Aem.get(
    "ACTION_RETAKEPHOTOBUTTON_TEXT_1",
    "Retake photo"
  );

  const navSaveButtonLabel: string = Aem.get(
    "ACTION_SAVEBUTTON_TEXT_1",
    "Save"
  );
  
  return (
    <Grid
      container
      direction="column"
      className={clsx(classes.root, theme.nowrap)}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={clsx(classes.topHeading, theme.navSection, theme.nowrap)}
      >
        <Grid item>{props.title}</Grid>
        <Grid item>
          <NavButton
            className={classes.cancelButton}
            label={cancelLabelStr}
            size="medium"
            variant="outlined"
            fullWidth={false}
            trackName="cancel"
            trackLocation="overlay"
            onClick={cancelRetakePhoto}
          />
        </Grid>
      </Grid>

      <div>
        <Grid>
          <img
            src={props.imageSrc}
            className={classes.imagePreview}
            alt={`${props.orientation} of card`}
          ></img>
        </Grid>
        <Grid item className={classes.retakeButtonSection}>
          <NavButton
            label={navRetakePhotoButtonLabel}
            size="large"
            variant="outlined"
            fullWidth={false}
            className={classes.retakeButton}
            trackName="retake photo"
            trackLocation="body"
            onClick={retakePhoto}
          />
          <NavButton
            label={navSaveButtonLabel}
            size="large"
            variant="outlined"
            fullWidth={false}
            className={classes.saveButton}
            trackName="save"
            trackLocation="body"
            onClick={savePhoto}
          />
        </Grid>
      </div>
    </Grid>
  )
}

export default PhotoRetakeScreen;