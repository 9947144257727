import axios from "../interceptors/interceptors";
import { AxiosRequestConfig } from "axios";
import { ConfirmAppointmentsData } from "../lib/save-state/confirmAppointmentData";
import { CancelAppointmentData } from "../lib/save-state/cancelAppointmentData";
import { SmsModelData } from "../lib/save-state/smsModelData";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import config from "../config";


const handleError = (err: any) => {
  return HandleApiErrorMiddleware("UpdatePSMAPI-UpdateConfirmOrCancel", err);
};

export const UpdateConfirmAppointmentAPI = async (confirmAppointmentData: ConfirmAppointmentsData) => {
  let url: string = config.updatePMSApi20.url + "confirmAppointments";
  let data = JSON.stringify(confirmAppointmentData);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(req)
    .then((res) => !!res)
    .catch(handleError);
};

export const UpdateCancelAppointmentAPI = async (cancelAppointmentData: CancelAppointmentData) => {
  let url: string = config.updatePMSApi20.url + "cancelAppointment";
  let data = JSON.stringify(cancelAppointmentData);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(req)
    .then((res) => !!res)
    .catch(handleError);
};

export const SendSMSMessageAPI = async (smsModelData: SmsModelData) => {
  let apiCfg = config.smsApi20;
  let url: string = apiCfg.url + "sendMessage";
  if (apiCfg.code) { 
    url += '?code=' + apiCfg.code;
  }
  let data = JSON.stringify(smsModelData);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(req)
    .then((res) => !!res)
    .catch(handleError);
};
