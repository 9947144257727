import defaultsDeep from "lodash/defaultsDeep";
import { InactivityTimeoutManager } from "./lib/inactivity-timeout";
import { secsToMS } from "./utility/utilityFunctions";
import config from "./config";


const _lastProps = {
  isPublicDevice: false,
};


export const getInactivityTimeoutConfig = (isPublicDevice: boolean): any => {
  // use default unless it is a public device
  let timeoutCfg = { ...config.timeouts?.default };
  if (isPublicDevice) { 
    // use the kiosk timeout config if we are in public device mode
    timeoutCfg = defaultsDeep({}, config.timeouts?.kiosk, timeoutCfg);
  }
  return timeoutCfg;
};

// create a singleton to be used within the application
let timeoutCfg = getInactivityTimeoutConfig(false);
let timeoutOpts = { 
  timeoutTimeMS: secsToMS(timeoutCfg.timeoutSecs),
  warningTimeMS: secsToMS(timeoutCfg.warningSecs),
  autoDisableOnTimeout: true,
  verbose: false,
};
export const appInactivityTimer = new InactivityTimeoutManager(timeoutOpts);


export const invalidateAppInactivityTimerTimeouts = (isPublicDevice: boolean): void => {
  if (_lastProps.isPublicDevice !== isPublicDevice) {
    _lastProps.isPublicDevice = isPublicDevice;
    _updateAppInactivityTimerTimeouts();
  }
};

const _updateAppInactivityTimerTimeouts = () => {
  let timeoutCfg = getInactivityTimeoutConfig(_lastProps.isPublicDevice);
  appInactivityTimer.timeoutTimeMS = secsToMS(timeoutCfg.timeoutSecs);
  appInactivityTimer.warningTimeMS = secsToMS(timeoutCfg.warningSecs);

  // stop and restart the timers so the new values take effect.
  // this will essentially restart the timer
  // if any flags have already triggered, 
  // then we do not want to clear them, so do not call reset.
  if (appInactivityTimer.started) {
    appInactivityTimer.stop();
    appInactivityTimer.start();
  }
};

