import { tracker } from "../appTracker";
import { Aem } from "../lib/aem";


let _callbackId: number = 1;
if (!window.cachedCallbacks) { 
  window.cachedCallbacks = {};
}

if (!window.linkTracker) {
  window.linkTracker = function (trackName, trackLocation) {
    try {
      if (!trackName) { trackName = "link"; }
      if (!trackLocation) { trackLocation = "body"; }
      tracker.externalLink(trackName, trackLocation);
    } catch (ex) { }
  };
}

if (!window.clickPdfDialogLinkTracker) {
  window.clickPdfDialogLinkTracker = function (callbackId, trackName, trackLocation) {
    try {
      if (!trackName) { trackName = "link"; }
      if (!trackLocation) { trackLocation = "body"; }
      tracker.externalLink(trackName, trackLocation);
    } catch (ex) { }
    
    try {
      let callbackItem: any = window.cachedCallbacks[callbackId];
      delete window.cachedCallbacks[callbackId];
      if (callbackItem && callbackItem.cb && typeof callbackItem.cb === 'function') {
        callbackItem.cb(callbackItem.args);
      }
    } catch (ex) { }
  };
}


const _getScreenReaderTextSpan = (): string => {
  let screenReaderHintText: string = Aem.get("ACCESSIBILITY_EXTLINK_TEXT_1", "(Opens a new window)");
  return `<span class="sr-only">${screenReaderHintText}</span>`;
};

export const OpenExternalLink = (
  url: string,
  trackName: string = "link",
  trackLocation: string = "body",
  target: string = "_blank",
  features: any = undefined,
) => {
  tracker.externalLink(trackName, trackLocation);
  window.open(url, target, features);
};

export const CreateExternalUrlLinkStr = (
  url: string,
  label: string | undefined = undefined,
  target: string = "_blank",
  trackName: string = "link",
  trackLocation: string = "body",
): string => {
  if (label === undefined) { label = url; }

  let screenReaderSpan: string = _getScreenReaderTextSpan();
  return `<a class="ext-link" href="${url}" target="${target}" onclick="linkTracker('${trackName}', '${trackLocation}')" rel="noopener">${label}${screenReaderSpan}</a>`;
};

export const CreatePdfDialogLinkStr = (
  functionName: Function,
  label: string | undefined = undefined,
  pdfLink: string | undefined = undefined,
  trackName: string = "link",
  trackLocation: string = "body",
): string => {
  let callbackId: number = _callbackId++;
  window.cachedCallbacks[callbackId] = { cb: functionName, args: { label, pdfLink } };

  let screenReaderSpan: string = _getScreenReaderTextSpan();
  return `<a class="ext-link" href="#" onclick="clickPdfDialogLinkTracker('${callbackId}','${label}', '${pdfLink}','${trackName}', '${trackLocation}')">${label}${screenReaderSpan}</a>`;
};
