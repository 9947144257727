import { AemConstants } from "../ConstantTokens";
import { titleCase } from "../../../utility/utilityFunctions";
import { collectAemTokensData } from "../../../lib/save-state/collectAemTokensData";


export const TransformAemTokenListOrItem = (
  messageInputs: string[] | string | null | undefined, 
  data: any = undefined
): string[] | string => {
  let retval: string[] | string = "";
  if (Array.isArray(messageInputs)) { 
    retval = TransformAemTokenList(messageInputs, data);
  } else {
    retval = TransformAemTokens(messageInputs, data);
  }
  return retval;
};

export const TransformAemTokenList = (
  messageInputs: string[] | null | undefined, 
  data: any = undefined
): string[] => {
  if (messageInputs?.length === 0) { 
    return [];
  }  
  if (data === undefined) {
    data = collectAemTokensData();
  }
  return messageInputs?.map(v => _transformAemTokensHelper(v, data)) as string[];
};

export const TransformAemTokens = (
  messageInput: string | null | undefined, 
  data: any = undefined
): string => {
  if (!messageInput) { 
    return "";
  }
  if (data === undefined) {
    data = collectAemTokensData();
  }
  return _transformAemTokensHelper(messageInput, data);
}

const _transformAemTokensHelper = (
  messageInput: string | null | undefined, 
  data: any
): string => {
  if (!messageInput) { 
    return "";
  }

  let message = messageInput as string;
  const { patient_first_name, location_phone, location_address, location_name, appt_date, appt_time, appt_date_time, provider_name } = (data || {});
   
  AemConstants.CONSTANTS_REPLACE.forEach(element => {
    switch (element) {
      case AemConstants.PATIENT_FIRST_NAME:
        message = message.replace(element, patient_first_name);
        break;
      case AemConstants.LOCATION_NAME:
        message = message.replace(element, titleCase(location_name));
        break;
      case AemConstants.LOCATION_PHONE:
        message = message.replace(element, location_phone);
        break;
      case AemConstants.LOCATION_ADDRESS:
        message = message.replace(element, titleCase(location_address));
        break;
      case AemConstants.VISIT_DATE:
        message = message.replace(element, appt_date);
        break;
      case AemConstants.VISIT_TIME:
        message = message.replace(element, appt_time);
        break;
      case AemConstants.VISIT_DATE_TIME:
        message =  message.replace(element, appt_date_time);
        break;
      case AemConstants.PROVIDER_FULL_NAME:
        message = message.replace(element, provider_name);
        break;
      case AemConstants.TERMS_LINK:
      case AemConstants.PRIVACY_LINK:
        // NOTE: special case. do not replace these links.  
        // this needs to be controlled by the client view dynamically.
        break;
      default:
        break;
    }
  });

  return message;
};
