export enum AemContentTypes {
  auto = "auto",
  plainText = "plainText",
  htmlText = "htmlText",
  imageUrl = "imageUrl",
  imageData = "imageData",
}

export enum AemPersonaTypes {
  patient = "patient",                   // same as self
  representative = "representative",
}

export enum AemCategoryVisitTypes {
  inperson = "inperson",
  phone = "phone",
  video = "video",
}

export enum AemLanguageCodes {
  eng = "eng",
  spa = "spa",
}

export const AemDebugFlags = {
  error: "error",
  warn: "warn",
  success: "success",
};

export interface IAemDebugFlags {
  error: boolean;
  warn: boolean;
  success: boolean;
}

export interface IAemLoader {
  debugMode: boolean;
  load(cdoShortName: string, languageCode: string): Promise<any>;
}

export interface AemKVPair {
  Key: string;
  Value: string;
}

export type AemGetOptions = {
  persona?: AemPersonaTypes | boolean;            // if true, then use the cached persona value
  categoryVisitType?: AemCategoryVisitTypes | boolean;
  transformTokens?: boolean;
  _resolved?: boolean;
};

export const DefaultAemGetOptions: AemGetOptions = {
  persona: true,
  categoryVisitType: true,
  transformTokens: true,
};

export const CreateAemGetOptions = (opts: any = undefined) => { 
  return { ...DefaultAemGetOptions, ...opts };
};

export const parseAemPersonaType = (persona: string): AemPersonaTypes => {
  let retval: AemPersonaTypes = AemPersonaTypes.patient;
  if ((persona || "").match(/^(representative|guardian)/i)) {
    retval = AemPersonaTypes.representative;
  }
  return retval;
};

export const parseAemCategoryVisitType = (categoryVisitType: string): AemCategoryVisitTypes => {
  let retval: AemCategoryVisitTypes = AemCategoryVisitTypes.inperson;
  if (categoryVisitType) { 
    if (categoryVisitType.match(/phone/i)) {
      retval = AemCategoryVisitTypes.phone;
    } else if (categoryVisitType.match(/video/i)) {
      retval = AemCategoryVisitTypes.video;
    }
  }
  return retval;
};

