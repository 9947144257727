
import React from "react";

export const promiseDelay = async (delayMS: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delayMS);
  });
};

type Callback = ()=>void;

export class TimeoutHelper {

  static InitTimerRefOnce = (ref: React.MutableRefObject<TimeoutHelper|undefined>) => {
    if (ref && !ref.current) {
      ref.current = new TimeoutHelper();
    }
  }

  id: NodeJS.Timeout|undefined = undefined;
  active: boolean = true;

  start(cb: Callback, delayMS: number = 0): void {
    if (!this.active) { return; }

    this.clear();
    if (delayMS > 0) {
      this.id = setTimeout(() => {
        this.id = undefined;
        cb();
      }, delayMS);
    } else {
      cb();
    }
  }

  clear(): void {
    if (!this.active) { return; }

    if (this.id) { 
      clearTimeout(this.id);
      this.id = undefined;
    }
  }

  dispose(): void {
    this.clear();
    this.active = false;
  }

};

