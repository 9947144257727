import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { appInactivityTimer } from "../../appInactivityTimer";
import { appColors, appFonts } from "../../theme";
import { NavButton } from "../Buttons";
import { ErrorPopupProps } from "./ErrorPopupProvider";
import Aem from "../../lib/aem/components/Aem";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: "355px",
    minHeight: "385px",
    padding: "24px",
    borderRadius: "16px",
    backgroundColor: appColors.darkGray1,
    color: appColors.white,
    justifyContent: "center",
  },
  title: {
    fontFamily: appFonts.medium,
    fontSize: "22px",
    textAlign: "center",
    marginBottom: "20px",
  },
  description: {
    fontFamily: appFonts.regular,
    fontSize: "18px",
    textAlign: "center",
    marginBottom: "45px",
  },
  errorIcon: {
    fontSize: "72px",
    color: appColors.darkError,
    marginBottom: "20px",
  },
  button: {
    height: "60px",
    width: "100%",
  },
});

export const SessionExpiringErrorPopup: React.FC<ErrorPopupProps> = (props: ErrorPopupProps) => {
  const classes = useStyles();
  const { ariaTitleId, onClose } = props;

  const handleClick = () => {
    // restart session inactivity timer if they click on the button
    appInactivityTimer.reset();
    if (onClose) { onClose(); }
  };
  
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.root}
    >
      <ErrorOutlinedIcon className={classes.errorIcon} />
      <Grid item id={ariaTitleId} className={classes.title}>
        <Aem cid="BODY_ERROR_POPUP_SESSIONEXPIRING_1">
          Session Expiring
        </Aem>
      </Grid>
      <Grid item className={classes.description}>
        <Aem cid="BODY_ERROR_POPUP_SESSIONEXPIRING_2">
          Your session is about to expire due to inactivity. If you do not take any action, your session will end and your work will not be saved.
        </Aem>
      </Grid>
      <Grid item>
        <NavButton
          className={classes.button}
          label={<Aem cid="ACTION_CONTINUEWORKINGBUTTON_TEXT_1">Continue Working</Aem>}
          size="large"
          variant="outlined"
          fullWidth={true}
          trackName="continue working"
          trackLocation="popup"
          onClick={handleClick}
        />
      </Grid>
    </Grid>
  );
};

export default SessionExpiringErrorPopup;
