/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slide,
  Dialog,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TransitionProps } from "@mui/material/transitions";
import { AppButton, BackButton, NavButton } from "../../../Components/Buttons";
import CustomTextField from "../../../Components/CustomTextField";
import CustomPhoneField from "../../../Components/CustomPhoneField";
import CustomCheckbox from "../../../Components/CustomCheckbox";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  updateEmail,
  updateHomePhone,
  updateCellPhone,
  updateWorkPhone,
  updateWorkPhoneExtn,
  updatePreferredPhoneType,
  updatePreferredPhoneTypeCode,
  updateEmergencyContactInfo,
  updateConsentToEmail,
} from "../../../features/patient/patientSlice";
import {
  appColors,
  appFonts,
  useAppTheme,
  appStyles,
  appDialogTransparentPaperProps,
} from "../../../theme";
import { filterEmailInput, filterPhoneExtnInput, filterNamePartInput, filterPhoneInput } from "../../../utility/inputFilterFunctions";
import ProgressStepper from "../../../Components/ProgressStepper";
import Header from "../../../Components/Header";
import {
  gotoNextTaskStep,  
  gotoPendingTaskStep,
  gotoPrevTaskStep,
  setTaskTransitionPending,
} from "../../../features/task/taskSlice";
import { UpdatePatientDemographicsAPI } from "../../../api/UpdateFhirAPI";
import {
  joinFullName,
  convertNamePartsIntoFhirNameParts,
  titleCase,
  joinWorkPhoneWithExtn,
} from "../../../utility/utilityFunctions";
import { AccAutoFillTokens, AriaLive } from "../../../data/accessibility/AccessibilityData";
import Aem, { AemContentTypes } from "../../../lib/aem/components/Aem";
import { EmergencyRelationshipsList } from "../../../data/relationshipsData";
import FormErrorBanner from "../../../Components/Errors/FormErrorBanner";
import { AemKVPair } from "../../../lib/aem";
import { tracker } from "../../../appTracker";


const useStyle = makeStyles({
  root: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  headerTitle: {
    marginTop: "0px",
  },
  navContent: {
    flexWrap: "nowrap",
  },
  mainContent: {
    position: "relative",
    height: "100%",
    padding: "30px 16px",
    flexWrap: "nowrap",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  sectionTitle: {
    margin: "32px 0 8px 0",
    padding: "0",
    fontFamily: appFonts.bold,
    fontSize: "18px",
  },
  inputBox: {
    width: "100%",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    minHeight: "60px",
    marginBottom: "8px",
  },
  phoneHeading: {
    marginTop: "30px",
    marginBottom: "9px",
    fontStyle: appFonts.bold,
    fontSize: "18px",
    fontWeight: "bold",
  },
  phoneHeadingError: {
    marginTop: "30px",
    marginBottom: "9px",
    fontStyle: appFonts.bold,
    fontSize: "18px",
    color: appColors.darkError,
    fontWeight: "bold",
  },
  workPhone: {
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
    width: "130px"
  },
  workPhoneExtnBox: {
    width: "70px",
    marginLeft: "8px",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  errorBox: {
    color: appColors.danger,
    "& .MuiFilledInput-input": {
      color: appColors.danger,
    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      // color: appColors.danger,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: appFonts.medium,
      fontSize: "14px",
      color: appColors.danger,
    },
    "& .Mui-error.Mui-error.MuiInputBase-fullWidth.MuiInputBase-formControl": {
      borderColor: appColors.danger,
    },
  },
  errorNoNumber: {
    color: appColors.darkError,
    "& .MuiFilledInput-input": {
      color: appColors.darkError,
    },
    "& .MuiInputLabel-root.Mui-error": {
      color: appColors.darkError,
    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      // color: appColors.danger,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: appFonts.medium,
      fontSize: "14px",
      color: appColors.darkError,
    },
    "& .Mui-error.Mui-error.MuiInputBase-fullWidth.MuiInputBase-formControl": {
      borderColor: appColors.darkError,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: appColors.darkError,
    },
  },
  errorMsg: {
    minHeight: "28px",
    backgroundColor: "rgba(240, 89, 115,0.2)",
    marginBottom: "7px",
  },
  errorInner: {
    fontWeight: "bold",
    color: "#FFFFFF",
    fontFamily: appFonts.bold,
    paddingLeft: "10px",
    paddingTop: "8px",
    paddingBottom: "6px",
    fontSize: "14px",
    // opacity: 1
  },
  sectionContent: {
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
    width: "auto",
  },
  sectionAction: {
    display: "flex",
    flexDirection: "row",
    flex: "60px 1 auto",
  },
  radioGroup: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    marginTop: "30px",
  },
  radioGroupLabel: {
    fontFamily: appFonts.medium,
    fontSize: "14px",
    color: appColors.whiteA07,
  },
  radioArea: {
    minWidth: "70px",
    marginLeft: "8px",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  radioButton: {
    padding: "8px 12px",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    color: appColors.white,
  },
  radioChecked: {
    color: appColors.white,
  },
  checkboxFormLabel: {
    padding: "0 8px",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    color: appColors.whiteA07,
  },
  overlayScrim: {
    position: "fixed",
    height: "100%",
    backgroundColor: appColors.blackScrim,
    overflow: "auto",
  },
  overlayHeader: {
    position: "relative",
    marginTop: "30px",
    marginBottom: "5px",
  },
  overlayHeading: {
    fontFamily: appFonts.bold,
    color: appColors.white,
    paddingLeft: "16px",
    fontSize: "20px",
  },
  searchIcon: {
    width: "22px",
    height: "22px",
    margin: "10px 22px 0 22px",
    color: appColors.white,
  },
  searchInputArea: {
    width: "100%",
  },
  searchInputTextField: {
    width: "100%",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    color: appColors.white,
  },
  searchInputInput: {
    color: appColors.white,
    ...appStyles.inputUnderlineBorder,

    "&$root": {
      color: appStyles.white,
    },
    "&.MuiInputBase-root": {
      color: appStyles.white,
    },
  },
  cancelButtonContainer: {
    margin: "0 15px",
    justifyContent: "center",
  },
  cancelButton: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    minWidth: "80px",
    minHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
  },
  choicesBox: {
    maxHheight: "100%",
    paddingLeft: "60px",
    paddingBottom: "20px",
  },
  choicesRow: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "49px",
    padding: "5px 16px",
    color: appColors.white,
    fontFamily: appFonts.semibold,
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.3)",
    },
  },
  pgFunc: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  headerText: {
    marginTop: "24px",
  },
});

interface EmergencyContactInfo {
  firstName: string;
  lastName: string;
  fullName: string;
  relationship: string;
  homePhone: string; // NOTE: home phone does not have a field, but preserve it if it already exists
  cellPhone: string; // NOTE: cell phone is used as the primary phone for the emergency contact
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReviewEmailScreen: React.FC = (props) => {
  const classes = useStyle();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();

  const consentInputId = useId();
  const rbPhoneGroupInputId = useId();
  const rbCellPhoneInputId = useId();
  const rbHomePhoneInputId = useId();
  const rbWorkPhoneInputId = useId();
  const dialogTitleId = useId();

  const fullScreen = true;
  const [overlay, setOverlay] = useState(false);
  const [searchRelationship, setSearchRelationship] = useState("");

  const patientId: string = useAppSelector((state) => state.patient.patientId);

  const defaultPreferredPhoneType: string = useAppSelector((state) => state.patient?.preferredPhoneType) || "cellPhone";
  const [preferredPhoneType, setPreferredPhoneType] = useState<string>(defaultPreferredPhoneType);

  const prefPhoneTypeOptions: AemKVPair[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_CONTACT_PREF_PHONE_TYPE_CODES",
    "BODY_DEMOGRAPHICS_PATIENT_CONTACT_PREF_PHONE_TYPE_LIST",
    []
  );

  const emailConsentOptions: AemKVPair[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_CONTACT_EMAIL_CONSENT_CODES",
    "BODY_DEMOGRAPHICS_PATIENT_CONTACT_EMAIL_CONSENT_LIST",
    []
  );

  const [formError, setFormError] = useState(false);
  const [preferredError, setPreferredError] = useState(false);

  const defaultHomePhone: string = useAppSelector((state) => state.patient?.homePhone) || "";
  const [homePhone, setHomePhone] = useState<string>(defaultHomePhone);
  const [homePhoneError, setHomePhoneError] = useState(false);

  const defaultWorkPhone: string = useAppSelector((state) => state.patient?.workPhone) || "";
  const [workPhone, setWorkPhone] = useState<string>(defaultWorkPhone);
  const [workPhoneError, setWorkPhoneError] = useState(false);

  const defaultWorkPhoneExtn: string = useAppSelector((state) => state.patient?.workPhoneExtn) || "";
  const [workPhoneExtn, setWorkPhoneExtn] = useState<string>(defaultWorkPhoneExtn);
  const [workPhoneExtnError, setWorkPhoneExtnError] = useState(false);

  // NOTE: cell phone is not editable
  const defaultCellPhone: string = useAppSelector((state) => state.patient?.cellPhone) || "";
  const cellPhone: string = defaultCellPhone;
  const [cellPhoneError, setCellPhoneError] = useState(false);

  const defaultEmail: string = useAppSelector((state) => state.patient.email) || "";
  const [email, setEmail] = useState<string>(defaultEmail);
  const [emailError, setEmailError] = useState(false);

  const defaultConsentChecked: string = useAppSelector((state) => state.patient.consentToEmail);
  const aemEmailConsentValue: string = Aem.parseEmailListValue(
    emailConsentOptions,
    defaultConsentChecked
  );

  const [consentChecked, setConsentChecked] = useState<boolean>(
    aemEmailConsentValue === "true"
  );

  const defaultEmergencyContact: any =
    useAppSelector((state) => state.patient?.emergencyContact) || {};
  const [emergencyInfo, setEmergencyInfo] = useState<EmergencyContactInfo>({
    firstName: defaultEmergencyContact.first_name || "",
    lastName: defaultEmergencyContact.last_name || "",
    fullName: defaultEmergencyContact.full_name || "",
    relationship: defaultEmergencyContact.relationship || "",
    homePhone: defaultEmergencyContact.homePhone || "",
    cellPhone: defaultEmergencyContact.cellPhone || "",
  });
  const updateEmergencyInfo = (key: string, value: string) => {
    let newInfo: EmergencyContactInfo = { ...emergencyInfo };
    newInfo[key] = value || "";
    if (key === "firstName" || key === "lastName") {
      newInfo.fullName = joinFullName(newInfo.firstName, newInfo.lastName);
    }
    setEmergencyInfo(newInfo);
  };

  const [emergencyCellPhoneError, setEmergencyCellPhoneError] = useState(false);

  const emerg_relationship_list: string[] = Aem.getList(
    "BODY_DEMOGRAPHICS_PATIENT_CONTACT_LIST_1",
    EmergencyRelationshipsList
  );

  const pendingNextRoute: string = useAppSelector(
    (state) => state.task.pendingNextRoute
  );
  const editMode: boolean = !!pendingNextRoute;
  const navButtonName: string = editMode ? "save" : "next";
  const navButtonLabel: string = editMode
    ? Aem.get("ACTION_SAVEBUTTON_TEXT_1", "Save")
    : Aem.get("ACTION_NEXTBUTTON_TEXT_1", "Next");

  const phoneErrorMsg: string = Aem.get(
    "BODY_DEMOGRAPHICS_PHONE_ERROR_3",
    "Please enter a valid phone number"
  );

  const emailErrorMsg: string = Aem.get(
    "BODY_DEMOGRAPHICS_EMAIL_ERROR",
    "Please enter a valid email id"
  );

  const consentMsg: string = Aem.get(
    "BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_8",
    "I consent to receive emails from Optum."
  );

  const preferredPhoneTypeLabel: string = Aem.get(
    "BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_14",
    "Preferred"
  );
  const cellPhoneLabel: string = Aem.get(
    "BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_5",
    "Mobile Phone"
  );
  const homePhoneLabel: string = Aem.get(
    "BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_3",
    "Home Phone"
  );
  const workPhoneLabel: string = Aem.get(
    "BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_4",
    "Work Phone"
  );

  useEffect(() => {
    if (preferredPhoneType !== "cellPhone" && (
      (preferredPhoneType === "homePhone" && homePhone.length <= 0) || 
      (preferredPhoneType === "workPhone" && workPhone.length <= 0))
    ) {
      setPreferredPhoneType("cellPhone");
    }
  }, [preferredPhoneType, homePhone, workPhone]);

  // watch for form errors and send them tos the analytics tracker
  useEffect(() => {
    tracker.watchFormErrors([
      { field: "preferred", alert: "preferred invalid", value: preferredError },
      { field: "homePhone", alert: "home phone invalid", value: homePhoneError },
      { field: "workPhone", alert: "work phone invalid", value: workPhoneError },
      { field: "workPhoneExtn", alert: "work phone extn invalid", value: workPhoneExtnError },
      { field: "cellPhone", alert: "cell phone invalid", value: cellPhoneError },
      { field: "email", alert: "email invalid", value: emailError },
      { field: "emergPhone", alert: "emerg phone invalid", value: emergencyCellPhoneError },
    ]);
  }, [
    preferredError,
    homePhoneError,
    workPhoneError,
    workPhoneExtnError,
    cellPhoneError,
    emailError,
    emergencyCellPhoneError,
  ]);

  function validateEmail(email: string, required: boolean = true) {
    if (email === "" && consentChecked) {
      return false;
    }
    if (email === "" && !consentChecked) {
      return true;
    }

    if (!email && !required) {
      return true;
    }
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  }

  function validatePhone(phone: string, required: boolean = true) {
    let cleaned = ("" + phone).replace(/\D/g, "");
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (!match && !required) {
      return true;
    }
    // eslint-disable-next-line
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return re.test(phone);
  }

  function formatPhoneNumber(phoneNumberString: string) {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }
  
  function validateWorkPhoneExtn(phone: string, required: boolean = true) {
    if (!phone && !required) {
      return true;
    }
    const re = /^[x]?[0-9]{0,5}$/;
    return re.test(phone);
  }

  const validatePreferredPhone = (
    preferredPhoneType: string,
    cellPhoneValid: boolean,
    homePhoneValid: boolean,
    workPhoneValid: boolean
  ): boolean => {
    let preferredValid: boolean = false;
    switch (preferredPhoneType) {
      case "cellPhone":
        preferredValid = cellPhoneValid;
        break;
      case "homePhone":
        preferredValid = homePhoneValid;
        break;
      case "workPhone":
        preferredValid = workPhoneValid;
        break;
      default:
        break;
    }
    return preferredValid;
  };

  const validateAllFields = () => {
    let emailValid: boolean = validateEmail(email, true);
    let homePhoneValid: boolean = validatePhone(homePhone, false);
    let workPhoneValid: boolean = validatePhone(workPhone, false);
    let workPhoneExtnValid: boolean = validateWorkPhoneExtn(workPhoneExtn, false);
    let cellPhoneValid: boolean = validatePhone(cellPhone, false);
    let emergencyCellPhoneValid: boolean = validatePhone(
      emergencyInfo?.cellPhone,
      false
    );
    let preferredValid: boolean = validatePreferredPhone(
      preferredPhoneType,
      cellPhoneValid,
      homePhoneValid,
      workPhoneValid
    );
    
    let formValid: boolean = (
      emailValid &&
      homePhoneValid &&
      workPhoneValid &&
      workPhoneExtnValid &&
      cellPhoneValid &&
      emergencyCellPhoneValid &&
      preferredValid
    );

    setEmailError(!emailValid);
    setHomePhoneError(!homePhoneValid);
    setWorkPhoneError(!workPhoneValid);
    setWorkPhoneExtnError(!workPhoneExtnValid);
    setCellPhoneError(!cellPhoneValid);
    setEmergencyCellPhoneError(!emergencyCellPhoneValid);
    setPreferredError(!preferredValid);    
    setFormError(!formValid);

    return formValid;
  };

  const updateDetailsToAPI = async () => {
    const preferredPhoneTypeCode = Aem.parseListKVPairKey(
      prefPhoneTypeOptions,
      preferredPhoneType
    );
 
    dispatch(updateEmail(email));
    dispatch(updateConsentToEmail(consentChecked ? "A" : "D"));
    dispatch(updateHomePhone(homePhone));
    dispatch(updateCellPhone(cellPhone));
    dispatch(updateWorkPhone(workPhone));
    dispatch(updateWorkPhoneExtn(workPhoneExtn));
    dispatch(updatePreferredPhoneType(preferredPhoneType));
    dispatch(updatePreferredPhoneTypeCode(preferredPhoneTypeCode));
    dispatch(updateEmergencyContactInfo(emergencyInfo));

    let phoneRanks: string[] = ["homePhone", "workPhone", "cellPhone"];
    phoneRanks.sort((a: string, b: string) => {
      if (a === preferredPhoneType) {
        return -1;
      }
      if (b === preferredPhoneType) {
        return +1;
      }
      return 0;
    });

    // update API state
    let data: any = {
      patientId,
    };

    data.telecom = [
      {
        system: "Phone",
        use: "Home",
        value: homePhone,
        rank: phoneRanks.indexOf("homePhone") + 1,
      },
      {
        system: "Phone",
        use: "Work",
        value: joinWorkPhoneWithExtn(workPhone, workPhoneExtn),
        rank: phoneRanks.indexOf("workPhone") + 1,
      },
      {
        system: "Phone",
        use: "Mobile",
        value: cellPhone,
        rank: phoneRanks.indexOf("cellPhone") + 1,
      },
      {
        system: "Email",
        use: "Home",
        value: titleCase(email),
        consentToContact: consentChecked ? "A" : "D",
        rank: 4,
      },
    ];

    data.emergencyContact = {};
    data.emergencyContact.name = convertNamePartsIntoFhirNameParts(
      titleCase(emergencyInfo.firstName),
      titleCase(emergencyInfo.lastName)
    );
    data.emergencyContact.relationship = emergencyInfo.relationship;
    data.emergencyContact.contactInformation = [
      {
        system: "Phone",
        value: emergencyInfo.homePhone,
        use: "Home",
      },
      {
        system: "Phone",
        value: emergencyInfo.cellPhone,
        use: "Mobile",
      },
    ];

    await UpdatePatientDemographicsAPI(data);
  };

  const filterOptions = (list: string[]) => {
    if (!searchRelationship || searchRelationship.length === 0) {
      return list;
    }
    const searchTextLowered: string = searchRelationship.toLowerCase();
    let newList = (list || []).filter((option) => {
      return (option || "").toLowerCase().includes(searchTextLowered);
    });
    return newList;
  };

  const handleNextClick = async () => {
    if (validateAllFields()) {
      dispatch(setTaskTransitionPending(true));
      await updateDetailsToAPI();
      dispatch(gotoNextTaskStep());
    }
  };

  // overlay for relationship list
  const renderOverlay = () => {
    let filteredOptions = filterOptions(emerg_relationship_list);
    return (
      <Grid
        container
        direction="column"
        className={clsx(classes.overlayScrim, theme.nowrap)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={clsx(classes.overlayHeader, theme.nowrap)}
        >
          <Grid item id={dialogTitleId} className={classes.overlayHeading}>
            <Aem cid="BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_11">Relationship to Patient</Aem>
          </Grid>
          <Grid item className={classes.cancelButtonContainer}>
            <AppButton
              variant="outlined"
              disableElevation
              className={classes.cancelButton}
              trackName="cancel"
              trackLocation="overlay"
              onClick={() => {
                setOverlay(false);
                setSearchRelationship("");
              }}
            >
              <Aem cid="ACTION_CANCELBUTTON_TEXT_1">Cancel</Aem>
            </AppButton>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          className={clsx(classes.choicesBox, theme.nowrap)}
        >
          {filteredOptions.map((option: string, idx: number) => {
            return (
              <Grid
                key={idx}
                item
                xs={12}
                className={classes.choicesRow}
                onClick={() => {
                  setOverlay(!overlay);
                  updateEmergencyInfo("relationship", option);
                  setSearchRelationship("");
                }}
              >
                {option}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        direction="column"
        className={clsx(classes.navContent, theme.navContent)}
      >
        <Grid
          container
          direction="column"
          className={clsx(theme.navHeader, theme.bgGreenGrad)}
          style={{
            marginBottom: "0px",
          }}
        >
          <Header showMenuButton={true} />

          {!editMode && <ProgressStepper step={1} totalSteps={4} />}

          <Grid container className={classes.pgFunc}>
            <Grid item>
              <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
                <Aem cid="HEADER_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_1">
                  Patient Contact Information
                </Aem>
              </h1>
            </Grid>
            <Grid item>
              {/* <DemoSVG/> */}
              <Aem
                cid="HEADER_DEMOGRAPHICS_PATIENT_CONTACT_LOGO_1"
                type={AemContentTypes.imageUrl}
              ></Aem>
            </Grid>
          </Grid>
        </Grid>
        {/* {error ? <FormErrorBanner aemId="BODY_PATIENTFORMSCONSENT_TEXT_5" /> : null} */}

        {formError && (
          <FormErrorBanner aemId="BODY_DEMOGRAPHICS_PHONE_ERROR_1" />
        )}

        <Grid container direction="column" className={theme.navSection}>
          <Grid item className={clsx(classes.headerText, theme.headerText)}>
            <Aem cid="BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_1">
              Please verify the patient's contact information. If it is
              incorrect, please update it.
            </Aem>
          </Grid>
          {preferredError && (
            <Grid item className={clsx(classes.errorMsg)} aria-live={AriaLive.polite}>
              <Grid item className={clsx(classes.errorInner)}>
                <Aem cid="BODY_DEMOGRAPHICS_PHONE_ERROR_2">
                  Please add at least one phone number.
                </Aem>
              </Grid>
            </Grid>
          )}
          {/* <FormErrorBanner aemId="BODY_DEMOGRAPHICS_PHONE_ERROR_2" className /> */}
          <RadioGroup
            id={rbPhoneGroupInputId}
            className={classes.radioGroup}
            aria-label={preferredPhoneTypeLabel}
            name="preferredPhoneType"
            value={preferredPhoneType}
            onChange={(e) => setPreferredPhoneType(e.target.value)}
          >
            <Grid container direction="column">
              <Grid container className={classes.section}>
                <Grid
                  item
                  justifyContent="center"
                  className={clsx(classes.inputBox, classes.sectionContent, {
                    [classes.errorBox]: cellPhoneError,
                    [classes.errorNoNumber]: preferredError,
                  })}
                >
                  <Grid
                    item
                    style={{
                      color: appColors.whiteA07,
                      fontSize: "12px",
                      fontFamily: appFonts.semibold,
                    }}
                  >
                    {cellPhoneLabel}
                  </Grid>
                  <Grid item style={{ fontSize: "17px" }}>
                    {formatPhoneNumber(cellPhone)}
                  </Grid>
                </Grid>
                <Grid item className={classes.sectionAction}>
                  <Grid
                    container
                    direction="column"
                    className={classes.radioArea}
                  >
                    <Grid item className={classes.radioGroupLabel}>
                      {preferredPhoneTypeLabel}
                    </Grid>
                    <Radio
                      id={rbCellPhoneInputId}
                      classes={{
                        root: classes.radioButton,
                        checked: classes.radioChecked,
                      }}
                      color="default"
                      value="cellPhone"                      
                      inputProps={{
                        "aria-label": cellPhoneLabel
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.section}>
                <Grid
                  item
                  className={clsx(classes.inputBox, classes.sectionContent, {
                    [classes.errorBox]: homePhoneError,
                    [classes.errorNoNumber]: preferredError,
                  })}
                >
                  <CustomPhoneField
                    error={homePhoneError || preferredError}
                    helperText={homePhoneError ? phoneErrorMsg : ""}
                    label={homePhoneLabel}
                    name="homePhone"
                    type="tel"
                    fullWidth={true}
                    variant="filled"
                    value={homePhone}
                    onChange={(e) => {
                      setHomePhone(filterPhoneInput(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item className={classes.sectionAction}>
                  <Grid
                    container
                    direction="column"
                    className={classes.radioArea}
                  >
                    <Grid item className={classes.radioGroupLabel}>
                      &nbsp;
                    </Grid>
                    <Radio
                      id={rbHomePhoneInputId}
                      classes={{
                        root: classes.radioButton,
                        checked: classes.radioChecked,
                      }}
                      color="default"
                      value="homePhone"
                      inputProps={{
                        "aria-label": homePhoneLabel
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className={classes.section}>
                <Grid
                  item
                  className={clsx(classes.inputBox, classes.workPhone, {
                    [classes.errorBox]: workPhoneError,
                    [classes.errorNoNumber]: preferredError,
                  })}
                >
                  <CustomPhoneField
                    error={workPhoneError || preferredError}
                    helperText={workPhoneError ? phoneErrorMsg : ""}
                    label={workPhoneLabel}
                    name="workPhone"
                    type="tel"
                    fullWidth={true}
                    variant="filled"
                    value={workPhone}
                    onChange={(e) => {
                      setWorkPhone(filterPhoneInput(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item className={classes.sectionAction}>
                  <Grid
                     container
                     direction="column"
                    className={clsx(classes.workPhoneExtnBox,{
                      [classes.errorBox]: workPhoneExtnError,
                      [classes.errorNoNumber]: preferredError,
                    })}
                  >
                    <CustomTextField
                      label={"Ext."}
                      type="text"
                      variant="filled"
                      value={workPhoneExtn}
                      autoComplete={AccAutoFillTokens.off}
                      onChange={(e) => {
                        setWorkPhoneExtnError(false);
                        setWorkPhoneExtn(filterPhoneExtnInput(e.target.value));
                      }}
                      onBlur={(e) =>{
                        if(!validateWorkPhoneExtn(workPhoneExtn,true)) {
                          setWorkPhoneExtnError(true);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item className={classes.sectionAction}>
                  <Grid
                    container
                    direction="column"
                    className={classes.radioArea}
                  >
                    <Grid item className={classes.radioGroupLabel}>
                      &nbsp;
                    </Grid>
                    <Radio
                      id={rbWorkPhoneInputId}
                      classes={{
                        root: classes.radioButton,
                        checked: classes.radioChecked,
                      }}
                      color="default"
                      value="workPhone"
                      inputProps={{
                        "aria-label": workPhoneLabel
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </RadioGroup>

          <Grid container className={classes.section}>
            <Grid
              item
              className={clsx(classes.inputBox, classes.sectionContent, {
                [classes.errorBox]: emailError,
              })}
            >
              <CustomTextField
                error={emailError}
                helperText={emailError ? emailErrorMsg : ""}
                label={Aem.get(
                  "BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_6",
                  "Email Address"
                )}
                name="email"
                type="email"
                fullWidth={true}
                variant="filled"
                value={email}
                autoComplete={AccAutoFillTokens.email}
                onChange={(e) => {
                  setEmailError(false);
                  setEmail(filterEmailInput(e.target.value));
                }}
                onBlur={(e) => {
                  if (!validateEmail(email, true)) {
                    setEmailError(true);
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.section}>
            <Grid item>
              <FormControlLabel
                className={classes.checkboxFormLabel}
                control={
                  <CustomCheckbox
                    checkboxProps={{
                      id: consentInputId,
                      checked: consentChecked,
                      required: true,
                      onChange: (e) => setConsentChecked(e.target.checked),
                    }}
                  />
                }
                label={consentMsg}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.sectionTitle}>
            <Aem cid="BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_9">
              Emergency Contact Information
            </Aem>
          </Grid>

          <Grid container className={classes.section}>
            <Grid
              item
              className={clsx(classes.inputBox, classes.sectionContent)}
            >
              <CustomTextField
                label={Aem.get(
                  "BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_10",
                  "Emergency Contact First Name"
                )}
                type="text"
                fullWidth={true}
                variant="filled"
                value={emergencyInfo.firstName}
                autoComplete={AccAutoFillTokens.off}
                onChange={(e) => {
                  updateEmergencyInfo("firstName", filterNamePartInput(e.target.value));
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.section}>
            <Grid
              item
              className={clsx(classes.inputBox, classes.sectionContent)}
            >
              <CustomTextField
                label={Aem.get(
                  "BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_15",
                  "Emergency Contact Last Name"
                )}
                type="text"
                fullWidth={true}
                variant="filled"
                value={emergencyInfo.lastName}
                onChange={(e) => {
                  updateEmergencyInfo("lastName", filterNamePartInput(e.target.value));
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.section}>
            <Grid
              item
              className={clsx(classes.inputBox, classes.sectionContent)}
            >
              <CustomTextField
                label={Aem.get(
                  "BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_11",
                  "Relationship to Patient"
                )}
                type="text"
                fullWidth={true}
                variant="filled"
                value={emergencyInfo.relationship}
                onClick={(e) => {
                  setOverlay(!overlay);
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.section}>
            <Grid
              item
              className={clsx(classes.inputBox, classes.sectionContent, {
                [classes.errorBox]: emergencyCellPhoneError,
              })}
            >
              <CustomPhoneField
                error={emergencyCellPhoneError}
                helperText={emergencyCellPhoneError ? phoneErrorMsg : ""}
                label={Aem.get(
                  "BODY_DEMOGRAPHICS_PATIENT_CONTACT_TEXT_13",
                  "Emergency Contact Phone"
                )}
                type="tel"
                fullWidth={true}
                variant="filled"
                value={emergencyInfo.cellPhone}
                onChange={(e) => {
                  updateEmergencyInfo("cellPhone", filterPhoneInput(e.target.value));
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        className={theme.navStickyFooter}
        style={{
          alignItems: "center",
        }}
      >
        <Grid item xs={3}>
          <BackButton
            trackName="prev"
            trackLocation="nav footer"
            onClick={async () => {
              if (editMode) {
                await dispatch(gotoPendingTaskStep("consent/review"));
              } else {
                await dispatch(gotoPrevTaskStep());
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <NavButton
            accentColor={"green"}
            label={navButtonLabel}
            fullWidth={true}
            trackName={navButtonName}
            trackLocation="nav footer"
            onClick={handleNextClick}
          />
        </Grid>
        <Dialog
          fullScreen={fullScreen}
          disableRestoreFocus={true}
          open={overlay}
          onClose={() => setOverlay(false)}
          TransitionComponent={Transition}
          aria-labelledby={dialogTitleId}
          PaperProps={appDialogTransparentPaperProps}
        >
          {renderOverlay()}
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default ReviewEmailScreen;
