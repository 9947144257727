import { AemCategoryVisitTypes } from "../../lib/aem/AemDefs";
import { Aem } from "../../lib/aem";


export const determineAppointmentCardAemContent = (
  aemCategoryVisitType: AemCategoryVisitTypes,
  confirmed: boolean,
  cancelled: boolean
) => {
  // logic to find the correct AEM values for various scenarios
  // based on categoryVisitType, confirmed, cancelled, or pending status

  // init values with default case of a pending In-Person office visit
  let visitTypeTitleAemId: string = "BODY_MULTI_APPOINTMENTS_TEXT_5";
  let visitTypeTitle: string = "Office Visit";
  
  let reminderTitleAemId: string = "BODY_MULTI_APPOINTMENTS_TEXT_14";
  let reminderTitle: string = "Office visit reminder";

  let reminderMessageAemId: string = "BODY_MULTI_APPOINTMENTS_TEXT_15";
  let reminderMessage: string = "Please arrive 15 minutes prior to your appointment time and check in with the receptionist upon arrival. For your safety and ours, masks are required at all times.";

  let showAddress: boolean = true;
  let showPhone: boolean = true;
  let showWebsiteUrl: boolean = false;

  switch (aemCategoryVisitType) {
    case AemCategoryVisitTypes.video: 
      if (confirmed) { 
        visitTypeTitleAemId = "BODY_MULTI_APPOINTMENTS_TEXT_29";
        visitTypeTitle = "Confirmed Virtual Visit";
      } else if (cancelled) {
        visitTypeTitleAemId = "BODY_MULTI_APPOINTMENTS_TEXT_30";
        visitTypeTitle = "Cancelled Virtual Visit";
      } else {
        visitTypeTitleAemId = "BODY_MULTI_APPOINTMENTS_TEXT_6";
        visitTypeTitle = "Virtual Visit";        
      }
      reminderTitleAemId = "BODY_MULTI_APPOINTMENTS_TEXT_16";
      reminderTitle = "Virtual visit reminder";
      reminderMessageAemId = "BODY_MULTI_APPOINTMENTS_TEXT_17";  
      reminderMessage = "When your clinician is ready to meet with you, you will receive a text message from a 5-digit number with a link to join the visit. Click on the link to be directed to the video visit. Based on the clinician’s schedule, it is possible the clinician will text you up to 30 minutes before or after your appointment time.";
      showAddress = false;
      showPhone = true;
      showWebsiteUrl = true;
      break;
    case AemCategoryVisitTypes.phone:
      if (confirmed) { 
        visitTypeTitleAemId = "BODY_MULTI_APPOINTMENTS_TEXT_31";
        visitTypeTitle = "Confirmed Phone Call";
      } else if (cancelled) {
        visitTypeTitleAemId = "BODY_MULTI_APPOINTMENTS_TEXT_32";
        visitTypeTitle = "Cancelled Phone Call";
      } else {
        visitTypeTitleAemId = "BODY_MULTI_APPOINTMENTS_TEXT_7";
        visitTypeTitle = "Phone Call";
      }
      reminderTitleAemId = "BODY_MULTI_APPOINTMENTS_TEXT_18";
      reminderTitle = "Phone call reminder";
      reminderMessageAemId = "BODY_MULTI_APPOINTMENTS_TEXT_19";  
      reminderMessage = "Your clinician will call you when it is time for your visit. Please note you may not recognize the phone number. Based on the clinician’s schedule, it is possible the clinician will text you up to 30 minutes before or after your appointment time.";
      showAddress = false;
      showPhone = true;
      showWebsiteUrl = false;
      break;
    case AemCategoryVisitTypes.inperson: 
    default:
      if (confirmed) { 
        visitTypeTitleAemId = "BODY_MULTI_APPOINTMENTS_TEXT_27";
        visitTypeTitle = "Confirmed Office Visit";
      } else if (cancelled) {
        visitTypeTitleAemId = "BODY_MULTI_APPOINTMENTS_TEXT_28";
        visitTypeTitle = "Cancelled Office Visit";
      } else {
        visitTypeTitleAemId = "BODY_MULTI_APPOINTMENTS_TEXT_5";
        visitTypeTitle = "Office Visit";
      }
      break;
  }

  // grab the AEM values
  let aemOpts = { categoryVisitType: aemCategoryVisitType };
  visitTypeTitle = Aem.get(visitTypeTitleAemId, visitTypeTitle, aemOpts);
  reminderTitle = Aem.get(reminderTitleAemId, reminderTitle, aemOpts);
  reminderMessage = Aem.get(reminderMessageAemId, reminderMessage, aemOpts);

  return {
    visitTypeTitle,
    reminderTitle,
    reminderMessage,
    showAddress,
    showPhone,
    showWebsiteUrl,
  };
};

