import axios from "../interceptors/interceptors";
import { AxiosRequestConfig } from "axios";
import { verificationRequestDataSchema } from "../lib/eligibility/verificationRequestDataSchema";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import config from "../config";


const handleError = (err: any) => {
  return HandleApiErrorMiddleware("CheckInsuranceEligibilityAPI", err);
};

export const CheckInsuranceEligibilityAPI = async (verificationRequestData: verificationRequestDataSchema) : Promise<any> => {
  let apiCfg = config.eligibilityApi;
  let url: string = apiCfg.url + "eligibility?code=" + apiCfg.code;
  
  let data = JSON.stringify(verificationRequestData);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(req)
    .then((res) => !!res)
    .catch((error) => {
      handleError(error);      
      return false;
    });
};
