

export interface SmsModelData {
  To: string[];
  Message: string;
  Tag: string;
};

export const InitializeSmsModelData = (to: string, message: string, tag: string): SmsModelData => {

  let toNum:string[] = [];
  toNum.push(to);

  return {
    To: toNum,
    Message: message,
    Tag: tag
  };
};
