import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useAppSelector } from "../app/hooks";
import { appFonts, useAppTheme } from "../theme";
import { ReactComponent as DoctorsSvg } from "../assets/illustration_doctors.svg";
import { AppointmentArrivalTimeRange } from "../data/apptData";
import { SingleApptState, hasConfirmedPreRegDoneAppointmentWithinTimeWindow } from "../features/appt/apptSlice";
import KioskStartOverPrompt from "../Components/Kiosk/KioskStartOverPrompt";
import Header from "../Components/Header";
import Aem from "../lib/aem/components/Aem";


const imageVerticalShift = 25;

const useStyles = makeStyles({
  root: {
    position: "relative",
    color: "#ffffff",
    width: "100%",
    height: "100%",
    fontFamily: appFonts.regular,
  },
  apptText: {
    marginTop: "24px",
    marginBottom: "30px",
    fontFamily: appFonts.bold,
    fontSize: "22px",
    textAlign: "center",
    padding: "0 36px",
  },
  graphicArea: {
    marginBottom: `-${imageVerticalShift}px`,
  },  
  graphic: {
    width: "100%",
    height: "100%",
    maxWidth: 372,
    maxHeight: 188,
    textAlign: "center",
    display: "inherit",
  },
  content: {
    marginTop: "55px", 
    padding: "0 16px 0 16px",
  },
  label1: {
    fontFamily: appFonts.bold,
    fontSize: "22px",
    textAlign: "center",
    marginBottom: "25px",
  },
  label2: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    textAlign: "center",
    marginBottom: "25px",
  },
});

interface KioskRegisteredFinishScreenProps {
}

const KioskRegisteredFinishScreen: React.FC<KioskRegisteredFinishScreenProps> = (props: KioskRegisteredFinishScreenProps) => {
  const classes = useStyles();
  const theme = useAppTheme();

  const isPublicDevice = useAppSelector<boolean>((state) => state.preferences.isPublicDevice);
  
  const allAppointmentItems: SingleApptState[] = useAppSelector<SingleApptState[]>((state) => state.appt.allAppointmentInfos);
  const hasApptWithinWindow = hasConfirmedPreRegDoneAppointmentWithinTimeWindow(
    allAppointmentItems, 
    AppointmentArrivalTimeRange.earlyTimeOffsetMins, 
    AppointmentArrivalTimeRange.lateTimeOffsetMins
  );
  
  const patientFirstName: string = useAppSelector((state) => state.patient.first_name);
  const patientNameStr = patientFirstName ? " " + patientFirstName : "";

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column">
        <Grid container direction="column" className={clsx(theme.navHeader, theme.bgBlueGrad)}>
          <Header center={true} showMenuButton={true} />
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={theme.textCenter}
          >
            <Grid item className={classes.graphicArea}>
              <DoctorsSvg className={classes.graphic} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="start"
          alignItems="center"
          className={clsx(theme.textCenter, classes.content)}
        >
          {hasApptWithinWindow && (
            <React.Fragment>
              <Grid item className={classes.label1}>
                <Aem cid="BODY_KIOSK_FINISH_TEXT_5">
                  Thank you, {patientNameStr}!
                </Aem>
              </Grid>

              <Grid item className={classes.label2}>
                <Aem cid="BODY_KIOSK_FINISH_TEXT_6">
                Your pre-registration is completed for this appointment. 
                  Please let us know that you're here.
                </Aem>
              </Grid>
            </React.Fragment>
          )}

          {!hasApptWithinWindow && (
            <React.Fragment>
              <Grid item className={classes.label1}>
                <Aem cid="BODY_KIOSK_FINISH_TEXT_1">
                  Thank you, {patientNameStr}!
                </Aem>
              </Grid>

              <Grid item className={classes.label2}>
                <Aem cid="BODY_KIOSK_FINISH_TEXT_2">
                  You've confirmed your appointments. 
                  Please notify the front desk that you've completed your 
                  pre-registration and have a seat in the lobby.
                </Aem>
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        {/* show the I am here button with no message */}
        {hasApptWithinWindow && (
          <KioskStartOverPrompt
            showMesssageText={false}
            showYesStartOverButton={false}
            showNoContinueButton={false}
            showIAmHereButton={true}
            checkArrivalStatus={true}
            autoHideAfterActions={true}
            enableSessionTimeoutCheck={true}
            defaultNoContinueRoute={""}
            defaultIAmHereRoute={""}
            arrivedNextRoute={"kioskArrivedFinish"}
          />
        )}

        {/* show the start over button with a message */}
        {isPublicDevice && (
          <KioskStartOverPrompt
            showMesssageText={true}
            showYesStartOverButton={true}
            showNoContinueButton={false}
            showIAmHereButton={false}
            checkArrivalStatus={false}
            autoHideAfterActions={false}
            enableSessionTimeoutCheck={!hasApptWithinWindow}
            defaultNoContinueRoute={""}
            defaultIAmHereRoute={""}
            arrivedNextRoute={"kioskArrivedFinish"}
          />
        )}

      </Grid>
    </Grid>
  );
};

export default KioskRegisteredFinishScreen;
