import axios from "../interceptors/interceptors";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import config from "../config";


const handleError = (err: any, suppressError: boolean = false) => {
  return HandleApiErrorMiddleware("UpdateArriveAppointmentAPI", err, suppressError);
};

export const UpdateArriveAppointmentAPI = async (appointmentId: string): Promise<any> => {
  let route: string = "ArriveAppointment";
  let apiCfg: any = config.updatePMSApi10;
  let url: string = apiCfg.url + route;
  if (apiCfg.code) { 
    url += "?code=" + apiCfg.code;
  }

  let params: any = {
    appointmentId,
    updateAncillaries: 1,
    updateResources: 1,
  };

  let headers: any = {
    "Content-Type": "application/json",
  };

  let req: any = {
    method: "POST",
    url: url,
    headers: headers,
    data: JSON.stringify(params),
  };

  return axios(req)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      handleError(error, true);
      // NOTE: return false on API error to let the user continue
      return false;
    });
};
