

// HTML aria live values
// default to polite for most cases
// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-live
export enum AriaLive {
  off = "off",
  polite = "polite",
  assertive = "assertive",
};

// HTML autofill tokens used in the application.
// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill-detail-tokens
export enum AccAutoFillTokens {
  // special cases for on/off 
  on = "on",                           // autofill is allowed, but no expected matching tokens
  off = "off",                         // no-autofill or data is sensitive

  name = "name",                       // full-name
  givenName = "given-name",            // first-name
  familyName = "family-name",          // last-name

  streetAddress = "street-address",
  addressLine1 = "address-line1",
  addressLine2 = "address-line2",
  addressLine3 = "address-line3",
  countryCode = "country",             // country code
  countryName = "country-name",
  postalCode = "postal-code",
  
  language = "language",

  bday = "bday",                       // birthday
  bdayDay = "bday-day",
  bdayMonth = "bday-month",
  bdayYear = "bday-year",

  sex = "sex",
  photo = "photo",

  tel = "tel",
  telExtension = "tel-extension",
  email = "email",
};

