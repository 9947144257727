import {store} from "../../app/store";
import { formatLanguageDate, formatLanguageDateTime, formatLanguageTime } from "../../data/languageData";
import { findBestAppointment, SingleApptState } from "../../features/appt/apptSlice";
import { PatientState } from "../../features/patient/patientSlice";

interface AemDynamicTokens {
  patient_first_name: string;
  provider_name: string; 
  location_name: string;
  location_phone: string; 
  location_address: string; 
  appt_date_time: string;
  appt_date: string;
  appt_time: string; 
};

export const collectAemTokensData = (): AemDynamicTokens => {
  const state: any = store.getState();
  const bestAppt: SingleApptState | undefined = findBestAppointment(state?.appt?.allAppointmentInfos);
  return collectAemTokensDataWithAppointment(bestAppt);
};

export const collectAemTokensDataWithAppointment = (appt: SingleApptState | undefined): AemDynamicTokens => {
  const state: any = store.getState();
  const patient: PatientState = state?.patient || {};
  const languageIsoCode: string = state?.preferences?.languageIsoCode || "";

  // convert data and times into language specific formats
  let date: Date | undefined = appt?.apptDateTime;
  let appt_date_time: string = formatLanguageDateTime(date, languageIsoCode);
  let appt_date: string = formatLanguageDate(date, languageIsoCode); 
  let appt_time: string = formatLanguageTime(date, languageIsoCode); 

  return {
    patient_first_name: patient.first_name || "",
    provider_name: appt?.providerName || "",
    location_name: appt?.location || "",
    location_phone: appt?.deptPhone || "",
    location_address: appt?.deptAddress || "",
    appt_date_time,
    appt_date,
    appt_time, 
  };
};

