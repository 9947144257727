import React from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { appColors } from "../theme";

const useStyles = makeStyles({
  stepper: {
    marginBottom: "20px",

    "&.centerVertically": {
      marginBottom: "0",
    },
  },
  stepBase: {
    height: "5px",
    width: "50px",
    borderRadius: "3px",
    marginRight: "5px",
    backgroundColor: appColors.white,
    opacity: 0.3,
  },
  stepFilled: {
    opacity: 1,
  },
});

interface ProgressStepperProps {
  step: number;                 // <= 0 means there are no completed steps
  totalSteps: number;           // the total number of expected steps
  centerVertically?: boolean;   // if true, then bottom margin will be omitted
}

/**
 * This is a horizontal dash step progress indicator.
 * It is a readonly component which can show the number of completed 
 * steps and the total number of steps with filled and empty sections.
 * 
 * example usage: 
 *   <ProgressStepper step={2} totalSteps={4} />
 */
const ProgressStepper: React.FC<ProgressStepperProps> = (props: ProgressStepperProps) => {
  const classes = useStyles();
  
  let { step, totalSteps, centerVertically } = props;
  if (step >= 0 && totalSteps < step) { totalSteps = step; }

  let steps: boolean[] = [];
  for (let i=0; i<totalSteps; i++) { 
    let filled: boolean = ((i+1) <= step);
    steps.push(filled);
  }

  return (
    <Grid container direction="row" className={clsx(classes.stepper, { "centerVertically": centerVertically } )}>
      {steps.map((filled, idx) => (
        <Grid key={idx} item className={clsx(classes.stepBase, { [classes.stepFilled]: filled })}></Grid>
      ))}      
    </Grid>
  );
};

ProgressStepper.defaultProps = {
  step: 0,
  totalSteps: 1,
  centerVertically: false,
};

export default ProgressStepper;
