import React, { useState } from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BackButton, NavButton } from "../../Components/Buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  InsuranceCoverageItem,
  updateInsuranceCoverage1BackCardImage,
  updateInsuranceCoverage1FrontCardImage,
} from "../../features/insurance/insuranceSlice";
import { 
  CaptureImage, 
  PhotoOrientations,
  ClickPhotoButtons,
  PhotoRetakeScreen
} from "../../Components/CaptureImage";
import { appFonts, useAppTheme } from "../../theme";
import Header from "../../Components/Header";
import ProgressStepper from "../../Components/ProgressStepper";     
import { 
  gotoTaskRoute, 
  setTaskTransitionPending,
} from "../../features/task/taskSlice";
import { UpdateInsuranceCoverageAPI } from "../../api/UpdateFhirAPI";
import Aem, { AemContentTypes } from "../../lib/aem/components/Aem";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  headerArea: {
    paddingTop: "24px",
    paddingBottom: "24px", 
  },
  headerTitle: {
    marginTop: "0px",
  },
  clickPhotoButton: {
    marginTop: "12px",
    marginBottom: "16px",
  },
  imagePresent: {
    marginBottom: "5%",
    maxHeight: "105px",
    maxWidth: "200px",
    transform:"translate(-5%,13%)"
  },
  pgFunc: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  headerText: {
    marginTop: "24px",
    marginBottom: "20px",
    fontFamily: appFonts.medium,
    fontSize: "18px",
  },
  infoText: {
    marginTop: "24px",
    marginBottom: "20px",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    textAlign: 'center',
  },
  photoIdText:{
    marginTop: "24px",
    marginBottom: "20px",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    color:"#ff612b !important",
  },
  retakeSection: {
    marginTop: "5%",
    border: "1px solid rgba(255,255,255,0.2)",
    backgroundColor: "rgba(0,0,0,0.2)",
    width: "100%",
    height: "210px",
    paddingLeft: "20px",
  },
  retakePhotoSection: {
    display: "flex",
  },
   alignCenter: {
    alignItems: "center",
   },
}));

const InsuranceCardScreen: React.FC = () => {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();

  const patientId: string = useAppSelector<string>((state) => state.patient.patientId);
  const coverage1: InsuranceCoverageItem = useAppSelector((state) => state.insurance.coverage1);
  const hasCoverage2: boolean = useAppSelector<boolean>((state) => state.insurance.hasCoverage2);

  const [frontImage, setFrontImage] = useState<string>(coverage1.insuranceCardFrontImage || "");
  const [backImage, setBackImage] = useState<string>(coverage1.insuranceCardBackImage || "");
  const [pendingFrontImage, setPendingFrontImage] = useState<string>("");
  const [pendingBackImage, setPendingBackImage] = useState<string>("");
  
  const [orientation, setOrientation] = useState<PhotoOrientations>(PhotoOrientations.Front);
  const [showCameraScreen, setShowCameraScreen] = useState<boolean>(false);
  const [showPhotoRetakeScreen, setShowPhotoRetakeScreen] = useState(false);

 

  const frontTitle: string = Aem.get(
    "BODY_PRIMARYINSURANCE_PHOTO_TEXT_1",
    "Front of Insurance Card"
  );
  const backTitle: string = Aem.get(
    "BODY_PRIMARYINSURANCE_PHOTO_TEXT_2",
    "Back of Insurance Card"
  );
  const photoTitle: string = Aem.get(
    "BODY_PRIMARYINSURANCE_PHOTO_TEXT_3",
    "Take Photo"
  );
  const uploadTitle: string = Aem.get(
    "BODY_PRIMARYINSURANCE_UPLOAD_TEXT_1",
    "Upload Photo"
  );
  const navRetakePhotoButtonLabel: string = Aem.get(
    "ACTION_RETAKEPHOTOBUTTON_TEXT_1",
    "Retake Photo"
  );
  const navReuploadPhotoButtonLabel: string = Aem.get(
    "ACTION_REUPLOADPHOTOBUTTON_TEXT_1",
    "Reupload Photo"
  );

  const cancelLabel: string = Aem.get("ACTION_CANCELBUTTON_TEXT_1", "Cancel");

  const updateImages = async () => {
    if (frontImage) {
      dispatch(updateInsuranceCoverage1FrontCardImage(frontImage));
    } 
    if (backImage) {
      dispatch(updateInsuranceCoverage1BackCardImage(backImage));
    }
  };

  const moveToPrevStep = async () => {
    // skip the pending route if it exists
    await dispatch(gotoTaskRoute("reviewPatient/insurance"));
  };

  const moveToNextStep = async () => {
    // skip the pending route if it exists
    if (!hasCoverage2) {
      await dispatch(gotoTaskRoute("reviewPatient/reviewInsuranceCopayBalance"));
    } else {
      await dispatch(gotoTaskRoute("reviewPatient/cardImage2"));
    }
  };

  const updateDetails = async () => {
    let photoIdentifiers: any[] | null = [];
    if (frontImage) {
      photoIdentifiers.push({
        imageContents: frontImage,
        title: "PrimaryInsuranceFront",
      });
    }
    if (backImage) {
      photoIdentifiers.push({
        imageContents: backImage,
        title: "PrimaryInsuranceBack",
      });
    }
    if (photoIdentifiers.length === 0) { 
      photoIdentifiers = null; 
    }

    const body = {
      patientId,
      insuranceCardImages: photoIdentifiers,
      order: 1,
    };
    await UpdateInsuranceCoverageAPI(body);
  };

  const hasPendingImageByOrientation = (): boolean => {
    return (orientation === PhotoOrientations.Front) ? !!pendingFrontImage : !!pendingBackImage;
  };


const setPendingImageByOrientation = (imageBase64: string): void => {

       if (orientation === PhotoOrientations.Front) {
             setPendingFrontImage(imageBase64);

       } else if (orientation === PhotoOrientations.Back) {
             setPendingBackImage(imageBase64);
       }
};

  const commitPendingPhotoByOrientation = (value: boolean): void => {
    if (orientation === PhotoOrientations.Front) { 
      if (value && pendingFrontImage) {
        setFrontImage(pendingFrontImage);
      }
      setPendingFrontImage("");
    } else if (orientation === PhotoOrientations.Back) { 
      if (value && pendingBackImage) { 
        setBackImage(pendingBackImage);
      }
      setPendingBackImage("");
    }
  };

const uploadImage = (event) => {
    if (event.target.files.length === 0) {
        return; 
    }

    const file = event.target.files[0];

    if (event.target.id === 'file-inputfront') {
        setFrontImage(URL.createObjectURL(file));
    } else if (event.target.id === 'file-inputback') {
        setBackImage(URL.createObjectURL(file));
    }
};

 

  const showCameraComponent = () => {
    if (showCameraScreen) {
      return (
        <CaptureImage
          title={orientation === PhotoOrientations.Front ? frontTitle : backTitle}
          orientation={orientation}
          cancelLabel={cancelLabel}
          onChangeShowCaptureScreen={(value) => setShowCameraScreen(value)}
          onChangeShowRetakeScreen={(value) => setShowPhotoRetakeScreen(value)}
          onSetImage={(imageBase64) => setPendingImageByOrientation(imageBase64 || "")}
          onCancel={() => {
            setShowCameraScreen(false);
            if (hasPendingImageByOrientation()) {
              setShowPhotoRetakeScreen(true);
            }
          }}
        />
      );
    } else if (showPhotoRetakeScreen) {
      return (
        <PhotoRetakeScreen 
          title={orientation === PhotoOrientations.Front ? frontTitle : backTitle}
          orientation={orientation} 
          cancelLabel={cancelLabel} 
          imageSrc={orientation === PhotoOrientations.Front ? pendingFrontImage : pendingBackImage}
          onChangeShowCaptureScreen={(value) => setShowCameraScreen(value)}     
          onChangeShowRetakeScreen={(value) => setShowPhotoRetakeScreen(value)}
          onSaveCapturedPhoto={(value) => commitPendingPhotoByOrientation(value)}
          onCancel={() => {
            setPendingImageByOrientation("");
            setShowPhotoRetakeScreen(false);
          }}
        />
      );
    } else {
      return (
        <Grid container direction="column" className={classes.root}>
          <Grid
            container
            direction="column"
            className={clsx(theme.navContent, theme.nowrap)}
          >
            <Grid
              container
              direction="column"
              className={clsx(theme.navHeader, theme.bgGreenGrad)}
            >
              <Header showMenuButton={true} />

              <ProgressStepper step={3} totalSteps={4} />

              <Grid container className={classes.pgFunc}>
                <Grid item xs={8}>
                  <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
                    <Aem cid="HEADER_PRIMARYINSURANCEPHOTO_TEXT_1">
                      Primary Insurance Card
                    </Aem>
                  </h1>
                </Grid>
                <Grid item>
                  <Aem cid="HEADER_INSURANCE_PRIMARY_PHOTOID" type={AemContentTypes.imageUrl}></Aem>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              className={clsx(theme.navSection, classes.headerArea)}
            >
              <Grid
                item
                className={clsx(theme.headerText, classes.headerText)}
              >
                <Aem cid="HEADER_PRIMARYINSURANCEPHOTO_TEXT_2">
                  Take a photo of your primary insurance card to save time at
                  our clinic.
                </Aem>
              </Grid>
              <Grid
                item
                className={clsx(theme.headerText, classes.photoIdText)}
              >
                <Aem cid="HEADER_PRIMARYINSURANCEPHOTO_TEXT_3">
                    This step is optional. If your insurance information has 
                    not changed since your last visit, you do not need to upload 
                    your insurance information. Please click 'Next'.
                </Aem>
              </Grid>

              <Grid 
                item
                className={clsx(theme.headerText, classes.infoText)}>
              <Aem cid="BODY_PRIMARYINSURANCE_PHOTO_TEXT_1">
                  Front Of Insurance Card
              </Aem>
              </Grid>
             

              <Grid >
               <input id="file-inputfront" type="file" accept=".jpg, .jpeg, .png" style={{display:"none"}} onChange={uploadImage}/>
              </Grid>
              {frontImage ?
                (
                  <Grid item className={classes.retakeSection}>
                    <Grid item className={clsx(theme.headerText, classes.headerText)}>
                      {frontTitle}
                    </Grid>
                    <Grid item className={classes.retakePhotoSection}>
                      <img
                        src={encodeURI(`${frontImage}`)}
                        className={classes.imagePresent}
                        alt="front of card"
                      >
                      </img>
                      <ClickPhotoButtons
                        retakeButtonCss={true}
                        label={navRetakePhotoButtonLabel}
                        label1={navReuploadPhotoButtonLabel}
                        disabled={false}
                        buttonAction={(showCamScreen) => {
                          setOrientation(PhotoOrientations.Front);
                          setShowCameraScreen(showCamScreen);
                        }}
                        orientation= {orientation}
                      />
                    </Grid>
                  </Grid>
                ) :
                (
                  <Grid item className={classes.clickPhotoButton}>
                    <ClickPhotoButtons
                      fullWidth={true}
                      label={photoTitle}
                      label1={uploadTitle}
                      disabled={false}
                      buttonAction={(showCamScreen) => {
                        setOrientation(PhotoOrientations.Front);
                        setShowCameraScreen(showCamScreen);
                      }}
                      orientation= {orientation}
                    />
                  </Grid>
                )
              }

             <Grid 
                item
                className={clsx(theme.headerText, classes.infoText)}>
              <Aem cid="BODY_PRIMARYINSURANCE_PHOTO_TEXT_2">
                  Back Of Insurance Card
              </Aem>
              </Grid>
              
              <Grid >
               <input id="file-inputback" type="file" accept=".jpg, .jpeg, .png" style={{display:"none"}} onChange={uploadImage}/>
              </Grid>

              {backImage ? (
                <Grid item className={classes.retakeSection}>
                  <Grid item className={clsx(theme.headerText, classes.headerText)}>
                    {backTitle}
                  </Grid>
                  <Grid item className={classes.retakePhotoSection}>
                    <img
                      src={encodeURI(`${backImage}`)}
                      className={classes.imagePresent}
                      alt="back of card"
                    >
                    </img>
                    <ClickPhotoButtons
                      retakeButtonCss={true}
                      label={navRetakePhotoButtonLabel}
                      label1={navReuploadPhotoButtonLabel}
                      disabled={false}
                      buttonAction={(showCamScreen) => {
                        setOrientation(PhotoOrientations.Back);
                        setShowCameraScreen(showCamScreen);
                      }}
                      orientation= {orientation}
                    />
                  </Grid>
                </Grid>
              ) :
                (<Grid item className={classes.clickPhotoButton}>
                  <ClickPhotoButtons
                    fullWidth={true}
                    label={photoTitle}
                    label1={uploadTitle}
                    disabled={false}
                    buttonAction={(showCamScreen) => {
                      setOrientation(PhotoOrientations.Back);
                      setShowCameraScreen(showCamScreen);
                    }}
                    orientation= {orientation}
                  />
                </Grid>
                )
              }
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            className={clsx(theme.navStickyFooter, classes.alignCenter)}
          >
            <Grid item xs={3}>
              <BackButton
                trackName="prev"
                trackLocation="nav footer"
                onClick={async () => {
                  dispatch(setTaskTransitionPending(true));
                  await updateImages();
                  await updateDetails();                    
                  await moveToPrevStep();
                }} 
              />
            </Grid>
            <Grid item xs={6}>
              <NavButton
                accentColor={"green"}
                label={Aem.get("ACTION_NEXTBUTTON_TEXT_1", "Next")}
                fullWidth={true}
                trackName="next"
                trackLocation="nav footer"
                onClick={async () => {
                  dispatch(setTaskTransitionPending(true));
                  await updateImages();
                  await updateDetails();
                  await moveToNextStep();
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Grid container direction="row" className={classes.root}>
      {showCameraComponent()}
    </Grid>
  );
};

export default InsuranceCardScreen;
